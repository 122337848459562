import { IconPropsType } from 'customTypes/icons';

interface ISearchBoxCrossProps extends IconPropsType {}

const SearchBoxCross = ({
  size = 16,
  fill = 'var(--blue-grey)',
  ...props
}: ISearchBoxCrossProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none">
      <circle cx={8} cy={8} r={8} fill="#F1F1F1" />
      <path
        fill={fill}
        d="m10.658 11.333.675-.675L8.676 8l2.657-2.658-.675-.675L8 7.324 5.342 4.667l-.675.675L7.324 8l-2.657 2.658.675.675L8 8.676z"
      />
    </g>
  </svg>
);

export default SearchBoxCross;
