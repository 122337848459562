import { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import Dropdown from 'components/Dropdown';
import ActiveApprovedFilterView from 'components/Filters/ActiveApprovedFilter';
import FilterSearchBar from 'components/Filters/ActiveApprovedFilter/FilterSearchBar';
import CatalogueFilterMaterial from 'components/Filters/catalogueFilterMaterial';
import CategoryFilterView from 'components/Filters/CategoryFilter';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import GenderFilter from 'components/Filters/GenderFilter';
import DesignTypeFilter from 'components/Filters/DesignTypeFilter';
import DesignSourceFilter from 'components/Filters/DesignSourceFilter';
import MoreFilterView from 'components/Filters/MoreFilterModal';
import SideDrawer from 'library/SideDrawer';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { getQueryParams } from '../../AddCatalogList/helpers';
import ICONOGRAPHY from 'CustomConstant/icons';
import { ACTIVITY_STATUS_OPTIONS, APPROVAL_STATUS_OPTIONS } from '../constants';
import { FilterSlugType } from '../../AddCatalogList/types';
import * as Styled from './styles';

const { filter: FilterIcon } = ICONOGRAPHY;

interface ISecondaryFilters {
  search: URLSearchParams;
  handleFilterChange(
    params: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const PrimaryFiltersView = ({
  search,
  handleFilterChange,
}: ISecondaryFilters) => {
  const selectedProductIds = useTypedSelector(
    state => state.catalog.selectedProductIds
  );
  const supplierOptions = useTypedSelector(
    state => state.catalog.filters.supplier
  );
  const isSupplier = useTypedSelector(state => state.user.is_supplier);
  const selectedItemsCount = selectedProductIds.size;
  const { search: queryParams } = useLocation();
  const currentQueryParams = getQueryParams(queryParams);

  const [isCategoryFilterOpen, categoryFilterActions] = useBoolean();
  const [isMoreFilterOpen, moreFilterActions] = useBoolean();
  const [isFabricFilterOpen, fabricFilterActions] = useBoolean();
  const [isApprovedActiveFilterOpen, approvedActivefilterActions] =
    useBoolean();
  const [isGenderFilterOpen, genderFilterActions] = useBoolean();
  const [isDesignTypeFilterOpen, designTypeFilterActions] = useBoolean();
  const [isDesignSourceFilterOpen, designSourceFilterActions] = useBoolean();

  function handleDropdownChange(key: string, value: string) {
    handleFilterChange({
      [key]: value === '' ? [] : [value],
    });
  }

  return (
    <Styled.FiltersContainer className="pos-r align-center">
      <Styled.FiltersContainerLeftPart className="d-flex full-height">
        {!isSupplier && (
          <Styled.DropdownWrapper>
            <Styled.DropdownWrapperTitle>Supplier</Styled.DropdownWrapperTitle>
            <Dropdown
              options={supplierOptions}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleDropdownChange('supplier_id', event.target.value)
              }
              value={search.get('supplier_id')}
              dataname="supplier_id"
              datalabel="All"
              dropdownClass="dropdown-class"
              dropdownBoxClass="dropdownBox-class"
              dropdownItemClass="dropdown-item-class"
              openClass="openClass"
              itemClass="itemClass"
              isSearchBox
            />
          </Styled.DropdownWrapper>
        )}
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Category</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={
              currentQueryParams?.category &&
              currentQueryParams?.category.length
            }
            isFilterOpen={isCategoryFilterOpen}
            toggleFilter={() => categoryFilterActions.toggle()}
            placeholder="Select"
          />

          {isCategoryFilterOpen && (
            <ClickOutside
              onClose={() => categoryFilterActions.off()}
              disableClickHandler={false}
            >
              <CategoryFilterView
                onClose={() => {
                  categoryFilterActions.off();
                }}
                onFilterChange={handleFilterChange}
              />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Material</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={
              currentQueryParams?.materials &&
              currentQueryParams?.materials.length
            }
            isFilterOpen={isFabricFilterOpen}
            toggleFilter={() => fabricFilterActions.toggle()}
            placeholder="Select"
          />
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>
            Approved/Active
          </Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isApprovedActiveFilterOpen}
            toggleFilter={() => approvedActivefilterActions.toggle()}
            placeholder="Select"
          />
          {isApprovedActiveFilterOpen && (
            <ClickOutside
              onClose={() => approvedActivefilterActions.off()}
              disableClickHandler={false}
            >
              <ActiveApprovedFilterView
                options={{
                  activityOption: ACTIVITY_STATUS_OPTIONS,
                  approvalOption: APPROVAL_STATUS_OPTIONS,
                }}
                onFilterChange={handleFilterChange}
              />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>

        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Gender</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isGenderFilterOpen}
            toggleFilter={() => genderFilterActions.toggle()}
            placeholder="Select"
          />
          {isGenderFilterOpen && (
            <ClickOutside
              onClose={() => genderFilterActions.off()}
              disableClickHandler={false}
            >
              <GenderFilter onFilterChange={handleFilterChange} />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Design Type</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isDesignTypeFilterOpen}
            toggleFilter={() => designTypeFilterActions.toggle()}
            placeholder="Select"
          />
          {isDesignTypeFilterOpen && (
            <ClickOutside
              onClose={() => designTypeFilterActions.off()}
              disableClickHandler={false}
            >
              <DesignTypeFilter onFilterChange={handleFilterChange} />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>
            Design Source
          </Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isDesignSourceFilterOpen}
            toggleFilter={() => designSourceFilterActions.toggle()}
            placeholder="Select"
          />
          {isDesignSourceFilterOpen && (
            <ClickOutside
              onClose={() => designSourceFilterActions.off()}
              disableClickHandler={false}
            >
              <DesignSourceFilter onFilterChange={handleFilterChange} />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.MoreFilter
            onClick={() => moreFilterActions.toggle()}
            className="space-around-center
            pos-r cursor-pointer "
          >
            <FilterIcon size={20} />
            <p>More Filter</p>
          </Styled.MoreFilter>
          <SideDrawer isOpen={isMoreFilterOpen} width="calc(25vw)">
            {isMoreFilterOpen && (
              <MoreFilterView
                onClose={() => moreFilterActions.off()}
                onFilterChange={handleFilterChange}
              />
            )}
          </SideDrawer>
        </Styled.DropdownWrapper>

        {isFabricFilterOpen && (
          <ClickOutside
            onClose={() => fabricFilterActions.off()}
            disableClickHandler={false}
          >
            <CatalogueFilterMaterial
              onClose={() => {
                fabricFilterActions.off();
              }}
              onFilterChange={handleFilterChange}
            />
          </ClickOutside>
        )}
      </Styled.FiltersContainerLeftPart>
    </Styled.FiltersContainer>
  );
};

export default PrimaryFiltersView;
