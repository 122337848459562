import { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import qs from 'qs';
import Modal from 'library/Modal';
import Toast from 'library/Toast';
import Button from 'library/Button';
import FashinzaIconLoader from 'library/IconLoader';
import { BsDownload, BsUpload } from 'react-icons/bs';
import FileUpload from 'components/FileUpload';
import SideMenu from 'components/SideMenu';
import TrendingModal from 'components/TrendingModal';
import FabricClusters from 'components/FabricClusters';
import SecondaryFilter from 'components/Filters/SecondaryFilters';
import HeaderFiltersView from 'components/Filters/HeaderFiltersView';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { getAddRuleDataThunk } from 'actions/ruleEngines';
import {
  fetchCatalogsThunk,
  fetchSuppliersThunk,
  addProductIds,
  removeProductIds,
  setUploadedSheetUrl,
  setPaginationInfo,
  fetchCatalogsSuccess,
  setSelectedFilters,
  fetchCategoriesThunk,
  fetchBrandsThunk,
  fetchAllLocationRegionThunk,
  setUploadModalVisbility,
  uploadUpdatedCatalogSheetThunk,
} from 'actions/catalog';
import { fetchAWSCredentialsThunk } from 'actions/credential';
import { setQueryStringValueToUrl } from 'utils/routing';
import { removeFalsyKeys } from 'utils/utility/utils';
import { uploadFileAws } from 'services/aws';
import { CATALOG_SHEET_FILE_TYPES } from 'components/AddCatalogue/constant';
import BottomButtonGroup from './BottomButtonGroup';
import ButtonGroupAbsolute from './ButtonGroupAbsolute';
import CategorySelector from './CategorySelector';
import Table from './Table';
import { getFields } from './Fields';
import PrimaryFiltersView from '../Filters/PrimaryFilterView';
import ShareableLink from '../ShareableLink';
import { areAllInactiveUnapproved, getQueryParams } from './helpers';
import { FilterSlugType } from './types';
import './tooltip.scss';
import * as Styled from './styles';

interface IAddCatalogListProps {}

const AddCatalogList = (props: IAddCatalogListProps) => {
  const {
    list,
    isFetchingProducts,
    shareableLink,
    catalogId,
    paginationInfo,
    uploadedSheetUrl,
    selectedProductIds,
    uploadModalVisibility,
    isTrendingModalOpen,
    isUpdatingSheetUrl,
  } = useTypedSelector(state => state.catalog);
  const isSupplier = useTypedSelector(state => state.user.is_supplier);
  const isAdmin = useTypedSelector(state => state.user.is_admin);
  const linkedSupplierId = useTypedSelector(
    store => store.user.linked_supplier
  )?.id;
  const credentials = useTypedSelector(
    store => store.awsCredentials.catalogExcel
  );
  const isFabricClusterSidebarOpen = useTypedSelector(
    state => state.ruleEngine.isFabricClusterSidebarOpen
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams(
    new URLSearchParams(
      linkedSupplierId ? { supplier_id: linkedSupplierId } : ''
    )
  );
  const [isShareableModalVisible, shareableModalAction] = useBoolean();
  const [isUploadingFile, setIsUploadingFile] = useBoolean();
  const [shareableLinkImage, setShareableLinkImage] = useState<string>('');
  const [shareableLinkIdentifier, setShareableLinkIdentifier] =
    useState<string>('');
  const [inactiveCounts, setInactiveCounts] = useState<number>(0);
  const { search: queryParams } = useLocation();
  const selectedItemsCount = selectedProductIds.size;

  const columns = useMemo(() => getFields(), []);

  const newColumns = isSupplier
    ? columns.filter(each => each.accessor !== 'added_by')
    : columns;

  useEffect(() => {
    let currentQueryParams = getQueryParams(queryParams);
    if (isSupplier) {
      currentQueryParams = {
        ...currentQueryParams,
        supplier_id: linkedSupplierId,
      };
    }
    const queryString1 = `${qs.stringify(currentQueryParams, {
      indices: false,
    })}`;
    dispatch(setSelectedFilters(currentQueryParams));
    dispatch(fetchCatalogsSuccess([]));
    dispatch(setPaginationInfo({ count: 0, totalPages: -1 }));
    dispatch(fetchCatalogsThunk(queryString1));
  }, [search, catalogId]);

  useEffect(() => {
    dispatch(fetchAWSCredentialsThunk());
    dispatch(fetchSuppliersThunk());
    dispatch(fetchBrandsThunk());
    dispatch(fetchCategoriesThunk());
  }, []);

  useEffect(() => {
    if (!isSupplier) {
      dispatch(getAddRuleDataThunk());
      dispatch(fetchAllLocationRegionThunk());
    }
  }, []);

  useEffect(() => {
    if (!uploadModalVisibility) {
      dispatch(setUploadedSheetUrl(''));
    }
  }, [dispatch, uploadModalVisibility]);

  function onSelectRowHeader(
    productIds: Array<string>,
    isAllRowsSelected: boolean
  ) {
    if (isAllRowsSelected) {
      dispatch(addProductIds([...productIds]));
    } else {
      dispatch(removeProductIds([...productIds]));
    }
  }

  function onSelectRow(productId: string, isSelected: boolean) {
    if (isSelected) {
      dispatch(addProductIds([productId]));
    } else {
      dispatch(removeProductIds([productId]));
    }
  }

  async function onClickShareDesign() {
    const selectedProducts = Array.from(selectedProductIds);
    const shouldNotMakeApiCall = areAllInactiveUnapproved(
      list,
      selectedProducts
    );
    if (shouldNotMakeApiCall) {
      setInactiveCounts(() => selectedProducts.length);
      shareableModalAction.toggle();
      return;
    }
    shareableModalAction.toggle();

    let imageUrl =
      'https://fashinza-media.s3.ap-south-1.amazonaws.com/default-img-rfq.svg';
    for (let i = 0; i < list.length; i += 1) {
      const item = list[i];
      if (item.product_id === selectedProducts[0]) {
        imageUrl = item.images[0].url;
        break;
      }
    }
    setShareableLinkImage(imageUrl);
  }

  function fetchNextSetOfProducts() {
    if (isFetchingProducts) {
      return;
    }
    let currentQueryParams = getQueryParams(queryParams);
    if (isSupplier) {
      currentQueryParams['supplier_id'] = linkedSupplierId;
    }
    const queryString = `${qs.stringify(currentQueryParams, {
      indices: false,
    })}`;
    dispatch(fetchCatalogsThunk(queryString));
  }

  function handleFilterChange(
    param: Partial<Record<FilterSlugType, Array<any>>>,
    reset: boolean = false
  ) {
    const prevQueryParams = getQueryParams(queryParams);
    let queryParamObject = {};
    if (!reset) {
      queryParamObject = {
        ...prevQueryParams,
        ...param,
      };
    }
    const sanitizedFilters = removeFalsyKeys(queryParamObject);
    const queryString = setQueryStringValueToUrl(sanitizedFilters);
    setSearch(new URLSearchParams(queryString));
  }

  function toggleModalVisibility() {
    dispatch(setUploadModalVisbility(!uploadModalVisibility));
  }

  async function updateSheetLink() {
    const data = {
      sheet_url: uploadedSheetUrl,
    };

    try {
      const [status, message]: any = await dispatch(
        uploadUpdatedCatalogSheetThunk(data)
      );

      if (status) {
        Toast.SUCCESS(message);
      } else {
        Toast.ERROR(message);
      }

      setSearch(new URLSearchParams());
      dispatch(fetchCatalogsSuccess([]));
      dispatch(setPaginationInfo({ count: 0, totalPages: -1 }));
      dispatch(fetchCatalogsThunk(''));
      dispatch(setUploadModalVisbility(false));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }

      dispatch(setUploadModalVisbility(false));
    }
  }

  async function fileUploadHandler(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files === null) {
      return;
    }
    setIsUploadingFile.on();
    const file = files[0];
    try {
      const response = await uploadFileAws({ file, credentials });
      setIsUploadingFile.off();
      dispatch(setUploadedSheetUrl(response.url));
    } catch (error) {
      setIsUploadingFile.off();

      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  }

  return (
    <>
      {isTrendingModalOpen && (
        <SideMenu>
          <TrendingModal />
        </SideMenu>
      )}
      {isFabricClusterSidebarOpen && (
        <SideMenu minWidth="680px" width="50vw">
          <FabricClusters />
        </SideMenu>
      )}
      {uploadModalVisibility && (
        <Modal
          heading="Upload a file"
          onClose={toggleModalVisibility}
          footer={
            <Styled.ModalFooterWrapper>
              <Button
                variant="primary"
                size="large"
                isLoading={isUpdatingSheetUrl}
                onClick={updateSheetLink}
                isDisabled={uploadedSheetUrl.length === 0}
              >
                Save
              </Button>
            </Styled.ModalFooterWrapper>
          }
        >
          <Styled.UploadButtonMsg className="full-width">
            Upload a file to update
          </Styled.UploadButtonMsg>

          <Styled.UploadButtonMsg className="full-width">
            ({CATALOG_SHEET_FILE_TYPES.join(', ')})
          </Styled.UploadButtonMsg>
          <Styled.UploadButtonWrapper className="full-width">
            <FileUpload
              accept={CATALOG_SHEET_FILE_TYPES.join(',')}
              onChange={event => fileUploadHandler(event)}
              className="upload-excel-button"
            >
              {isUploadingFile ? (
                <Styled.UploadSpinnerWrapper>
                  <FashinzaIconLoader show />
                </Styled.UploadSpinnerWrapper>
              ) : (
                <BsUpload fill="var(--white)" />
              )}
              &nbsp;Upload Updated Sheet
            </FileUpload>
          </Styled.UploadButtonWrapper>

          <Styled.FileNameDisplay
            className="cursor-pointer truncated-text"
            href={uploadedSheetUrl}
          >
            {uploadedSheetUrl.length > 0 && <BsDownload size="1em" />}
            &nbsp; &nbsp;
            {uploadedSheetUrl.split('public/')[1]}
          </Styled.FileNameDisplay>
        </Modal>
      )}
      <Styled.Wrapper className="full-view-width full-height v-d-flex">
        {isShareableModalVisible && (
          <ShareableLink
            onClose={() => {
              shareableModalAction.toggle();
            }}
            link={shareableLink}
            linkIdentifier={shareableLinkIdentifier}
            listCount={list.length}
            imageLink={shareableLinkImage}
            selectedCount={selectedItemsCount}
            inactiveCounts={inactiveCounts}
          />
        )}
        <CategorySelector />
        <ButtonGroupAbsolute />
        <div>
          <PrimaryFiltersView
            search={search}
            handleFilterChange={handleFilterChange}
          />
          <SecondaryFilter onFiltersChange={handleFilterChange} />
          <HeaderFiltersView onFilterChange={handleFilterChange} />
        </div>
        <Styled.Count className="spread">
          <Styled.AllStyles>
            {`All Styles - ${paginationInfo.count}`}
          </Styled.AllStyles>
          <Styled.SelectedStyles>
            {selectedItemsCount > 0 && (
              <Styled.SelectedCountIndicator>
                {selectedItemsCount} style
                {selectedItemsCount > 1 ? 's' : ''} selected
              </Styled.SelectedCountIndicator>
            )}
          </Styled.SelectedStyles>
        </Styled.Count>
        <Styled.TableContainer className="auto-x-scroll auto-y-scroll">
          <Table
            isLoading={isFetchingProducts}
            columns={newColumns}
            data={list}
            toggleAllRows={Array.from(selectedProductIds).length === 0}
            hasCheckbox={isAdmin || isSupplier}
            onSelectRowHeader={onSelectRowHeader}
            onSelectRow={onSelectRow}
            fetchNextSetOfProducts={fetchNextSetOfProducts}
            selectedRowIds={Array.from(selectedProductIds).reduce(
              (accumulator, currentProductId) => ({
                ...accumulator,
                [currentProductId]: true,
              }),
              {}
            )}
            autoResetSelectedRows={false}
          />
        </Styled.TableContainer>
      </Styled.Wrapper>
      <BottomButtonGroup
        selectedProductIds={selectedProductIds}
        search={search}
      />
    </>
  );
};

export default AddCatalogList;
