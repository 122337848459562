import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllAdminsThunk,
  getCategoryManagerAndSalesRepThunk,
} from 'actions/catalog';
import AddCatalogList from 'components/AddCatalogList';
import Collections from 'components/Collections';
import ShareCatalogList from 'components/ShareCatalogList';
import RuleEngineContainer from 'components/RuleEngineCollection';
import DesignLibrary from 'components/DesignLibrary';
import Trends from 'components/Trends';
import FashinzaIconLoader from 'library/IconLoader';
import { useTypedSelector } from 'utils/hooks';

type CatalogViewPropsType = {};

const CatalogViewer = ({}: CatalogViewPropsType) => {
  const { tabType } = useParams() as { tabType: string };

  const loaderStatus = useTypedSelector(store => store.catalog.loaderStatus);
  const isSupplier = useTypedSelector(state => state.user.is_supplier);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSupplier) {
      dispatch(getAllAdminsThunk());
      dispatch(getCategoryManagerAndSalesRepThunk());
    }
  }, []);

  function getBaseComponent() {
    switch (tabType) {
      case 'sharedCatalogs':
        return <ShareCatalogList />;
      case 'collections':
        return <Collections />;
      case 'ruleEngineContainer':
        return <RuleEngineContainer />;
      case 'designLibrary':
        return <DesignLibrary />;
      case 'trends':
        return <Trends />;
      default:
        return <AddCatalogList />;
    }
  }

  return (
    <>
      {getBaseComponent()}
      {loaderStatus && <FashinzaIconLoader show />}
    </>
  );
};

export default CatalogViewer;
