import { TOKEN } from 'config';

export function getFromStorage(key: string): string | null {
  return localStorage.getItem(key);
}

export function setInStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function removeItemFromStorage(key: string) {
  localStorage.removeItem(key);
}

export function clearStorages() {
  removeItemFromStorage(TOKEN);
}
