import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FilterSearchBar from 'components/Filters/ActiveApprovedFilter/FilterSearchBar';
import Toast from 'library/Toast';
import MultiSelect from 'library/MultiSelect';
import CategoryFilterView from 'components/Filters/CategoryFilter';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import GenderFilter from 'components/Filters/GenderFilter';
import RegionFilter from 'components/Filters/RegionFilter';
import RetailersFilter from 'components/Filters/RetailersFilter';
import { useBoolean } from 'utils/hooks/useBoolean';
import { useTypedSelector } from 'utils/hooks';
import { setTrendsDates } from 'actions/trend';
import { getTrendDates } from 'utils/api';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import { RequiredFilters } from '../constants';
import * as Styled from './styles';

interface ISecondaryFilters {
  search: URLSearchParams;
  handleFilterChange(
    params: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const Filters = ({ search, handleFilterChange }: ISecondaryFilters) => {
  const { search: queryParams } = useLocation();
  const dispatch = useDispatch();
  const trendsDate = useTypedSelector(state => state.trends.trendsDates);
  const currentQueryParams = getQueryParams(queryParams);
  const [isCategoryFilterOpen, categoryFilterActions] = useBoolean();
  const [isRetailersFilterOpen, retailersFilterActions] = useBoolean();
  const [isRegionFilterOpen, regionFilterActions] = useBoolean();
  const [isGenderFilterOpen, genderFilterActions] = useBoolean();
  const [dateData, setDateData] = useState([]);

  useEffect(() => {
    async function getTrendsDatesData() {
      if (
        RequiredFilters.every(element =>
          Object.keys(currentQueryParams).includes(element)
        )
      ) {
        const dateEndpoint = `${currentQueryParams.retailer[0]}/${currentQueryParams.category[0]}/${currentQueryParams.gender[0]}/${currentQueryParams.region[0]}`;
        try {
          const response = await getTrendDates(dateEndpoint);
          if (!response.status) {
            Toast.ERROR(response.msg);
            return;
          }
          dispatch(setTrendsDates(response.data));
          setDateData(response.data);
          if (response.data.length > 0) {
            handleFilterChange({
              date: [response.data[0]],
            });
          }
          if (response.data.length === 0) {
            Toast.INFO('No date data for currently selected filters');
          }
        } catch (error) {
          if (error instanceof Error) {
            Toast.ERROR(error.message);
          }
        }
      } else {
        handleFilterChange({
          date: [],
        });
      }
    }

    getTrendsDatesData();
  }, [
    RequiredFilters.every(element =>
      Object.keys(currentQueryParams).includes(element)
    ),
  ]);

  return (
    <Styled.FiltersContainer className="pos-r align-center">
      <Styled.FiltersContainerLeftPart className="d-flex full-height">
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Category</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={
              currentQueryParams?.category &&
              currentQueryParams?.category.length
            }
            isFilterOpen={isCategoryFilterOpen}
            toggleFilter={() => categoryFilterActions.toggle()}
            placeholder="Select"
          />

          {isCategoryFilterOpen && (
            <ClickOutside
              onClose={() => categoryFilterActions.off()}
              disableClickHandler={false}
            >
              <CategoryFilterView
                onClose={() => {
                  categoryFilterActions.off();
                }}
                onFilterChange={handleFilterChange}
              />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Retailers</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isRetailersFilterOpen}
            toggleFilter={() => retailersFilterActions.toggle()}
            placeholder="Select"
          />
          {isRetailersFilterOpen && (
            <ClickOutside
              onClose={() => retailersFilterActions.off()}
              disableClickHandler={false}
            >
              <RetailersFilter onFilterChange={handleFilterChange} />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Gender</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isGenderFilterOpen}
            toggleFilter={() => genderFilterActions.toggle()}
            placeholder="Select"
          />
          {isGenderFilterOpen && (
            <ClickOutside
              onClose={() => genderFilterActions.off()}
              disableClickHandler={false}
            >
              <GenderFilter onFilterChange={handleFilterChange} />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        <Styled.DropdownWrapper>
          <Styled.DropdownWrapperTitle>Region</Styled.DropdownWrapperTitle>
          <FilterSearchBar
            value={0}
            isFilterOpen={isRegionFilterOpen}
            toggleFilter={() => regionFilterActions.toggle()}
            placeholder="Select"
          />
          {isRegionFilterOpen && (
            <ClickOutside
              onClose={() => regionFilterActions.off()}
              disableClickHandler={false}
            >
              <RegionFilter onFilterChange={handleFilterChange} />
            </ClickOutside>
          )}
        </Styled.DropdownWrapper>
        {RequiredFilters.every(element =>
          Object.keys(currentQueryParams).includes(element)
        ) && (
          <Styled.MultiSelectDropdownWrapper isDisabled={dateData.length === 0}>
            <Styled.DropdownWrapperTitle>Dates</Styled.DropdownWrapperTitle>
            <MultiSelect
              values={trendsDate}
              onChange={(value: string) => handleFilterChange({ date: value })}
              selectedValues={search.get('date') ? [search.get('date')] : []}
              showLabel={false}
              width={150}
            />
          </Styled.MultiSelectDropdownWrapper>
        )}
      </Styled.FiltersContainerLeftPart>
    </Styled.FiltersContainer>
  );
};

export default Filters;
