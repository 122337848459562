import { useTypedSelector } from 'utils/hooks';

export function useConditonsProperties() {
  const conditionsData = useTypedSelector(
    store => store.ruleEngine.conditionsData
  );

  return Object.keys(conditionsData).map((item: string) => ({
    name: conditionsData[item].attr_name,
    id: conditionsData[item].attr_name,
  }));
}

export function useCategoryManager() {
  const categoryManagersData = useTypedSelector(
    store => store.catalog.categoryManagersData
  );

  return categoryManagersData.map(item => ({
    id: item.id,
    name: item.full_name,
  }));
}

export function useSaleRepresentative() {
  const salesRepresentativesData = useTypedSelector(
    store => store.catalog.salesRepresentativesData
  );

  return salesRepresentativesData.map(item => ({
    id: item.id,
    name: item.full_name,
  }));
}

export function useSortingProperties() {
  const sortingData = useTypedSelector(store => store.ruleEngine.sortingsData);
  const SelectedSorting = useTypedSelector(
    store => store.ruleEngine.ruleStaging.sortings
  );

  const selectedAttribute: Array<string> = Object.keys(SelectedSorting).map(
    (item: string) => {
      return SelectedSorting[item].attributeName;
    }
  );

  let AttributeToShow: Array<Record<string, string>> = Object.keys(
    sortingData
  ).map((item: string) => ({
    name: sortingData[item].attr_name,
    id: sortingData[item].attr_name,
  }));

  return AttributeToShow.filter(item => !selectedAttribute.includes(item.name));
}
