import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducer";

const middleware = applyMiddleware(thunk);

export const appStore = createStore(
  rootReducer,
  composeWithDevTools(middleware)
);
