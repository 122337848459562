import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import ICONOGRAPHY from 'CustomConstant/icons';
import { FilterTypes } from '../type';
import * as Styles from './styles';

const { 'magnifying-glass': MagnifyingGlass } = ICONOGRAPHY;

interface ILinkIdFilterProps {
  onFilterChange(param: Partial<Record<FilterTypes, Array<string>>>): void;
}

const LinkIdFilter = ({ onFilterChange }: ILinkIdFilterProps) => {
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const initialState = currentQueryParams?.link_identifier__in ?? [];
  const [selectedCheckbox, setSelectedCheckbox] = useState<string>(
    initialState.join()
  );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedCheckbox(event.target.value);
  }

  function handleSearchEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onFilterChange({
        link_identifier__in: [selectedCheckbox.replace(/\s+/g, '')],
      });
    }
  }

  return (
    <Styles.LinkIdWrapper className="align-center truncated-text">
      <MagnifyingGlass size={18} />
      <input
        value={selectedCheckbox}
        onChange={handleSearchChange}
        onKeyDown={handleSearchEnter}
        className="fit-height"
        placeholder="Search Link Ids"
      />
    </Styles.LinkIdWrapper>
  );
};

export default LinkIdFilter;
