import { ChangeEvent, Dispatch } from 'react';
import { useTypedSelector } from 'utils/hooks';
import Checkbox from 'library/checkbox/checkbox';
import { insertOrRemove } from 'utils/utility/utils';
import { ToggleFilterEventType } from 'components/AddCatalogList/types';
import * as Styles from './styles';

interface ILocationFilterProps {
  selectedLocations: Array<string>;
  onChange: (value: string) => void;
}

const LocationFilter = ({
  selectedLocations = [],
  onChange,
}: ILocationFilterProps) => {
  const dropdownOptions = useTypedSelector(
    store => store.catalog.allLocationRegionData
  );

  function handleCategoryCheck(
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    const updatedSelectedOptions = insertOrRemove(selectedLocations, value);
    onChange(updatedSelectedOptions);
  }

  return (
    <Styles.LocationFilterContainer
      onClick={(event: ToggleFilterEventType) => {
        event.stopPropagation();
      }}
      className="pos-a full-width fit-height"
    >
      {dropdownOptions?.map(item => (
        <Styles.FilterOption key={item.id}>
          <Checkbox
            item={{
              value: item.id,
            }}
            label={item.name}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleCategoryCheck(event, item.name)
            }
            checked={selectedLocations.includes(item.name)}
          />
        </Styles.FilterOption>
      ))}
    </Styles.LocationFilterContainer>
  );
};

export default LocationFilter;
