import * as Styles from './styles';

interface IButtonWithLoaderProps {
  text: string;
  onClick: () => void;
  showLoader: boolean;
  isDisabled: boolean;
  className?: string;
}

const ButtonWithLoader = ({
  text,
  onClick,
  showLoader = false,
  isDisabled = false,
  className = '',
}: IButtonWithLoaderProps) => (
  <Styles.Button
    className={`center ${className}`}
    type="button"
    onClick={onClick}
    data-with-loader={showLoader}
    disabled={isDisabled || showLoader}
  >
    {showLoader ? <Styles.Loader /> : <p>{text}</p>}
  </Styles.Button>
);

export default ButtonWithLoader;
