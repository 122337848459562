const nsp = 'catalog/';

const CATALOG_ACTION_TYPES = {
  FETCH_EXCEL_TEMPLATE_CATEGORIES_REQUEST: `${nsp}FETCH_EXCEL_TEMPLATE_CATEGORIES_REQUEST`,
  FETCH_EXCEL_TEMPLATE_CATEGORIES_SUCCESS: `${nsp}FETCH_EXCEL_TEMPLATE_CATEGORIES_SUCCESS`,
  FETCH_EXCEL_TEMPLATE_CATEGORIES_FAILURE: `${nsp}FETCH_EXCEL_TEMPLATE_CATEGORIES_FAILURE`,
  UPLOAD_CATALOGUE_SHEET_REQUEST: `${nsp}UPLOAD_CATALOGUE_SHEET_REQUEST`,
  UPLOAD_CATALOGUE_SHEET_FAILURE: `${nsp}UPLOAD_CATALOGUE_SHEET_FAILURE`,
  UPLOAD_CATALOGUE_SHEET_SUCCESS: `${nsp}UPLOAD_CATALOGUE_SHEET_SUCCESS`,
  UPLOAD_CATALOGUE_SHEET_IDLE: `${nsp}UPLOAD_CATALOGUE_SHEET_IDLE`,
  FETCH_CATALOGS_REQUEST: `${nsp}FETCH_CATALOGS_REQUEST`,
  FETCH_CATALOGS_SUCCESS: `${nsp}FETCH_CATALOGS_SUCCESS`,
  FETCH_DOWNLOAD_ALL_PRODUCT: `${nsp}FETCH_DOWNLOAD_ALL_PRODUCT`,
  SET_SHAREABLE_CATALOGS_COUNT: `${nsp}SET_SHAREABLE_CATALOGS_COUNT`,
  SET_LOADER_STATUS: `${nsp}SET_LOADER_STATUS`,
  SET_ALL_ADMINS: `${nsp}SET_ALL_ADMINS`,
  SET_ALL_CATEGORY_MANAGERS_AND_SALESREPS: `${nsp}SET_ALL_CATEGORY_MANAGERS_AND_SALESREPS`,
  SET_TAB_ID: `${nsp}SET_TAB_ID`,
  SET_UPLOAD_MODAL_VISIBILITY: `${nsp}SET_UPLOAD_MODAL_VISIBILITY`,
  // FETCH_CATALOGS_FAILURE: `${nsp}FETCH_CATALOGS_FAILURE`,
  ADD_SELECTED_PRODUCT_IDS: `${nsp}ADD_SELECTED_PRODUCT_IDS`,
  REMOVE_SELECTED_PRODUCT_IDS: `${nsp}REMOVE_SELECTED_PRODUCT_IDS`,
  SET_IS_DATA_PAGINATED: `${nsp}SET_IS_DATA_PAGINATED`,
  SET_PAGINATION_INFO: `${nsp}SET_PAGINATION_INFO`,
  FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_REQUEST: `${nsp}FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_REQUEST`,
  FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_SUCCESS: `${nsp}FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_SUCCESS`,
  FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_FAILURE: `${nsp}FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_FAILURE`,
  SET_UPLOADED_SHEET_URL: `${nsp}SET_UPLOADED_SHEET_URL`,
  SET_IS_UPDATING_SHEET_URL: `${nsp}SET_IS_UPDATING_SHEET_URL`,
  FETCH_SUPPLIERS_REQUEST: `${nsp}FETCH_SUPPLIERS_REQUEST`,
  FETCH_SUPPLIERS_SUCCESS: `${nsp}FETCH_SUPPLIERS_SUCCESS`,
  FETCH_SUPPLIERS_FAILURE: `${nsp}FETCH_SUPPLIERS_FAILURE`,
  FETCH_MATERIALS_SUCCESS: `${nsp}FETCH_MATERIALS_SUCCESS`,
  FETCH_ALL_CATEGORIES_REQUEST: `${nsp}FETCH_ALL_CATEGORIES_REQUEST`,
  FETCH_ALL_CATEGORIES_SUCCESS: `${nsp}FETCH_ALL_CATEGORIES_SUCCESS`,
  FETCH_ALL_CATEGORIES_FAILURE: `${nsp}FETCH_ALL_CATEGORIES_FAILURE`,
  TOGGLE_IS_SET_INACTIVE_BUTTON_LOADING: `${nsp}TOGGLE_IS_SET_INACTIVE_BUTTON_LOADING`,
  TOGGLE_IS_APPROVE_BUTTON_LOADING: `${nsp}TOGGLE_IS_APPROVE_BUTTON_LOADING`,
  UPDATE_STATUS_OF_INDIVIDUAL_ITEMS: `${nsp}UPDATE_STATUS_OF_INDIVIDUAL_ITEMS`,
  SHAREABLE_LINK: `${nsp}SHAREABLE_LINK`,
  GET_ALL_SHAREABLE_CATALOGS: `${nsp}GET_ALL_SHAREABLE_CATALOGS`,
  FETCH_SUPPLIERS_AND_MATERIALS_SUCCESS: `${nsp}FETCH_SUPPLIERS_AND_MATERIALS_SUCCESS`,
  FETCH_MATERIALS_REQUEST: `${nsp}FETCH_MATERIALS_REQUEST`,
  FETCH_MATERIALS_FAILURE: `${nsp}FETCH_MATERIALS_FAILURE`,
  TOGGLE_IS_ARCHIVE_BUTTON_LOADING: `${nsp}TOGGLE_IS_ARCHIVE_BUTTON_LOADING`,
  SET_SELECTED_PRODUCTS_AS_ARCHIVED: `${nsp}SET_SELECTED_PRODUCTS_AS_ARCHIVED`,
  SET_SECONDARY_FILTERS_INFO: `${nsp}SET_SECONDARY_FILTERS_INFO`,
  SET_SECONDARY_SELECTED_FILTERS_INFO: `${nsp}SET_SECONDARY_SELECTED_FILTERS_INFO`,
  SET_SELECTED_FILTERS: `${nsp}SET_SELECTED_FILTERS`,
  SET_PRIMARY_FILTERS: `${nsp}SET_PRIMARY_FILTERS`,
  SET_CATALOG_ID: `${nsp}SET_CATALOG_ID`,
  TOGGLE_IS_ACTIVE_BUTTON_LOADING: `${nsp}TOGGLE_IS_ACTIVE_BUTTON_LOADING`,
  SET_FIRST_TIME_RELOAD: `${nsp}SET_FIRST_TIME_RELOAD`,
  TOGGLE_TRENDING_MODAL: `${nsp}TOGGLE_TRENDING_MODAL`,
  SET_TRENDING_CATEGORY_TITLE: `${nsp}SET_TRENDING_CATEGORY_TITLE`,
  SET_ENTERED_TAGS_FOR_CATEGORY: `${nsp}SET_ENTERED_TAGS_FOR_CATEGORY`,
  DELETE_ENTERED_TAG_FOR_CATEGORY: `${nsp}DELETE_ENTERED_TAG_FOR_CATEGORY`,
  SET_SELECTED_TRENDING_CATEGORY_DATA: `${nsp}SET_SELECTED_TRENDING_CATEGORY_DATA`,
  SUBMIT_TRENDING_CATEGORY_DATA_LOADER: `${nsp}SUBMIT_TRENDING_CATEGORY_DATA_LOADER`,
  CHANGE_TRENDING_CATEGORY_LOADER: `${nsp}CHANGE_TRENDING_CATEGORY_LOADER`,
  SET_BRANDS_DATA: `${nsp}SET_BRANDS_DATA`,
  SET_COLLECTION_DATA: `${nsp}SET_COLLECTION_DATA`,
  COLLECTION_PAGINATION_INFO: `${nsp}COLLECTION_PAGINATION_INFO`,
  SET_COLLECTION_URL: `${nsp}SET_COLLECTION_URL`,
  REMOVE_COLLECTION_FROM_ALL_COLLECTIONS: `${nsp}REMOVE_COLLECTION_FROM_ALL_COLLECTIONS`,
  UPDATE_COLLECTION: `${nsp}UPDATE_COLLECTION`,
  SET_SAVE_COLLECTION_LOADER: `${nsp}SET_SAVE_COLLECTION_LOADER`,
  SET_COLLECTION_SAVE_SUCCESS: `${nsp}SET_COLLECTION_SAVE_SUCCESS`,
  SET_LOCATION_REGION_DATA: `${nsp}SET_LOCATION_REGION_DATA`,
  SET_SELECTED_PRODUCTS_LOCATION: `${nsp}SET_SELECTED_PRODUCTS_LOCATION`,
  FETCH_QR_CODE_LOADER: `${nsp}FETCH_QR_CODE_LOADER`,
  UPDATE_IMAGES_IN_ORIGINAL_LIST: `${nsp}UPDATE_IMAGES_IN_ORIGINAL_LIST`,
  TOGGLE_SAVE_IMAGE_LOADER: `${nsp}TOGGLE_SAVE_IMAGE_LOADER`,
};

export default CATALOG_ACTION_TYPES;
