import Active from 'assets/icons/Active';
import AddIcon from 'assets/icons/AddIcon';
import AddIconWithOutline from 'assets/icons/AddIconWithOutline';
import Archive from 'assets/icons/Archive';
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import Catalog from 'assets/icons/Catalog';
import CheckCircle from 'assets/icons/CheckCircle';
import CheveronDownIcon from 'assets/icons/ChevronDownIcon';
import CheveronUpIcon from 'assets/icons/ChevronUpIcon';
import CloseIcon from 'assets/icons/Close';
import CopyIcon from 'assets/icons/CopyIcon';
import EditIcon from 'assets/icons/EditIcon';
import EmptyView from 'assets/icons/EmptyView';
import ExcelUploadFailure from 'assets/icons/ExcelUploadFailure';
import ExcelUploadInProgress from 'assets/icons/ExcelUploadInProgress';
import ExcelUploadSuccess from 'assets/icons/ExcelUploadSuccess';
import Fashinza from 'assets/icons/Fashinza';
import FilterIcon from 'assets/icons/FilterIcon';
import Funnel from 'assets/icons/Funnel';
import FZ from 'assets/icons/FZ';
import ImageLinkShirt from 'assets/icons/ImageLinkShirt';
import KebabIcon from 'assets/icons/Kebab';
import MagnifyingGlass from 'assets/icons/MagnifyingGlass';
import MainLogo from 'assets/icons/MainLogo2';
import QrCodeIcon from 'assets/icons/QrCodeIcon';
import SearchBoxCross from 'assets/icons/SearchBoxCross';
import VerticalDotsIcon from 'assets/icons/VerticalDotsIcon';
import TrashIcon from 'assets/icons/TrashIcon';
import UploadIcon from 'assets/icons/Upload';
import FabricClusterIcon from 'assets/icons/FabricClusterIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';
import InformationCircleIcon from 'assets/icons/InformationCircleIcon';
import { IconographyNameType, IconPropsType } from 'customTypes/icons';

const ICONOGRAPHY: Record<
  IconographyNameType,
  (props: IconPropsType) => JSX.Element
> = Object.freeze({
  active: Active,
  add: AddIcon,
  'add-with-outline': AddIconWithOutline,
  archive: Archive,
  'arrow-left': ArrowLeftIcon,
  catalog: Catalog,
  calendar: CalendarIcon,
  'check-circle': CheckCircle,
  'chevron-down': CheveronDownIcon,
  'chevron-up': CheveronUpIcon,
  'chevron-right': ChevronRightIcon,
  close: CloseIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  edit: EditIcon,
  'empty-view': EmptyView,
  'excel-upload-failure': ExcelUploadFailure,
  'excel-upload-in-progress': ExcelUploadInProgress,
  'excel-upload-success': ExcelUploadSuccess,
  fashinza: Fashinza,
  'fabric-cluster': FabricClusterIcon,
  filter: FilterIcon,
  funnel: Funnel,
  fz: FZ,
  'image-link-shirt': ImageLinkShirt,
  information: InformationCircleIcon,
  kebab: KebabIcon,
  'magnifying-glass': MagnifyingGlass,
  'main-logo': MainLogo,
  'qr-code': QrCodeIcon,
  'search-box': SearchBoxCross,
  'vertical-dots': VerticalDotsIcon,
  trash: TrashIcon,
  upload: UploadIcon,
});

export default ICONOGRAPHY;
