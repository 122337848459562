import React from 'react';

interface ITabProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  tabTitleProps?: Record<string, unknown>;
  isSelected: boolean;
}

export const Tab = ({ children }: ITabProps) => <>{children}</>;
