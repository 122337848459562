import { useState } from "react";
import { useDispatch } from "react-redux";
import { MdClose } from "react-icons/md";
import ReactTags, { Tag } from "react-tag-autocomplete";
import TagComponent from "components/TrendingModal/TagComponent";
import { updateSharedCatalogProductsThunk } from "actions/catalog";
import { ReactTagsWrapper } from "components/TrendingModal/styles";
import Button from "../../../library/Button";
import { ModifyEnum } from "../constants";
import { FIELDS_ARRAY, FOOTER, TAGS_PLACEHOLDER } from "./constants";
import * as Styled from "../../ShareableLink/styles";
import * as ModalStyled from "./styles";

export interface IModifyModalProps {
  onClose(): void;
  data: {
    title: string;
    category: string;
    addedBy: string;
    linkIdentifier: string;
    description: string;
    imageUrl: string;
    operator: ModifyEnum;
  };
}

const ModifyModal = ({ onClose, data }: IModifyModalProps) => {
  const [tags, setTags] = useState<Array<Tag>>([]);
  const dispatch = useDispatch();
  const { linkIdentifier, description, imageUrl, operator } = data;

  function header() {
    return operator === ModifyEnum.Add ? ModifyEnum.Add : ModifyEnum.Remove;
  }

  function handleSubmission() {
    const tagNames = tags.map((tag) => tag.name);
    const payload = {
      products: tagNames,
      operator: header(),
    };

    dispatch(
      updateSharedCatalogProductsThunk(payload, linkIdentifier, () => {
        onClose();
      })
    );
  }

  function onTagAddition(tag: Tag) {
    let productIdsString = tag.name;
    if (productIdsString.startsWith(",")) {
      productIdsString = productIdsString.slice(1);
    }
    if (productIdsString.endsWith(",")) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }
    const newTags = productIdsString.split(",");
    const newCommaSeparatedTagsMap = new Map<String, Tag>();

    newTags
      .filter((tag) => tag.trim() !== "")
      .forEach((eachTag) => {
        const tagObj = {
          id: eachTag.trim(),
          name: eachTag.trim(),
        };
        newCommaSeparatedTagsMap.set(eachTag, tagObj);
      });
    setTags([...tags, ...Array.from(newCommaSeparatedTagsMap.values())]);
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = tags[tagIndex];
    const newTags = tags.filter((tag) => tag !== tagToDelete);
    setTags(newTags);
  }

  return (
    <Styled.Wrapper className="center">
      <Styled.Container className="v-d-flex">
        <Styled.HeaderSection className="align-center">
          <Styled.HeaderText>{header()}&nbsp;Styles</Styled.HeaderText>

          <Styled.CloseModalButton onClick={onClose}>
            <MdClose size="1.3em" />
          </Styled.CloseModalButton>
        </Styled.HeaderSection>
        <Styled.HeaderLine />
        <>
          <Styled.Content>
            <Styled.ImageWrapper>
              <Styled.ImageContentWrapper className="d-flex">
                <div>
                  <Styled.Image src={imageUrl} alt="fashinza" />
                </div>
                <Styled.TextWrapper className="d-flex">
                  <Styled.MainWrapper>
                    <Styled.MainWrapper className="d-flex">
                      {FIELDS_ARRAY.map((item) => (
                        <Styled.BlockWrapper flex={1} key={item.key}>
                          <Styled.AlignWrapper>
                            <Styled.BoldTextWrapper>
                              {item.fieldName}
                            </Styled.BoldTextWrapper>
                          </Styled.AlignWrapper>
                          <Styled.ShareableProductCount>
                            <ModalStyled.TextContainer>
                              {data[item.key as keyof typeof data]}
                            </ModalStyled.TextContainer>
                          </Styled.ShareableProductCount>
                        </Styled.BlockWrapper>
                      ))}
                    </Styled.MainWrapper>

                    <Styled.CustomWrapper className="d-flex">
                      <Styled.BlockWrapper flex={1}>
                        <Styled.AlignWrapper>
                          <Styled.BoldTextWrapper>
                            Description
                          </Styled.BoldTextWrapper>
                        </Styled.AlignWrapper>

                        <Styled.ShareableProductCount>
                          <ModalStyled.TextContainer>
                            {description}
                          </ModalStyled.TextContainer>
                        </Styled.ShareableProductCount>
                      </Styled.BlockWrapper>
                    </Styled.CustomWrapper>
                    <Styled.BlockWrapper flex={1}>
                      <Styled.AlignWrapper>
                        {header()}&nbsp;Style ID
                      </Styled.AlignWrapper>
                      <ModalStyled.ProductModifyArea>
                        <ReactTagsWrapper>
                          <ReactTags
                            tags={tags}
                            tagComponent={TagComponent}
                            onAddition={onTagAddition}
                            onDelete={onTagDeletion}
                            addOnBlur
                            allowNew
                            placeholderText={TAGS_PLACEHOLDER}
                          />
                        </ReactTagsWrapper>
                      </ModalStyled.ProductModifyArea>
                      <ModalStyled.Footer>{FOOTER}</ModalStyled.Footer>
                    </Styled.BlockWrapper>
                  </Styled.MainWrapper>
                </Styled.TextWrapper>
              </Styled.ImageContentWrapper>
            </Styled.ImageWrapper>
          </Styled.Content>
          <Styled.HeaderLine />
          <Styled.ButtonGroup className="end-align-center">
            <Button variant="primary" size="medium" onClick={handleSubmission}>
              {header()}&nbsp;Styles
            </Button>
          </Styled.ButtonGroup>
        </>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default ModifyModal;
