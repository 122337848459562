import React, { ChangeEvent, useState, MouseEvent, useRef } from 'react';
import FileUpload from 'components/FileUpload';
import Toast from 'library/Toast';
import TextAreaInput from 'components/TextAreaInput';
import MultiSelect from 'library/MultiSelect';
import { useBoolean } from 'utils/hooks/useBoolean';
import { replaceUnderscoreWithSpace } from 'utils/helpers';
import { insertOrRemove } from 'utils/utility/utils';
import { copyToClipboard } from 'utils/utility';
import { uploadFile } from 'services/cloudinary';
import ICONOGRAPHY from 'CustomConstant/icons';
import { uploadImageToS3 } from 'utils/api';
import { IMAGE_SCALES } from './constants';
import * as Styles from './styles';

const { close: CloseIcon, upload: UploadIcon, copy: CopyIcon } = ICONOGRAPHY;

interface IModalProps {
  onClose(): void;
}

const Modal = ({ onClose }: IModalProps) => {
  const [s3Urls, setS3Urls] = useState<Record<string, string>>({});
  const [isLoading, loaderActions] = useBoolean();
  const [selectedImageScales, setSelectedImageScales] = useState<Array<string>>(
    []
  );
  const [s3Prefix, setS3Prefix] = useState('');
  const [s3BucketName, setS3BucketName] = useState('');
  const imgRef = useRef<HTMLInputElement>(null);

  async function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files === null || files.length === 0) {
      return;
    }
    const imageToUpload = files[0];
    loaderActions.on();
    try {
      const cloudinaryResponse = await uploadFile(imageToUpload);
      const s3Response = await uploadImageToS3({
        cloudinary_url: cloudinaryResponse?.link,
        scales: selectedImageScales,
        s3_prefix: `${s3Prefix}/`,
        s3_bucket_name: s3BucketName,
      });
      if (s3Response.status === true) {
        setS3Urls(s3Response.data);
      } else {
        Toast.ERROR(s3Response.msg);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }

    loaderActions.off();
  }

  function handleUploadClick(event: ChangeEvent<HTMLInputElement>) {
    if (selectedImageScales.length === 0) {
      if (imgRef.current?.value) {
        imgRef.current.value = '';
      }
      Toast.INFO('Please select image scales');
      return;
    }
    if (s3BucketName.length === 0) {
      if (imgRef.current?.value) {
        imgRef.current.value = '';
      }
      Toast.INFO('Please enter valid a S3 bucket name');
      return;
    }
    if (s3Prefix.length === 0) {
      if (imgRef.current?.value) {
        imgRef.current.value = '';
      }
      Toast.INFO('Please enter valid a S3 prefix');
      return;
    }
    handleImageUpload(event);
  }

  function handleMultiSelectChange(
    value: string,
    event: MouseEvent<HTMLElement>
  ) {
    const updatedScales = insertOrRemove(selectedImageScales, value);
    setSelectedImageScales([...updatedScales]);
  }

  return (
    <Styles.ModalWrapper className="full-view-dimensions center">
      <Styles.UploadImageModal>
        <Styles.Header className="spread full-width">
          <Styles.ModalTitle>Upload Image</Styles.ModalTitle>
          <CloseIcon className="cursor-pointer" onClick={() => onClose()} />
        </Styles.Header>
        <div className="v-d-flex">
          <Styles.ScalesSelectWrapper className="full-width justify-center">
            <MultiSelect
              label="scales *"
              placeholder="Select scales"
              onChange={handleMultiSelectChange}
              selectedValues={selectedImageScales}
              values={IMAGE_SCALES}
            />
          </Styles.ScalesSelectWrapper>
          <Styles.PrefixInputWrapper>
            <TextAreaInput
              inputHeight={40}
              label="S3 bucket name *"
              value={s3BucketName}
              onChange={(value: string) => setS3BucketName(value)}
              shoudShowCharacterCount={false}
              characterCount={0}
              placeholder="Enter S3 bucket name"
            />
          </Styles.PrefixInputWrapper>
          <Styles.PrefixInputWrapper>
            <TextAreaInput
              inputHeight={40}
              label="S3 prefix *"
              value={s3Prefix}
              onChange={(value: string) => setS3Prefix(value)}
              shoudShowCharacterCount={false}
              characterCount={0}
              placeholder="Enter S3 prefix"
            />
          </Styles.PrefixInputWrapper>

          <Styles.UploadImageContainer className="center">
            {isLoading ? (
              <Styles.Spinner />
            ) : (
              <FileUpload
                ref={imgRef}
                onChange={event => {
                  handleUploadClick(event);
                }}
                className="center cursor-pointer"
              >
                <UploadIcon stroke="var(--watermelon)" />
                <Styles.UploadText>Upload Image</Styles.UploadText>
              </FileUpload>
            )}
          </Styles.UploadImageContainer>
        </div>
        {Object.keys(s3Urls).map(eachUrl => (
          <Styles.LinkContainer className="full-width" key={eachUrl}>
            <Styles.UrlLabel>
              {replaceUnderscoreWithSpace(eachUrl)}
            </Styles.UrlLabel>
            <Styles.ImageLinkContainer className="align-center">
              <Styles.ImageLink className="truncated-text">
                {s3Urls[eachUrl]}
              </Styles.ImageLink>
              <Styles.Copy
                className="center cursor-pointer"
                onClick={() => copyToClipboard(`${s3Urls[eachUrl]}`)}
              >
                <CopyIcon />
              </Styles.Copy>
            </Styles.ImageLinkContainer>
          </Styles.LinkContainer>
        ))}
      </Styles.UploadImageModal>
    </Styles.ModalWrapper>
  );
};

export default Modal;
