import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.5);
  z-index: 20;
`;

export const Container = styled.div`
  width: 624px;
  border-radius: 4px;
  background: var(--white);
`;

export const Content = styled.div`
  padding: 24px;
`;

export const UnshareableProductCount = styled.p`
  font-size: 12px;
  color: var(--manatee);
  margin-top: 8px;
  margin-left: 1rem;
  line-height: 15px;
`;

export const ShareableProductCount = styled.p`
  font-size: 14px;
  color: var(--rhino);
  margin-left: 1rem;
  line-height: 17px;
`;

export const BoldTextWrapper = styled.span`
  font-weight: 600;
`;

export const AlignWrapper = styled.div`
  margin-left: 1rem;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const ImageContentWrapper = styled.div`
  margin-top: 0;
`;

export const Image = styled.img`
  flex: 1;
  border-radius: 4px;
  margin-right: 8px;
  height: 220px;
  width: 150px;
  display: block;
  object-fit: cover;
`;

export const TextWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
`;

export const MainWrapper = styled.div`
  margin: 0;
`;

export const DataContainer = styled.input`
  border: 1px solid var(--mischka);
  border-radius: 4px;
  padding: 12px;
  margin: 0.5rem 0 0 1rem;
  width: calc(100% - 1rem);
  flex: 1;
  color: var(--rhino);
  &:focus {
    outline: none;
  }
`;

export const LinkContainer = styled.div`
  border: 1px solid var(--mischka);
  border-radius: 4px;
  padding: 12px;
  margin: 0.5rem 0 0 1rem;
  flex: 1;
  background: var(--input-disabled);
`;

export const Link = styled.div`
  font-size: 14px;
  color: var(--rhino);
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CopyToClipboardIcon = styled.div`
  margin-left: 4px;
  cursor: pointer;
  svg {
    fill: var(--tertiary-text);
  }
`;

export const HeaderSection = styled.div`
  padding: 24px;
  justify-content: space-between;
`;

export const HeaderText = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: var(--rhino);
  line-height: 28px;
`;

export const CloseModalButton = styled.div`
  cursor: pointer;
`;

export const HeaderLine = styled.hr`
  margin: 0;
  color: var(--athens-gray);
  border: 1px solid var(--athens-gray);
`;

export const ErrorHeaderWrapper = styled.p`
  svg {
    fill: var(--red-dirt);
  }
`;

export const ErrorHeaderText = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: var(--red-dirt);
  line-height: 28px;
  margin-left: 8px;
`;

export const ErrorText = styled.p`
  font-size: 16px;
  color: var(--rhino);
  line-height: 24px;
`;

export const ButtonGroup = styled.div`
  margin: 24px;
  button {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
  }
`;

export const CustomWrapper = styled.div`
  margin: 1rem 0 1.5rem 0;
`;

export const BlockWrapper = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  font-size: 12px;
`;
