import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextInput from 'components/textInput/textInput';
import Button from 'library/Button';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import * as Styles from './styles';

interface ICatalogFiltersMaterialProps {
  onClose(): void;
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const CatalogueFilterMaterial = ({
  onClose,
  onFilterChange,
}: ICatalogFiltersMaterialProps) => {
  const { materials = [] } = useTypedSelector(
    state => state.catalog.primaryFilters
  );
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const selectedMaterialFilter = currentQueryParams.materials ?? [];
  const [materialSearch, setMaterialSearch] = useState('');
  const [selectedMaterials, setSelectedMaterialsActions] = useState(
    selectedMaterialFilter
  );
  const [list, setMaterialList] = useState(materials);

  function onClear() {
    onFilterChange({ materials: [] });
  }

  function onChangeHandler(value: string) {
    const updatedSelectedList = insertOrRemove(selectedMaterials, value);
    setSelectedMaterialsActions(updatedSelectedList);
  }

  function onSubmit() {
    onFilterChange({ materials: selectedMaterials });
  }

  function onChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setMaterialSearch(value);
    setMaterialList(matchQuery(value, materials));
  }

  function onClearSearch() {
    setMaterialSearch('');
    setMaterialList(materials);
  }

  function matchQuery(value: string, options: any[]) {
    const newMaterials: any = [];
    options.forEach(eachOption => {
      if (eachOption.value.toLowerCase().startsWith(value.toLowerCase())) {
        newMaterials.push(eachOption);
      }
    });
    return newMaterials;
  }

  return (
    <Styles.FabricFilterWrapper tabIndex={0}>
      <Styles.FabricFilterBody>
        <Styles.DropboxHeaderWrapper className="spread">
          <div className="d-flex">
            <Styles.FilterHeader className="center">Fabric</Styles.FilterHeader>
            <Styles.SearchBoxContainer className="center">
              <TextInput
                placeholder="Search Fabric..."
                value={materialSearch}
                dataname=""
                onChange={onChangeSearch}
                onClear={onClearSearch}
                listItemSearchBoxClass="list-item-search-box-class"
              />
            </Styles.SearchBoxContainer>
          </div>

          <Styles.ClearButtonWrapper
            isDisabled={!selectedMaterials.length}
            onClick={onClear}
            className="center"
          >
            Clear
          </Styles.ClearButtonWrapper>
        </Styles.DropboxHeaderWrapper>
        <Styles.ContentWrapper className="v-d-flex flex-wrap">
          {list?.map((eachMaterial: { value: string; count: number }) => {
            return (
              <div className="align-center" key={eachMaterial.value}>
                <Checkbox
                  label={eachMaterial.value}
                  onChange={() => onChangeHandler(eachMaterial.value)}
                  checked={selectedMaterials.includes(eachMaterial.value)}
                />
              </div>
            );
          })}
        </Styles.ContentWrapper>
        <div className="spread">
          <div>
            <Styles.DropboxBottomCountContainer>
              {selectedMaterials.length}/{materials.length}
            </Styles.DropboxBottomCountContainer>
          </div>
          <Styles.DropboxBottomButtonContainer className="align-center">
            <Button size="medium" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit} size="medium">
              Apply
            </Button>
          </Styles.DropboxBottomButtonContainer>
        </div>
      </Styles.FabricFilterBody>
    </Styles.FabricFilterWrapper>
  );
};

export default CatalogueFilterMaterial;
