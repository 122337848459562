import EMAILER_TYPES from 'actionTypes/emailer';
import { IEmailers } from 'components/Emailers/types';
import { IReduxActionType } from 'utils/types';
import { update } from './helpers';

export interface IEmailerInitialState {
  emailData: IEmailers;
  isLoading: false;
}

const initialState: IEmailerInitialState = {
  emailData: {} as IEmailers,
  isLoading: false,
};

function emailerReducer(
  state: IEmailerInitialState = initialState,
  action: IReduxActionType
) {
  switch (action.type) {
    case EMAILER_TYPES.SET_LOADER_STATUS: {
      return update(state, { isLoading: action.payload });
    }

    case EMAILER_TYPES.SET_EMAILER_DATA: {
      return update(state, { emailData: action.payload });
    }

    default:
      return state;
  }
}

export default emailerReducer;
