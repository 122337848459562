import styled from 'styled-components';

export const Label = styled.label`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .info-tooltip {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  &[type='checkbox'] {
    width: 0px;
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  /* Text color for the label */
  &[type='checkbox'] + span {
    cursor: pointer;
    // font: 16px sans-serif;
    // color: black;
  }

  /* Checkbox un-checked style */
  &[type='checkbox'] + span:before {
    content: '';
    box-sizing: border-box;
    border-radius: 2px;
    border: solid 1px var(--very-light-pink);
    background-color: var(--white);
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  /* Checked checkbox style (in this case the background is red) */
  &[type='checkbox']:checked + span:before {
    /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
    background-image: url('/check.svg');
    background-repeat: no-repeat;
    background-position: center;
    /* The size of the checkmark icon, you may/may not need this */
    background-size: 25px;
    border-radius: 2px;
    background-color: var(--watermelon);
    border-color: var(--watermelon);
    color: white;
  }

  /* Adding a dotted border around the active tabbed-into checkbox */
  &[type='checkbox']:focus + span:before,
  &[type='checkbox']:not(:disabled) + span:hover:before {
    /* Visible in the full-color space */
    // box-shadow: 0px 0px 0px 2px var(--very-light-pink);

    /* Visible in Windows high-contrast themes
     box-shadow will be hidden in these modes and
     transparency will not be hidden in high-contrast
     thus box-shadow will not show but the outline will
     providing accessibility */
    outline-color: transparent;
    /*switch to transparent*/
    outline-width: 2px;
    outline-style: dotted;
  }

  /* Disabled checkbox styles */
  &[type='checkbox']:disabled + span {
    cursor: default;
    color: black;
    opacity: 0.5;
  }
`;

export const Span = styled.span`
  display: flex;
  align-items: center;
`;

export const CkLabel = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--almost-black);
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;

  .disabled {
    color: #8188a4 !important;
  }
`;
