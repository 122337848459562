import { Tag } from "react-tag-autocomplete";
import { MdOutlineClose } from "react-icons/md";
import * as Styles from "./styles";

interface IBrandTagComponentProps {
  tag: Tag;
  onDelete(tag: Tag): void;
}

const BrandTagComponent = ({ tag, onDelete }: IBrandTagComponentProps) => (
  <Styles.TagContainer className="align-center">
    <p>{tag.name}</p>
    <Styles.SvgButton onClick={() => onDelete(tag)}>
      <MdOutlineClose />
    </Styles.SvgButton>
  </Styles.TagContainer>
);

export default BrandTagComponent;
