import React, { ChangeEvent, useState } from 'react';
import Checkbox from 'library/checkbox/checkbox';
import { insertOrRemove } from 'utils/utility/utils';
import ICONOGRAPHY from 'CustomConstant/icons';
import { IEachOption } from './types';
import * as Styles from './styles';

interface IMultiSelectWithApplyProps {
  heading: string;
  list: Array<IEachOption>;
  selectedOptions: Array<string>;
  onCancel(): void;
  onApply(selectedFilters: Array<string>): void;
  onClear(): void;
  placeholder?: string;
}

const { 'magnifying-glass': MagnifyingGlass } = ICONOGRAPHY;

const MultiSelectWithApply = ({
  heading,
  list,
  selectedOptions,
  onClear,
  onCancel,
  onApply,
  placeholder = 'Search',
}: IMultiSelectWithApplyProps) => {
  const [search, setSearch] = useState('');
  const [filteredList, setFilteredList] = useState<Array<IEachOption>>(list);
  const [checkedOptions, setCheckedOptions] =
    useState<Array<string>>(selectedOptions);

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setSearch(value);
    setFilteredList(matchQuery(value, list));
  }

  function matchQuery(value: string, options: Array<IEachOption>) {
    const newCategories: Array<IEachOption> = [];
    options.forEach(eachOption => {
      if (eachOption.name.toLowerCase().startsWith(value.toLowerCase())) {
        newCategories.push(eachOption);
      }
    });

    return newCategories;
  }

  function handleCheckboxChange(value: string) {
    const updatedSelectedOptions = insertOrRemove(checkedOptions, value);
    setCheckedOptions(updatedSelectedOptions);
  }

  return (
    <Styles.ContainerWrapper className="ms-wrapper full-width pos-r">
      <div className="ms-container full-width">
        <div className="ms-header spread full-width">
          <p className="ms-header-title">{heading}</p>
          <p onClick={onClear} className="ms-header-clear cursor-pointer">
            Clear
          </p>
        </div>
        <div className="ms-search-bar align-center full-width">
          <MagnifyingGlass size={12} />
          <input
            className="ms-search-input"
            placeholder={placeholder}
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <div className="ms-options-list full-width has-y-scroll">
          {filteredList.map(eachOption => (
            <div className="ms-option">
              <Checkbox
                item={{
                  value: eachOption.id,
                }}
                label={eachOption.name}
                onChange={() => handleCheckboxChange(eachOption.id)}
                checked={checkedOptions.includes(eachOption.id)}
              />
            </div>
          ))}
        </div>
        <div className="ms-button-container full-width spread pos-a">
          <button
            className="ms-button-cancel cursor-pointer"
            onClick={onCancel}
          >
            Cancel
          </button>

          <button
            className="ms-button-apply cursor-pointer"
            onClick={() => onApply(checkedOptions)}
          >
            Apply
          </button>
        </div>
      </div>
    </Styles.ContainerWrapper>
  );
};

export default MultiSelectWithApply;
