const EmptyView = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={358}
      height={338}
      viewBox="0 0 358 338"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F8F8F8"
          fillRule="nonzero"
          d="M329.993 171.725c1.381 37.291-39.683 76.765-82.925 95.917-43.156 19.475-88.285 18.747-124.674-.9C85.801 246.977 58.068 208.09 53.442 161.3 48.817 114.51 67.3 59.821 102.393 42.5c35.212-17.525 87.034 2.318 134.815 29.632 47.781 27.314 91.608 62.422 92.785 99.594z"
        />
        <g transform="translate(99 106)">
          <path
            fill="#E6E7E8"
            fillRule="nonzero"
            d="M151.375 15.098h-.69c-.103-4.827-4.046-8.73-8.897-8.73H15.148c-4.917 0-8.917 4-8.917 8.917V195.12c0 4.916 4 8.916 8.917 8.916h.69c.104 4.828 4.047 8.73 8.897 8.73h126.64c4.917 0 8.917-4 8.917-8.917V24.015c0-4.917-4-8.917-8.917-8.917z"
          />
          <g transform="translate(0 1)">
            <path
              fill="#FF6A6F"
              fillRule="nonzero"
              d="M144.242 8.748h-2.454v178.237c0 3.94-3.194 7.134-7.133 7.134H10.47v1.596c0 3.939 3.194 7.133 7.134 7.133h126.64c3.939 0 7.133-3.194 7.133-7.133V15.88c-.002-3.939-3.196-7.133-7.135-7.133z"
            />
            <path
              fill="#ECF7FF"
              fillRule="nonzero"
              d="M134.655.019H8.015C4.075.019.88 3.213.88 7.152v179.831c0 3.94 3.194 7.134 7.134 7.134h126.64c3.939 0 7.133-3.194 7.133-7.134V7.153c0-3.94-3.194-7.134-7.133-7.134z"
            />
            <g transform="translate(21 20)">
              <rect
                width={40}
                height={40}
                fill="#FFF"
                stroke="#0B78CD"
                strokeWidth={0.5}
                rx={0.373}
              />
              <g fillRule="nonzero" opacity={0.2}>
                <path
                  fill="#EF5826"
                  d="M3.99 23.795L3.878 21.864 4.296 21.836 4.383 23.843 3.99 23.795"
                  transform="translate(6.667 6.667) matrix(1 0 0 -1 0 45.68)"
                />
                <path
                  fill="#12826D"
                  d="M22.528 13.585s.599.262 1.54-1.101c.941-1.363 2.191-2.608 2.058-2.741-.747-.747-3.347.55-3.778 1.735-.431 1.185.18 2.107.18 2.107"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#1EBCA0"
                  d="M.005 9.355c-.074.085.638 1.787 1.59 2.74.952.952 1.624 1.454 1.93 1.203.325-.265-.308-2.21-1.67-3.33C.492 8.845.005 9.354.005 9.354M10.381 1.342s3.08.844 5.945.252c0 0-1.992 5.505-1.539 9.335.454 3.83-.022 14.872-.022 14.872H8.887l1.494-24.459"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#2DB194"
                  d="M14.764 25.801h-3.71c.105-1.249.226-2.697.352-4.162 1.042-1.518 1.968-3.408 2.757-5.3.213 2.884.458 6.99.601 9.462"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#3CDDD5"
                  d="M.005 9.355s3.52 1.704 3.52 3.943l1.447-1.093s2.326-5.334 2.185-6.892C7.015 3.755 5.53 3.506 5.53 3.506L.005 9.355"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#2CB9A4"
                  d="M3.57 13.255v-.013.013m0-.006v-.014.014"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#43D2CC"
                  d="M3.526 13.298v-.006-.001-.005-.002-.005-.007-.006c-.013-.84-.517-1.604-1.139-2.23.677-.52 1.244-1.09 1.491-1.668.518-1.21 1.148-1.339 1.826-1.656-.039.31-.089.633-.154.968v.003l-.002.012-.016.079-.002.012c-.511 2.552-.56 3.212-.56 3.371 0 .038.003.048.003.048l-1.447 1.093"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#26EFCB"
                  d="M12.122 13.59c-.38 2.727-1.287 14.282-1.287 14.282-3.102-.519-6.657-2.683-6.657-2.683l.795-12.984s-.076-.26.557-3.419 0-5.28 0-5.28l3.527-1.573 1.325-.59.132.272c-.022.82.017 1.76.246 2.324.472 1.169 1.362.995 1.362.995s.377 5.93 0 8.656"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#1EA58B"
                  d="M12.122 4.934c.113 2.268.244 6.345-.05 8.61-.39 2.904-1.069 11.37-1.281 14.27-2.185-.39-4.785-1.56-6.613-2.625.514.311 1.042.6 1.577.875 3.211 1.647 4.984 1.817 5.124 1.866.294-3.559.98-11.915 1.301-14.37.286-2.372.098-6.253-.058-8.626"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#26EFCB"
                  d="M21.602 25.452s-3.604 2.056-6.716 2.481c0 0-.642-11.579-.957-14.316-.314-2.737.2-8.652.2-8.652 1.494-.936 1.83-2.427 1.841-3.529l1.117.538 3.664 1.761s-.682 2.101-.122 5.28c.56 3.177.973 16.437.973 16.437"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#4ED1AA"
                  d="M10.513 2.208l-.132-.866.246-1.341s3.688.303 5.182 0l.712 1.342.227.786-6.235.079"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#A6F2EA"
                  d="M12.122 4.934L9.72 6.406s-1.04-2.635-.833-3.914c.031-.189.091-.377.17-.559C9.514.88 10.627 0 10.627 0s-.09.739-.113 1.615c-.022.82.017 1.76.246 2.324.472 1.169 1.362.995 1.362.995M17.07 2.895c-.368 1.688-1.19 3.214-1.19 3.214l-1.751-1.144c1.494-.936 1.83-2.427 1.841-3.529C15.98.61 15.809 0 15.809 0s1.06.786 1.278 1.974c.052.285.055.594-.016.921"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#40DACB"
                  d="M5.746 7.742h0"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#46D2C4"
                  d="M5.565 8.785l.019-.097-.02.097m.02-.094L5.6 8.6l-.018.09m-.033-.01c.065-.336.115-.659.154-.969-.038.31-.089.633-.154.968"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#30E8C3"
                  d="M5.546 25.93c-.487-.246-.948-.496-1.368-.741l.795-12.984s-.003-.01-.003-.048c0-.16.049-.82.56-3.37l.002-.013.016-.08.002-.01V8.68c.065-.335.116-.658.154-.968.029 0 1.278.052 1.278 6.896 0 4.044-.784 8.399-1.436 11.321m-1.306-.773l-.032-.019.032.02"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#29B092"
                  d="M5.543 25.94c-.788-.405-1.309-.717-1.36-.749l-.005-.002c.42.245.88.495 1.368.74l-.003.011"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#D5F4EB"
                  d="M20.678 9.006c-.004-.027-.01-.052-.013-.08l.013.08m-.004-.023l-.01-.057.01.057m-.002-.011l-.01-.056.01.056m-.002-.009l-.01-.06.01.06m0-.005l-.012-.071.012.07m-.051.003c-.156-.906-.211-1.724-.211-2.43 0 .706.055 1.523.211 2.43"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#30E8C3"
                  d="M20.82 25.868c-.793-5.89-1.84-14.611-1.384-16.557.442-1.885.78-2.548.972-2.78 0 .705.055 1.523.211 2.429v.001l.002.01v.002l.002.008v.003l.001.007.001.003.001.007v.003l.003.01c.56 3.178.973 16.438.973 16.438-.006.004-.298.17-.783.416"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#26D1CB"
                  d="M20.75 3.736l5.376 6.007s-3.546 1.603-3.598 3.842l-1.653-2.393s-1.832-1.14-1.634-3.38c.199-2.24 1.51-4.076 1.51-4.076"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#43A895"
                  d="M20.884 11.202c-1.23-.785-1.88-2.08-1.688-3.555.167-1.428.797-2.877 1.554-3.911-1.296 2.08-1.422 3.911-1.425 3.925-.205 1.42.42 2.697 1.559 3.541"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#6FF2DA"
                  d="M10.105 8.976L8.19 7.998 6.174 9.238 6.436 13.168 10.367 12.906 10.105 8.976"
                  transform="translate(6.667 6.667) matrix(1 0 0 -1 0 21.166)"
                />
                <path
                  fill="#43A895"
                  d="M10.05 12.154l-1.858.94-1.96-1.196c.044-.676.147-2.524.204-3.9-.146 1.601-.246 2.977-.32 3.96l2.07 1.284 1.973-1.016c.09-1.587.143-2.424.208-3.966-.06.654-.271 3.248-.316 3.894"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#6FF2DA"
                  d="M19.166 9.627L17.384 8.423 15.233 9.408 15.014 13.341 18.947 13.56 19.166 9.627"
                  transform="translate(6.667 6.667) matrix(1 0 0 -1 0 21.983)"
                />
                <path
                  fill="#C5F8F4"
                  d="M10.136 5.35c-.2 0-.422-.127-.61-.496-.489-.956-.243-3.167 0-3.277.009-.004.019-.006.029-.006.38 0 1.165 3.013 1.165 3.283 0 .177-.266.497-.584.496"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#EF5826"
                  d="M16.666 8.873L16.721 8.423 18.964 8.521 18.947 9 16.666 8.873"
                  transform="translate(6.667 6.667) matrix(1 0 0 -1 0 17.423)"
                />
                <path
                  fill="#43A895"
                  d="M19.108 12.327l-1.73 1.16-2.09-.95c-.04-.676-.163-2.522-.274-3.895.05 1.607.118 2.985.165 3.97l2.212 1.022 1.833-1.25c-.104-1.585-.153-2.423-.277-3.961.02.656.127 3.257.161 3.904M11.593 6.555c0 .131-.107.238-.238.238-.132 0-.238-.107-.238-.238 0-.132.106-.238.238-.238.131 0 .238.106.238.238M11.935 10.205c0 .132-.106.238-.238.238-.131 0-.238-.106-.238-.238 0-.131.107-.238.238-.238.132 0 .238.107.238.238M11.622 14.187c0 .131-.107.238-.239.238-.13 0-.238-.107-.238-.238 0-.132.107-.238.238-.238.132 0 .239.106.239.238M11.145 18.298c0 .132-.106.238-.238.238-.131 0-.238-.106-.238-.238 0-.131.107-.238.238-.238.132 0 .238.107.238.238M10.67 23.687c0 .131-.107.238-.239.238-.131 0-.238-.107-.238-.238 0-.132.107-.238.238-.238.132 0 .238.106.238.238M20.464 10.845l.146 3.632c.451 10.132.933 10.443.992 10.975-.862-3.164-1.144-11.338-1.138-14.607"
                  transform="translate(6.667 6.667)"
                />
                <path
                  fill="#3CDDD5"
                  d="M20.464 4.97s.044 2.03.78 3.69c.736 1.66 3.756 3.078 3.29.93-.467-2.149-4.015-6.179-4.07-4.62"
                  transform="translate(6.667 6.667)"
                />
              </g>
            </g>
            <rect
              width={100}
              height={4.48}
              x={21}
              y={87}
              fill="#FFF"
              stroke="#0B78CD"
              strokeWidth={0.56}
              rx={0.56}
            />
            <rect
              width={50}
              height={4.48}
              x={71}
              y={29}
              fill="#FFF"
              stroke="#0B78CD"
              strokeWidth={0.56}
              rx={0.56}
            />
            <rect
              width={30}
              height={4.48}
              x={71}
              y={49}
              fill="#FFF"
              stroke="#0B78CD"
              strokeWidth={0.56}
              rx={0.56}
            />
            <rect
              width={100}
              height={4.48}
              x={21}
              y={107}
              fill="#FFF"
              stroke="#0B78CD"
              strokeWidth={0.56}
              rx={0.56}
            />
            <rect
              width={100}
              height={4.48}
              x={21}
              y={127}
              fill="#FFF"
              stroke="#0B78CD"
              strokeWidth={0.56}
              rx={0.56}
            />
            <rect
              width={50}
              height={4.48}
              x={21}
              y={147}
              fill="#FFF"
              stroke="#0B78CD"
              strokeWidth={0.56}
              rx={0.56}
            />
            <path
              fill="#3A2C60"
              fillRule="nonzero"
              d="M151.375 45.123c-.984 0-1.783.797-1.783 1.783V64.59c0 .986.799 1.783 1.783 1.783.985 0 1.783-.797 1.783-1.783V46.906c0-.986-.798-1.783-1.783-1.783z"
            />
          </g>
          <rect width={141} height={195} x={1} stroke="#187FCF" rx={8} />
        </g>
      </g>
    </svg>
  );
};

export default EmptyView;
