import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 40px;
  padding: 32px;
  border: 1px dashed var(--mischka);
  border-radius: 4px;
`;

export const SectionHeader = styled.p`
  color: var(--rhino);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const SubText = styled.p`
  color: var(--rhino);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .label {
    color: var(--manatee);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .dropdown2-wrapper {
    width: 320px;
    margin-bottom: 24px;
    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdown-class {
    border: none;
    height: auto;
    width: auto;
    padding: 12px;

    .label-name {
      color: var(--manatee) !important;
      font-size: 13px !important;
      line-height: 16px;
    }
    .label-value {
      font-size: 13px !important;
      color: var(--rhino) !important;
      line-height: 16px;
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;
      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }

  .dropdown-placeholder {
    padding-right: 240px;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }
`;

export const DropdownContainer = styled.div`
  width: min-content;
  margin-top: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
`;
