import aT from '../actionTypes/imageLink';
import { update } from './helpers';
import { IReduxActionType, IObjectType } from '../utils/types';

export interface IInitialState {
  isLoading: boolean;
  uploadedImages: Array<any>;
  failedToUpload: Array<any>;
  imagesToBeUploaded: Array<IObjectType>;
  rejectedImages: Array<IObjectType>;
  totalPages: number;
  currentPage: number;
  selectedSupplier: number | string;
}

const initialState: IInitialState = {
  isLoading: false,
  totalPages: 1,
  uploadedImages: [],
  imagesToBeUploaded: [],
  rejectedImages: [],
  failedToUpload: [],
  selectedSupplier: '',
  currentPage: 1,
};

function imageLinkReducer(state = initialState, action: IReduxActionType) {
  switch (action.type) {
    case aT.FETCH_UPLOADED_IMAGE_LINKS_REQUEST:
      return update(state, { isLoading: true });

    case aT.FETCH_UPLOADED_IMAGE_LINKS_FAILURE:
      return update(state, { isLoading: false });

    case aT.FETCH_UPLOADED_IMAGE_LINKS_SUCCESS:
      return update(state, {
        isLoading: false,
        uploadedImages: [...state.uploadedImages, ...action.payload.results],
        totalPages: action.payload.total_pages,
      });

    case aT.UPDATE_UPLOADED_IMAGES_LINKS_REQUEST:
      return update(state, { isLoading: true });

    case aT.UPDATE_UPLOADED_IMAGES_LINKS_FAILURE:
      return update(state, { isLoading: false });

    case aT.UPDATE_UPLOADED_IMAGES_LINKS_SUCCESS:
      return update(state, { isLoading: false });

    case aT.RESET_UPLOADED_IMAGES:
      return update(state, {
        uploadedImages: [],
        totalPages: 1,
        rejectedImages: [],
        imagesToBeUploaded: [],
        selectedSupplier: '',
        currentPage: 1,
      });

    case aT.SET_IMAGES_IN_UPLOAD_QUEUE:
      return update(state, {
        imagesToBeUploaded: action.payload,
      });

    case aT.SET_REJECTED_IMAGES:
      return update(state, {
        rejectedImages: action.payload,
      });

    case aT.SET_SELECTED_SUPPLIER:
      return update(state, { selectedSupplier: action.payload });

    case aT.SET_CURRENT_PAGE:
      return update(state, {
        currentPage: action.payload ?? state.currentPage + 1,
      });

    default:
      return state;
  }
}

export default imageLinkReducer;
