import { useLocation } from 'react-router-dom';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import FilterSearchBox from 'components/Filters/CreatedByFilter/FilterSearchBox';
import DesignerFilter from 'components/Filters/DesignerFilter';
import SaleRepresentativeFilter from 'components/Filters/SaleRepresentativeFilter';
import MultiSelectWithApply from 'library/MultiSelectWithApply';
import SeasonFilter from 'components/Filters/SeasonFilter';
import CategoriesFilter from 'components/Filters/CategoriesFilter';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  getSelectOptionNames,
  useAllCategoriesData,
  useAllDesignerDetail,
  useAllSaleRepresentativeDetail,
  useBuHeadDetails,
  useSeasonsData,
} from 'components/Filters/helper';
import BrandsTextFilter from 'components/Filters/BrandsTextFilter';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterTypes } from 'components/Filters/type';
import * as Styles from './styles';

interface IFilterSearchBarProps {
  onFilterChange(param: Partial<Record<FilterTypes, Array<string>>>): void;
}

const Filters = ({ onFilterChange }: IFilterSearchBarProps) => {
  const buHeadData = useBuHeadDetails();
  const saleRepresentative = useAllSaleRepresentativeDetail();
  const designers = useAllDesignerDetail();
  const seasonsData = useSeasonsData();
  const designCategories = useAllCategoriesData();
  const { search: queryParams } = useLocation();
  const currentQueryParams = getQueryParams(queryParams);
  const [isBuHeadFilterOpen, buHeadFilterOpenActions] = useBoolean();
  const [isSaleRepFilterOpen, saleRepFilterOpenActions] = useBoolean();
  const [isDesignerFilterOpen, designerFilterOpenActions] = useBoolean();
  const [isSeasonFilterOpen, seasonFilterOpenActions] = useBoolean();
  const [isCategoryFilterOpen, categoryFilterOpenActions] = useBoolean();

  function handleBuHeadFilterClear() {
    onFilterChange({ bu_head__in: [] });
  }

  function handleApplyBuHeadFilter(selectedFilters: Array<string>) {
    onFilterChange({ bu_head__in: selectedFilters });
  }

  return (
    <Styles.FilterWrapper className="d-flex full-width">
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>BU Head</Styles.FilterViewBarTitle>
        <FilterSearchBox
          value={getSelectOptionNames(
            buHeadData,
            currentQueryParams.bu_head__in
          )}
          isFilterSearchBarOpen={isBuHeadFilterOpen}
          toggleFilterSearchBox={() => buHeadFilterOpenActions.toggle()}
          placeholder="Bu Head"
        />
        {isBuHeadFilterOpen && (
          <ClickOutside
            onClose={() => buHeadFilterOpenActions.off()}
            disableClickHandler={false}
          >
            <Styles.MultiSelectWrapper className="pos-a">
              <MultiSelectWithApply
                heading="BU Head"
                list={buHeadData}
                selectedOptions={currentQueryParams?.bu_head__in ?? []}
                onClear={handleBuHeadFilterClear}
                onCancel={() => buHeadFilterOpenActions.off()}
                onApply={handleApplyBuHeadFilter}
              />
            </Styles.MultiSelectWrapper>
          </ClickOutside>
        )}
      </Styles.FilterViewBarWrapper>
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>
          Sales Representative
        </Styles.FilterViewBarTitle>
        <FilterSearchBox
          value={getSelectOptionNames(
            saleRepresentative,
            currentQueryParams.sales_representative__in
          )}
          isFilterSearchBarOpen={isSaleRepFilterOpen}
          toggleFilterSearchBox={() => saleRepFilterOpenActions.toggle()}
          placeholder="Sales Representative"
        />
        {isSaleRepFilterOpen && (
          <ClickOutside
            onClose={() => saleRepFilterOpenActions.off()}
            disableClickHandler={false}
          >
            <SaleRepresentativeFilter
              onClose={() => saleRepFilterOpenActions.off()}
              onFilterChange={onFilterChange}
            />
          </ClickOutside>
        )}
      </Styles.FilterViewBarWrapper>
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>Designers</Styles.FilterViewBarTitle>
        <FilterSearchBox
          value={getSelectOptionNames(
            designers,
            currentQueryParams.designer__in
          )}
          isFilterSearchBarOpen={isDesignerFilterOpen}
          toggleFilterSearchBox={() => designerFilterOpenActions.toggle()}
          placeholder="Designers"
        />
        {isDesignerFilterOpen && (
          <ClickOutside
            onClose={() => designerFilterOpenActions.off()}
            disableClickHandler={false}
          >
            <DesignerFilter
              onClose={() => designerFilterOpenActions.off()}
              onFilterChange={onFilterChange}
            />
          </ClickOutside>
        )}
      </Styles.FilterViewBarWrapper>
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>Season</Styles.FilterViewBarTitle>
        <FilterSearchBox
          value={getSelectOptionNames(
            seasonsData,
            currentQueryParams.season__in
          )}
          isFilterSearchBarOpen={isSeasonFilterOpen}
          toggleFilterSearchBox={() => seasonFilterOpenActions.toggle()}
          placeholder="Season"
        />
        {isSeasonFilterOpen && (
          <ClickOutside
            onClose={() => seasonFilterOpenActions.off()}
            disableClickHandler={false}
          >
            <SeasonFilter
              onClose={() => seasonFilterOpenActions.off()}
              onFilterChange={onFilterChange}
            />
          </ClickOutside>
        )}
      </Styles.FilterViewBarWrapper>
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>Category</Styles.FilterViewBarTitle>
        <FilterSearchBox
          value={getSelectOptionNames(
            designCategories,
            currentQueryParams.category__in
          )}
          isFilterSearchBarOpen={isCategoryFilterOpen}
          toggleFilterSearchBox={() => categoryFilterOpenActions.toggle()}
          placeholder="Category"
        />
        {isCategoryFilterOpen && (
          <ClickOutside
            onClose={() => categoryFilterOpenActions.off()}
            disableClickHandler={false}
          >
            <CategoriesFilter
              onClose={() => categoryFilterOpenActions.off()}
              onFilterChange={onFilterChange}
            />
          </ClickOutside>
        )}
      </Styles.FilterViewBarWrapper>
    </Styles.FilterWrapper>
  );
};

export default Filters;
