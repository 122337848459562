import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TagCloud } from 'react-tagcloud';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import qs from 'qs';
import Dropdown from 'components/Dropdown';
import HeaderFiltersView from 'components/Filters/HeaderFiltersView';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import {
  fetchChartTagsThunk,
  fetchChartThunk,
  fetchRetailersThunk,
  fetchTagsThunk,
  setChartsData,
  setSelectedFilters,
} from 'actions/trend';
import { fetchExcelTemplateCategoriesThunk } from 'actions/catalog';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { setQueryStringValueToUrl } from 'utils/routing';
import { capitalizeFirstLetter, removeFalsyKeys } from 'utils/utility/utils';
import ICONOGRAPHY from 'CustomConstant/icons';
import { URL } from 'config';
import NoTrendsDataIllustration from 'assets/Illustrations/NoTrendsDataIllustration';
import ErrorStateTrendsIllustration from 'assets/Illustrations/ErrorStateTrendsIllustration';
import Filters from './Filters';
import {
  COLORS,
  PILL_COLOR_COMBINATIONS,
  RADIAN,
  TagsFilters,
  TAGS_COLORS,
} from './constants';
import * as Styles from './styles';

const { information: InformationCircleIcon } = ICONOGRAPHY;

const Trends = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams(new URLSearchParams(''));
  const [showTags, setShowTags] = useBoolean();
  const { search: queryParams } = useLocation();
  const tags = useTypedSelector(state => state.trends.tags) ?? [];
  const chartData = useTypedSelector(state => state.trends.chartData);
  const chartTags = useTypedSelector(state => state.trends.chartTags);
  const tagsValue = chartTags.map(item => ({
    id: item,
    name: item,
  }));
  const [defaultTag, setSelectedTag] = useState('');
  const categories = useTypedSelector(
    state => state.catalog.excelTemplateCategories
  );
  const currentQueryParams = getQueryParams(queryParams);
  let currentCategory =
    currentQueryParams.category?.length > 0
      ? currentQueryParams.category[0]
      : '';

  const cacheStrings: Record<string, string> = {};
  let cacheDependency = '';
  TagsFilters.forEach(eachKey => {
    const selectedValues = currentQueryParams[eachKey] ?? [];
    const element = selectedValues.length > 0 ? selectedValues[0] : '';
    cacheStrings[eachKey] = element;
    cacheDependency = `${cacheDependency}-${element}`;
  });

  const categoryCodeForCurrentCategory =
    categories.find(({ name }) => name === currentCategory) ?? [];

  useEffect(() => {
    const queryString1 = `${qs.stringify(currentQueryParams, {
      indices: false,
    })}`;
    dispatch(setSelectedFilters(currentQueryParams));
    if (
      TagsFilters.every(element =>
        Object.keys(currentQueryParams).includes(element)
      )
    ) {
      setShowTags.on();
      dispatch(fetchTagsThunk(queryString1));
    } else {
      setShowTags.off();
      setSelectedTag('');
      dispatch(setChartsData([]));
    }
  }, [queryParams]);

  useEffect(() => {
    if (
      TagsFilters.every(
        element =>
          Object.keys(cacheStrings).includes(element) && cacheStrings[element]
      )
    ) {
      dispatch(fetchChartTagsThunk(cacheStrings));
      setSelectedTag('');
    }
  }, [cacheDependency]);

  function getChartData(event: ChangeEvent<HTMLInputElement>) {
    setSelectedTag(event.target.value);
    let currentQueryParams = getQueryParams(queryParams);
    const chartDataEndpoint = `${event.target.value}/${currentQueryParams.retailer[0]}/${currentQueryParams.category[0]}/${currentQueryParams.gender[0]}/${currentQueryParams.region[0]}/${currentQueryParams.date[0]}`;
    dispatch(fetchChartThunk(chartDataEndpoint));
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="var(--rhino)"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    dispatch(fetchRetailersThunk());
    dispatch(fetchExcelTemplateCategoriesThunk(1));
  }, []);

  const customRenderer = (
    tag: {
      value: string;
      count: number;
      attr_name: string;
      attr_value: string;
    },
    size: number
  ) => {
    let randomColorPill =
      TAGS_COLORS[Math.floor(Math.random() * TAGS_COLORS.length)];

    return (
      <Styles.Pills
        key={tag.value}
        className="cursor-pointer"
        href={
          Object.keys(categoryCodeForCurrentCategory)?.length > 0
            ? `${URL}/trends/${
                categoryCodeForCurrentCategory?.category_code
              }/list?${setQueryStringValueToUrl({
                [tag.attr_name]: [tag.attr_value],
              })}&gender=${currentQueryParams.gender[0]}`
            : '#'
        }
        target="_blank"
        style={{
          fontSize: `${size / 2}px`,
          margin: '10px',
          padding: '12px',
          display: 'inline-block',
          color: `${randomColorPill}`,
          backgroundColor: `${PILL_COLOR_COMBINATIONS[randomColorPill]}`,
          borderRadius: '24px',
        }}
      >
        {capitalizeFirstLetter(tag.value)}
      </Styles.Pills>
    );
  };

  function handleFilterChange(
    param: Partial<Record<FilterSlugType, Array<any>>>,
    reset: boolean = false
  ) {
    const prevQueryParams = getQueryParams(queryParams);
    let queryParamObject = {};
    if (!reset) {
      queryParamObject = {
        ...prevQueryParams,
        ...param,
      };
    }
    const sanitizedFilters = removeFalsyKeys(queryParamObject);
    const queryString = setQueryStringValueToUrl(sanitizedFilters);
    setSearch(new URLSearchParams(queryString));
  }

  return (
    <>
      <Styles.HeaderWrapper className="center">
        <InformationCircleIcon />
        <Styles.BetaText>
          Currently in beta. Supports only single selection of filters
        </Styles.BetaText>
      </Styles.HeaderWrapper>
      <Styles.Wrapper>
        <Filters search={search} handleFilterChange={handleFilterChange} />
        <HeaderFiltersView onFilterChange={handleFilterChange} />
        <Styles.TrendsWrapper>
          <Styles.HeadingText>Trend Analytics</Styles.HeadingText>
          <Styles.TagsWrapper className="d-flex">
            {showTags ? (
              <>
                {tags.length > 0 ? (
                  <>
                    <TagCloud
                      minSize={20}
                      maxSize={70}
                      tags={tags}
                      renderer={customRenderer}
                      shuffle
                    />
                    <Styles.ChartWrapper>
                      <Styles.LabelName>Tags</Styles.LabelName>
                      <Dropdown
                        options={tagsValue}
                        onChange={getChartData}
                        value={defaultTag}
                        dataname="tag"
                        hideAllOption
                        datalabel="Select"
                        dropdownClass="dropdownClass"
                        dropdownPlaceholderClass="dropdownPlaceholderClass"
                        dropdownBoxClass="dropdownBoxClass"
                        dropDownItemClass="dropDownItemClass"
                        dropDownItemActiveClass="dropDownItemActiveClass"
                        isSearchBox={false}
                      />
                      {defaultTag.length > 0 && chartData.length > 0 ? (
                        <>
                          <Styles.LedgerWrapper className="d-flex flex-wrap">
                            {chartData?.map((each, index) => (
                              <Styles.EachTag className="center" key={index}>
                                <Styles.TagColor
                                  backgroundColor={COLORS[index]}
                                />
                                <Styles.TagName>{each.name}</Styles.TagName>
                              </Styles.EachTag>
                            ))}
                          </Styles.LedgerWrapper>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={chartData}
                              cx={200}
                              cy={200}
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius={120}
                              fill="var(--moon-raker)"
                              dataKey="value"
                            >
                              {chartData.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </>
                      ) : (
                        <Styles.NoDataWrapper className="spread">
                          <Styles.NoDataText>
                            {defaultTag.length > 0
                              ? 'No Data for this Tag'
                              : 'Select the above dropdown to view analytics'}
                          </Styles.NoDataText>
                        </Styles.NoDataWrapper>
                      )}
                    </Styles.ChartWrapper>
                  </>
                ) : (
                  <Styles.NoTagsDataWrapper className="full-width v-center">
                    <ErrorStateTrendsIllustration />
                    <Styles.NoTagsText>
                      We currently do not have the data to show on selected
                      fields
                    </Styles.NoTagsText>
                  </Styles.NoTagsDataWrapper>
                )}
              </>
            ) : (
              <Styles.NoTagsWrapper className="v-center full-width">
                <NoTrendsDataIllustration />
                <Styles.NoTagsText>
                  Please select all the above fields to see trending data
                </Styles.NoTagsText>
              </Styles.NoTagsWrapper>
            )}
          </Styles.TagsWrapper>
        </Styles.TrendsWrapper>
        <Styles.DisclaimerWrapper className="d-flex">
          <Styles.IconOuterWrapper className="center">
            <InformationCircleIcon stroke="var(--watermelon)" size="16px" />
          </Styles.IconOuterWrapper>
          <Styles.DisclaimerTextWrapper className="v-d-flex">
            <Styles.DisclaimerText>Disclaimer</Styles.DisclaimerText>
            <Styles.DisclaimerDescriptionText>
              Note that the trends assessment and analysis is conducted solely
              on the basis of publicly available information as released and
              listed on the e-commerce websites of certain key global apparel
              brands. The analysis or any part thereof is not to be treated as
              trends, business or commercial assessment / analysis and this is
              purely for informational purposes and not for distribution to any
              party, and must not be reproduced or redistributed to any person.
              None can use the analysis or any part thereof as a base for any
              claim, demand or cause of action and, also none is responsible for
              any loss incurred based upon any such trends assessment /
              analysis.
            </Styles.DisclaimerDescriptionText>
          </Styles.DisclaimerTextWrapper>
        </Styles.DisclaimerWrapper>
      </Styles.Wrapper>
    </>
  );
};

export default Trends;
