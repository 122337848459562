import copy from 'copy-to-clipboard';
import Toast from '../../library/Toast';
import { isEmptyObject } from './assertion';
import { IObjectType } from '../types';

export const createElementWithEvent = ({
  value,
  dataname,
  onChange,
  event,
  dataProps,
}: IObjectType) => {
  const elem = document.createElement('input');
  if (!isEmptyObject(dataProps)) {
    for (const item in dataProps) {
      if (dataProps.hasOwnProperty(item)) {
        elem.setAttribute(item, dataProps[item]);
      }
    }
  }
  elem.setAttribute('data-name', dataname);
  elem.setAttribute('value', value);
  elem.addEventListener(event, onChange);
  elem.dispatchEvent(new Event(event));
  return elem;
};

export const stopEvent = (event: Event) => {
  event.preventDefault();
  event.stopPropagation();
};

export const getFileExt = (fname: string) =>
  fname.slice((Math.max(0, fname.lastIndexOf('.')) || Infinity) + 1);

export const getFileNameWithoutExt = (fname: string) =>
  fname.slice(0, Math.max(0, fname.lastIndexOf('.')));

export const copyToClipboard = (textToCopy: string) => {
  const didCopy = copy(textToCopy);
  if (didCopy) {
    Toast.SUCCESS('Copied');
  }
  return textToCopy;
};
