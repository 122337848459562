import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const { 'excel-upload-in-progress': ExcelUploadInProgress } = ICONOGRAPHY;

interface IUploadInProgressProps {
  fileName: string;
}

const UploadInProgress = ({ fileName }: IUploadInProgressProps) => {
  return (
    <Styles.Wrapper className="outer center">
      <ExcelUploadInProgress />
      <Styles.FileName>{fileName}</Styles.FileName>
      <Styles.UploadProgressBar>
        <Styles.Wrapper className="progress-bar">
          <Styles.Title>Uploading file</Styles.Title>
          <Styles.CompletionPercentage>70%</Styles.CompletionPercentage>
        </Styles.Wrapper>
        <Styles.ProgressBar>
          <Styles.ProgressBarFill isComplete={false} />
        </Styles.ProgressBar>
      </Styles.UploadProgressBar>
    </Styles.Wrapper>
  );
};

export default UploadInProgress;
