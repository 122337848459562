import React from 'react';
import { useBoolean } from 'utils/hooks/useBoolean';
import { StyledButtonVariant } from 'components/StyledButton';
import Modal from './Modal';
import * as Styles from './styles';

const UploadImages = () => {
  const [isUploadImageModalOpen, uploadImageModalActions] = useBoolean();

  return (
    <>
      {isUploadImageModalOpen && (
        <Modal onClose={() => uploadImageModalActions.off()} />
      )}
      <Styles.UploadImageTab
        variant={StyledButtonVariant.Tertiary}
        onClick={() => uploadImageModalActions.on()}
      >
        Upload To CDN
      </Styles.UploadImageTab>
    </>
  );
};

export default UploadImages;
