import { useTypedSelector } from 'utils/hooks';
import { convertArrayToObject } from 'utils/utility/array';

export function useSetInitialFeedback() {
  const feedbackData = useTypedSelector(store => store.ruleEngine.feedbackData);

  const feedbackDataInObject = Object.keys(feedbackData).map(
    key => ({
      attributeName: key,
      attributeOptions: [],
      otherInput: [],
    }),
    {}
  );

  return convertArrayToObject(feedbackDataInObject, 'attributeName');
}

export function useSetInitialViewFeedback() {
  const viewfeedbackData = useTypedSelector(
    store => store.ruleEngine.viewfeedbackData
  );

  const feedbackDataInObject = Object.keys(viewfeedbackData).map(
    item => ({
      attributeName: viewfeedbackData[item].attributeName,
      attributeOptions: viewfeedbackData[item].attributeOptions,
      otherInput: viewfeedbackData[item].otherInput,
    }),
    {}
  );

  return convertArrayToObject(feedbackDataInObject, 'attributeName');
}
