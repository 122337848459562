import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextInputWithLabel from 'components/textInputWithLabel/TextInputWithLabel';
import { getEmailerDataThunk, updateEmailersDataThunk } from 'actions/emailers';
import { useTypedSelector } from 'utils/hooks';
import ICONOGRAPHY from 'CustomConstant/icons';
import { EMAILER_DATA } from './constants';
import { IEmailers } from './types';
import * as Styles from './styles';

interface IEmailerProps {
  onClose: () => void;
}

const { close: CloseIcon } = ICONOGRAPHY;

const Emailers = ({ onClose }: IEmailerProps) => {
  const [emailForm, setEmailForm] = useState<IEmailers>({
    subject: '',
    secondary_button_background_color: '',
    secondary_description_text_color: '',
    description_text_color: '',
    description: '',
    first_collection_url: '',
    secondary_description: '',
    primary_button_text_color: '',
    hero_image_link: '',
    secondary_button_title: '',
    secondary_title: '',
    collection_description_text_color: '',
    title_text_color: '',
    primary_button_background_color: '',
    secondary_title_text_color: '',
    secondary_section_background_color: '',
    primary_button_url: '',
    collection_products_background_color: '',
    primary_section_background_color: '',
    secondary_button_url: '',
    secondary_button_title_color: '',
    primary_button_text: '',
    collection_description: '',
    collection_products_name_text_color: '',
  });
  const dispatch = useDispatch();
  const isSendingMail = useTypedSelector(state => state.emailer.isLoading);
  const emailData = useTypedSelector(state => state.emailer.emailData) ?? {};

  useEffect(() => {
    dispatch(getEmailerDataThunk());
  }, []);

  useEffect(() => {
    setEmailForm(emailData);
  }, [emailData]);

  function handleEmailFormChange(key: string | number, value: string) {
    setEmailForm(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }

  function handleSendMail() {
    dispatch(updateEmailersDataThunk(emailForm));
  }

  return (
    <>
      <Styles.Header className="spread">
        <Styles.Title>Emailers</Styles.Title>
        <div className="center">
          <Styles.Example
            className="cursor-pointer"
            href="https://tna-app-media.s3.ap-south-1.amazonaws.com/Email+Designs/collection_template_example.pdf"
            target="_blank"
          >
            Example Image for keys
          </Styles.Example>
          <Styles.CloseWrapper className="center cursor-pointer">
            <CloseIcon onClick={onClose} />
          </Styles.CloseWrapper>
        </div>
      </Styles.Header>
      <Styles.EmailDataWrapper className="full-width d-flex flex-wrap has-y-scroll">
        {EMAILER_DATA?.map(each => (
          <TextInputWithLabel
            key={each.id}
            datalabel={each.key}
            dataname={each.key}
            placeholder={each.key}
            shouldAutoFocus={false}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleEmailFormChange(each.key, event.target.value)
            }
            value={emailForm[each.key]}
          />
        ))}
      </Styles.EmailDataWrapper>
      <Styles.Actions className="full-width flex-end">
        <Styles.SaveButton onClick={handleSendMail}>
          {isSendingMail ? <Styles.Spinner /> : <p>Update Mail</p>}
        </Styles.SaveButton>
      </Styles.Actions>
    </>
  );
};

export default Emailers;
