import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultToastStyles: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

const Toast = Object.freeze({
  DEFAULT: (text: string) => toast(text, defaultToastStyles),
  INFO: (text: string) => toast.info(text, defaultToastStyles),
  SUCCESS: (text: string) => toast.success(text, defaultToastStyles),
  WARN: (text: string) => toast.warn(text, defaultToastStyles),
  ERROR: (text: string) => toast.error(text, defaultToastStyles),
});

export default Toast;
