import { Link, useParams } from 'react-router-dom';
import HeaderToolbar from 'library/HeaderToolbar';
import TabsGroup from 'library/TabsGroup';
import { Tab } from 'library/TabsGroup/Tab';
import {
  moveToCatalogAddRoute,
  routeUrlsChanged,
} from 'services/crossOriginMsg';
import { useTypedSelector } from 'utils/hooks';
import {
  CATALOG_MANAGER_TABS,
  CATEGORY_MANAGER_SUPPLIER_TABS,
} from './constants';
import * as Styles from './style';

interface Props {
  children: JSX.Element;
  rightContent?: JSX.Element;
  hideBackBtn?: boolean;
  onMoveBack?: () => void;
}

const RouteElementWrapper = ({
  children,
  rightContent,
  hideBackBtn = true,
  onMoveBack,
}: Props) => {
  const { tabType } = useParams() as { tabType: string };
  const isSupplier = useTypedSelector(state => state.user.is_supplier);

  const currentUserTabs = isSupplier
    ? CATEGORY_MANAGER_SUPPLIER_TABS
    : CATALOG_MANAGER_TABS;

  return (
    <div className="v-d-flex full-width">
      <HeaderToolbar
        title="Catalog Manager"
        hasDefaultBack={hideBackBtn}
        rightNode={rightContent}
        onBackClick={moveToCatalogAddRoute}
      >
        <div>
          <TabsGroup
            activeChildIndex={currentUserTabs.findIndex(
              eachField => eachField.tabType === tabType
            )}
          >
            {currentUserTabs.map(item => (
              <Tab
                key={item.tabType}
                tabTitleProps={{
                  as: Link,
                  to: `/list/${item.tabType}`,
                  className: 'd-flex',
                  onClick: () => routeUrlsChanged(item.tabType),
                }}
                title={item.title}
                isSelected={item.tabType === tabType}
              />
            ))}
          </TabsGroup>
        </div>
      </HeaderToolbar>
      <Styles.HeaderLine />
      <Styles.Body className="full-view-dimensions">{children}</Styles.Body>
    </div>
  );
};

export default RouteElementWrapper;
