import React from "react";
import { useDispatch } from "react-redux";
import { BsDownload, BsArrowRight } from "react-icons/bs";
import StyledButton, { StyledButtonVariant } from "../../StyledButton";
import Dropdown from "../../Dropdown";
import { AttachmentType } from "../../../utils/types";
import { IDownloadExcelState } from "../types";
import * as Styles from "./styles";
import { fetchExcelTemplateDownloadLinkThunk } from "../../../actions/catalog";

const BUTTON_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  padding: "12px",
  lineHeight: "15px",
  fontWeight: "600",
  width: "100%",
};

interface IDownloadExcelProps {
  handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    data?: Array<AttachmentType> | number | string
  ): void;
  excelTemplateCategory: Array<IDownloadExcelState>;
  className?: string;
}

const DownloadExcelTemplate = ({
  handleChange,
  excelTemplateCategory,
  className = "",
}: IDownloadExcelProps) => {
  const dispatch = useDispatch();
  const downloadExcelTemplateButton =
    excelTemplateCategory.length > 0 &&
    excelTemplateCategory[excelTemplateCategory.length - 1]
      .selectedProductId === "" ? (
      <StyledButton
        variant={StyledButtonVariant.Disabled}
        style={BUTTON_STYLE}
        disabled
      >
        <BsDownload size="1.3em" />
        &nbsp; Download Excel Template
      </StyledButton>
    ) : (
      <StyledButton
        variant={StyledButtonVariant.PacificBlueOutlined}
        style={BUTTON_STYLE}
        onClick={() => {
          const level = excelTemplateCategory.length - 1;
          const selectedCategoryId =
            excelTemplateCategory[level].selectedProductId;
          dispatch(fetchExcelTemplateDownloadLinkThunk(selectedCategoryId));
        }}
      >
        Download Excel Template&nbsp; <BsArrowRight size="1.5em" />
      </StyledButton>
    );

  return (
    <Styles.Wrapper className={`${className} center`}>
      <Styles.Content>
        <Styles.SectionHeader>
          Don&apos;t have an excel file to upload?
        </Styles.SectionHeader>
        <Styles.SubText>
          Select your category and download an excel template.
        </Styles.SubText>
        <Styles.DropdownContainer>
          {excelTemplateCategory.map((item, index) => {
            const selectedValue =
              item.selectedProductId !== ""
                ? item.levelOptions.filter(
                    (options) => options.id === Number(item.selectedProductId)
                  )[0]?.name
                : "";

            return (
              <Styles.DropdownWrapper key={index}>
                <div className="label">{`Select Category L${index + 1}`}</div>
                <Dropdown
                  options={item.levelOptions}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "excelTemplateCategory", index)
                  }
                  value={selectedValue}
                  dataname={"type"}
                  datalabel="Select"
                  dropdownClass="dropdown-class"
                  dropdownBoxClass="dropdownBox-class"
                  dropdownItemClass="dropdown-item-class"
                  openClass="openClass"
                  itemClass="itemClass "
                  isSearchBox
                  hideAllOption
                />
              </Styles.DropdownWrapper>
            );
          })}
          {downloadExcelTemplateButton}
        </Styles.DropdownContainer>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default DownloadExcelTemplate;
