import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 32px;
  margin: 40px 0 40px;
  border: 1px dashed var(--mischka);
  border-radius: 4px;

  .upload-image {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    padding: 12px 103px;
    margin-top: 24px;

    .bs-upload {
      stroke-width: 0.8px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
`;

export const SectionHeader = styled.p`
  color: var(--rhino);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  line-height: 22px;
`;
