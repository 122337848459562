import { MdContentCopy } from 'react-icons/md';
import { Column } from 'react-table';
import { convertToDate } from 'utils/helpers';
import { copyToClipboard } from 'utils/utility';
import ToggleDropdownControl from './Table/ToggleDropdownControl';
import * as Styled from './styles';

type ColumnType = Column & {
  disableSortBy?: true | false;
};

export function getFields(): Array<ColumnType> {
  return [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row, value }) => {
        const data = row.original as { imageUrl: string };

        return (
          <Styled.TitleWrapper className="align-center">
            <Styled.Image src={data.imageUrl} />
            {value}
          </Styled.TitleWrapper>
        );
      },
    },
    {
      Header: 'Link ID',
      accessor: 'linkIdentifier', // accessor is the "key" in the data
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Created By',
      accessor: 'addedBy',
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Created Date',
      accessor: 'createdOn',
      Cell: ({ value }) => (
        <Styled.RowData>{convertToDate(value)}</Styled.RowData>
      ),
    },
    {
      Header: 'Link',
      accessor: 'url',
      Cell: ({ value }) => (
        <Styled.TitleWrapper className="align-center">
          <Styled.RowData>{value}</Styled.RowData>
          <Styled.CopyToClipboardIcon>
            <MdContentCopy size="20px" onClick={() => copyToClipboard(value)} />
          </Styled.CopyToClipboardIcon>
        </Styled.TitleWrapper>
      ),
    },
    {
      Header: '',
      Cell: ({ value }) => <ToggleDropdownControl data={value} />,
      accessor: 'dots',
      disableSortBy: true,
    },
  ];
}
