export const TITLE_LIMIT = 64;

export const DESCRIPTION_LIMIT = 256;

export const INITIAL_STATE = {
  id: null,
  name: '',
  season: '',
  material: [],
  fabric_type: null,
  description: '',
  tags: [],
  brand_ids: [],
  sales_representative: null,
  category_manager: null,
  designer: null,
  pdf_url: '',
  thumbnail_url: '',
  category: 1,
  bu_head: null,
};

export const DEFAULT_DROPDOWN_PROPS = Object.freeze({
  datalabel: 'Select',
  dropdownClass: 'dropdownClass',
  dropdownPlaceholderClass: 'dropdownPlaceholderClass',
  dropdownBoxClass: 'dropdownBoxClass',
  dropDownItemClass: 'dropDownItemClass',
  dropDownItemActiveClass: 'dropDownItemActiveClass',
});

export const DEFAULT_IMAGE =
  'https://catalog-sheets-prod.s3.ap-south-1.amazonaws.com/design-library/thumbnail/default_thumbnail.png';
