import { ChangeEvent } from 'react';
import { Tag } from 'react-tag-autocomplete';
import Dropdown from 'components/Dropdown';
import DropdownTags from './DropdownTags';
import * as Styles from './styles';

interface IDropdownWithTags {
  label: string;
  options: Array<Record<string | number, string>>;
  onChange(name: string): void;
  selectedOptions: Array<Tag>;
  handleTagDelete(tag: Tag): void;
  isSearchBox: boolean;
  hideAllOption: boolean;
}

const DropdownWithTags = ({
  label,
  options,
  onChange,
  selectedOptions,
  handleTagDelete,
  isSearchBox,
  hideAllOption,
}: IDropdownWithTags) => {
  return (
    <>
      <Styles.DropdownLabel>{label}</Styles.DropdownLabel>
      <>
        <Dropdown
          options={options}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.value)
          }
          dataname={label}
          hideAllOption={hideAllOption}
          dropdownClass="dropdown-class"
          dropdownBoxClass="dropdownBox-class"
          dropdownItemClass="dropdown-item-class"
          openClass="openClass"
          itemClass="itemClass"
          placeholder="Select"
          isSearchBox={isSearchBox}
        />
      </>
      <div className="d-flex flex-wrap">
        {selectedOptions.map(each => {
          return (
            <DropdownTags
              key={each.id}
              tag={{
                id: each.id,
                name: each.name,
              }}
              onDelete={handleTagDelete}
            />
          );
        })}
      </div>
    </>
  );
};

export default DropdownWithTags;
