export enum createSheetUploadStatusEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export const ROUTE_PREFIX = '/catalog/add';

export const EMPTY_UPLOAD_VIEW_IMAGE_URL =
  'https://tna-app-media-development.s3.amazonaws.com/public/0be50978cc8a2c5b1abbdec6b2de5174b31795de.png';

export const STANDARD_PAGE_SIZE = 50;
export const MAXIMUM_PAGE_SIZE = 10000;
export enum BulkOperationEnum {
  SET_INACTIVE,
  SET_APPROVED,
  SET_ARCHIVE,
  SET_ACTIVE,
}

export const FILE_NAME_REGEX = /[^a-zA-Z.0-9-_]+/g;
