import { Tag } from 'react-tag-autocomplete';
import { getSetDifference, getSetUnion } from 'utils/utility/utils';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import {
  IAdminObject,
  ISharedCatalog,
} from 'components/ShareCatalogList/types';
import {
  IGetEachUserObject,
  IGetLocationData,
} from 'components/AddCatalogList/types';
import { ICollection } from 'components/Collections/types';
import { update } from './helpers';
import {
  BulkOperationEnum,
  createSheetUploadStatusEnum,
} from '../utils/constants';
import CATALOG_ACTION_TYPES from '../actionTypes/catalog';
import { IBrand, IReduxActionType } from '../utils/types';

export interface IPaginationInfoType {
  count: number;
  totalPages: number;
  currentPage: number;
}

export interface ICatalogInitialState {
  list: Array<any>;
  isLoading: boolean;
  excelTemplateDownloadLink: string;
  excelTemplateCategories: Array<any>;
  selectedProductIds: Set<string>;
  isFetchingProducts: boolean;
  isDownloadAllProductsButtonLoading: boolean;
  isQrCodeButtonLoading: boolean;
  isApproveButtonLoading: boolean;
  isSetArchiveButtonLoading: boolean;
  isSetInactiveButtonLoading: boolean;
  isSetActiveButtonLoading: boolean;
  createSheetUploadStatus: createSheetUploadStatusEnum;
  isUpdatingSheetUrl: boolean;
  isFetchingAllCategories: boolean;
  isFetchingSuppliers: boolean;
  isFetchingMaterials: boolean;
  paginationInfo: IPaginationInfoType;
  uploadedSheetUrl: string;
  filters: {
    category: Array<any>;
    supplier: Array<any>;
    material: Array<any>;
  };
  primaryFilters: Record<string, Array<any>>;
  secondaryFilters: Array<any>;
  secondaryFilterSelectedTag: string;
  secondaryFilterSelectedTagOptions: Array<any>;
  selectedFilters: Record<string, Array<any>>;
  createSheetUploadErrorMessage: string;
  shareableLink: string;
  shareableCatalogs: Array<ISharedCatalog>;
  loaderStatus: boolean;
  catalogId: string;
  admins: Array<IAdminObject>;
  categoryManagersData: Array<IGetEachUserObject>;
  salesRepresentativesData: Array<IGetEachUserObject>;
  designersData: Array<IGetEachUserObject>;
  buHeadData: Array<IGetEachUserObject>;
  shareableCatalogsCount: Number;
  uploadModalVisibility: boolean;
  isTrendingModalOpen: boolean;
  trendingCategoryTitle: string;
  trendingTags: Array<Tag>;
  trendingCategory: Record<string, string>;
  submitTrendingDataLoader: boolean;
  isTrendingCategoryLoading: boolean;
  tabId: string;
  brands: Array<IBrand>;
  collectionPaginationInfo: Record<string, number>;
  collectionData: Array<ICollection>;
  collectionUrl: string;
  isSaveCollectionButtonLoading: boolean;
  isSaveCollectionSuccessfull: boolean;
  allLocationRegionData: Array<IGetLocationData>;
  isSaveImageButtonLoading: boolean;
}

const initialState: ICatalogInitialState = {
  list: [],
  isLoading: false,
  excelTemplateDownloadLink: '',
  excelTemplateCategories: [],
  selectedProductIds: new Set(),
  isFetchingProducts: false,
  isDownloadAllProductsButtonLoading: false,
  isQrCodeButtonLoading: false,
  isApproveButtonLoading: false,
  isSetInactiveButtonLoading: false,
  isSetArchiveButtonLoading: false,
  isSetActiveButtonLoading: false,
  createSheetUploadStatus: createSheetUploadStatusEnum.IDLE,
  createSheetUploadErrorMessage: '',
  isUpdatingSheetUrl: false,
  isFetchingAllCategories: false,
  isFetchingSuppliers: false,
  isFetchingMaterials: false,
  paginationInfo: {
    count: 0,
    totalPages: -1,
    currentPage: 0,
  },
  uploadedSheetUrl: '',
  filters: {
    category: [],
    supplier: [],
    material: [],
  },
  primaryFilters: {},
  secondaryFilters: [],
  secondaryFilterSelectedTag: '',
  secondaryFilterSelectedTagOptions: [],
  selectedFilters: {},
  shareableLink: '',
  shareableCatalogs: [],
  shareableCatalogsCount: 0,
  loaderStatus: false,
  catalogId: 'CAT001',
  admins: [],
  categoryManagersData: [],
  salesRepresentativesData: [],
  designersData: [],
  buHeadData: [],
  uploadModalVisibility: false,
  isTrendingModalOpen: false,
  trendingCategoryTitle: '',
  trendingTags: [],
  trendingCategory: {},
  submitTrendingDataLoader: false,
  isTrendingCategoryLoading: false,
  tabId: 'clothing',
  brands: [],
  collectionPaginationInfo: {
    count: 0,
    totalPages: 0,
    currentPage: 0,
  },
  collectionData: [],
  collectionUrl: '',
  isSaveCollectionButtonLoading: false,
  isSaveCollectionSuccessfull: false,
  allLocationRegionData: [],
  isSaveImageButtonLoading: false,
};

function catalogReducer(
  state: ICatalogInitialState = initialState,
  action: IReduxActionType
) {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.FETCH_CATALOGS_REQUEST:
      return update(state, { isFetchingProducts: action.payload });

    case CATALOG_ACTION_TYPES.FETCH_CATALOGS_SUCCESS: {
      // default for set, else for append
      let newList = [...action.payload];
      if (action?.meta?.operation === OperatorEnum.Append) {
        newList = [...state.list, ...newList];
      } else if (action?.meta?.operation === OperatorEnum.Modify) {
        let updatedList = state.list.map(item => {
          if (state.selectedProductIds.has(item.product_id)) {
            let newData = newList.find(
              newItem => newItem.product_id === item.product_id
            );
            if (newData) {
              return newData;
            }
          }
          return item;
        });
        newList = [...updatedList];
      }
      return update(state, { list: newList });
    }

    case CATALOG_ACTION_TYPES.FETCH_DOWNLOAD_ALL_PRODUCT: {
      return update(state, {
        isDownloadAllProductsButtonLoading: action.payload,
      });
    }

    case CATALOG_ACTION_TYPES.ADD_SELECTED_PRODUCT_IDS: {
      const selectedProductIds = [
        ...Array.from(state.selectedProductIds),
        ...action.payload,
      ];
      return update(state, {
        selectedProductIds: new Set(selectedProductIds),
      });
    }

    case CATALOG_ACTION_TYPES.REMOVE_SELECTED_PRODUCT_IDS: {
      const selectedProductIds = new Set(Array.from(state.selectedProductIds));
      [...action.payload].forEach(eachProductId => {
        selectedProductIds.delete(eachProductId);
      });
      return update(state, {
        selectedProductIds,
      });
    }

    case CATALOG_ACTION_TYPES.TOGGLE_IS_APPROVE_BUTTON_LOADING:
      return update(state, { isApproveButtonLoading: action.payload });

    case CATALOG_ACTION_TYPES.TOGGLE_IS_SET_INACTIVE_BUTTON_LOADING:
      return update(state, { isSetInactiveButtonLoading: action.payload });

    case CATALOG_ACTION_TYPES.TOGGLE_IS_ARCHIVE_BUTTON_LOADING:
      return update(state, { isSetArchiveButtonLoading: action.payload });

    case CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_CATEGORIES_REQUEST:
      return update(state, { isLoading: true });

    case CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_CATEGORIES_SUCCESS:
      return update(state, {
        isLoading: false,
        excelTemplateCategories: action.payload,
      });

    case CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_CATEGORIES_FAILURE:
      return update(state, { isLoading: false });

    case CATALOG_ACTION_TYPES.SET_IS_DATA_PAGINATED:
      return update(state, { isDataPaginated: action.payload });

    case CATALOG_ACTION_TYPES.SET_PAGINATION_INFO:
      return update(state, { paginationInfo: { ...action.payload } });

    case CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_REQUEST:
      return update(state, { isLoading: true });

    case CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_FAILURE:
      return update(state, { isLoading: false });

    case CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_SUCCESS:
      return update(state, {
        isLoading: false,
        excelTemplateDownloadLink: action.payload,
      });

    case CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_REQUEST:
      return update(state, {
        isLoading: true,
        createSheetUploadStatus: createSheetUploadStatusEnum.LOADING,
      });

    case CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_SUCCESS:
      return update(state, {
        isLoading: false,
        createSheetUploadStatus: createSheetUploadStatusEnum.SUCCESS,
      });

    case CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_FAILURE:
      return update(state, {
        isLoading: false,
        createSheetUploadErrorMessage: action.payload,
        createSheetUploadStatus: createSheetUploadStatusEnum.FAILED,
      });

    case CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_IDLE:
      return update(state, {
        createSheetUploadStatus: createSheetUploadStatusEnum.IDLE,
      });

    case CATALOG_ACTION_TYPES.FETCH_SUPPLIERS_REQUEST:
      return update(state, { isFetchingSuppliers: true });

    case CATALOG_ACTION_TYPES.FETCH_SUPPLIERS_SUCCESS: {
      return update(state, {
        isFetchingSuppliers: false,
        filters: { ...state.filters, supplier: [...action.payload] },
      });
    }

    case CATALOG_ACTION_TYPES.FETCH_MATERIALS_REQUEST:
      return update(state, { isFetchingMaterials: true });

    case CATALOG_ACTION_TYPES.FETCH_MATERIALS_SUCCESS: {
      return update(state, {
        isFetchingMaterials: false,
        filters: { ...state.filters, material: [...action.payload] },
      });
    }

    case CATALOG_ACTION_TYPES.FETCH_MATERIALS_FAILURE: {
      return update(state, { isFetchingMaterials: false });
    }

    case CATALOG_ACTION_TYPES.FETCH_SUPPLIERS_FAILURE: {
      return update(state, { isFetchingSuppliers: false });
    }

    case CATALOG_ACTION_TYPES.FETCH_ALL_CATEGORIES_REQUEST:
      return update(state, { isFetchingAllCategories: true });

    case CATALOG_ACTION_TYPES.FETCH_ALL_CATEGORIES_SUCCESS: {
      return update(state, {
        isFetchingAllCategories: false,
        filters: { ...state.filters, category: [...action.payload] },
      });
    }

    case CATALOG_ACTION_TYPES.FETCH_ALL_CATEGORIES_FAILURE:
      return update(state, { isFetchingAllCategories: false });

    case CATALOG_ACTION_TYPES.SET_ALL_ADMINS:
      return update(state, { admins: action.payload });

    case CATALOG_ACTION_TYPES.SET_ALL_CATEGORY_MANAGERS_AND_SALESREPS:
      const { data, value } = action.payload;
      return update(state, { [value]: data });

    case CATALOG_ACTION_TYPES.SET_UPLOADED_SHEET_URL:
      return { ...state, uploadedSheetUrl: action.payload };

    case CATALOG_ACTION_TYPES.SET_IS_UPDATING_SHEET_URL:
      return { ...state, isUpdatingSheetUrl: action.payload };

    case CATALOG_ACTION_TYPES.SET_SHAREABLE_CATALOGS_COUNT:
      return { ...state, shareableCatalogsCount: action.payload };

    case CATALOG_ACTION_TYPES.UPDATE_STATUS_OF_INDIVIDUAL_ITEMS: {
      const { selectedProductIds } = state;
      const { operation } = action.meta;
      const updatedList = state.list.map(item => {
        if (selectedProductIds.has(item.product_id)) {
          if (operation === BulkOperationEnum.SET_INACTIVE) {
            // ? set "is_active" to false
            item.is_active = false;
          } else if (operation === BulkOperationEnum.SET_APPROVED) {
            // ? set "is_active" & "is_approved" to true
            item.is_active = true;
            item.is_approved = true;
          } else if (operation === BulkOperationEnum.SET_ACTIVE) {
            item.is_active = true;
          }
        }
        return { ...item };
      });

      return update(state, { list: updatedList });
    }

    case CATALOG_ACTION_TYPES.SET_SELECTED_PRODUCTS_AS_ARCHIVED:
      const { selectedProductIds } = state;
      const updatedList = state.list.filter(item => {
        return !selectedProductIds.has(item.product_id);
      });
      return {
        ...state,
        list: updatedList,
        selectedProductIds: new Set(),
      };

    case CATALOG_ACTION_TYPES.SHAREABLE_LINK:
      return update(state, { shareableLink: action.payload });

    case CATALOG_ACTION_TYPES.GET_ALL_SHAREABLE_CATALOGS: {
      if (action.meta.operator === OperatorEnum.Append) {
        const newList = [...state.shareableCatalogs, ...action.payload];
        return update(state, { shareableCatalogs: newList });
      }

      return update(state, { shareableCatalogs: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_LOADER_STATUS:
      return update(state, { loaderStatus: action.payload });

    case CATALOG_ACTION_TYPES.SET_UPLOAD_MODAL_VISIBILITY:
      return update(state, { uploadModalVisibility: action.payload });

    case CATALOG_ACTION_TYPES.SET_SECONDARY_FILTERS_INFO:
      return update(state, { secondaryFilters: action.payload });

    case CATALOG_ACTION_TYPES.SET_SECONDARY_SELECTED_FILTERS_INFO:
      return update(state, { ...action.payload });

    case CATALOG_ACTION_TYPES.SET_SELECTED_FILTERS: {
      let newFilters = { ...state.selectedFilters };
      if (
        action.payload.shouldReset ||
        Object.keys(action.payload.selectedFilters).length === 0
      ) {
        newFilters = {};
      } else {
        newFilters = {
          ...newFilters,
          ...action.payload.selectedFilters,
        };
      }
      return {
        ...state,
        selectedFilters: newFilters,
      };
    }

    case CATALOG_ACTION_TYPES.SET_PRIMARY_FILTERS: {
      let newPrimaryFilters: Record<string, Array<any>> = {};
      action.payload.forEach((eachFilter: any) => {
        newPrimaryFilters[eachFilter.key] = eachFilter.options;
      });
      return update(state, { primaryFilters: newPrimaryFilters });
    }

    case CATALOG_ACTION_TYPES.SET_CATALOG_ID: {
      return update(state, { ...action.payload });
    }

    case CATALOG_ACTION_TYPES.TOGGLE_TRENDING_MODAL: {
      return update(state, { isTrendingModalOpen: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_TRENDING_CATEGORY_TITLE: {
      return update(state, { trendingCategoryTitle: action.payload });
    }

    case CATALOG_ACTION_TYPES.DELETE_ENTERED_TAG_FOR_CATEGORY: {
      const newEnteredTags = state.trendingTags.filter(
        eachTag => eachTag.name !== action.payload.name
      );
      return update(state, { trendingTags: newEnteredTags });
    }

    case CATALOG_ACTION_TYPES.SET_ENTERED_TAGS_FOR_CATEGORY: {
      const newEnteredTags = [...action.payload];
      return update(state, { trendingTags: newEnteredTags });
    }

    case CATALOG_ACTION_TYPES.SET_SELECTED_TRENDING_CATEGORY_DATA: {
      return update(state, { trendingCategory: action.payload });
    }

    case CATALOG_ACTION_TYPES.SUBMIT_TRENDING_CATEGORY_DATA_LOADER: {
      return update(state, { submitTrendingDataLoader: action.payload });
    }

    case CATALOG_ACTION_TYPES.CHANGE_TRENDING_CATEGORY_LOADER: {
      return update(state, { isTrendingCategoryLoading: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_TAB_ID: {
      return update(state, { ...action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_BRANDS_DATA: {
      return update(state, { brands: action.payload });
    }

    case CATALOG_ACTION_TYPES.COLLECTION_PAGINATION_INFO: {
      return update(state, { collectionPaginationInfo: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_COLLECTION_DATA: {
      const { data, mode } = action.payload;
      if (mode === OperatorEnum.Append) {
        return update(state, {
          collectionData: [...state.collectionData, ...data],
        });
      }
      return update(state, { collectionData: data });
    }

    case CATALOG_ACTION_TYPES.SET_COLLECTION_URL: {
      return update(state, { collectionUrl: action.payload });
    }

    case CATALOG_ACTION_TYPES.UPDATE_COLLECTION: {
      const changingCollection = action.payload;
      const updatedAllCollection = [...state.collectionData];
      for (const eachCollection of updatedAllCollection) {
        if (
          eachCollection.linkIdentifier === changingCollection.link_identifier
        ) {
          let updatedProductIds = getSetUnion(
            new Set(eachCollection.productsData),
            new Set(changingCollection.add)
          );
          updatedProductIds = getSetDifference(
            updatedProductIds,
            new Set(changingCollection.delete)
          );
          eachCollection.title = changingCollection.title;
          eachCollection.description = changingCollection.description;
          eachCollection.brandIds = changingCollection.brand_ids;
          eachCollection.productsData = Array.from(updatedProductIds);
          break;
        }
      }

      return update(state, { collectionData: updatedAllCollection });
    }

    case CATALOG_ACTION_TYPES.SET_SAVE_COLLECTION_LOADER: {
      return update(state, { isSaveCollectionButtonLoading: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_COLLECTION_SAVE_SUCCESS: {
      return update(state, { isSaveCollectionSuccessfull: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_LOCATION_REGION_DATA: {
      return update(state, { allLocationRegionData: action.payload });
    }

    case CATALOG_ACTION_TYPES.SET_SELECTED_PRODUCTS_LOCATION: {
      const { selectedProductIds, allLocationRegionData } = state;
      const newList = [...state.list];
      const selectedRegion = action.payload;
      let updatedRegion = allLocationRegionData.filter(
        item => selectedRegion.indexOf(item.id) !== -1
      );
      const updatedList = newList.map(item => {
        return {
          ...item,
          region: selectedProductIds.has(item.product_id)
            ? updatedRegion
            : item.region,
        };
      });
      return {
        ...state,
        list: updatedList,
        selectedProductIds: new Set(),
      };
    }

    case CATALOG_ACTION_TYPES.TOGGLE_SAVE_IMAGE_LOADER: {
      return update(state, {
        isSaveImageButtonLoading: action.payload,
      });
    }

    case CATALOG_ACTION_TYPES.UPDATE_IMAGES_IN_ORIGINAL_LIST: {
      const { selectedId, updatedImage } = action.payload;
      const newList = [...state.list];

      const updatedList = newList.map(item => {
        return {
          ...item,
          images: selectedId === item.id ? updatedImage : item.images,
        };
      });

      return {
        ...state,
        list: updatedList,
      };
    }

    case CATALOG_ACTION_TYPES.FETCH_QR_CODE_LOADER: {
      return update(state, {
        isQrCodeButtonLoading: action.payload,
      });
    }

    default:
      return state;
  }
}

export default catalogReducer;
