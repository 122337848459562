import styled, { keyframes } from "styled-components";

interface IProgressBar {
  isComplete: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 121px;

  &.outer {
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    width: fit-content;
    margin: 49px auto;
  }

  &.progress-bar {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 8px;
  }
`;

export const UploadProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const CompletionPercentage = styled.span`
  color: var(--ocean-green);
  font-size: 14px;
  font-weight: 600;
`;

export const Title = styled.span`
  color: var(--rhino);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

const progressBarAnimation = keyframes`
  0% {width: 0%;}
  30% {width: 30%;}
  70% {width: 70%;}
`;

export const ProgressBar = styled.div`
  width: 320px;
  height: 8px;
  background-color: var(--athens-gray);
  border-radius: 8px;
`;

export const ProgressBarFill = styled.div<IProgressBar>`
  height: 8px;
  width: ${(props) => (props.isComplete ? "100%" : "70%")};
  background-color: var(--ocean-green);
  animation: ${progressBarAnimation} 1s linear 1;
  border-radius: 8px;
`;

export const FileName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--manatee);
`;
