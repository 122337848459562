import * as Styles from './styles';

interface ICurrencyInputProps {
  amount: number;
  onChange: any;
  showSymbol: boolean;
  type: string;
  placeholder: string;
  category?: string;
}

const CurrencyInput = ({
  category,
  amount,
  onChange,
  showSymbol = true,
  type,
  placeholder,
}: ICurrencyInputProps) => (
  <Styles.InputContainer className="full-width">
    <Styles.CurrencyCategory>{category}</Styles.CurrencyCategory>
    <Styles.Input className="d-flex">
      {showSymbol && (
        <Styles.CurrencySymbol className="center">$</Styles.CurrencySymbol>
      )}
      <Styles.InputPrice
        type={type}
        value={amount}
        placeholder={placeholder}
        onChange={event => onChange(event.target.value)}
      />
    </Styles.Input>
  </Styles.InputContainer>
);
export default CurrencyInput;
