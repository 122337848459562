import Toast from 'library/Toast';
import { openInNewTab } from 'utils/helpers';
import {
  ApiResponseEnum,
  ApiResponseHandlerType,
  APIResponseType,
} from 'customTypes/callbackActionTypes';

async function apiResponseHandler<T>(
  apiPromise: Promise<APIResponseType<T>>
): Promise<ApiResponseHandlerType<T>> {
  try {
    const response = await apiPromise;
    if (response.status) {
      return {
        type: ApiResponseEnum.Success,
        data: response.data,
        msg: response.msg,
      };
    }
    return {
      type: ApiResponseEnum.Failure,
      error: response.msg,
      source: 'response',
    };
  } catch (error) {
    return {
      type: ApiResponseEnum.Failure,
      error: (error as Error).toString(),
      source: 'network',
    };
  }
}

export async function handleFileDownload(
  promise: Promise<APIResponseType<{ url: string }>>,
  errorMessage?: string
) {
  const response = await apiResponseHandler(promise);
  if (response.type === ApiResponseEnum.Success) {
    openInNewTab(response.data.url);
  } else if (errorMessage) {
    Toast.ERROR(errorMessage);
  } else {
    Toast.ERROR(response.error);
  }
}

export default apiResponseHandler;
