import { StyledButtonVariant } from 'components/StyledButton';
import { TUTORIAL_LINKS } from './constants';
import * as Styles from './styles';

const TutorialDropdown = () => (
  <Styles.TutorialContainer className="pos-r">
    <Styles.TutorialButton
      className="full-width"
      variant={StyledButtonVariant.Tertiary}
    >
      Tutorials
    </Styles.TutorialButton>
    <Styles.TutorialDropdown className="pos-a v-d-flex">
      {TUTORIAL_LINKS.map(eachLink => {
        return (
          <Styles.Link
            key={eachLink.link}
            className="fit-height"
            target="_blank"
            href={eachLink.link}
          >
            {eachLink.name}
          </Styles.Link>
        );
      })}
    </Styles.TutorialDropdown>
  </Styles.TutorialContainer>
);

export default TutorialDropdown;
