import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Toast from 'library/Toast';
import Dropdown from 'components/Dropdown';
import Checkbox from 'library/checkbox/checkbox';
import FileUpload from 'components/FileUpload';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  createRuleCollection,
  editRuleCollectionThunk,
  fillDataToStaging,
  toggleShowUrl,
} from 'actions/ruleEngines';
import {
  getImageMetaData,
  showErrorMessage,
} from 'components/AddCatalogList/ImageUpdation/helper';
import { copyToClipboard } from 'utils/utility';
import { modifyImageURl } from 'utils/utility/utils';
import { uploadFile } from 'services/cloudinary';
import { ACCEPTED_SIZE } from 'components/ImageLinks/helperConstant';
import ICONOGRAPHY from 'CustomConstant/icons';
import { useCategoryManager, useSaleRepresentative } from './helperHooks';
import { showMoodboardImagesUploadErrorMsg } from './helpers';
import { MAX_MOODBOARD_IMAGES } from './constants';
import { IMoodboardImage, StagingModeEnum } from './type';
import * as Styles from './styles';

const { add: AddIcon, copy: CopyIcon, trash: TrashIcon } = ICONOGRAPHY;

const GenerateLinkHeader = () => {
  const stagingData = useTypedSelector(store => store.ruleEngine.ruleStaging);
  const stagingMode = useTypedSelector(store => store.ruleEngine.stagingMode);
  const [fabricPrint, setFabricPrint] = useBoolean();
  const [aiPrint, setAIPrint] = useBoolean();
  const isSaveRuleButtonLoading = useTypedSelector(
    state => state.ruleEngine.isSaveRuleButtonLoading
  );
  const shouldShowUrl = useTypedSelector(
    state => state.ruleEngine.shouldShowUrl
  );
  const moodboardImages = stagingData?.images.map(eachImage => ({
    id: uuidv4(),
    url: eachImage,
  }));
  const [currentImages, setCurrentImages] =
    useState<Array<IMoodboardImage>>(moodboardImages);
  const [loaderStatus, setLoaderStatus] = useBoolean();
  const saleRepresentative = useSaleRepresentative();
  const categoryManager = useCategoryManager();
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(toggleShowUrl(false));
    }, 15000);

    return () => {
      clearTimeout(timer);
    };
  }, [shouldShowUrl]);

  useEffect(() => {
    if (stagingData.clusterType === 'fabric') {
      setFabricPrint.on();
    }
    if (stagingData.clusterType === 'digital_print') {
      setAIPrint.on();
    }
  }, [stagingMode]);

  useEffect(() => {
    if (StagingModeEnum.EDIT) {
      if (aiPrint) {
        dispatch(fillDataToStaging('clusterType', 'digital_print'));
        dispatch(fillDataToStaging('isCluster', true));
      }

      if (fabricPrint) {
        dispatch(fillDataToStaging('clusterType', 'fabric'));
        dispatch(fillDataToStaging('isCluster', true));
      }

      if (!aiPrint && !fabricPrint) {
        dispatch(fillDataToStaging('clusterType', ''));
        dispatch(fillDataToStaging('isCluster', false));
      }
    }
  }, [aiPrint, fabricPrint]);

  function GenerateLinkHandler() {
    if (stagingMode === StagingModeEnum.CREATE) {
      dispatch(createRuleCollection(stagingData));
    } else {
      dispatch(editRuleCollectionThunk(stagingData));
    }
  }

  function handleRuleNameChange(value: string, key: string) {
    dispatch(fillDataToStaging(key, value));
  }

  function handleOptinalNameCategorties(
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) {
    dispatch(fillDataToStaging(key, Number(event.target.value)));
  }

  async function handleUploadChange(event: ChangeEvent<HTMLInputElement>) {
    setLoaderStatus.on();
    const { files } = event.target;
    if (files === null || files.length === 0) {
      return;
    }

    const imageToUpload = files[0];
    const { width, height } = await getImageMetaData(imageToUpload);
    if (
      imageToUpload.size / 1000000 <= ACCEPTED_SIZE.MinimumImageSize &&
      height >= ACCEPTED_SIZE.MoodboardImageMinimumHeight &&
      height <= ACCEPTED_SIZE.MoodboardImageMaximumHeight &&
      width >= ACCEPTED_SIZE.MoodboardImageMinimumWidth &&
      width <= ACCEPTED_SIZE.MoodboardImageMaximumWidth
    ) {
      setLoaderStatus.on();
      const res = await uploadFile(imageToUpload);
      const updatedImageArray: Array<IMoodboardImage> = [
        ...currentImages,
        { id: uuidv4(), url: res?.link },
      ];
      setCurrentImages(updatedImageArray);
      dispatch(
        fillDataToStaging(
          'images',
          updatedImageArray.map(eachImage => eachImage.url)
        )
      );
    } else {
      showMoodboardImagesUploadErrorMsg({
        width,
        height,
        imageSize: imageToUpload.size,
        minHeight: ACCEPTED_SIZE.MoodboardImageMinimumHeight,
        maxHeight: ACCEPTED_SIZE.MoodboardImageMaximumHeight,
        minWidth: ACCEPTED_SIZE.MoodboardImageMinimumWidth,
        maxWidth: ACCEPTED_SIZE.MoodboardImageMaximumWidth,
      });
    }

    event.target.value = '';
    setLoaderStatus.off();
  }

  function handleMoodboardImageDelete(id: string) {
    const newImages = currentImages.filter(eachImage => eachImage.id !== id);
    setCurrentImages(newImages);
    dispatch(
      fillDataToStaging(
        'images',
        newImages.map(eachImage => eachImage.url)
      )
    );
  }

  return (
    <Styles.GenerateLinkWrapper className="v-d-flex">
      <div className="d-flex">
        <Styles.TitleText>
          <Styles.DropdownTitle>Rule Name *</Styles.DropdownTitle>
          <input
            value={stagingData.ruleName}
            type="text"
            placeholder="Rule name"
            onChange={event =>
              handleRuleNameChange(event.target.value, 'ruleName')
            }
          />
        </Styles.TitleText>
        <Styles.ConditionContainer>
          <Styles.DropdownTitle>Category Manager</Styles.DropdownTitle>
          <Dropdown
            options={categoryManager}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleOptinalNameCategorties(event, 'categoryManager')
            }
            value={stagingData.categoryManager}
            dataname="categoryManager"
            hideAllOption
            dropdownClass="dropdown-class"
            dropdownBoxClass="dropdownBox-class"
            dropdownItemClass="dropdown-item-class"
            openClass="openClass"
            itemClass="itemClass"
            placeholder="Category Manager"
            isSearchBox
          />
        </Styles.ConditionContainer>
        <Styles.ConditionContainer>
          <Styles.DropdownTitle>Sales Representative</Styles.DropdownTitle>
          <Dropdown
            options={saleRepresentative}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleOptinalNameCategorties(event, 'salesRepresentative')
            }
            value={stagingData.salesRepresentative}
            dataname="salesRepresentative"
            hideAllOption
            dropdownClass="dropdown-class"
            dropdownBoxClass="dropdownBox-class"
            dropdownItemClass="dropdown-item-class"
            openClass="openClass"
            itemClass="itemClass"
            placeholder="Sales Representative"
            isSearchBox
          />
        </Styles.ConditionContainer>
        <Styles.TitleText>
          <Styles.DropdownTitle>Approached Brand</Styles.DropdownTitle>
          <input
            value={stagingData.approachedBrand}
            type="text"
            placeholder="Approached Brand"
            onChange={event =>
              handleRuleNameChange(event.target.value, 'approachedBrand')
            }
          />
        </Styles.TitleText>
        <Styles.GenerateLinkButton
          className="center cursor-pointer full-width"
          onClick={GenerateLinkHandler}
        >
          {isSaveRuleButtonLoading ? (
            <Styles.Spinner className="pos-r" />
          ) : stagingMode === StagingModeEnum.CREATE ? (
            'Generate Link'
          ) : (
            'Update Link'
          )}
        </Styles.GenerateLinkButton>
        {shouldShowUrl && (
          <Styles.ShowUrlWrapper className="align-center">
            {stagingData.url}
            <div
              className="cursor-pointer"
              onClick={() => copyToClipboard(stagingData?.url ?? '')}
            >
              <CopyIcon size={24} strokeWidth={1.75} />
            </div>
          </Styles.ShowUrlWrapper>
        )}
      </div>
      <div className="align-center">
        <Styles.UploadMoodboardTitle>
          Upload moodboard images:
        </Styles.UploadMoodboardTitle>
        <Styles.MoodboardImagesContainer className="d-flex fit-width">
          {currentImages.map(item => (
            <Styles.EachImageWrapper key={item.id} className="flex-start">
              <img
                className="full-width full-height cursor-pointer"
                src={modifyImageURl(item.url)}
                alt="eachitem"
              />
              <Styles.EachDeleteIcon
                className="center cursor-pointer pos-r"
                onClick={() => handleMoodboardImageDelete(item.id)}
              >
                <TrashIcon size={18} />
              </Styles.EachDeleteIcon>
            </Styles.EachImageWrapper>
          ))}
        </Styles.MoodboardImagesContainer>
        {currentImages.length < MAX_MOODBOARD_IMAGES && (
          <FileUpload onChange={handleUploadChange}>
            {loaderStatus === true ? (
              <Styles.Spinner />
            ) : (
              <Styles.AddPhotoIcon className="center cursor-pointer">
                <AddIcon strokeWidth={1.75} />
              </Styles.AddPhotoIcon>
            )}
          </FileUpload>
        )}
        <Styles.FabricClusterWrapper className="align-center">
          <Styles.FabricClusterCheckLabel>
            Change to fabric cluster:
          </Styles.FabricClusterCheckLabel>
          <Checkbox
            checked={fabricPrint}
            onChange={() => {
              if (aiPrint) {
                Toast.INFO(
                  'You can select either fabric or digital print. Please unselect to change cluster.'
                );
                return;
              } else {
                setFabricPrint.toggle();
                if (fabricPrint) {
                  dispatch(fillDataToStaging('clusterType', 'fabric'));
                  dispatch(fillDataToStaging('isCluster', true));
                }
              }
            }}
          />
        </Styles.FabricClusterWrapper>
        <Styles.FabricClusterWrapper className="align-center">
          <Styles.FabricClusterCheckLabel>
            Change to AI Digital Print:
          </Styles.FabricClusterCheckLabel>
          <Checkbox
            checked={aiPrint}
            onChange={() => {
              if (fabricPrint) {
                Toast.INFO(
                  'You can select either fabric or digital print. Please unselect to change cluster.'
                );
                return;
              } else {
                setAIPrint.toggle();
                if (aiPrint) {
                  dispatch(fillDataToStaging('clusterType', 'digital_print'));
                  dispatch(fillDataToStaging('isCluster', true));
                }
              }
            }}
          />
        </Styles.FabricClusterWrapper>
      </div>
    </Styles.GenerateLinkWrapper>
  );
};

export default GenerateLinkHeader;
