import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from 'library/checkbox/checkbox';
import { capitalizeFirstLetter, insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import { useRetailersData } from '../helper';
import * as Styles from './styles';

interface IRetailersFilterProps {
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const RetailersFilter = ({ onFilterChange }: IRetailersFilterProps) => {
  const retailers = useRetailersData();
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedRetailers, setSelectedRetailers] = useState<Array<string>>(
    currentQueryParams?.retailer ?? []
  );

  function handleRetailersCheck(retailer: string) {
    const newSelectedRetailers = insertOrRemove(selectedRetailers, retailer);
    onFilterChange({ retailer: newSelectedRetailers });
  }

  function handleRetailersClear() {
    onFilterChange({ retailer: [] });
  }

  return (
    <Styles.RetailersFilterContainer className="pos-a fit-height">
      <Styles.RetailersFilterHeader className="spread">
        <Styles.RetailersFilterHeaderText>
          Retailers
        </Styles.RetailersFilterHeaderText>
        <Styles.RetailersFilterHeaderClearAll onClick={handleRetailersClear}>
          Clear
        </Styles.RetailersFilterHeaderClearAll>
      </Styles.RetailersFilterHeader>
      <Styles.RetailersFilterResults>
        {retailers?.map((eachRetailer: any) => (
          <Styles.FilterOption key={eachRetailer.id}>
            <Checkbox
              item={{
                name: eachRetailer.name,
              }}
              label={capitalizeFirstLetter(eachRetailer.name)}
              onChange={() => handleRetailersCheck(eachRetailer.name)}
              checked={selectedRetailers.includes(eachRetailer.name)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.RetailersFilterResults>
    </Styles.RetailersFilterContainer>
  );
};

export default RetailersFilter;
