import styled, { keyframes } from "styled-components";

const dash = keyframes` 
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
} `;

const rotate = keyframes` {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  } `;

export const WrapperCircular = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 101;
  padding: 0.3rem;
`;

export const ProfileMainLoader = styled.div`
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 58px;
  height: 58px;
  z-index: 9000 !important;
  .circular-loader {
    position: relative;
    margin: 0px auto;
    width: 58px;
    height: 58px;
    animation: rotate 2s linear infinite;
    s &:before {
      display: block;
      padding-top: 100%;
    }
  }

  .loader-path {
    animation: rotate 2s linear infinite;
    position: relative;
    bottom: 53.5px;
    left: 4.5px;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
    animation-name: ${dash};
    animation-direction: ${rotate};
  }
`;

export const Image = styled.div`
  height: 46px;
  width: 46px;
  margin: 2px;
  position: relative;
  bottom: 56.5px;
  left: 4.5px;
`;
