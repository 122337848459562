import styled from "styled-components";

interface IStyledTableProps {
  maxWidth: string;
  oddRowBackgroundColor: string;
  evenRowBackgroundColor: string;
}

export const StyledTable = styled.table<IStyledTableProps>`
  border-spacing: 0;
  max-width: ${({ maxWidth = "1306px" }) => maxWidth};
  width: 100%;
  margin: 0 auto;
  border: 1px solid var(--athens-gray);
  tbody {
    tr {
      padding: 14px;
    }
    tr:nth-child(odd) {
      background-color: ${(props) => props.oddRowBackgroundColor};
    }
    tr:nth-child(even) {
      background-color: ${(props) => props.evenRowBackgroundColor};
    }
  }
  td {
    padding: 15px;
    vertical-align: middle;
  }
  thead {
    cursor: pointer;
    font-weight: bold;
    background-color: var(--white);
    font-size: 14px;
    border: 1px solid var(--athens-gray);
    color: var(--rhino);
    text-align: left;
    th {
      padding: 20px 0px 20px 15px;
      border: 0;
    }
  }
`;

export const HeaderWrapper = styled.span`
  margin-top: 0;
`;

export const Space = styled.span`
  width: 5px;
`;
