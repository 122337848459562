import { lazy, Suspense } from 'react';
import { Tag } from 'react-tag-autocomplete';
import Tooltip from 'react-tooltip-lite';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import Toast from 'library/Toast';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import LocationRegion from 'components/Filters/LocationFilter';
import AddRules from 'components/CatalogRules';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  activeBulkProductsThunk,
  allProductDownloadHandlerThunk,
  approveBulkProductsThunk,
  archiveBulkProductsThunk,
  fileDownloadHandlerThunk,
  setInactivateBulkProductsThunk,
  updateLocationRegionData,
} from 'actions/catalog';
import {
  setAddRuleWithProductIds,
  setFabricClusterSidebarOpen,
  toggleAddRule,
} from 'actions/ruleEngines';
import ICONOGRAPHY from 'CustomConstant/icons';
import { IConditionProperties } from 'components/CatalogRules/type';
import { getQueryParams } from '../helpers';
import { IMenuOptions } from './types';
import * as Styled from './styles';

const MoreActionsMenu = lazy(() => import('../MoreActionsMenu'));

const {
  active: Active,
  archive: Archive,
  'check-circle': CheckCircleIcon,
  'chevron-down': CheveronDownIcon,
  'vertical-dots': VerticalDotsIcon,
  'fabric-cluster': FabricClusterIcon,
} = ICONOGRAPHY;

interface IBottomButtonGroupProps {
  selectedProductIds: Set<string>;
  search: URLSearchParams;
}

const BottomButtonGroup = ({
  selectedProductIds,
  search,
}: IBottomButtonGroupProps) => {
  const {
    isSetInactiveButtonLoading,
    isApproveButtonLoading,
    isSetArchiveButtonLoading,
    catalogId,
    isSetActiveButtonLoading,
    isDownloadAllProductsButtonLoading,
  } = useTypedSelector(state => state.catalog);
  const isAddRuleOpen = useTypedSelector(
    state => state.ruleEngine.isAddRuleOpen
  );
  const isSupplier = useTypedSelector(state => state.user.is_supplier);
  const isAdmin = useTypedSelector(state => state.user.is_admin);
  const { search: queryParams } = useLocation();
  const currentQueryParams = getQueryParams(queryParams);
  const dispatch = useDispatch();
  const [isMoreActionsMenuOpen, moreActionsMenuActions] = useBoolean();
  const [isAddLocationOpen, addLocationOpenAction] = useBoolean();
  const selectedItemsCount = selectedProductIds.size;
  const isDownloadButtonEnabled =
    Boolean(search.get('category')) && selectedItemsCount > 0;
  const isDownloadAllProductButtonEnabled =
    Boolean(search.get('category')) && selectedItemsCount === 0;
  const isLocationButtonEnabled = selectedItemsCount !== 0;
  const areUpdateButtonsEnabled = selectedItemsCount > 0;

  function onClickArchive() {
    if (selectedItemsCount === 0) {
      return;
    }
    dispatch(archiveBulkProductsThunk());
  }

  function onClickInactive() {
    if (selectedItemsCount === 0) {
      return;
    }

    dispatch(setInactivateBulkProductsThunk());
  }

  function approveHandler() {
    if (selectedItemsCount === 0) {
      return;
    }

    dispatch(approveBulkProductsThunk());
  }

  function activeHandler() {
    if (selectedItemsCount === 0) {
      return;
    }

    dispatch(activeBulkProductsThunk());
  }

  function fabricClusterSidebarHandler() {
    dispatch(setFabricClusterSidebarOpen(true));
  }

  const moreActionsMenuOptions: Array<IMenuOptions> = [
    {
      icon: CheckCircleIcon,
      label: 'Approve',
      onClick: approveHandler,
      iconColor: 'tertiary-text',
      isVisible: isAdmin,
      isDisabled: !areUpdateButtonsEnabled || isApproveButtonLoading,
    },
    {
      icon: Active,
      label: 'Set Active',
      onClick: activeHandler,
      iconColor: 'tertiary-text',
      isVisible: isAdmin || isSupplier,
      isDisabled: !areUpdateButtonsEnabled || isSetActiveButtonLoading,
    },
    {
      icon: Active,
      label: 'Set Inactive',
      onClick: onClickInactive,
      iconColor: 'mischka',
      isVisible: isAdmin || isSupplier,
      isDisabled: !areUpdateButtonsEnabled || isSetInactiveButtonLoading,
    },
    {
      icon: Archive,
      label: 'Archive',
      onClick: onClickArchive,
      iconColor: 'tertiary-text',
      isVisible: isAdmin,
      isDisabled: !areUpdateButtonsEnabled || isSetArchiveButtonLoading,
    },
    {
      icon: FabricClusterIcon,
      label: 'Add cluster',
      onClick: fabricClusterSidebarHandler,
      iconColor: 'mischka',
      isVisible: isAdmin,
      isDisabled: false,
    },
  ];

  function handleAddRule() {
    const selectedIds: Array<Tag> = Array.from(selectedProductIds).map(
      eachId => ({
        id: eachId,
        name: eachId,
      })
    );
    const condition: IConditionProperties = {
      id: uuidv4(),
      operator: 'contains',
      attributeName: 'product_id',
      attributeOptions: selectedIds,
    };
    dispatch(setAddRuleWithProductIds(condition));
    dispatch(toggleAddRule(true));
  }

  function fileDownloadHandler() {
    const categoryId = search.get('category');
    if (categoryId === null) {
      Toast.WARN('Please select a category to continue');
      return;
    }

    dispatch(fileDownloadHandlerThunk(selectedProductIds));
  }

  function allFileDownloadHandler() {
    const queryString = `${qs.stringify(currentQueryParams, {
      indices: false,
    })}`;
    dispatch(allProductDownloadHandlerThunk(queryString, catalogId));
  }

  function applyLocationChange(region: Array<number>) {
    const selectedIDs = Array.from(selectedProductIds);
    const data = {
      product_ids: selectedIDs,
      region_ids: region,
    };
    dispatch(updateLocationRegionData(data));
    addLocationOpenAction.off();
  }

  return (
    <>
      {isAddRuleOpen && <AddRules />}
      <Styled.ButtonGroup className="center full-view-width">
        {!isSupplier && (
          <Styled.GenerateLinkWrapper
            className="center cursor-pointer full-width"
            onClick={handleAddRule}
            disabled={!areUpdateButtonsEnabled}
          >
            {isSetInactiveButtonLoading ? (
              <Styled.Spinner className="pos-r" />
            ) : (
              <>Share Designs</>
            )}
          </Styled.GenerateLinkWrapper>
        )}

        <Tooltip
          useHover={!isDownloadButtonEnabled}
          background="var(--white)"
          color="var(--rhino)"
          direction="bottom"
          content="Select a category and products to download"
          className="download-button-tooltip"
        >
          <Styled.DownloadWrapper
            className="center pos-r cursor-pointer"
            onClick={fileDownloadHandler}
            disabled={!isDownloadButtonEnabled}
          >
            Download Excel
          </Styled.DownloadWrapper>
        </Tooltip>
        {!isSupplier && (
          <Tooltip
            useHover={!isDownloadAllProductButtonEnabled}
            background="var(--white)"
            color="var(--rhino)"
            direction="bottom"
            content="Select a category"
            className="download-button-tooltip"
          >
            <Styled.DownloadAllButton
              className="center cursor-pointer full-width"
              onClick={allFileDownloadHandler}
              disabled={
                !isDownloadAllProductButtonEnabled ||
                isDownloadAllProductsButtonLoading
              }
            >
              {isDownloadAllProductsButtonLoading ? (
                <Styled.Spinner className="pos-r" />
              ) : (
                <>Download All</>
              )}
            </Styled.DownloadAllButton>
          </Tooltip>
        )}
        {!isSupplier && (
          <Tooltip
            useHover={!isLocationButtonEnabled}
            background="var(--white)"
            color="var(--rhino)"
            direction="bottom"
            content="Select atleast one product"
            className="download-button-tooltip"
          >
            <Styled.AddLocationWrapper
              className="center pos-r cursor-pointer"
              onClick={() => addLocationOpenAction.toggle()}
              disabled={!isLocationButtonEnabled}
            >
              <Styled.TitleLocation>Add Location</Styled.TitleLocation>
              <CheveronDownIcon stroke={'var(--metallic-seaweed)'} />
            </Styled.AddLocationWrapper>
            {isLocationButtonEnabled && isAddLocationOpen && (
              <ClickOutside
                onClose={() => addLocationOpenAction.off()}
                disableClickHandler={false}
              >
                <LocationRegion
                  onClose={() => {
                    addLocationOpenAction.off();
                  }}
                  applyLocationChange={applyLocationChange}
                />
              </ClickOutside>
            )}
          </Tooltip>
        )}
        <Styled.MoreActions
          className="center cursor-pointer pos-r"
          onClick={() => moreActionsMenuActions.toggle()}
        >
          <VerticalDotsIcon stroke="var(--metallic-seaweed)" size={24} />
          {isMoreActionsMenuOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <ClickOutside
                onClose={() => moreActionsMenuActions.toggle()}
                disableClickHandler={false}
              >
                <MoreActionsMenu menuOptions={moreActionsMenuOptions} />
              </ClickOutside>
            </Suspense>
          )}
        </Styled.MoreActions>
      </Styled.ButtonGroup>
    </>
  );
};

export default BottomButtonGroup;
