import styled from 'styled-components';

export const DesignTypeContainer = styled.div`
  left: 0;
  top: 70px;
  height: 172px;
  width: 240px;
  z-index: 11;
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
  background-color: var(--white);
  padding: 20px 16px;
`;

export const DesignTypeFilter = styled.div`
  margin-bottom: 8px;
`;

export const DesignTypeFilterText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
`;

export const DesignTypeFilterClearAll = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--watermelon);
`;

export const DesignTypeFilterResults = styled.div`
  height: 91%;
  padding-bottom: 16px;
`;

export const FilterOption = styled.div`
  margin: 0 20px 10px 0;
  width: 146px;

  label {
    justify-content: flex-start;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
