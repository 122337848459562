import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextInput from 'components/textInput/textInput';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import Button from 'library/Button';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import * as Styles from './styles';

interface ICategoryFilterViewProps {
  onClose(): void;
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const CategoryFilterView = ({
  onClose,
  onFilterChange,
}: ICategoryFilterViewProps) => {
  const category =
    useTypedSelector(state => state.catalog.primaryFilters.category) ?? [];
  const [categorySearch, setCategorySearch] = useState('');
  const [list, setCategoryList] = useState<Array<any>>(category);
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedCheckbox, setSelectedCheckbox] = useState(
    currentQueryParams.category ?? []
  );

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    setCategorySearch(value);
    setCategoryList(matchQuery(value, category));
  }

  function matchQuery(value: string, options: Array<any>) {
    const newCategories: Array<any> = [];
    options.forEach(eachOption => {
      if (eachOption.value.toLowerCase().startsWith(value.toLowerCase())) {
        newCategories.push(eachOption);
      }
    });
    return newCategories;
  }

  function handleCategoryCheck(value: string) {
    const updatedSelectedOptions = insertOrRemove(selectedCheckbox, value);
    setSelectedCheckbox(updatedSelectedOptions);
  }

  function handleApplyFilter() {
    onFilterChange({ category: selectedCheckbox });
  }

  function handleClearOption() {
    onFilterChange({ category: [] });
  }

  return (
    <Styles.CategoryFilterContainer>
      <Styles.CategoryFilterHeader className="spread">
        <Styles.CategoryFilterHeaderText>
          Category
        </Styles.CategoryFilterHeaderText>
        <Styles.CategoryFilterHeaderClearAll onClick={handleClearOption}>
          Clear
        </Styles.CategoryFilterHeaderClearAll>
      </Styles.CategoryFilterHeader>
      <Styles.CategorySearchContainer>
        <TextInput
          placeholder="Search Name"
          dataname=""
          onChange={handleSearchChange}
          onClear={() => null}
          value={categorySearch}
          listItemSearchBoxClass="list-item-search-box-class"
          showCrossButton={false}
        />
      </Styles.CategorySearchContainer>
      <Styles.CategoryfilterResults>
        {list?.map((eachCategory: any) => (
          <Styles.FilterOption key={eachCategory.value}>
            <Checkbox
              item={{
                value: eachCategory.value,
              }}
              label={eachCategory.value}
              onChange={() => handleCategoryCheck(eachCategory.value)}
              checked={selectedCheckbox.includes(eachCategory.value)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.CategoryfilterResults>
      <Styles.BottomTabButtons className="spread full-width">
        <Button size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleApplyFilter} size="medium">
          Apply
        </Button>
      </Styles.BottomTabButtons>
    </Styles.CategoryFilterContainer>
  );
};

export default CategoryFilterView;
