import { IObjectType } from 'utils/types';

const ImageLinkShirt = (props: IObjectType) => (
  <svg
    width={90}
    height={98}
    viewBox="0 0 90 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={41.666} cy={56.402} rx={41.666} ry={41.599} fill="#FFFAE6" />
    <ellipse cx={38.789} cy={15.298} rx={2.889} ry={2.884} fill="#FFC400" />
    <ellipse cx={75.717} cy={16.592} rx={1.297} ry={1.295} fill="#FFC400" />
    <ellipse cx={68.839} cy={36.967} rx={1.297} ry={1.295} fill="#FFC400" />
    <ellipse cx={84.631} cy={47.744} rx={1.686} ry={1.683} fill="#FFC400" />
    <ellipse cx={8.697} cy={10.73} rx={1.686} ry={1.683} fill="#FFC400" />
    <path
      d="m60.002 3.227 1.552-1.55 1.122 1.121-1.552 1.55 1.754 1.75-1.182 1.181-1.754-1.751-1.556 1.554-1.123-1.12 1.557-1.554-1.681-1.679 1.182-1.18 1.681 1.678ZM19.361 18.732l1.25-1.247.903.902-1.25 1.247 1.413 1.41-.952.95-1.412-1.41-1.253 1.25-.903-.9 1.252-1.252-1.353-1.35.952-.951 1.353 1.351ZM2.644 39.917l.796-.795.576.575-.797.795.9.898-.606.606-.9-.898-.798.797-.576-.575.798-.797-.862-.861.606-.606.863.861Zm85.084-7.82.797-.795.575.575-.796.795.9.898-.607.606-.9-.899-.798.797-.576-.574.799-.797-.863-.862.607-.605.862.861Z"
      fill="#FFC400"
    />
    <path
      d="m68.297 49.016-9.98-10.311a.87.87 0 0 0-.463-.25l-9.163-1.74a.873.873 0 0 0-1.033.854c0 1.887-2.39 3.48-5.22 3.48-2.83 0-5.221-1.593-5.221-3.48a.87.87 0 0 0-1.033-.855l-9.162 1.74a.87.87 0 0 0-.463.25l-9.98 10.312a.87.87 0 0 0 0 1.21l6.238 6.447a.87.87 0 0 0 .625.265h.001a.871.871 0 0 0 .626-.265l2.41-2.497v23.42c0 .48.39.87.87.87h30.003a.87.87 0 0 0 .87-.87V57.582a.87.87 0 1 0-1.74 0v19.143H28.22v-3.48h11.607a.87.87 0 1 0 0-1.74H28.22V52.022a.87.87 0 0 0-1.497-.604l-3.281 3.398-5.027-5.195 9.202-9.508 7.995-1.519c.636 2.39 3.455 4.196 6.826 4.196 3.37 0 6.19-1.806 6.826-4.196l7.994 1.519 9.203 9.508-5.029 5.196-3.456-3.566a.87.87 0 1 0-1.25 1.211l4.082 4.212a.87.87 0 0 0 1.25 0l6.24-6.448a.87.87 0 0 0 0-1.21Z"
      fill="#00A3BF"
      stroke="#00A3BF"
      strokeWidth={1.167}
    />
    <path
      d="M45.048 48.003c0-1.133-.73-2.09-1.74-2.45v-1.03a.87.87 0 1 0-1.74 0v1.03a2.606 2.606 0 0 0-1.74 2.45c0 1.144.744 2.108 1.77 2.46-.01.05-.03.097-.03.15v2.771a2.606 2.606 0 0 0-1.74 2.45c0 1.144.744 2.108 1.77 2.46-.01.05-.03.097-.03.15v2.771a2.606 2.606 0 0 0-1.74 2.45c0 1.44 1.17 2.61 2.61 2.61 1.439 0 2.61-1.17 2.61-2.61 0-1.132-.73-2.09-1.74-2.45v-2.77c0-.054-.022-.1-.03-.151a2.605 2.605 0 0 0 1.77-2.46c0-1.133-.73-2.09-1.74-2.45v-2.77c0-.054-.022-.1-.03-.151a2.606 2.606 0 0 0 1.77-2.46Zm-2.61 16.533a.871.871 0 0 1 0-1.74.87.87 0 0 1 0 1.74Zm0-7.832a.871.871 0 1 1 .001-1.742.871.871 0 0 1-.001 1.742Zm0-7.83a.871.871 0 0 1 0-1.741.871.871 0 0 1 0 1.74Z"
      fill="#00A3BF"
      stroke="#00A3BF"
      strokeWidth={1.167}
    />
  </svg>
);

export default ImageLinkShirt;
