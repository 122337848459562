import styled from 'styled-components';

export const UploadExcel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px dashed var(--pacific-blue);
  border-radius: 4px;
  background-color: rgba(230, 252, 255, 0.5);
  .upload-excel-button {
    padding: 14px 20px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: var(--white);
    background-color: var(--watermelon);
    border-radius: 4px;
    cursor: pointer;

    .bs-upload {
      stroke-width: 0.8px;
    }
  }

  .label-disabled {
    font-weight: 500;
    background-color: var(--input-disabled);
    color: var(--manatee);
    border: 1px solid var(--athens-gray);
    cursor: not-allowed;
  }

  .dropdown2-wrapper {
    width: 350px;
    margin-bottom: 24px;
    background: var(--white);
    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdown-class {
    border: none;
    height: auto;
    width: auto;
    padding: 12px;

    .label-name {
      color: var(--manatee) !important;
      font-size: 14px !important;
      line-height: 16px;
    }
    .label-value {
      font-size: 14px !important;
      color: var(--rhino) !important;
      line-height: 16px;
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;
      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }
`;

export const SecondaryText = styled.p`
  color: var(--tertiary-text);
  font-size: 14px;
  line-height: 17px;
  margin: 8px auto;
`;

export const HeadingText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--rhino);
  margin-bottom: 4px;
`;

export const LocationWrapper = styled.div`
  margin: 0 24px;
  min-width: 320px;

  svg {
    width: 14px;
    height: 14px;

    path {
      stroke-width: 2.5;
    }
  }
`;

export const NoteText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--tertiary-text);
  margin: 8px 0 16px 0;
`;
