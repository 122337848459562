import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AddCatalogue from './components/AddCatalogue';
import CatalogViewer from 'components/CatalogViewer';
import TutorialDropdown from 'components/TutorialDropdown';
import UploadImages from 'components/UploadImages';
import ImageLinks from './components/ImageLinks/index';
import SupplierFilter from './components/ImageLinks/SupplierFilter';
import { useTypedSelector } from 'utils/hooks';
import FashinzaIconLoader from './library/IconLoader';
import HeaderToolbar from 'library/HeaderToolbar';
import Button from './library/Button';
import Layout from './library/Layout';
import { RoutePathEnum } from './library/SidebarLink/constants';
import RouteElementWrapper from './library/RouteElementWrapper';
import { useSetAuthFromUrl } from './hooks/auth';
import {
  moveToCatalogCategoryRoute,
  moveToCatalogListRoute,
} from './services/crossOriginMsg';
import './reset.css';
import './App.css';
import './styles/flex.css';
import './styles/scrollbar.css';
import 'react-day-picker/lib/style.css';

function App() {
  //check auth

  const [isTokenValid, isVerifying] = useSetAuthFromUrl();
  const isSupplier = useTypedSelector(store => store.user.is_supplier);
  if (isVerifying) {
    return <FashinzaIconLoader show />;
  }
  if (!isVerifying && !isTokenValid) {
    return <div>Cannot load page. Token not valid</div>;
  }

  const CatalogAdd = (
    <HeaderToolbar
      title="Fashinza Designs"
      rightNode={
        <div className="d-flex header-toolbar-container">
          {!isSupplier && (
            <>
              <UploadImages />
              <TutorialDropdown />
              <Button
                variant="secondary"
                size="medium"
                onClick={moveToCatalogCategoryRoute}
              >
                View Designs
              </Button>
            </>
          )}
          <Button
            variant="secondary"
            size="medium"
            onClick={moveToCatalogListRoute}
          >
            View Products
          </Button>
        </div>
      }
    >
      <AddCatalogue isAdmin />
    </HeaderToolbar>
  );

  return (
    <Layout navigation={<></>}>
      <Routes>
        <Route
          path={RoutePathEnum.UPLOADS}
          element={
            <RouteElementWrapper
              rightContent={<SupplierFilter />}
              hideBackBtn={false}
              heading="Image Links"
            >
              <ImageLinks />
            </RouteElementWrapper>
          }
        />
        <Route
          path={`/${RoutePathEnum.LIST}/:tabType`}
          element={
            <RouteElementWrapper>
              <CatalogViewer />
            </RouteElementWrapper>
          }
          exact={false}
        />
        <Route path="/" element={CatalogAdd} />
        <Route path="*" element={CatalogAdd} />
      </Routes>
      <ToastContainer />
    </Layout>
  );
}

export default App;
