import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import Dropdown from 'components/Dropdown';
import TagComponent from 'components/TrendingModal/TagComponent';
import { useTypedSelector } from 'utils/hooks';
import {
  RemoveAttributeOptionTag,
  setAttributeOptionTag,
} from 'actions/ruleEngines';
import { getSortingAttributeOptions } from '../helpers';
import * as Styles from './styles';

interface IAttributeOptionsProps {
  identifier: string;
}

const ValueOption = ({ identifier }: IAttributeOptionsProps) => {
  const sortingData = useTypedSelector(store => store.ruleEngine.sortingsData);
  const eachSorting = useTypedSelector(
    store => store.ruleEngine.ruleStaging.sortings[identifier]
  );

  const dispatch = useDispatch();

  function handleFunctionChange(event: ChangeEvent<HTMLInputElement>) {
    const values: Array<Tag> = [
      { id: event.target.value, name: event.target.value },
    ];
    dispatch(setAttributeOptionTag('sortings', identifier, values));
  }

  function onTagAddition(tag: Tag) {
    let productIdsString = tag.name;
    if (productIdsString.startsWith(',')) {
      productIdsString = productIdsString.slice(1);
    }
    if (productIdsString.endsWith(',')) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }
    const newTags: Array<string> = productIdsString.split(',');
    const newCommaSeparatedTagsMap = new Map<String, Tag>();
    newTags.forEach(eachTag => {
      const tagObj = {
        id: eachTag.trim(),
        name: eachTag.trim(),
      };
      newCommaSeparatedTagsMap.set(eachTag, tagObj);
    });
    dispatch(
      setAttributeOptionTag('sortings', identifier, [
        ...eachSorting.attributeOptions,
        ...Array.from(newCommaSeparatedTagsMap.values()),
      ])
    );
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = eachSorting.attributeOptions[tagIndex];
    dispatch(RemoveAttributeOptionTag('sortings', identifier, tagToDelete));
  }

  return (
    <Styles.ValueContainer>
      {eachSorting.key === 'product_id' && (
        <Styles.ReactTagsWrappers>
          <ReactTags
            tags={eachSorting.attributeOptions}
            tagComponent={TagComponent}
            onAddition={onTagAddition}
            onDelete={onTagDeletion}
            addOnBlur
            allowNew
            placeholderText="PRODUCT ID"
          />
        </Styles.ReactTagsWrappers>
      )}
      {eachSorting.key! !== 'product_id' && (
        <Dropdown
          options={getSortingAttributeOptions(
            sortingData,
            eachSorting.attributeName
          )}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleFunctionChange(event)
          }
          value={eachSorting.attributeOptions[0]?.name ?? ''}
          dataname="value"
          hideAllOption
          dropdownClass="dropdown-class"
          dropdownBoxClass="dropdownBox-class"
          dropdownItemClass="dropdown-item-class"
          openClass="openClass"
          itemClass="itemClass"
          placeholder="Select Attribute"
          disable_edit={eachSorting.attributeName === ''}
        />
      )}
    </Styles.ValueContainer>
  );
};
export default ValueOption;
