export const TagsFilters = ['retailer', 'category', 'region', 'gender', 'date'];

export const RequiredFilters = ['retailer', 'category', 'region', 'gender'];

export const RADIAN = Math.PI / 180;

export const COLORS = [
  'var(--malibu)',
  'var(--anakiwa)',
  'var(--moon-raker)',
  'var(--bubbles)',
];

export const TAGS_COLORS = [
  'var(--purple-ink)',
  'var(--metallic-seaweed)',
  'var(--gigas)',
  'var(--frosted-blueberries)',
];

export const PILL_COLOR_COMBINATIONS: Record<string, string> = {
  'var(--purple-ink)': 'var(--cloud-break)',
  'var(--metallic-seaweed)': 'var(--twilight-blue)',
  'var(--gigas)': 'var(--magnolia)',
  'var(--frosted-blueberries)': 'var(--zircon)',
};
