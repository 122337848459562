export const TUTORIAL_LINKS = [
  {
    name: 'Video',
    link: 'https://www.loom.com/share/6a75ff6687094718a7182e8bd6d65074',
  },
  {
    name: 'Presentation',
    link: 'https://docs.google.com/presentation/d/1vZLPGXnzEFf1RQdZ8He_zHf0QBCck8lLM30TgBq_oW4/edit#slide=id.p',
  },
];
