import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  padding: 32px 121px;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
`;

export const FileName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--manatee);
  margin-top: 8px;
  margin-bottom: 32px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const SuccessText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--ocean-green);
  margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  .upload-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
