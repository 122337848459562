import Toast from 'library/Toast';
import { ACCEPTED_SIZE } from 'components/ImageLinks/helperConstant';
import { IImage } from 'utils/types';

export function showErrorMessage(
  imageToUpload: File,
  width: number,
  height: number
) {
  let fileSize = '',
    imageHeight = '',
    aspectRatio = '',
    sizeCorrectionMessage = '',
    heightCorrectionMessage = '',
    aspectRatioCorrectionMessage = '';
  let errorMessage = 'Image parameters not met.\nIncorrect Parameters :';

  if (imageToUpload.size > ACCEPTED_SIZE.MinimumImageSize * 1000000) {
    fileSize =
      ' File size : ' +
      Number((imageToUpload.size / 1000000).toFixed(2)) +
      ' MB\n';
    sizeCorrectionMessage =
      'File size should be less than ' +
      ACCEPTED_SIZE.MinimumImageSize +
      ' MB!\n';
  }
  if (
    height < ACCEPTED_SIZE.MinimumImageHeight ||
    height > ACCEPTED_SIZE.MaximumImageHeight
  ) {
    imageHeight = 'Image height : ' + height + 'px\n';
    heightCorrectionMessage =
      'Image height should be in the range [720-2400] px!\n';
  }
  if (
    !(
      (width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio1 &&
        width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio1) ||
      (width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio2 &&
        width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio2)
    )
  ) {
    aspectRatio =
      ' Aspect Ratio : ' + Number((width / height).toFixed(2)) + '\n';
    aspectRatioCorrectionMessage = 'Width / Height ratio should be 1:1 or 3:4!';
  }
  let correctionMessage = errorMessage.concat(
    fileSize,
    sizeCorrectionMessage,
    imageHeight,
    heightCorrectionMessage,
    aspectRatio,
    aspectRatioCorrectionMessage
  );
  Toast.ERROR(correctionMessage);
}

export const getImageMetaData = async (
  files: File
): Promise<{
  width: number;
  height: number;
}> => {
  async function getImageParams(files: File): Promise<{
    width: number;
    height: number;
  }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        const image = new Image();
        image.src = URL.createObjectURL(files);
        await image.decode();

        resolve({ width: image.width, height: image.height });
      };
      reader.readAsDataURL(files);
    });
  }
  const { width, height } = await getImageParams(files);

  return { width, height };
};

export function handleErrorForDefaultImageData(currentImages: Array<IImage>) {
  let shouldShowError = true;

  currentImages.forEach(image => {
    if (image.view === 'default') shouldShowError = false;
  });

  return shouldShowError;
}

export function eachProductImageData(
  rowId: number,
  defaultImage: Array<IImage>,
  currentImages: Array<IImage>
) {
  const unchangedImageBasedOnId = currentImages.filter(({ id: id1 }) =>
    defaultImage.some(({ id: id2 }) => id2 === id1)
  );
  const addedImages = currentImages.filter(
    ({ id: id1 }) => !defaultImage.some(({ id: id2 }) => id2 === id1)
  );
  const deletedImages = defaultImage.filter(
    ({ id: id1 }) => !currentImages.some(({ id: id2 }) => id2 === id1)
  );
  const modifiedImages = unchangedImageBasedOnId.filter(item1 =>
    defaultImage.some(
      item2 => item1.id === item2.id && item1.view !== item2.view
    )
  );
  const addedImagesInGivenForm = addedImages.map(item => ({
    url: item.url,
    view: item.view,
  }));

  return {
    id: rowId,
    add: addedImagesInGivenForm,
    delete: deletedImages,
    modify: modifiedImages,
  };
}
