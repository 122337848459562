import styled from 'styled-components';

const colorConfig = {
  primary: {
    background: 'var(--watermelon)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'var(--watermelon)',
  },
  tertiary: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
    borderColor: 'var(--watermelon)',
  },
  subtle: {
    background: 'var(--input-disabled)',
    color: 'var(--rhino)',
    textDecoration: 'none',
    borderColor: 'var(--athens-gray)',
  },
  link: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
    borderColor: 'transparent',
  },
  ghost: {
    background: 'transparent',
    color: 'var(--watermelon)',
    textDecoration: 'underline',
    borderColor: 'transparent',
  },
  subtleLink: {
    background: 'var(--white)',
    color: 'var(--manatee)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  outlined: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
    borderColor: 'var(--watermelon)',
  },
  green: {
    background: 'var(--ocean-green)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'initial',
  },
  allPortsBorderless: {
    background: 'var(--white)',
    color: 'var(--metallic-seaweed)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  acceptOutlined: {
    background: 'var(--white)',
    color: 'var(--ocean-green)',
    textDecoration: 'none',
    borderColor: 'var(--ocean-green)',
  },
  rejectOutlined: {
    background: 'var(--white)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
    borderColor: 'var(--red-dirt)',
  },
  acceptFilled: {
    background: 'var(--scandal)',
    color: 'var(--ocean-green)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  rejectFilled: {
    background: 'var(--chablis)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  disabled: {
    background: 'var(--input-disabled)',
    color: 'var(--manatee)',
    textDecoration: 'none',
    borderColor: 'var(--mischka)',
  },
  pacificBlueOutlined: {
    background: 'var(--white)',
    color: 'var(--pacific-blue)',
    textDecoration: 'none',
    borderColor: 'var(--pacific-blue)',
  },
  deepCeruleanOutLined: {
    background: 'var(--white)',
    color: 'var(--deep-cerulean)',
    textDecoration: 'none',
    borderColor: 'var(--deep-cerulean)',
  },
  metallicSeaweedOutLined: {
    background: 'var(--white)',
    color: 'var(--metallic-seaweed)',
    textDecoration: 'none',
    borderColor: 'var(--metallic-seaweed)',
  },
  metallicSeaweed: {
    background: 'var(--metallic-seaweed)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'var(--metallic-seaweed)',
  },
};

const hoverColorConfig = {
  primary: {
    background: 'rgb(250 87 98 / 92%)',
    color: 'var(--white)',
    textDecoration: 'none',
  },
  tertiary: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
    borderColor: 'var(--watermelon)',
  },
  allPortsBorderless: {
    background: 'var(--white)',
    color: 'var(--metallic-seaweed)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  subtle: {
    background: 'var(--input-disabled)',
    color: 'var(--rhino)',
    textDecoration: 'none',
  },
  link: {
    background: 'var(--white)',
    color: 'rgb(250 87 98 / 90%)',
    textDecoration: 'underline',
  },
  ghost: {
    background: 'transparent',
    color: 'rgb(250 87 98 / 90%)',
    textDecoration: 'underline',
  },
  subtleLink: {
    background: 'var(--white)',
    color: 'var(--manatee)',
    textDecoration: 'underline',
  },
  outlined: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
  },
  green: {
    background: 'var(--ocean-green)',
    color: 'var(--white)',
    textDecoration: 'none',
  },
  acceptOutlined: {
    background: 'var(--white)',
    color: 'var(--ocean-green)',
    textDecoration: 'none',
  },
  rejectOutlined: {
    background: 'var(--white)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
  },
  acceptFilled: {
    background: 'var(--scandal)',
    color: 'var(--ocean-green)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  rejectFilled: {
    background: 'var(--chablis)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  disabled: {
    background: 'var(--input-disabled)',
    color: 'var(--manatee)',
    textDecoration: 'none',
    borderColor: 'var(--mischka)',
  },
  pacificBlueOutlined: {
    background: 'var(--white)',
    color: 'var(--pacific-blue)',
    textDecoration: 'none',
    borderColor: 'var(--pacific-blue)',
  },
  deepCeruleanOutLined: {
    background: 'var(--white)',
    color: 'var(--deep-cerulean)',
    textDecoration: 'none',
    borderColor: 'var(--deep-cerulean)',
  },
  metallicSeaweedOutLined: {
    background: 'var(--white)',
    color: 'var(--metallic-seaweed)',
    textDecoration: 'none',
    borderColor: 'var(--metallic-seaweed)',
  },
  metallicSeaweed: {
    background: 'var(--metallic-seaweed)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'var(--metallic-seaweed)',
  },
};

const clickColorConfig = {
  primary: {
    background: '#E80A47',
    color: 'var(--white)',
    textDecoration: 'none',
  },
  tertiary: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
    borderColor: 'var(--watermelon)',
  },
  allPortsBorderless: {
    background: 'var(--white)',
    color: 'var(--metallic-seaweed)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  subtle: {
    background: 'var(--mischka)',
    color: 'var(--rhino)',
    textDecoration: 'none',
  },
  link: {
    background: 'var(--white)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
  },
  ghost: {
    background: 'transparent',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
  },
  subtleLink: {
    background: 'var(--white)',
    color: 'var(--tertiary-text)',
    textDecoration: 'none',
  },
  outlined: {
    background: 'var(--white)',
    color: 'var(--watermelon)',
    textDecoration: 'none',
  },
  green: {
    background: 'var(--ocean-green)',
    color: 'var(--white)',
    textDecoration: 'none',
  },
  acceptOutlined: {
    background: 'var(--white)',
    color: 'var(--ocean-green)',
    textDecoration: 'none',
  },
  rejectOutlined: {
    background: 'var(--white)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
  },
  acceptFilled: {
    background: 'var(--scandal)',
    color: 'var(--ocean-green)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  rejectFilled: {
    background: 'var(--chablis)',
    color: 'var(--red-dirt)',
    textDecoration: 'none',
    borderColor: 'none',
  },
  disabled: {
    background: 'var(--input-disabled)',
    color: 'var(--manatee)',
    textDecoration: 'none',
    borderColor: 'var(--mischka)',
  },
  pacificBlueOutlined: {
    background: 'var(--white)',
    color: 'var(--pacific-blue)',
    textDecoration: 'none',
    borderColor: 'var(--pacific-blue)',
  },
  deepCeruleanOutLined: {
    background: 'var(--white)',
    color: 'var(--deep-cerulean)',
    borderColor: 'var(--deep-cerulean)',
    textDecoration: 'none',
  },
  metallicSeaweedOutLined: {
    background: 'var(--white)',
    color: 'var(--metallic-seaweed)',
    textDecoration: 'none',
    borderColor: 'var(--metallic-seaweed)',
  },
  metallicSeaweed: {
    background: 'var(--metallic-seaweed)',
    color: 'var(--white)',
    textDecoration: 'none',
    borderColor: 'var(--metallic-seaweed)',
  },
};

export enum StyledButtonVariant {
  Primary = 'primary',
  Tertiary = 'tertiary',
  Subtle = 'subtle',
  Link = 'link',
  SubtleLink = 'subtleLink',
  Outlined = 'outlined',
  Ghost = 'ghost',
  Green = 'green',
  AcceptOutlined = 'acceptOutlined',
  RejectOutlined = 'rejectOutlined',
  AcceptFilled = 'acceptFilled',
  RejectFilled = 'rejectFilled',
  MetallicSeaweed = 'metallicSeaweed',
  Disabled = 'disabled',
  AllPortsBorderless = 'allPortsBorderless',
  PacificBlueOutlined = 'pacificBlueOutlined',
  DeepCeruleanOutLined = 'deepCeruleanOutLined',
  MetallicSeaweedOutLined = 'metallicSeaweedOutLined',
}

const StyledButton = styled.button<{ variant: StyledButtonVariant }>`
  cursor: pointer;
  pointer-events: default;
  background-color: ${props => colorConfig[props.variant].background};
  color: ${props => colorConfig[props.variant].color};
  text-decoration: ${props => colorConfig[props.variant].textDecoration};
  border-radius: 4px;
  border: 1px solid ${props => colorConfig[props.variant].borderColor};
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  text-overflow: ellipsis;
  transition: color 0.2s ease-in, background-color 0.2s ease-in;

  &:hover {
    background-color: ${props => hoverColorConfig[props.variant].background};
    color: ${props => hoverColorConfig[props.variant].color};
    text-decoration: ${props => hoverColorConfig[props.variant].textDecoration};
  }

  &:active,
  &:focus {
    background-color: ${props => clickColorConfig[props.variant].background};
    color: ${props => clickColorConfig[props.variant].color};
    text-decoration: ${props => clickColorConfig[props.variant].textDecoration};
  }

  ${props =>
    props.disabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  `};
`;

export default StyledButton;
