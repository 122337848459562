import styled from 'styled-components';

export const SuperWrapper = styled.header`
  padding: 0 24px;
  box-shadow: 0 4px 12px rgba(44, 53, 86, 0.05);
  background-color: var(--white);
`;

export const Wrapper = styled.div`
  height: 64px;
  padding: 16px 0;
`;

export const BackIconWrapper = styled.div`
  height: 32px;
  width: 32px;
  border: 1px solid var(--loblolly);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(12, 16, 33, 0.05);
  margin-right: 12px;
`;

export const Title = styled.h6`
  font-family: Lora;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: var(--rhino);
`;
