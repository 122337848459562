import { IObjectType } from 'utils/types';

const ExcelUploadInProgress = (props: IObjectType) => (
  <svg
    width={95}
    height={110}
    viewBox="0 0 95 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={45} cy={44.279} rx={44.5} ry={44.279} fill="#FFFAE6" />
    <rect
      x={0.63}
      y={0.687}
      width={46.119}
      height={64.269}
      rx={4.613}
      transform="matrix(.99928 .04341 -.04383 .99882 38.854 34.513)"
      fill="#E6FCFF"
      stroke="#B3F5FF"
      strokeWidth={1.318}
    />
    <rect
      x={-0.74}
      y={-0.568}
      width={48.761}
      height={66.896}
      rx={5.931}
      transform="rotate(-7.463 250.53 -82.493) skewX(.074)"
      fill="#fff"
      stroke="#00A3BF"
      strokeWidth={1.318}
    />
    <path
      d="M13.46 37.17a5.264 5.264 0 0 1 4.536-5.911l36.587-4.793a5.28 5.28 0 0 1 5.92 4.541l1.286 9.726-47.042 6.163-1.287-9.726Z"
      fill="#B3F5FF"
    />
    <rect
      width={1.967}
      height={6.589}
      rx={0.984}
      transform="rotate(82.463 19.19 53.833) skewX(-.074)"
      fill="#FFC400"
    />
    <rect
      width={1.977}
      height={6.558}
      rx={0.988}
      transform="rotate(-7.463 155.435 -390.098) skewX(.074)"
      fill="#FFC400"
    />
    <rect
      width={1.972}
      height={6.574}
      rx={0.986}
      transform="matrix(.79533 .60724 -.61028 .79138 63.57 18.674)"
      fill="#FFC400"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 400.001 -131.742) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 500.946 -138.451) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 601.876 -145.167) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 440.386 -134.43) skewX(.074)"
      fill="#B3F5FF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 541.315 -141.147) skewX(.074)"
      fill="#B3F5FF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 642.244 -147.85) skewX(.074)"
      fill="#B3F5FF"
    />
  </svg>
);

export default ExcelUploadInProgress;
