import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Table = styled.table`
  table-layout: fixed;
`;

export const TableHead = styled.thead`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  height: 60px;
  text-align: left;
  color: var(--rhino);
  border-bottom: 1px solid var(--rhino);

  th {
    vertical-align: middle;
  }

  th:nth-child(1) {
    width: 8%;
  }

  th:nth-child(2) {
    width: 34%;
  }

  th:nth-child(3) {
    width: 18%;
  }

  th:nth-child(4) {
    width: 24%;
  }

  th:nth-child(5) {
    width: 16%;
  }
`;

export const TableBody = styled.tbody<{
  oddRowBackgroundColor: string;
  evenRowBackgroundColor: string;
}>`
  tr:nth-child(odd) {
    background-color: ${props => props.oddRowBackgroundColor};
  }

  tr:nth-child(even) {
    background-color: ${props => props.evenRowBackgroundColor};
  }
`;

export const TableRow = styled.tr`
  height: 60px;
  line-height: 20px;
  font-size: 14px;

  td {
    vertical-align: middle;
    overflow-wrap: break-word;
    padding: 4px;
  }
`;

export const Spinner = styled.div`
  font-size: 4px;
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  height: 28px;
  width: 28px;
  border: 4px solid var(--white);
  border-radius: 50%;
  border-left-color: var(--rhino);
`;
