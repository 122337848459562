import qs from 'qs';
import { toTitleCase } from 'utils/helpers';
import { useTypedSelector } from 'utils/hooks';
import { IGetEachOption } from './type';

export function useAllAdminDetail() {
  const admins = useTypedSelector(state => state.catalog.admins);

  return admins.map(item => ({
    id: String(item.id),
    name: `${item.first_name} ${item.last_name}`,
  }));
}

export function useAllCategoryManagerDetail() {
  const categoryManagersData = useTypedSelector(
    store => store.catalog.categoryManagersData
  );

  return categoryManagersData.map(item => ({
    id: String(item.id),
    name: item.full_name,
  }));
}

export function useBuHeadDetails() {
  const buHeadData = useTypedSelector(state => state.catalog.buHeadData);

  return buHeadData.map(item => ({
    id: String(item.id),
    name: item.full_name,
  }));
}

export function useAllSaleRepresentativeDetail() {
  const salesRepresentativesData = useTypedSelector(
    store => store.catalog.salesRepresentativesData
  );

  return salesRepresentativesData.map(item => ({
    id: String(item.id),
    name: item.full_name,
  }));
}

export function useAllCategoriesData() {
  const categoriesData = useTypedSelector(
    store => store.catalog.filters.category
  );

  return categoriesData.map(item => ({
    id: String(item.id),
    name: item.name,
  }));
}

export function useAllDesignerDetail() {
  const designersData = useTypedSelector(store => store.catalog.designersData);

  return designersData.map(item => ({
    id: String(item.id),
    name: item.full_name,
  }));
}

export function useSeasonsData() {
  const seasonsData = useTypedSelector(store => store.designLibrary.seasons);

  return seasonsData.map(item => ({
    id: item,
    name: item,
  }));
}

export function useRetailersData() {
  const retailersData = useTypedSelector(store => store.trends.retailers);

  return retailersData.map(item => ({
    id: item,
    name: item,
  }));
}

export function useFabricTypeData() {
  const fabricTypeData = useTypedSelector(
    store => store.designLibrary.fabricType
  );

  return fabricTypeData.map(item => ({
    id: item,
    name: toTitleCase(item),
  }));
}

export function getSelectOptionNames(
  allOptions: Array<IGetEachOption>,
  selectedOptions: Array<string> = []
) {
  if (selectedOptions.length === 0) {
    return [];
  }

  return allOptions
    .filter(option => selectedOptions.includes(option.id))
    .map(item => item.name);
}
