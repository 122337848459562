import React, { ReactElement } from 'react';
import TabTitle from './TabTitle';
import { useTabTransition } from './helperHooks';
import * as Styles from './styles';

interface ITabsGroupProps {
  children: ReactElement | Array<ReactElement>;
  activeChildIndex: number;
}

const TabsGroup = ({ children, activeChildIndex }: ITabsGroupProps) => {
  const childrenElements = Array.isArray(children) ? children : [children];
  const { wrapperRef } = useTabTransition([activeChildIndex]);

  return (
    <Styles.TabWrapper className="full-width" ref={wrapperRef}>
      <Styles.TabHeader className="d-flex pos-r">
        {childrenElements.map((item, index) => (
          <TabTitle
            key={item.props.title}
            title={item.props.title}
            className="fit-width cursor-pointer"
            tabTitleProps={item.props.tabTitleProps}
            isSelected={item.props.isSelected}
          />
        ))}
      </Styles.TabHeader>
      {childrenElements[activeChildIndex]}
    </Styles.TabWrapper>
  );
};

export default TabsGroup;
