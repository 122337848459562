import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ImageGrid from './ImageGrid';
import AddImage from './AddImage';
import { useImageLink } from './helperHook';
import { fetchSuppliersThunk } from '../../actions/catalog';
import {
  fetchUploadedImageThunk,
  setSelectedSupplier,
} from '../../actions/imageLinks';
import { useTypedSelector } from 'utils/hooks';
import { EMPTY_UPLOAD_VIEW_IMAGE_URL } from '../../utils/constants';
import * as Styles from './styles';

const ImageLinks = () => {
  const {
    previouslyUploadedImages,
    handleChange,
    handleRetry,
    handleDelete,
    failedImages,
    uploadedImages,
    supplierFilter,
    currentPage,
  } = useImageLink();
  const dispatch = useDispatch();
  const isSupplier = useTypedSelector(store => store.user.is_supplier);
  const linkedSupplier = useTypedSelector(store => store.user.linked_supplier);
  const isFetchingImages = useTypedSelector(
    store => store.imageLinks.isLoading
  );

  useEffect(() => {
    dispatch(fetchSuppliersThunk());
    if (isSupplier) {
      dispatch(setSelectedSupplier(linkedSupplier.id));
    }
  }, []);

  useEffect(() => {
    if (supplierFilter.selectedSupplier !== '') {
      dispatch(
        fetchUploadedImageThunk(supplierFilter.selectedSupplier, currentPage)
      );
    }
  }, [currentPage, supplierFilter.selectedSupplier]);

  return (
    <Styles.Wrapper className="v-d-flex pos-r has-y-scroll">
      {supplierFilter.selectedSupplier === '' && !isFetchingImages && (
        <Styles.EmptyState>
          <img
            src={EMPTY_UPLOAD_VIEW_IMAGE_URL}
            alt="Select a supplier to upload image or view previously uploaded Images"
          />
          <p>
            Select a supplier to upload image or view previously uploaded Images
          </p>
        </Styles.EmptyState>
      )}
      {uploadedImages.length === 0 &&
        previouslyUploadedImages.length === 0 &&
        failedImages.length === 0 &&
        supplierFilter.selectedSupplier !== '' &&
        !isFetchingImages && <AddImage handleChange={handleChange} />}
      {(uploadedImages.length > 0 ||
        failedImages.length > 0 ||
        previouslyUploadedImages.length > 0) &&
        supplierFilter.selectedSupplier !== '' && (
          <ImageGrid
            uploadedImages={uploadedImages}
            failedImages={failedImages}
            handleRetry={handleRetry}
            handleDelete={handleDelete}
            previouslyUploadedImages={previouslyUploadedImages}
          />
        )}
    </Styles.Wrapper>
  );
};

export default ImageLinks;
