import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const ButtonGroup = styled.div`
  position: fixed;
  bottom: 0;
  padding: 12px 0;
  background-color: var(--white);
  z-index: 9;
  box-shadow: 0 -4px 32px rgba(44, 53, 86, 0.05);
  border-top: 1px solid var(--athens-gray);

  button {
    margin-right: 16px;
  }
`;

export const MoreActions = styled.div`
  width: 40px;
  height: 40px;
`;

export const Spinner = styled.div`
  font-size: 4px;
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 1.1em solid rgba(255, 255, 255, 0.4);
  border-left-color: var(--rhino);
`;

export const DownloadAllButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweedOutLined,
}))`
  width: 160px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const GenerateLinkWrapper = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweed,
}))`
  width: 160px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const DownloadWrapper = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweedOutLined,
}))`
  width: 160px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const AddLocationWrapper = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweedOutLined,
}))`
  width: 160px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-color: var(--white);
`;

export const TitleLocation = styled.div`
  margin-right: 10px;
`;
