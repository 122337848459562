import { useDispatch } from 'react-redux';
import SideMenu from 'components/SideMenu';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  getEachCollectionFeedbackThunk,
  setDefaultStagingFeedback,
} from 'actions/ruleEngines';
import SideManuFeedback from './SideMenuFeedback';
import { useSetInitialFeedback } from './helperHooks';
import * as Styles from './styles';

interface IFeedbackProps {
  eachRuleId: string;
  feedbackExist: boolean;
  linkIdentifier: string;
}

const FeedbackForm = ({
  eachRuleId,
  feedbackExist,
  linkIdentifier,
}: IFeedbackProps) => {
  const loaderStatus = useTypedSelector(store => store.catalog.loaderStatus);
  const emptyFeedback = useSetInitialFeedback();
  const [isSideMenuOpen, sideMenuOpenAction] = useBoolean();
  const dispatch = useDispatch();

  function FeedbackButtonClick() {
    if (feedbackExist) {
      dispatch(getEachCollectionFeedbackThunk(eachRuleId));
    }
    dispatch(setDefaultStagingFeedback(emptyFeedback));
    sideMenuOpenAction.on();
  }

  return (
    <>
      <Styles.TitleWrapper
        feedbackExist={feedbackExist}
        onClick={FeedbackButtonClick}
        className="cursor-pointer"
      >
        {feedbackExist ? 'View Feedback' : '+ Add Feedback'}
      </Styles.TitleWrapper>
      {!loaderStatus && isSideMenuOpen && (
        <SideMenu key={eachRuleId}>
          <SideManuFeedback
            eachRuleId={eachRuleId}
            feedbackExist={feedbackExist}
            onClose={() => sideMenuOpenAction.off()}
            linkIdentifier={linkIdentifier}
          />
        </SideMenu>
      )}
    </>
  );
};

export default FeedbackForm;
