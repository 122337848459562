import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import * as Styles from './styles';

interface IRegionFilterProps {
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const RegionFilter = ({ onFilterChange }: IRegionFilterProps) => {
  const regionFilter =
    useTypedSelector(store => store.catalog.allLocationRegionData) ?? [];
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedRegion, setSelectedRegion] = useState<Array<string>>(
    currentQueryParams?.region ?? []
  );

  function handleRegionCheck(region: string) {
    const newSelectedRegion = insertOrRemove(selectedRegion, region);
    onFilterChange({ region: newSelectedRegion });
  }

  function handleDesignSourceClear() {
    onFilterChange({ region: [] });
  }

  return (
    <Styles.RegionContainer className="pos-a fit-height">
      <Styles.RegionFilter className="spread">
        <Styles.RegionFilterText>Region</Styles.RegionFilterText>
        <Styles.RegionFilterClearAll
          className="cursor-pointer"
          onClick={handleDesignSourceClear}
        >
          Clear
        </Styles.RegionFilterClearAll>
      </Styles.RegionFilter>
      <Styles.RegionFilterResults>
        {regionFilter?.map(eachRegion => (
          <Styles.FilterOption key={eachRegion.id}>
            <Checkbox
              item={{
                value: eachRegion.name,
              }}
              label={eachRegion.name}
              onChange={() => handleRegionCheck(eachRegion.name)}
              checked={selectedRegion.includes(eachRegion.name)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.RegionFilterResults>
    </Styles.RegionContainer>
  );
};

export default RegionFilter;
