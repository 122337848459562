import { useDispatch } from 'react-redux';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import { uploadCatalogueSheetIdle } from 'actions/catalog';
import { moveToCatalogListRoute } from 'services/crossOriginMsg';
import { openInNewTab } from 'utils/helpers';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const {
  'add-with-outline': AddIconWithOutline,
  'excel-upload-success': ExcelUploadSuccess,
} = ICONOGRAPHY;

interface IUploadSuccess {
  filename: string;
  fileUrl: string;
}

const UploadSuccess = ({ filename, fileUrl }: IUploadSuccess) => {
  const dispatch = useDispatch();

  return (
    <Styles.Wrapper>
      <ExcelUploadSuccess />
      <Styles.FileName
        onClick={() => {
          if (fileUrl) openInNewTab(fileUrl);
        }}
      >
        {filename}
      </Styles.FileName>
      <Styles.SuccessText>Upload Successful 🎉</Styles.SuccessText>
      <Styles.ButtonWrapper>
        <StyledButton
          variant={StyledButtonVariant.Primary}
          style={{ padding: '12px 115px', marginBottom: '24px' }}
          onClick={moveToCatalogListRoute}
        >
          View Details
        </StyledButton>
        <StyledButton
          variant={StyledButtonVariant.Outlined}
          style={{ padding: '12px 103px', width: 'auto' }}
          onClick={() => {
            dispatch(uploadCatalogueSheetIdle());
          }}
          className="upload-more-button"
        >
          <AddIconWithOutline />
          &nbsp;Upload More
        </StyledButton>
      </Styles.ButtonWrapper>
    </Styles.Wrapper>
  );
};

export default UploadSuccess;
