import styled, { keyframes } from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const DropdownTitle = styled.p`
  color: var(--rhino);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const Wrapper = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  background: var(--white);
`;

export const Header = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--athens-gray);
  height: 64px;
  gap: 20px;
`;

export const RuleCloseIcon = styled.div`
  height: 34px;
  width: 34px;
  margin-left: 20px;
  background: var(--white);
  border: 1px solid var(--mischka);
  border-radius: 50%;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: var(--rhino);
`;

export const Body = styled.div`
  height: 92vh;
  padding: 19px 16px;
  justify-content: space-between;
  background-color: var(--input-disabled);
`;

export const GenerateLinkButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweed,
}))`
  width: 12%;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 18px;
`;

export const Spinner = styled.div`
  font-size: 4px;
  border: 4px solid var(--white);
  border-left-color: var(--rhino);
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  height: 28px;
  width: 28px;
  border-radius: 50%;
`;

export const TitleText = styled.div`
  width: 20%;
  margin-bottom: 19px;
  padding-right: 16px;

  input {
    width: 100%;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    color: var(--rhino);
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
    color: var(--manatee);
  }
`;

export const ConditionWrapper = styled.div`
  width: 65%;
  padding-right: 15px;
`;

export const SortingWrapper = styled.div`
  width: 35%;
`;

export const GenerateLinkWrapper = styled.div`
  padding: 15px 15px 15px 0;
`;

export const ShowUrlWrapper = styled.div`
  top: 4px;
  right: 500px;
  position: fixed;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  max-width: 300px;
  border: 1px solid var(--athens-gray);
`;

export const ConditionContainer = styled.div`
  width: 20%;
  padding-right: 16px;

  .dropdown2-wrapper {
    width: 100%;
    margin-bottom: 24px;

    span {
      background-color: var(--white);
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;

      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;

      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }

  .dropdown-placeholder {
    padding-right: 80%;
    color: var(--manatee);
    font-size: 14px;
    line-height: 16px;
  }
`;

export const UploadMoodboardTitle = styled.h2`
  color: var(--rhino);
  font-weight: 600;
  font-size: 14px;
  margin: 0 4px 4px 0;
`;

export const AddPhotoIcon = styled.div`
  width: 48px;
  height: 48px;
  background: var(--white);
  border: 2px solid var(--mischka);
  border-radius: 6px;
  font-size: 26px;
`;

export const EachImageWrapper = styled.div`
  height: 48px;
  width: 96px;
  margin-right: 8px;
  border-radius: 4px;

  img {
    border-radius: 4px;
  }
`;

export const EachDeleteIcon = styled.div`
  top: -8px;
  right: 8px;
  background-color: var(--white);
  border-radius: 24px;
  z-index: 2;
`;

export const MoodboardImagesContainer = styled.div`
  padding: 4px;
`;

export const FabricClusterWrapper = styled.div`
  margin-left: 16px;
`;

export const FabricClusterCheckLabel = styled.h2`
  color: var(--rhino);
  font-weight: 600;
  font-size: 14px;
  margin: 0 8px 4px 0;
  white-space: nowrap;
`;
