import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'utils/hooks';
import {
  handleAddOrEditRule,
  makeDefaultRuleStaging,
  toggleAddRule,
  toggleShowUrl,
} from 'actions/ruleEngines';
import ICONOGRAPHY from 'CustomConstant/icons';
import ConditonData from './AddNewConditions';
import SortingData from './AddNewSortings';
import GenerateLinkHeader from './GenerateLinkInput';
import { StagingModeEnum } from './type';
import * as Styles from './styles';

const { 'arrow-left': LeftArrow } = ICONOGRAPHY;

const AddRules = () => {
  const stagingMode = useTypedSelector(store => store.ruleEngine.stagingMode);
  const dispatch = useDispatch();

  function handleAddRuleClose() {
    dispatch(makeDefaultRuleStaging());
    dispatch(handleAddOrEditRule(StagingModeEnum.CREATE));
    dispatch(toggleAddRule(false));
    dispatch(toggleShowUrl(false));
  }

  return (
    <Styles.Wrapper className="full-view-width">
      <Styles.Header className="align-center">
        <Styles.RuleCloseIcon
          className="center cursor-pointer"
          onClick={handleAddRuleClose}
        >
          <LeftArrow strokeWidth={2} />
        </Styles.RuleCloseIcon>
        <Styles.Title>
          {stagingMode === StagingModeEnum.CREATE && (
            <>Add Rule Based Collection </>
          )}
          {stagingMode === StagingModeEnum.EDIT && (
            <>Edit Rule Based Collection</>
          )}
        </Styles.Title>
      </Styles.Header>
      <Styles.Body className="full-width pos-a">
        <GenerateLinkHeader />
        <div className="d-flex">
          <Styles.ConditionWrapper>
            <ConditonData />
          </Styles.ConditionWrapper>
          <Styles.SortingWrapper>
            <SortingData />
          </Styles.SortingWrapper>
        </div>
      </Styles.Body>
    </Styles.Wrapper>
  );
};

export default AddRules;
