import { ChangeEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextInput from 'components/textInput/textInput';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import Checkbox from 'library/checkbox/checkbox';
import { insertOrRemove } from 'utils/utility/utils';
import { useAllAdminDetail } from '../helper';
import { IGetEachOption, FilterTypes } from '../type';
import * as Styles from './styles';

interface ICreatedByFilterProps {
  onClose(): void;
  onFilterChange(param: Partial<Record<FilterTypes, Array<string>>>): void;
}

const CreatedByFilter = ({
  onClose,
  onFilterChange,
}: ICreatedByFilterProps) => {
  const admins = useAllAdminDetail();
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [createdBySearch, setCreatedBySearch] = useState('');
  const [list, setCreatedByList] = useState<Array<IGetEachOption>>(admins);
  const [selectedCheckbox, setSelectedCheckbox] = useState<Array<string>>(
    currentQueryParams?.added_by__in ?? []
  );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    setCreatedBySearch(value);
    setCreatedByList(matchQuery(value, admins));
  }

  function matchQuery(value: string, options: Array<IGetEachOption>) {
    const newCategories: Array<IGetEachOption> = [];
    options.forEach(eachOption => {
      if (eachOption.name.toLowerCase().startsWith(value.toLowerCase())) {
        newCategories.push(eachOption);
      }
    });

    return newCategories;
  }

  function handleCategoryCheck(value: string) {
    const updatedSelectedOptions = insertOrRemove(selectedCheckbox, value);
    setSelectedCheckbox(updatedSelectedOptions);
  }

  function handleApplyFilter() {
    onFilterChange({ added_by__in: selectedCheckbox });
  }

  function handleClearOption() {
    onFilterChange({ added_by__in: [] });
  }

  return (
    <Styles.CreatedByFilterContainer className="pos-a full-width">
      <Styles.CreatedByFilterHeader className="spread">
        <Styles.CategoryFilterHeaderText>
          Created By
        </Styles.CategoryFilterHeaderText>
        <Styles.CreatedByFilterHeaderClearAll
          className="cursor-pointer"
          onClick={handleClearOption}
        >
          Clear
        </Styles.CreatedByFilterHeaderClearAll>
      </Styles.CreatedByFilterHeader>
      <Styles.CreatedBySearchContainer className="full-width">
        <TextInput
          placeholder="Search Name"
          onChange={handleSearchChange}
          onClear={() => null}
          value={createdBySearch}
          listItemSearchBoxClass="list-item-search-box-class"
          showCrossButton={false}
        />
      </Styles.CreatedBySearchContainer>
      <Styles.CreatedByfilterResults className="has-y-scroll">
        {list?.map(item => (
          <Styles.FilterOption key={item.id}>
            <Checkbox
              item={{
                value: item.id,
              }}
              label={item.name}
              onChange={() => handleCategoryCheck(item.id)}
              checked={selectedCheckbox.includes(item.id)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.CreatedByfilterResults>
      <Styles.BottomTabButtons className="spread">
        <Styles.ClearButton onClick={onClose}>Cancel</Styles.ClearButton>
        <Styles.ApplyButton
          className="cursor-pointer"
          onClick={handleApplyFilter}
        >
          Apply
        </Styles.ApplyButton>
      </Styles.BottomTabButtons>
    </Styles.CreatedByFilterContainer>
  );
};

export default CreatedByFilter;
