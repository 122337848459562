import { isEmptyArray } from './assertion';

const downloadBlob = (blob, filename) => {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      if (typeof this !== 'undefined') {
        this.removeEventListener('click', clickHandler);
      }
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
};

export const downloadFromLink = async ({ name, link }) => {
  try {
    const options = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const newblob = await fetch(link, options).then(response =>
      response.blob()
    );

    downloadBlob(newblob, name);
  } catch (err) {
    console.log('err: ', err);
  }
};

export const detectKeyPress = e => {
  const returnObj = {};
  if ('key' in e) {
    switch (e.key) {
      case 'Escape' || 'Esc':
        returnObj['escape'] = true;
        break;
      case 'ArrowLeft':
        returnObj['leftarrow'] = true;
        break;
      case 'ArrowUp':
        returnObj['uparrow'] = true;
        break;
      case 'ArrowRight':
        returnObj['rightarrow'] = true;
        break;
      case 'ArrowDown':
        returnObj['downarrow'] = true;
        break;
      case 'Enter':
        returnObj['enter'] = true;
        break;
      default:
        returnObj['anotherKey'] = true;
    }
  } else {
    switch (e.keyCode) {
      case 27 || '27':
        returnObj['escape'] = true;
        break;
      case 37 || '37':
        returnObj['leftarrow'] = true;
        break;
      case 38 || '38':
        returnObj['uparrow'] = true;
        break;
      case 39 || '39':
        returnObj['rightarrow'] = true;
        break;
      case 40 || '40':
        returnObj['downarrow'] = true;
        break;
      case 13 || '13':
        returnObj['enter'] = true;
        break;
      default: {
        returnObj['anotherKey'] = true;
      }
    }
  }
  return returnObj;
};

export const queryStringify = obj => {
  let arr = [];
  for (let x in obj) {
    arr.push(`${x}=${obj[x]}`);
  }
  return arr.join('&');
};

export const isStrDecimal = (e, onChange, max, min) => {
  var value = String(e.target.value);
  if (!value) {
    if (onChange) return onChange(e);
    // if value is empty
    else return true;
  }
  // var reg = new RegExp('^\\d+$'); // accept only numbers.
  var reg = /^[0-9]*\.?[0-9]*$/;
  if (reg.test(value)) {
    if (max && Number(max) && Number(value) && Number(value) > max) return;
    if (min && Number(min) && Number(value) && Number(value) < min) return;
    if (onChange) return onChange(e);
    else return true;
  }
  return false;
};

export const REGEX_NUM = new RegExp('^\\d*$');

export const isStrNumber = (e, onChange, max, min) => {
  const value = String(e.target.value);
  if (REGEX_NUM.test(value)) {
    if (max && Number(max) && Number(value) && Number(value) > max) return;
    if (min && Number(min) && Number(value) && Number(value) < min) return;
    if (onChange) return onChange(e);
    else return true;
  }
  return false;
};

export function getPathname(searchObject) {
  const LOCATION = window.location.pathname;
  const pathname = `${LOCATION}?${queryStringify(searchObject)}`;
  return pathname;
}

export function insertOrRemove(array, item) {
  let finalArray = [];
  if (array.includes(item)) {
    finalArray = array.filter(i => i !== item);
  } else {
    finalArray = [...array, item];
  }
  return finalArray;
}

export function removeFalsyKeys(keyArrayObject) {
  const result = {};

  Object.keys(keyArrayObject).forEach(eachKey => {
    const value = keyArrayObject[eachKey];
    if (!isEmptyArray(value)) {
      result[eachKey] = value;
    }
  });
  return result;
}

export function removeMultipleFromArray(array, item) {
  return array.filter(eachItem => {
    return eachItem !== item;
  });
}

export const modifyImageURl = url => {
  const { hostname } = new URL(url);
  const str = 'image/upload/';
  if (hostname === 'res.cloudinary.com' && url.indexOf(str) > -1) {
    return url.replace(str, `${str}f_auto,q_auto/`);
  }
  return url;
};

export function sanitizeFileName(name, regex) {
  const index = name.lastIndexOf('.');
  return name.slice(0, index).replace(regex, '');
}

export function getSetDifference(setA, setB) {
  return new Set([...setA].filter(element => !setB.has(element)));
}

export function getSetUnion(setA, setB) {
  return new Set([...setA, ...setB]);
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const customFormatDate = date => {
  // Formats date to: 11 Jan 2020
  if (!date) return '';
  const _d = Date.parse(date);
  const day = new Date(_d);
  const refined =
    day.getDate() + ' ' + months[day.getMonth()] + ' ' + day.getFullYear();
  return refined;
};

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
