import { IconPropsType } from 'customTypes/icons';

interface IUploadIconProps extends IconPropsType {}

const UploadIcon = ({
  size = 18,
  stroke = 'var(--rhino)',
  ...props
}: IUploadIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 10v.75A2.25 2.25 0 0 0 3.25 13h7.5A2.25 2.25 0 0 0 13 10.75V10m-3-6L7 1m0 0L4 4m3-3v9"
      stroke={stroke}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadIcon;
