import styled, { css } from 'styled-components';
import { COLOR_SET } from './helperConstant';

interface IStyleImage {
  isLinkCopied: boolean;
  checkStatus: string;
}

interface ILoadingStateProps {
  bgColorIndex: number;
}

const imageDimensions = css`
  height: 250px;
  width: 238px;
`;

export const TooltipContentForError = styled.div`
  color: var(--dirt-red);
`;

export const TooltipContentForCorrection = styled.div`
  color: var(--ocean-green);
`;
export const Wrapper = styled.div`
  padding: 15px 30px 0;
  height: 95%;
  .intersection-observer-target {
    height: 10px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  height: 40px;

  .add-image-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: var(--white);
    background-color: var(--watermelon);
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }

  .label-disabled {
    font-weight: 500;
    background-color: var(--input-disabled);
    color: var(--manatee);
    border: 1px solid var(--athens-gray);
    cursor: not-allowed;
  }

  .dropdown2-wrapper {
    width: 300px;
    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdown-class {
    border: none;
    height: auto;
    width: auto;
    padding: 12px;

    .label-name {
      color: var(--manatee) !important;
      font-size: 13px !important;
      line-height: 16px;
    }
    .label-value {
      font-size: 13px !important;
      color: var(--rhino) !important;
      line-height: 16px;
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;
      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }
`;

export const ImageListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 32px;
  column-gap: 24px;
`;

export const CopyLinkPopUp = styled.div`
  position: absolute;
  display: none;
  bottom: 0;
  color: var(--pacific-blue);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  z-index: 3;
  cursor: pointer;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  background-color: var(--white);
  ${imageDimensions}
  height: 64px;
`;

export const Pill = styled.span`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
  padding: 2px 8px;
  color: var(--white);
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  background-color: var(--rhino);
  opacity: 0.8;
  border-radius: 10px;
  cursor: pointer;
`;

export const DeleteWrapper = styled.div`
  position: absolute;
  display: none;
  top: 8px;
  right: 8px;
  padding: 8px 9px;
  border-radius: 4px;
  background-color: var(--white);
  cursor: pointer;
`;

export const Overlay = styled.div`
  position: absolute;
  display: none;
  border-radius: 4px;
  background-color: var(--rhino);
  opacity: 0.5;
  ${imageDimensions}
`;

export const FailOverlay = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  border-radius: 4px;
  background-color: rgba(146, 155, 170, 0.5);
  ${imageDimensions}
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 10px;
  color: var(--rhino);
  background-color: var(--white);
  border-radius: 4px;
  cursor: pointer;
  .bs-upload {
    stroke-width: 0.8px;
  }
`;

export const StyleItemWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  ${imageDimensions}

  &:hover ${CopyLinkPopUp} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover ${DeleteWrapper} {
    display: block;
  }

  &:hover ${Overlay} {
    display: block;
  }
`;

export const StyleImage = styled.img<IStyleImage>`
  position: absolute;
  bottom: 0;
  object-fit: cover;
  border-radius: 4px;
  border: ${props =>
    props.checkStatus === 'SIZE_ERROR'
      ? '4px solid var(--red-primary)'
      : '1px solid var(--athens-gray)'};
  opacity: ${props => (props.isLinkCopied ? '0.5' : '1')};
  ${imageDimensions}
`;

export const AddImage = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  border-radius: 4px;
  background-color: var(--watermelon);
`;

export const AddImageText = styled.p`
  color: var(--mischka);
  font-size: 16px;
  font-weight: 600;
`;

export const LoadingContainer = styled.div<ILoadingStateProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${props => COLOR_SET[props.bgColorIndex]};
  border: 1px solid var(--mischka);
  border-radius: 4px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EmptyState = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 65px;

  img {
    object-fit: contain;
    height: 225px;
  }

  p {
    color: var(--tertiary-text);
  }
`;
