import CREDENTIAL_ACTION_TYPES from "../actionTypes/credentials";
import { IReduxActionType } from "../utils/types";

interface ICatalogExcel {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
  AWS_REGION: string;
  S3_BUCKET: string;
}
export interface IInitialState {
  catalogExcel: ICatalogExcel;
}

const initialState: IInitialState = {
  catalogExcel: {
    AWS_ACCESS_KEY_ID: String(),
    AWS_SECRET_ACCESS_KEY: String(),
    AWS_REGION: String(),
    S3_BUCKET: String(),
  },
};

const credentialsReducer = (state = initialState, action: IReduxActionType) => {
  switch (action.type) {
    case CREDENTIAL_ACTION_TYPES.SET_CREDENTIAL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default credentialsReducer;
