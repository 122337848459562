import Toast from 'library/Toast';
import {
  DesignLibraryType,
  IDesignLibrary,
} from 'components/DesignLibrary/types';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import {
  addDesignLibraryDataApi,
  getAllDesignsLibraryApi,
  getSeasonAndFabricTypeApi,
  removeDesignLibraryItemApi,
  updateDesignLibraryItemApi,
} from 'utils/api';
import { IReduxActionType, ThunkActionType } from 'utils/types';
import DESIGN_LIBRARY_TYPES from 'actionTypes/designLibrary';
import { ApiResponseEnum } from 'customTypes/callbackActionTypes';
import apiResponseHandler from './apiResponseHandler';

export const setLoaderStatus = (payload: boolean): IReduxActionType => ({
  type: DESIGN_LIBRARY_TYPES.SET_LOADER_STATUS,
  payload,
});

export const setDesignLibrarySaveSuccess = (isSuccess: boolean) => ({
  type: DESIGN_LIBRARY_TYPES.SET_SAVE_DESIGN_LIBRARY_SUCCESS,
  payload: isSuccess,
});

export const designLibraryPaginationInfo = (data: Record<string, number>) => ({
  type: DESIGN_LIBRARY_TYPES.DESIGN_LIBRARY_PAGINATION_INFO,
  payload: data,
});

export const setDesignLibraryData = (
  data: Array<IDesignLibrary>,
  mode: OperatorEnum
) => ({
  type: DESIGN_LIBRARY_TYPES.SET_DESIGN_LIBRARY_DATA,
  payload: { data, mode },
});

export const getDesignLibraryDataThunk =
  (pageNumber: number, mode: OperatorEnum, filterParams: string = '') =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await getAllDesignsLibraryApi(pageNumber, filterParams);
      const { data } = response;
      const { count = 0, total_pages: totalPages = 0 } = data ?? {};
      const results: Array<IDesignLibrary> = data?.results ?? [];
      dispatch(
        designLibraryPaginationInfo({
          count: count,
          totalPages: totalPages,
          currentPage: pageNumber,
        })
      );
      const modifiedResults = results.map(eachResult => ({
        id: eachResult.id,
        name: eachResult.name,
        season: eachResult.season,
        material: eachResult.material,
        fabric_type: eachResult.fabric_type,
        description: eachResult.description,
        tags: eachResult.tags,
        brand_ids: eachResult.brand_ids,
        sales_representative: eachResult.sales_representative,
        category_manager: eachResult.category_manager,
        designer: eachResult.designer,
        pdf_url: eachResult.pdf_url,
        thumbnail_url: eachResult.thumbnail_url,
        category: eachResult.category,
        bu_head: eachResult.bu_head,
      }));
      dispatch(setDesignLibraryData(modifiedResults, mode));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

export const setSeasonAndFabricData = (
  data: Array<string>,
  value: string
): IReduxActionType => ({
  type: DESIGN_LIBRARY_TYPES.SET_SEASON_AND_FABRIC_DATA,
  payload: { data, value },
});

export const getSeasonAndFabricDataThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    const promise = getSeasonAndFabricTypeApi();
    const response = await apiResponseHandler<any>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        let seasons: Array<string> = [];
        let fabricType: Array<string> = [];
        seasons = [...seasons, ...response.data[0].season];
        fabricType = [...fabricType, ...response.data[1].fabric_type];

        dispatch(setSeasonAndFabricData(seasons, 'seasons'));
        dispatch(setSeasonAndFabricData(fabricType, 'fabricType'));
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const postDesignLibraryData =
  (data: IDesignLibrary) =>
  async (dispatch: (action: ThunkActionType | IReduxActionType) => void) => {
    dispatch(setDesignLibrarySaveSuccess(true));
    try {
      const response = await addDesignLibraryDataApi({
        name: data.name,
        season: data.season,
        material: data.material,
        fabric_type: data.fabric_type,
        description: data.description,
        tags: data.tags,
        brand_ids: data.brand_ids,
        sales_representative: data.sales_representative,
        category_manager: data.category_manager,
        designer: data.designer,
        pdf_url: data.pdf_url,
        category: data.category,
      });

      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      Toast.SUCCESS('Data saved successfully');
      dispatch(getDesignLibraryDataThunk(1, OperatorEnum.Modify));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setDesignLibrarySaveSuccess(false));
    }
  };

export const deleteDesignLibraryThunk =
  (data: Record<string, any>) =>
  async (dispatch: (action: ThunkActionType) => void) => {
    try {
      const response = await removeDesignLibraryItemApi(data);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(getDesignLibraryDataThunk(1, OperatorEnum.Modify));
      Toast.SUCCESS(response.msg);
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const updateDesignLibraryThunk =
  (data: DesignLibraryType) =>
  async (dispatch: (action: ThunkActionType | IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await updateDesignLibraryItemApi(data);
      if (response.status) {
        Toast.SUCCESS('Design Information Updated!');
        dispatch(getDesignLibraryDataThunk(1, OperatorEnum.Modify));
      } else {
        Toast.ERROR(response.msg);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };
