import React from 'react';
import FileUpload from 'components/FileUpload';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const { 'image-link-shirt': ImageLinkShirt } = ICONOGRAPHY;

interface IAddImageProps {
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const AddImage = ({ handleChange }: IAddImageProps) => {
  return (
    <Styles.Wrapper className="outer center">
      <ImageLinkShirt />
      <Styles.ContentWrapper className="center">
        <FileUpload
          onChange={handleChange}
          multiple
          className="catalogue-upload-image"
        >
          Upload Images
        </FileUpload>
        {/* <Styles.SecondaryText>or</Styles.SecondaryText>
        <Styles.SecondaryText>
          Drag and drop any jpg, png files
        </Styles.SecondaryText> */}
      </Styles.ContentWrapper>
    </Styles.Wrapper>
  );
};

export default AddImage;
