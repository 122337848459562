import * as Styles from './styles';

interface ITextAreaInputProps {
  value: string;
  label: string;
  inputHeight: number;
  characterCount: number;
  placeholder: string;
  onChange(value: string): void;
  isDisabled?: boolean;
  shoudShowCharacterCount?: boolean;
}

const TextAreaInput = ({
  inputHeight,
  value,
  label,
  characterCount,
  placeholder,
  onChange,
  isDisabled = false,
  shoudShowCharacterCount = true,
}: ITextAreaInputProps) => (
  <div className="v-d-flex">
    <Styles.InputLabel>{label}</Styles.InputLabel>
    <Styles.Input
      className="no-y-scroll"
      inputHeight={inputHeight}
      placeholder={placeholder}
      value={value}
      onChange={event => onChange(event.target.value)}
      isDisabled={isDisabled}
    />
    {shoudShowCharacterCount && (
      <Styles.CharacterCount className="end-align-center">
        <p>{`${value ? value.length : 0} / ${characterCount}`}</p>
      </Styles.CharacterCount>
    )}
  </div>
);

export default TextAreaInput;
