import { Tag } from 'react-tag-autocomplete';

export interface IEachRule {
  title: string;
  conditions: Array<IEachCondition>;
  sorting: Array<IEachSorting>;
  link_identifier?: string;
  category_manager: number;
  sales_representative: number;
  approached_brand: string;
  images: Array<string>;
  is_cluster: boolean;
  cluster_type: string;
}

export interface IEachCondition {
  attr_name: string;
  operator: string;
  attr_value: Array<Tag>;
}

export interface IEachSorting {
  key: string;
  value: Array<string>;
}

export interface IConditionProperties {
  id: string;
  operator: string;
  attributeName: string;
  attributeOptions: Array<Tag>;
}

export interface ISortingProperties {
  id: string;
  attributeName: string;
  attributeOptions: Array<Tag>;
  key: string;
}

export interface IRuleProperties {
  id: string;
  ruleName: string;
  categoryManager: number;
  salesRepresentative: number;
  approachedBrand: string;
  condition: Record<string, IConditionProperties>;
  sortings: Record<string, ISortingProperties>;
  link_identifier?: string;
  url?: string;
  images: Array<string>;
  isCluster: boolean;
  clusterType: string;
}

export interface IConditionPropertiesData {
  attr_name: string;
  operators: Array<string>;
  attr_options: Array<Tag>;
  data_type: string;
}

export interface IRuleCollectionData {
  id: number;
  added_by: number;
  conditions: Array<IEachCondition>;
  created_on: string;
  link_identifier: string;
  sorting: Array<IEachSorting>;
  title: string;
  url: string;
  sales_representative: number;
  category_manager: number;
  approached_brand: string;
  feedback_exist: boolean;
  images: Array<string>;
  is_cluster: boolean;
  cluster_type: string;
}

export interface ISortingPropertiesData {
  attr_name: string;
  attr_options: Array<Tag>;
  key: string;
}

export type ToggleAttributeEventType =
  | React.MouseEvent<HTMLElement>
  | MouseEvent
  | KeyboardEvent;

export enum StagingModeEnum {
  EDIT = 'edit',
  CREATE = 'create',
}

export interface IMoodboardImage {
  id: string;
  url: string;
}

export interface IMoodboardImagesUploadError {
  height: number;
  width: number;
  imageSize: number;
  minHeight?: number;
  maxHeight?: number;
  minWidth?: number;
  maxWidth?: number;
}
