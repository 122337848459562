import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const EachButton = styled.button<{ isActive: boolean }>`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--loblolly);
  color: var(--rhino);
  background-color: ${props =>
    props.isActive ? 'var(--wash-me)' : 'var(--white)'};

  &:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:nth-child(2),
  &:nth-child(3) {
    border-left: 0;
    border-radius: 0;
  }

  &:nth-child(4) {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
  &:nth-child(5) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
