import { ReactChild, ReactNode } from 'react';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styled from './styles';

const { close: CloseIcon } = ICONOGRAPHY;

interface IModalProps {
  heading: string;
  children: ReactChild | ReactChild[] | null;
  footer: ReactNode;
  onClose: () => void;
}

const Modal = ({ heading, children, onClose, footer }: IModalProps) => {
  return (
    <Styled.Wrapper onClick={onClose}>
      <Styled.Content
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <Styled.Header>
          <h2>{heading}</h2>
          <button type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </Styled.Header>
        <Styled.Body className="body-content">{children}</Styled.Body>
        <Styled.Footer>{footer}</Styled.Footer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default Modal;
