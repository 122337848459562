import styled, { css } from 'styled-components';
import { StatusEnum } from './constants';

export const Wrapper = styled.div`
  padding: 15px 30px 100px;
  background-color: var(--brilliance);
`;

export const TableContainer = styled.div`
  width: calc(100vw - 60px);
  min-height: 200px;
  flex-grow: 1;
`;

interface ICellHeaderWrapperProps {
  hideBorder?: boolean;
}

export const CellHeaderWrapper = styled.h4<ICellHeaderWrapperProps>`
  height: 50px;
  ${props =>
    !props.hideBorder &&
    css`
      border: 1px solid var(--athens-gray);
      background: var(--input-disabled);
    `}
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: var(--rhino);
  font-weight: 500;
  font-size: 12px;
  margin: 0 0 8px 0;
  white-space: nowrap;
`;

interface ICellBodyWrapperProps {
  hideBorder?: boolean;
  isVertical?: boolean;
  centerEverything?: boolean;
}

export const ProductIdTitle = styled.div`
  color: var(--metallic-seaweed);

  &:hover {
    color: var(--watermelon);
  }
`;

export const CellBodyWrapper = styled.p<ICellBodyWrapperProps>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--white);

  ${props =>
    props.isVertical &&
    css`
      flex-direction: column;
      justify-content: space-between;
    `}
  ${props =>
    !props.hideBorder &&
    css`
      border: 1px solid var(--athens-gray);
      padding: 16px 12px;
    `}
  ${props =>
    props.centerEverything &&
    css`
      align-items: center;
      justify-content: center;
      padding: 0;
    `}
`;
interface IStatusIndicatorProps {
  status: StatusEnum;
}

const STATUS_COLORS = {
  active: 'var(--cerulean)',
  inactive: 'var(--manatee)',
  approved: 'var(--ocean-green)',
  notapproved: 'var(--web-orange)',
};

export const StatusIndicator = styled.span<IStatusIndicatorProps>`
  color: ${props => STATUS_COLORS[props.status]};
  text-transform: capitalize;
`;

export const ImageWrapper = styled.div`
  width: 80px;
  height: 110px;
  display: flex;
  justify-content: center;
  border: 1px solid var(--athens-gray);
  position: relative;

  img {
    text-indent: -9999px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  span {
    position: absolute;
    right: 8px;
    bottom: 8px;
    color: var(--white);
    background: var(--rhino);
    box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
    border-radius: 10px;
    height: 16px;
    min-width: 24px;
    font-weight: 500;
    font-size: 10px;
    max-width: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TableRow = styled.tr`
  background: var(--white);
  /* TODO: CONFIRM THESE*/
  /* &.selected {
    background: var(--fair-pink);
  } */
`;

export const TableHead = styled.th`
  /* position: sticky;
  top: 0; */
  margin: 1px;
`;

export const TableData = styled.td`
  height: 110px;
  margin: 1px;
`;

interface ICheckboxWrapperProps {
  isHeader?: boolean;
}

export const CheckboxWrapper = styled.div<ICheckboxWrapperProps>`
  background: var(--white);
  border: 1px solid var(--athens-gray);
  ${props =>
    props.isHeader
      ? css`
          height: calc(100% - 8px);
          align-items: center;
        `
      : css`
          height: 100%;
        `}
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

export const Table = styled.table`
  height: auto;
`;

export const THead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 4;
  background: var(--white);

  th:nth-child(1) {
    left: 1px !important;
  }
  th:nth-child(2) {
    left: 47px !important;
  }
  th:nth-child(3) {
    left: 128px !important;
  }
  th:nth-child(4) {
    left: 278px !important;
  }
`;

export const TBody = styled.tbody`
  td:nth-child(1) {
    left: 1px !important;
  }
  td:nth-child(2) {
    left: 47px !important;
  }
  td:nth-child(3) {
    left: 129px !important;
  }
  td:nth-child(4) {
    left: 279px !important;
  }
`;

export const SpinnerContainer = styled.div`
  height: 50vh;
  width: calc(100vw - 130px);
  position: sticky;
  left: 0;
`;

export const EmptyViewContainer = styled.div`
  width: calc(100vw - 60px);
  position: sticky;
  left: 0;
`;

export const AllStyles = styled.p`
  color: var(--rhino);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

interface IgetRandomColorForImageBgProps {
  backgroundColor: string;
}

export const BrokenImageView = styled.div<IgetRandomColorForImageBgProps>`
  background: ${props => props.backgroundColor};
  height: 100%;
  width: 100%;
`;

export const ModalFooterWrapper = styled.div`
  button {
    width: 260px !important;
  }
`;

export const UploadButtonMsg = styled.p`
  display: block;
  font-size: 14px;
  color: var(--brownish-grey);
`;

export const UploadButtonWrapper = styled.div`
  height: 60px;

  .upload-excel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 14px 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    background-color: var(--watermelon);
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const FileNameDisplay = styled.a`
  font-size: 12px;
  max-width: 260px;
  text-decoration: none;
  color: var(--rhino);
  margin-top: -10px;

  :hover {
    text-decoration: underline;
  }
`;

export const UploadSpinnerWrapper = styled.div`
  svg {
    margin: 0;
    height: 16px;
    width: 16px;

    circle.path {
      stroke: var(--white);
    }
  }
`;

export const Count = styled.div`
  margin-bottom: 16px;
`;

export const SelectedCountIndicator = styled.span`
  color: var(--pacific-blue);
  background: var(--bubbles);
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 20px;
  white-space: nowrap;
`;

export const SelectedStyles = styled.div`
  height: 28px;
`;

export const ClusterText = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--rhino);
  margin-bottom: 2px;
`;
