import { URL } from 'config';
import qs from 'qs';
import { ObjectType } from 'customTypes/defined';

export function areAllInactiveUnapproved(
  list: Array<ObjectType>,
  selectedProductIds: Array<number | string>
) {
  const areAllInEligible = selectedProductIds.every(item => {
    const productDetail = list.find(product => product.product_id === item);
    return (
      productDetail?.is_active === false || productDetail?.is_approved === false
    );
  });
  return areAllInEligible;
}

export function getQueryParams(queryString: string) {
  const parsed = qs.parse(queryString.slice(1));
  const selectedFiltersObject: any = {};
  for (const [key, value] of Object.entries(parsed)) {
    if (key !== 'Token') {
      selectedFiltersObject[key] = String(value).split(',');
    }
  }
  return selectedFiltersObject;
}

export function redirectToProduct(value: string) {
  return `${URL}/${value}/buy`;
}

export function redirectToOrder(value: string | number) {
  return `${URL}/purchaseorder/${value}`;
}
