import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import { BiLoaderAlt } from 'react-icons/bi';

export const Header = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid var(--athens-gray);
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  height: 64px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--rhino);
`;

export const CloseWrapper = styled.div`
  background-color: var(--input-disabled);
  width: 34px;
  height: 34px;
  border-radius: 50%;
`;

export const BodyWrapper = styled.div`
  background-color: var(--input-disabled);
  overflow: scroll;
  flex: 1;
`;

export const UploadSection = styled.div`
  width: 65%;
`;

export const DesignFormWrapper = styled.div`
  width: 35%;
  background-color: var(--white);
`;

export const FormWrapper = styled.div`
  padding: 24px;
`;

export const FormTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--manatee);
  margin-bottom: 20px;
`;

export const CollectionName = styled.div`
  textarea {
    &::placeholder {
      color: var(--manatee) !important;
      font-size: 14px;
    }
  }
`;

export const CategoryDropdown = styled.div`
  margin-bottom: 20px;

  .dropdown2-wrapper span {
    border-radius: 4px !important;
    border: 1px solid var(--athens-gray);

    svg {
      fill: var(--manatee);
    }
  }
`;

export const LabelName = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--rhino);
  margin-bottom: 4px;
`;

export const TagContainerBottomText = styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
`;

export const TagsInput = styled.div`
  margin-bottom: 32px;

  .react-tags__selected {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 100% !important;
    height: 40px;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    padding: 12px 16px;
    color: var(--manatee);
    outline: none;
  }
`;

export const SaveLibraryButtonWrapper = styled.div`
  padding: 24px;
  border-top: 1px solid var(--athens-gray);
`;

export const UploadButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  width: 200px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 18px;
`;

export const UploadWrapper = styled.div`
  padding: 24px;
  height: 92vh;

  .file-upload {
    padding: 6px 16px;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--watermelon);
  }
`;

export const ExtraInfo = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--rhino);
  margin-top: 24px;
`;

export const Compress = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--rhino);
  text-decoration: underline;
  margin-top: 24px;
  margin-left: 4px;
`;

export const UploadText = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--white);
  margin-left: 8px;
`;

export const UploadModal = styled.div`
  background-color: var(--white);
  padding: 24px;
  width: 432px;
  border-radius: 4px;
  margin: 20% auto;
`;

export const UploadSpinnerWrapper = styled.div`
  top: 0;
  left: 0;
  border-radius: 4px;
`;

export const BrandWrapper = styled.div`
  margin-bottom: 20px;

  .dropdown2-wrapper {
    margin-top: 4px;
    border: 1px solid var(--athens-gray) !important;

    svg {
      fill: var(--manatee);
    }
  }
`;

export const PdfWrapper = styled.div`
  padding: 24px;

  .react-pdf__Page__canvas {
    margin: 24px auto;
    width: 80% !important;
    height: 100% !important;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  margin-top: 32px;
`;

export const ActionButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Subtle,
}))`
  width: 100px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  padding: 12px;

  &:focus {
    background-color: var(--input-disabled);
  }
`;

export const PageNumbers = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--rhino);
`;

export const InformationText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--rhino);
  margin-bottom: 24px;
`;

export const SaveSuccessful = styled.span`
  p {
    color: var(--deep-cerulean);
    font-size: 14px;
    margin-left: 6px;
    font-weight: 600;
  }
`;

export const SaveButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  padding: 9px 24px;
  font-weight: 600;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
`;

export const Spinner = styled(BiLoaderAlt)`
  animation: rotate360 1s linear infinite;
  margin-right: 0;
  width: inherit;
`;

export const PdfText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const SideWrapper = styled.div`
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: auto;
`;
