import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import * as Styles from './styles';

interface IDesignTypeFilterProps {
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const DesignSourceFilter = ({ onFilterChange }: IDesignTypeFilterProps) => {
  const designSource =
    useTypedSelector(state => state.catalog.primaryFilters.design_source) ?? [];
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedDesignSource, setSelectedDesignSource] = useState<
    Array<string>
  >(currentQueryParams?.design_source ?? []);

  function handleDesignSourceCheck(design_source: string) {
    const newSelectedDesignSource = insertOrRemove(
      selectedDesignSource,
      design_source
    );
    onFilterChange({ design_source: newSelectedDesignSource });
  }

  function handleDesignSourceClear() {
    onFilterChange({ design_source: [] });
  }

  return (
    <Styles.DesignSourceContainer className="pos-a">
      <Styles.DesignSourceFilter className="spread">
        <Styles.DesignSourceFilterText>
          Design Source
        </Styles.DesignSourceFilterText>
        <Styles.DesignSourceFilterClearAll
          className="cursor-pointer"
          onClick={handleDesignSourceClear}
        >
          Clear
        </Styles.DesignSourceFilterClearAll>
      </Styles.DesignSourceFilter>
      <Styles.DesignSourceFilterResults>
        {designSource?.map(eachDesign => (
          <Styles.FilterOption key={eachDesign.value}>
            <Checkbox
              item={{
                value: eachDesign.value,
              }}
              label={eachDesign.value}
              onChange={() => handleDesignSourceCheck(eachDesign.value)}
              checked={selectedDesignSource.includes(eachDesign.value)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.DesignSourceFilterResults>
    </Styles.DesignSourceContainer>
  );
};

export default DesignSourceFilter;
