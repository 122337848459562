export enum FileUploadStatusEnum {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Loading = 'LOADING',
  SizeError = 'SIZE_ERROR',
}

export const COLOR_SET = [
  '#EDFAFF',
  '#EEEFFF',
  '#FFF4F0',
  '#EEFFF4',
  '#FFFBEF',
  '#FFEDF3',
];

export enum ACCEPTED_SIZE {
  MinimumImageHeight = 720,
  MaximumImageHeight = 2400,
  MinimumImageWidth = 540,
  MaximumImageWidth = 1800,
  MinimumImageSize = 4,
  AspectRatio1 = 1,
  AspectRatio2 = 0.75,
  AspectRatio3 = 2,
  MoodboardImageMinimumWidth = 2600,
  MoodboardImageMaximumWidth = 2872,
  MoodboardImageMinimumHeight = 1416,
  MoodboardImageMaximumHeight = 1560,
}
