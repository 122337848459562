import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin-top: 2px;
  background-color: var(--input-disabled);
  height: 48px;
`;

export const BetaText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--rhino);
  margin-left: 12px;
`;

export const Wrapper = styled.div`
  padding: 24px 24px 24px;
`;

export const TrendsWrapper = styled.div`
  margin: 16px 0;
`;

export const HeadingText = styled.h2`
  font-family: Lora;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: var(--rhino);
`;

export const TagsWrapper = styled.div`
  padding: 16px;

  .tag-cloud {
    width: 60%;
  }
`;

export const Pills = styled.a`
  padding: 16px;
  margin: 8px;
  text-decoration: none;
`;

export const NoTagsWrapper = styled.div`
  height: 54vh;
`;

export const NoTagsText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
  margin-top: 24px;
`;

export const ChartWrapper = styled.div`
  width: 40%;
  padding: 0 48px;

  .dropdown2-wrapper {
    width: 300px !important;
  }

  .recharts-wrapper {
    top: -60px !important;
  }

  .recharts-sector {
    &:focus {
      outline: none;
    }
  }
`;

export const LabelName = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--tertiary-text);
`;

export const NoDataWrapper = styled.div`
  height: 120px;
`;

export const NoDataText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--tertiary-text);
`;
export const LedgerWrapper = styled.div`
  margin-top: 24px;
`;

export const TagColor = styled.div<{ backgroundColor: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${props => props.backgroundColor};
`;

export const EachTag = styled.div`
  margin: 0 20px 12px 0;
`;

export const TagName = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--rhino);
`;

export const NoTagsDataWrapper = styled.div`
  height: 54vh;
`;

export const DisclaimerTextWrapper = styled.div`
  margin-left: 16px;
`;

export const DisclaimerWrapper = styled.div`
  padding: 16px;
  border: 1px solid var(--athens-gray);
  border-radius: 8px;
  margin-bottom: 48px;
`;

export const IconOuterWrapper = styled.div`
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
  background-color: var(--pipin);
  outline: 6px solid var(--chablis);
`;

export const DisclaimerText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
`;

export const DisclaimerDescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--tertiary-text);
  margin-top: 4px;
`;
