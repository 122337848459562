import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { verifyExistingToken } from '../actions/auth';
import { TOKEN } from '../config';
import { getFromStorage } from '../services/localStorage';

export const useSetAuthFromUrl = () => {
  // Gets token from url and verifies
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isVerifying, setIsVerifying] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  // get token from localstorage
  // than check in url
  const token =
    searchParams.get('Token') ||
    searchParams.get('token') ||
    getFromStorage(TOKEN) ||
    null;

  const onSuccess = (data = {}) => {
    setIsTokenValid(true);
    setIsVerifying(false);
  };

  const onFail = () => {
    setIsVerifying(false);
  };

  useEffect(() => {
    if (token) {
      setIsVerifying(true);
      dispatch(verifyExistingToken(token, onSuccess, onFail));
    } else {
      setIsVerifying(false);
    }
  }, [token]);

  return [isTokenValid, isVerifying];
};
