import React, { ForwardedRef, forwardRef } from 'react';
import { AttachmentType, IObjectType } from '../../utils/types';

interface IFileUploadProps {
  children: any;
  onChange(
    event: React.ChangeEvent<HTMLInputElement>,
    field?: string,
    data?: Array<AttachmentType>
  ): void;
  multiple?: boolean;
  accept?: string;
  className?: string;
  disabled?: boolean;
  restProps?: IObjectType;
}

const FileUpload = forwardRef(
  (
    {
      children,
      onChange,
      multiple = false,
      accept = 'image/*',
      className = '',
      disabled = false,
      ...restProps
    }: IFileUploadProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <label
        className={`${className} ${disabled ? 'label-disabled' : ''}`}
        {...restProps}
      >
        {children}
        <input
          ref={ref}
          type="file"
          onChange={onChange}
          style={{ display: 'none' }}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
        />
      </label>
    );
  }
);

export default FileUpload;
