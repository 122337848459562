import { ReactNode } from 'react';
import * as Styles from './styles';

interface ISideDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  minWidth?: string;
  width?: string;
}

const SideDrawer = ({
  children,
  width = '35vw',
  minWidth = '200px',
  isOpen = true,
}: ISideDrawerProps) => (
  <Styles.Wrapper className="v-d-flex full-height" isOpen={isOpen}>
    <Styles.List
      width={width}
      minWidth={minWidth}
      className="v-d-flex full-height pos-r"
    >
      {children}
    </Styles.List>
  </Styles.Wrapper>
);

export default SideDrawer;
