import styled from 'styled-components';

export const ToggleSwitch = styled.div`
  width: 48px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .toggle-switch-checkbox {
    display: none;
  }
`;

export const ToggleSwitchLabel = styled.label`
  display: block;
  border: 0 solid var(--athens-gray);
  border-radius: 20px;
  margin: 0;
`;

export const ToggleSwitchInner = styled.span`
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.2s ease-in;

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 10px;
    font-weight: 600;
    color: var(--white);
    box-sizing: border-box;
  }

  &:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 6px;
    background-color: var(--watermelon);
    color: var(--white);
  }

  &:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 6px;
    background-color: var(--athens-gray);
    color: var(--rhino);
    text-align: right;
  }
`;

export const ToggleSwitchSwitch = styled.span`
  display: block;
  width: 14px;
  margin: 5px;
  background: var(--white);
  top: 0;
  bottom: 0;
  right: 24px;
  border: 0 solid var(--athens-gray);
  border-radius: 50%;
  transition: all 0.2s ease-in;
`;

export const CheckboxInput = styled.input`
  display: none;

  &:checked + ${ToggleSwitchLabel} {
    ${ToggleSwitchInner} {
      margin-left: 0;
    }

    ${ToggleSwitchSwitch} {
      right: 0px;
    }
  }
`;
