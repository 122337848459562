import { useRef, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTypedSelector } from "utils/hooks";
import * as Styled from "./styles";

interface ITableProps {
  columns: any;
  data: any;
  maxWidth: string;
  paginationHandler(): void;
  oddRowBackgroundColor?: string;
  evenRowBackgroundColor?: string;
  className?: string;
}

const Table = ({
  columns,
  data,
  maxWidth,
  paginationHandler,
  oddRowBackgroundColor = "var(--white)",
  evenRowBackgroundColor = "var(--input-disabled)",
  className = "",
}: ITableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  const observer = useRef<any>(null);
  const loaderStatus = useTypedSelector((store) => store.catalog.loaderStatus);

  function lastItemRef(node: HTMLTableRowElement) {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !loaderStatus) {
        paginationHandler();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }

  return (
    <Styled.StyledTable
      {...getTableProps()}
      maxWidth={maxWidth}
      oddRowBackgroundColor={oddRowBackgroundColor}
      evenRowBackgroundColor={evenRowBackgroundColor}
      className={className}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps(),
                  style: { minWidth: column.minWidth, width: column.width },
                })}
              >
                <Styled.HeaderWrapper className="d-flex">
                  {column.render("Header")}
                  {<Styled.Space />}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <IoIosArrowDown />
                    ) : (
                      <IoIosArrowUp />
                    )
                  ) : (
                    ""
                  )}
                </Styled.HeaderWrapper>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, val) => {
          prepareRow(row);
          return (
            <>
              <tr
                {...row.getRowProps()}
                ref={val === rows.length - 1 ? lastItemRef : null}
                key={["row", val].join("-")}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            </>
          );
        })}
      </tbody>
    </Styled.StyledTable>
  );
};

export default Table;
