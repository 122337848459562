import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FashinzaIconLoader from 'library/IconLoader';
import StyleItem from './StyleItem';
import { useTypedSelector } from '../../utils/hooks';
import { setCurrentPage } from '../../actions/imageLinks';
import { IObjectType } from '../../utils/types';
import * as Styles from './styles';

interface IImageGridProps {
  uploadedImages: Array<IObjectType>;
  failedImages: Array<IObjectType>;
  previouslyUploadedImages: Array<IObjectType>;
  handleRetry(id: number | string): void;
  handleDelete(id: number | string): void;
}

const ImageGrid = ({
  uploadedImages,
  failedImages,
  previouslyUploadedImages,
  handleRetry,
  handleDelete,
}: IImageGridProps) => {
  const dispatch = useDispatch();
  const isFetching = useTypedSelector(store => store.imageLinks.isLoading);
  let bottomBoundaryRef = useRef<HTMLDivElement>(null);

  const scrollObserver = useCallback((node: HTMLDivElement) => {
    new IntersectionObserver(entries => {
      entries.forEach(en => {
        if (en.intersectionRatio > 0 || en.isIntersecting) {
          dispatch(setCurrentPage());
        }
      });
    }).observe(node);
  }, []);

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef]);

  return (
    <div>
      <Styles.ImageListWrapper>
        {failedImages.length > 0 &&
          failedImages.map((item, index) => (
            <StyleItem
              key={item.id}
              data={item}
              handleDelete={handleDelete}
              bgColorIndex={index}
            />
          ))}
        {uploadedImages.length > 0 &&
          uploadedImages.map((image, index) => (
            <StyleItem
              data={image}
              key={image.id}
              handleRetry={handleRetry}
              bgColorIndex={index}
            />
          ))}
        {previouslyUploadedImages.length > 0 &&
          previouslyUploadedImages.map(item => (
            <StyleItem data={item} key={item.id} />
          ))}
      </Styles.ImageListWrapper>
      {isFetching && (
        <Styles.SpinnerWrapper>
          <FashinzaIconLoader show />
        </Styles.SpinnerWrapper>
      )}
      <div className="intersection-observer-target" ref={bottomBoundaryRef} />
    </div>
  );
};

export default ImageGrid;
