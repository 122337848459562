import Toast from 'library/Toast';
import {
  ISentFeedbackData,
  IModifiedFeedback,
  ISentFeedbackInArray,
} from '../types';

export function modifiedFeedbackDataInRequriedForm(
  eachRuleId: string,
  data: Record<
    string,
    Pick<IModifiedFeedback, 'attributeName' | 'attributeOptions' | 'otherInput'>
  >
) {
  let newCollection: Array<ISentFeedbackInArray> = [];

  Object.keys(data).forEach(id => {
    newCollection.push({
      attr_name: data[id].attributeName,
      attr_value: data[id].attributeOptions,
      other_input: data[id].otherInput,
    });
  });

  const collection: ISentFeedbackData = {
    id: eachRuleId,
    feedback: newCollection,
  };
  return collection;
}

export function checkForUnfilledFeedback(
  data: Record<string, IModifiedFeedback>
) {
  let hasUnfilledData = true;

  Object.keys(data).forEach(key => {
    if (data[key].attributeOptions.length !== 0) {
      hasUnfilledData = false;
    }
    if (data[key].otherInput.length !== 0) {
      hasUnfilledData = false;
    }
  });
  if (hasUnfilledData) {
    Toast.INFO('Please fill atleast one Feedback Option');
  }
  return hasUnfilledData;
}
