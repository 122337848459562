import styled from "styled-components";

export const ImagePreviewWrapper = styled.div`
  height: 100%;
  width: 100%;

  img {
    border-radius: 6px;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
