import styled from 'styled-components';

export const ActiveApprovedContainer = styled.div`
  position: absolute;
  left: 0;
  top: 70px;
  height: 236px;
  width: 240px;
  z-index: 10;
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
  background-color: var(--white);
  padding: 16px 20px;
`;

export const ActiveContainerHeader = styled.div`
  margin-bottom: 16px;
`;

export const ApprovedContainerHeader = styled.div`
  margin-bottom: 16px;
`;

export const ContainerHeaderText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
`;

export const ApprovedContainerHeaderClearAll = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--watermelon);
  cursor: pointer;
`;

export const FiltersContainer = styled.div`
  label {
    justify-content: flex-start;
    margin-bottom: 16px;
  }
`;

export const MaterialFilterWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  padding: 8px;
`;

export const CountWrapper = styled.div`
  margin-left: 8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
  color: var(--white);
  background-color: var(--watermelon);
  font-size: 11px;
  font-weight: 600;
`;

export const FilterNameWrapper = styled.div`
  font-size: 14px;
  padding: 2px;
  p {
    color: var(--manatee);
  }
`;

export const FilterNameAndCountWrapper = styled.div`
  padding-left: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

export const InnerWrapper = styled.div<{ width: number }>`
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--mischka);
  background-color: var(--white);
  cursor: pointer;
  width: ${props => props.width}px;
`;
