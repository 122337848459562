import { DependencyList, useEffect, useRef } from 'react';

export function useTabTransition(deps?: DependencyList) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapperNode = wrapperRef.current;
    if (!wrapperNode) {
      return;
    }
    const activeTab = wrapperNode.querySelector(
      '[data-active="true"]'
    ) as HTMLElement;
    const movingLine = wrapperNode.querySelector(
      '[data-type="active-tab-line"]'
    ) as HTMLElement;
    if (!activeTab || !movingLine) {
      return;
    }
    const wrapperLeft = wrapperNode.getBoundingClientRect().left;
    const activeTabLeft = activeTab.getBoundingClientRect().left;
    const activeTabWidth = activeTab.getBoundingClientRect().width;
    movingLine.classList.remove('no-transition');
    movingLine.style.transform = `translateX(${activeTabLeft - wrapperLeft}px)`;
    movingLine.style.width = `${activeTabWidth}px`;
  }, deps);

  return {
    wrapperRef,
  };
}
