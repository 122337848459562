import React, { ChangeEvent } from 'react';
import { Tag } from 'react-tag-autocomplete';
import Dropdown from 'components/Dropdown';
import { IBrand } from 'utils/types';
import BrandTagComponent from './BrandTagComponent';
import * as Styles from './styles';

interface IBrandDropdownProps {
  label: string;
  brands: Array<IBrand>;
  selectedBrands: Array<Tag>;
  onChange(name: string): void;
  handleBrandTagDelete(tag: Tag): void;
}

const BrandDropdown = ({
  label,
  brands,
  selectedBrands,
  onChange,
  handleBrandTagDelete,
}: IBrandDropdownProps) => (
  <div>
    <Styles.BrandDropdownLabel>{label}</Styles.BrandDropdownLabel>
    <Styles.BrandsDropdown>
      <Dropdown
        options={brands}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value)
        }
        dataname="brands"
        hideAllOption
        dropdownClass="dropdown-class"
        dropdownBoxClass="dropdownBox-class"
        dropdownItemClass="dropdown-item-class"
        openClass="openClass"
        itemClass="itemClass"
        placeholder="Select brand"
        isSearchBox
      />
    </Styles.BrandsDropdown>
    <Styles.SelectedBrandsContainer className="d-flex flex-wrap">
      {selectedBrands.map(eachSelectedBrands => {
        return (
          <BrandTagComponent
            key={eachSelectedBrands.id}
            tag={{
              id: eachSelectedBrands.id,
              name: eachSelectedBrands.name,
            }}
            onDelete={handleBrandTagDelete}
          />
        );
      })}
    </Styles.SelectedBrandsContainer>
  </div>
);

export default BrandDropdown;
