import styled from 'styled-components';

export const TagContainer = styled.div`
  background-color: var(--athens-gray);
  padding: 4px 10px;
  border-radius: 12px;
  margin-right: 12px;
  margin-top: 6px;
  cursor: default;

  p {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
    color: var(--rhino);
  }
`;

export const SvgButton = styled.button`
  all: unset;

  svg {
    cursor: pointer;
    color: var(--tertiary-text);
    font-size: 16px;
  }
`;
