import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_CONDITION = {
  id: uuidv4(),
  operator: '',
  attributeName: '',
  attributeOptions: [],
};

export const DEFAULT_SORTING = {
  id: uuidv4(),
  attributeName: '',
  attributeOptions: [],
  key: '',
};

export enum ColorSchemeEnum {
  RED = 'red',
  BLUE = 'blue',
}

export const MAX_MOODBOARD_IMAGES = 3;
