import { ChangeEvent, useState } from 'react';
import Button from 'library/Button';
import Checkbox from 'library/checkbox/checkbox';
import { ToggleFilterEventType } from 'components/AddCatalogList/types';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import * as Styles from './styles';

interface IAddLocationProps {
  onClose(): void;
  applyLocationChange(param: Array<number>): void;
}

const LocationRegion = ({
  applyLocationChange,
  onClose,
}: IAddLocationProps) => {
  const locationData = useTypedSelector(
    store => store.catalog.allLocationRegionData
  );
  const [selectedOptions, setSetlectedOptions] = useState<Array<number>>([1]);

  function handleOptionsCheck(
    event: ChangeEvent<HTMLInputElement>,
    value: number
  ) {
    const updatedSelectedOptions = insertOrRemove(selectedOptions, value);
    setSetlectedOptions(updatedSelectedOptions);
    event.stopPropagation();
  }

  function handleApplyFilter() {
    applyLocationChange(selectedOptions);
  }

  return (
    <Styles.MultiDropDownWrapper
      className="pos-a"
      onClick={(event: ToggleFilterEventType) => {
        event.stopPropagation();
      }}
    >
      <div className="fit-height">
        {locationData.map(item => (
          <Styles.DropDownOption key={item.id}>
            <Checkbox
              item={{
                value: item.id,
              }}
              label={item.name}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleOptionsCheck(event, item.id)
              }
              checked={selectedOptions.includes(item.id)}
            />
          </Styles.DropDownOption>
        ))}
      </div>
      <Styles.BottomTabButtons className="spread">
        <Button size="medium" onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleApplyFilter} size="medium">
          Apply
        </Button>
      </Styles.BottomTabButtons>
    </Styles.MultiDropDownWrapper>
  );
};

export default LocationRegion;
