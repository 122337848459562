export const CATALOG_MANAGER_TABS = [
  {
    title: 'Category',
    tabType: 'category',
  },
  {
    title: 'Shareable Link',
    tabType: 'ruleEngineContainer',
  },
  {
    title: 'Collection',
    tabType: 'collections',
  },
  {
    title: 'Design Library',
    tabType: 'designLibrary',
  },
  {
    title: 'Trends',
    tabType: 'trends',
  },
];

export const CATEGORY_MANAGER_SUPPLIER_TABS = [
  {
    title: 'Category',
    tabType: 'category',
  },
];
