import styled from "styled-components";

export const ProductModifyArea = styled.div`
  margin-left: 1rem;
  margin-top: 5px;
`;

export const ProductTagsArea = styled.textarea`
  border: 0;
  outline: none;
  width: 100%;
  resize: none;
  height: 100%;
`;

export const Tag = styled.div`
  background: var(--athens-gray);
  border-radius: 20px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  margin: 5px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  opacity: 0.5;
`;

export const Footer = styled.div`
  margin: 0.5rem 0 0 1rem;
`;

export const TextContainer = styled.div`
  margin: 0.5rem 0;
`;
