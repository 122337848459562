import { Row } from 'react-table';
import { COLOR_SET } from '../../components/ImageLinks/helperConstant';
import { DEFAULT_VALUE } from '../defaults';
import { SizeRatioJson } from '../types';

export function convertSizeJsonToString(
  sizeRatioJson: SizeRatioJson,
  separator = ' | '
): string {
  try {
    const newCategories = sizeRatioJson.categories.map(eachCategory => {
      let newCategory = eachCategory.name;
      if (eachCategory.quantity && eachCategory.sku_id) {
        newCategory += `: ${eachCategory.quantity}, ${eachCategory.sku_id}`;
      } else if (eachCategory.quantity) {
        newCategory += `: ${eachCategory.quantity}`;
      } else if (eachCategory.sku_id) {
        newCategory += `: ${eachCategory.sku_id}`;
      }
      return newCategory;
    });
    return newCategories.join(separator);
  } catch (error) {
    return DEFAULT_VALUE;
  }
}

export const openInNewTab = (url: string) => {
  const win = window.open(url, '_blank');
  win?.focus();
};

export const getRandomColorForImageBg = (): string => {
  return COLOR_SET[Math.floor(Math.random() * COLOR_SET.length)];
};

export const convertToDate = (timestamp: string) => {
  if (!timestamp) return DEFAULT_VALUE;

  const convertedDate = new Date(timestamp);
  const formattedDate = convertedDate
    .toDateString()
    .split(' ')
    .splice(1)
    .join(' ');

  return formattedDate;
};

export const covertToTimeFormat = (timestamp: string) => {
  if (!timestamp) return DEFAULT_VALUE;

  const convertedDate = new Date(timestamp);
  const date = convertedDate.getDate();
  const month = convertedDate.getMonth();
  const year = convertedDate.getFullYear();

  return `${date}/${month + 1}/${year}`;
};

export const replaceUnderscoreWithSpace = (text: string) =>
  text.replaceAll('_', ' ');

export const toTitleCase = (data: string) => {
  const arr = data.split('_');
  const result = [];

  for (const word of arr) {
    result.push(word.charAt(0).toUpperCase() + word.slice(1));
  }

  return result.join(' ');
};
