import React from "react";
import { BsUpload } from "react-icons/bs";
import StyledButton, { StyledButtonVariant } from "../../StyledButton";
import { moveToCatalogUploadsRoute } from "../../../services/crossOriginMsg";
import * as Styles from "./styles";

interface IGenerateImageLinkProps {
  className?: string;
}

const GenerateImageLink = ({ className = "" }: IGenerateImageLinkProps) => {
  return (
    <Styles.Wrapper className={`${className} center`}>
      <Styles.Content>
        <Styles.SectionHeader>Generate image link</Styles.SectionHeader>
        <StyledButton
          variant={StyledButtonVariant.PacificBlueOutlined}
          className="upload-image"
          onClick={moveToCatalogUploadsRoute}
        >
          <BsUpload fill="var(--pacific-blue)" className="bs-upload" />
          &nbsp; Upload Images
        </StyledButton>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default GenerateImageLink;
