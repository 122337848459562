import { useLocation } from "react-router-dom";
import Checkbox from "library/checkbox/checkbox";
import { getQueryParams } from "components/AddCatalogList/helpers";
import { FilterSlugType } from "components/AddCatalogList/types";
import * as Styles from "./styles";

interface IActiveApprovedFilterViewProps {
  options: Record<string, Array<any>>;
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const ActiveApprovedFilterView = ({
  options,
  onFilterChange,
}: IActiveApprovedFilterViewProps) => {
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);

  function handleCheckboxCheck(
    option: Extract<FilterSlugType, "is_approved" | "is_active">,
    optionId: string
  ) {
    let newFilterArr = [optionId];
    if (
      currentQueryParams[option] &&
      currentQueryParams[option][0] === optionId
    ) {
      newFilterArr = [];
    }
    onFilterChange({ [option]: newFilterArr });
  }

  function handleClearOption() {
    onFilterChange({ is_active: [], is_approved: [] });
  }

  return (
    <Styles.ActiveApprovedContainer>
      <div className="v-d-flex">
        <Styles.ApprovedContainerHeader className="spread">
          <Styles.ContainerHeaderText>Approved</Styles.ContainerHeaderText>
          <Styles.ApprovedContainerHeaderClearAll onClick={handleClearOption}>
            Clear
          </Styles.ApprovedContainerHeaderClearAll>
        </Styles.ApprovedContainerHeader>
        <Styles.FiltersContainer className="v-d-flex">
          {options.approvalOption.map((eachOption) => (
            <Checkbox
              key={eachOption.name}
              item={{
                value: eachOption.name,
              }}
              label={eachOption.name}
              onChange={() => handleCheckboxCheck("is_approved", eachOption.id)}
              checked={
                currentQueryParams?.is_approved &&
                currentQueryParams?.is_approved[0] === eachOption.id
              }
            />
          ))}
        </Styles.FiltersContainer>
      </div>
      <div className="v-d-flex">
        <Styles.ActiveContainerHeader>
          <Styles.ContainerHeaderText>Active</Styles.ContainerHeaderText>
        </Styles.ActiveContainerHeader>
        <Styles.FiltersContainer className="v-d-flex">
          {options.activityOption.map((eachOption) => (
            <Checkbox
              key={eachOption.name}
              item={{
                value: eachOption.name,
              }}
              label={eachOption.name}
              onChange={() => handleCheckboxCheck("is_active", eachOption.id)}
              checked={
                currentQueryParams?.is_active &&
                currentQueryParams?.is_active[0] === eachOption.id
              }
            />
          ))}
        </Styles.FiltersContainer>
      </div>
    </Styles.ActiveApprovedContainer>
  );
};

export default ActiveApprovedFilterView;
