import { MdOutlineClose } from 'react-icons/md';
import { Tag } from 'react-tag-autocomplete';
import * as Styles from './styles';

interface IDropdownTagsProps {
  tag: Tag;
  onDelete(tag: Tag): void;
}

const DropdownTags = ({ tag, onDelete }: IDropdownTagsProps) => {
  return (
    <Styles.TagContainer className="align-center fit-width">
      <p>{tag.name}</p>
      <Styles.SvgButton onClick={() => onDelete(tag)}>
        <MdOutlineClose />
      </Styles.SvgButton>
    </Styles.TagContainer>
  );
};
export default DropdownTags;
