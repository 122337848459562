import { ReactNode } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/scripts/pdf.worker.js';

interface IPdfWrapperSetupProps {
  children: ReactNode;
}

const PdfWrapperSetup = ({ children }: IPdfWrapperSetupProps) => (
  <>{children}</>
);

export default PdfWrapperSetup;
