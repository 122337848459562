import { ObjectType } from 'customTypes/defined';

const Active = (props: ObjectType) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 14 11"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.19 5.653-.004-.004a1.562 1.562 0 1 0-1.68-1.558 1.563 1.563 0 0 0 1.561 1.562h.124Zm.25-1.934a.526.526 0 0 1 0 .745.527.527 0 1 1-.745-.745.527.527 0 0 1 .745 0Z"
      strokeWidth={0.1}
    />
    <path
      d="M1.467 1.168C1.136 1.5.95 1.948.95 2.416v6.168a1.764 1.764 0 0 0 1.764 1.764h8.572a1.764 1.764 0 0 0 1.764-1.764V2.416A1.764 1.764 0 0 0 11.286.65H2.714c-.468 0-.916.186-1.247.517Zm1.246.519h8.573a.73.73 0 0 1 .73.73v4.677L9.618 5.242l-.032-.025-.03.027-2.307 1.95-3.037-2.427-.032-.026-.032.028-2.17 1.888V2.416a.729.729 0 0 1 .73-.73h.004Zm9.302 6.716v.182a.73.73 0 0 1-.73.728h-.053v.002H2.714a.73.73 0 0 1-.729-.73V8.03l2.224-1.94 3.02 2.42.032.026.031-.026 2.324-1.961 2.399 1.855Z"
      strokeWidth={0.1}
    />
  </svg>
);

export default Active;
