import { PptDataType } from 'components/ShareCatalogList/types';

export const DOWNLOAD_PPT_FILTERS: Array<{
  id: keyof PptDataType;
  name: string;
}> = [
  {
    id: 'show_fabric',
    name: 'Show Fabric',
  },
  {
    id: 'show_fit',
    name: 'Show Fit',
  },
  {
    id: 'show_wash_type',
    name: 'Show Wash Type',
  },
  {
    id: 'show_fabric_type',
    name: 'Show Fabric Type',
  },
  {
    id: 'show_occasion',
    name: 'Show Occasion',
  },
  {
    id: 'show_gsm',
    name: 'Show Gsm',
  },
  {
    id: 'show_oz',
    name: 'Show Oz',
  },
];

export const DOWNLOAD_PPT_TYPE = [
  {
    id: 1,
    name: 'Old - 1 FNID per Slide',
  },
  {
    id: 2,
    name: '1 FNID per Slide',
  },
  {
    id: 3,
    name: '2 FNIDs per Slide',
  },
];

export const CLUSTER_TYPE: Record<string, string> = Object.freeze({
  fabric: 'Fabric',
  digital_print: 'Digital Print',
});
