import { ChangeEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextInput from 'components/textInput/textInput';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import Checkbox from 'library/checkbox/checkbox';
import { insertOrRemove } from 'utils/utility/utils';
import { useSeasonsData } from '../helper';
import { IGetEachOption, FilterTypes } from '../type';
import * as Styles from './styles';

interface ISeasonFilterProps {
  onClose(): void;
  onFilterChange(param: Partial<Record<FilterTypes, Array<string>>>): void;
}

const SeasonFilter = ({ onClose, onFilterChange }: ISeasonFilterProps) => {
  const seasonsData = useSeasonsData();
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [createdBySearch, setCreatedBySearch] = useState('');
  const [list, setCreatedByList] = useState<Array<IGetEachOption>>(seasonsData);
  const [selectedCheckbox, setSelectedCheckbox] = useState<Array<string>>(
    currentQueryParams?.season__in ?? []
  );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    setCreatedBySearch(value);
    setCreatedByList(matchQuery(value, seasonsData));
  }

  function matchQuery(value: string, options: Array<any>) {
    const newCategories: Array<IGetEachOption> = [];
    options.forEach(eachOption => {
      if (eachOption.name.toLowerCase().startsWith(value.toLowerCase())) {
        newCategories.push(eachOption);
      }
    });

    return newCategories;
  }

  function handleCategoryCheck(value: string) {
    const updatedSelectedOptions = insertOrRemove(selectedCheckbox, value);
    setSelectedCheckbox(updatedSelectedOptions);
  }

  function handleApplyFilter() {
    onFilterChange({ season__in: selectedCheckbox });
  }

  function handleClearOption() {
    onFilterChange({ season__in: [] });
  }

  return (
    <Styles.SeasonFilterContainer className="pos-a full-width">
      <Styles.SeasonHeader className="spread">
        <Styles.SeasonHeaderText>Season</Styles.SeasonHeaderText>
        <Styles.SeasonHeaderClearAll
          className="cursor-pointer"
          onClick={handleClearOption}
        >
          Clear
        </Styles.SeasonHeaderClearAll>
      </Styles.SeasonHeader>
      <Styles.SeasonContainer>
        <TextInput
          placeholder="Search Name"
          onChange={handleSearchChange}
          onClear={() => null}
          value={createdBySearch}
          listItemSearchBoxClass="list-item-search-box-class"
          showCrossButton={false}
        />
      </Styles.SeasonContainer>
      <Styles.SeasonFilterResults className="has-y-scroll">
        {list?.map(item => (
          <Styles.FilterOption key={item.id}>
            <Checkbox
              item={{
                value: item.id,
              }}
              label={item.name}
              onChange={() => handleCategoryCheck(item.id)}
              checked={selectedCheckbox.includes(item.id)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.SeasonFilterResults>
      <Styles.BottomTabButtons className="spread">
        <Styles.ClearButton onClick={onClose}>Cancel</Styles.ClearButton>
        <Styles.ApplyButton
          className="cursor-pointer"
          onClick={handleApplyFilter}
        >
          Apply
        </Styles.ApplyButton>
      </Styles.BottomTabButtons>
    </Styles.SeasonFilterContainer>
  );
};

export default SeasonFilter;
