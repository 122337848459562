import { IconPropsType } from 'customTypes/icons';

interface IAddIconWithOutlineProps extends IconPropsType {}

const AddIconWithOutline = ({
  stroke = 'var(--rhino)',
  strokeWidth = 1.25,
  size = 24,
  ...props
}: IAddIconWithOutlineProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddIconWithOutline;
