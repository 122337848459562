import styled from 'styled-components';
import { BiLoaderAlt } from 'react-icons/bi';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

export const Header = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid var(--athens-gray);
  height: 64px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--rhino);
`;

export const CloseWrapper = styled.div`
  background-color: var(--input-disabled);
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
`;

export const Example = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--watermelon);
  margin-right: 26px;
`;

export const EmailerHeading = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--rhino);
`;

export const EmailDataWrapper = styled.div`
  min-height: calc(100vh - 132px);
  gap: 24px;
  flex-basis: 33%;
  padding: 24px;
  margin-bottom: 80px;

  .TextInputWithLabel-wrapper {
    width: 440px !important;
  }
`;

export const Actions = styled.div`
  position: fixed;
  bottom: 0;
  border-top: 1px solid var(--athens-gray);
  background-color: var(--white);
`;

export const SaveButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  padding: 9px 24px;
  font-weight: 600;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  width: 15%;
  margin: 12px 44px 12px 12px;
`;

export const Spinner = styled(BiLoaderAlt)`
  animation: rotate360 1s linear infinite;
  margin-right: 0;
  width: inherit;
`;
