import styled from 'styled-components';
import StyledButton from 'components/StyledButton';

export const TutorialDropdown = styled.div`
  opacity: 0;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(1, 1, 1, 0.2);
`;

export const TutorialContainer = styled.div`
  width: 120px;

  &:hover {
    ${TutorialDropdown} {
      opacity: 1;
    }
  }
`;

export const TutorialButton = styled(StyledButton)`
  font-size: 14px;
  height: 40px;
`;

export const Link = styled.a`
  padding: 16px 20px;
  text-decoration: none;
  color: var(--rhino);
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: var(--input-disabled);
    color: var(--watermelon);
  }
`;
