import styled, { keyframes } from 'styled-components';
import StyledButton from 'components/StyledButton';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const FabricClusterHeader = styled.div`
  padding: 24px 32px;
  height: 64px;
  border-bottom: 1px solid var(--athens-gray);
`;

export const FabricClusterTitle = styled.p`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: var(--rhino);
`;

export const FabricClusterTable = styled.div`
  padding: 24px 36px;
  height: 72%;
`;

export const TableTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--rhino);
  margin-bottom: 16px;
`;

export const ButtonFooter = styled.div`
  bottom: 0;
  right: 0;
  padding: 16px 24px;
  border-top: 1px solid var(--athens-gray);
  background-color: var(--white);
`;

export const SubmitButton = styled(StyledButton)`
  width: 100px;
  height: 40px;
  font-size: 14px;
`;

export const TabSwitcher = styled.div`
  padding: 24px 36px 12px 36px;
`;

export const EachTab = styled.div<{ isActive: boolean }>`
  border: 1px solid var(--loblolly);
  padding: 8px 16px;
  background-color: ${props =>
    props.isActive ? 'var(--input-disabled)' : 'var(--white)'};
  border-radius: 8px;

  &:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const ClusterText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--rhino);
`;
