import styled from 'styled-components';

export const RowData = styled.div`
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  color: var(--black-primary);
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
`;

export const RowDataUrl = styled.div`
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--metallic-seaweed);

  &:hover {
    color: var(--watermelon);
  }
`;

export const TitleWrapper = styled.div`
  font-size: 14px;
  color: black;
`;

export const TitleText = styled.div`
  color: var(--metallic-seaweed);
  font-size: 14px;
  word-wrap: break-word;

  &:hover {
    color: var(--watermelon);
  }
`;

export const Wrapper = styled.div`
  padding: 15px 30px 100px;
  background-color: var(--athens-gray);
  height: max(100vh, 100%);
  overflow: scroll;
`;

export const Image = styled.img`
  flex: 2;
  border-radius: 4px;
  margin-right: 12px;
  height: 80px;
  max-width: 60px;
  display: block;
  object-fit: cover;
`;

export const CopyToClipboardIcon = styled.div`
  margin-left: 20px;
  cursor: pointer;
  svg {
    fill: var(--tertiary-text);
  }
`;
