import * as Styles from './styles';

const SpinnerLoader = () => (
  <Styles.Wrapper className="v-center">
    <Styles.Spinner />
    <Styles.Text>Uploading...</Styles.Text>
  </Styles.Wrapper>
);

export default SpinnerLoader;
