import CREDENTIAL_ACTION_TYPES from "../actionTypes/credentials";
import { getCredentialsApi } from "../utils/api";
import { IReduxActionType } from "../utils/types";

export interface ObjectType {
  [key: string]: any;
}

const setCredentials = (payload: any): IReduxActionType => ({
  type: CREDENTIAL_ACTION_TYPES.SET_CREDENTIAL,
  payload,
});

export const fetchAWSCredentialsThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getCredentialsApi();
      const catalogueExcel = response.data;

      const credentialData = {
        catalogExcel: {
          AWS_ACCESS_KEY_ID: catalogueExcel.AWS_ACCESS_KEY_ID,
          AWS_SECRET_ACCESS_KEY: catalogueExcel.AWS_SECRET_ACCESS_KEY,
          AWS_REGION: catalogueExcel.AWS_REGION,
          S3_BUCKET: catalogueExcel.S3_BUCKET,
        },
      };
      dispatch(setCredentials(credentialData));
    } catch (err) {
      console.log(err);
    }
  };
