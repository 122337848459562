import styled from 'styled-components';

export const CategoryFilterContainer = styled.div`
  position: absolute;
  height: 366px;
  width: 240px;
  z-index: 11;
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
  left: 0;
  top: 70px;
  background-color: var(--white);
  padding: 20px 16px;
`;

export const CategoryFilterHeader = styled.div`
  margin-bottom: 8px;
`;

export const CategoryFilterHeaderText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
`;

export const CategoryFilterHeaderClearAll = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--watermelon);
  cursor: pointer;
`;

export const CategorySearchContainer = styled.div`
  margin-bottom: 16px;
  input {
    border: none;
  }
  input:focus {
    outline-width: 0;
  }
`;

export const FilterOption = styled.div`
  margin: 0 20px 10px 0;
  width: 146px;
  label {
    justify-content: flex-start;
    p {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const CategoryfilterResults = styled.div`
  height: 68%;
  overflow: scroll;
  overflow-x: hidden;
`;

export const BottomTabButtons = styled.div`
  height: 46px;
  z-index: 11;
  button {
    min-width: 96px;
    height: 30px;
  }
  button:first-child {
    background-color: var(--white);
    color: var(--watermelon);
  }
`;
