import { useMemo } from 'react';
import DayPicker from 'react-day-picker';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import { customFormatDate } from 'utils/utility/utils';
import ICONOGRAPHY from 'CustomConstant/icons';
import { ObjectType } from 'customTypes/defined';
import * as Styles from './styles';

const { calendar: CalendarIcon } = ICONOGRAPHY;

interface IDateInputProps {
  isOpen: boolean;
  setIsCalendarOpen: ObjectType;
  handleDayClick(day: Date): void;
  disabledAfter: Date;
  disabledBefore?: Date;
  isDisabled?: boolean;
  dateValue?: Date | string | null;
}

const DateInput = ({
  isOpen,
  setIsCalendarOpen,
  handleDayClick,
  dateValue,
  isDisabled,
  disabledAfter,
  disabledBefore = new Date(0),
}: IDateInputProps) => {
  const disabledDaysAfter = useMemo(() => {
    const date = new Date(disabledAfter);
    const disabledYear = date.getFullYear();
    const disabledDate = date.getDate();
    const disabledMonth = date.getMonth();

    return new Date(disabledYear, disabledMonth, disabledDate);
  }, [disabledAfter]);

  const disabledDaysBefore = useMemo(() => {
    const date = new Date(disabledBefore);
    const disabledYear = date.getFullYear();
    const disabledDate = date.getDate();
    const disabledMonth = date.getMonth();

    return new Date(disabledYear, disabledMonth, disabledDate);
  }, [disabledBefore]);

  let selectedDays: Array<Date> = [];
  if (dateValue) {
    selectedDays = [new Date(dateValue)];
  }

  return (
    <Styles.Wrapper className="v-d-flex" isDisabled={isDisabled}>
      <Styles.CalenderDataContainer
        className="cursor-pointer pos-r align-center"
        role="presentation"
        onClick={event => {
          setIsCalendarOpen.on();
          event.stopPropagation();
        }}
      >
        <CalendarIcon />
        <Styles.DateText>
          {dateValue ? customFormatDate(new Date(dateValue)) : 'Select Date'}
        </Styles.DateText>
        {isOpen && (
          <ClickOutside
            onClose={() => setIsCalendarOpen.off()}
            disableClickHandler={false}
          >
            <DayPicker
              className="day-picker-absolute"
              onDayClick={(day, modifiers, event) => {
                if (modifiers.disabled) {
                  return;
                }
                handleDayClick(day);
                event.stopPropagation();
              }}
              disabledDays={[
                { before: disabledDaysBefore, after: disabledDaysAfter },
              ]}
              month={new Date()}
              selectedDays={selectedDays}
            />
          </ClickOutside>
        )}
      </Styles.CalenderDataContainer>
    </Styles.Wrapper>
  );
};

export default DateInput;
