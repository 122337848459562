import styled, { keyframes } from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 6vh;
  width: 30vw;
  height: 85vh;
  min-width: 400px;
  min-height: 500px;
  padding: 20px;
  z-index: 11;
  background-color: var(--white);
  border-radius: 4px;
  pointer-events: all;
  box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.3);
`;

export const ShowItemCount = styled.div`
  color: var(--rhino);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  left: 32px;
  top: 80px;
  z-index: 2;
  border-radius: 4px;
  background: var(--white);
  padding: 8px;
`;

export const CheckboxContainer = styled.div`
  color: var(--rhino);
  font-size: 12px;
  font-weight: 600;
  left: 90px;
  top: 80px;
  z-index: 2;
  background: var(--white);
  padding: 8px 16px;
  gap: 8px;
  border-radius: 4px;
`;

export const AllPhotosWrapper = styled.div`
  height: 10%;
  margin: 16px 0 8px 0;
`;

export const ImageWrapper = styled.div`
  top: 0;
  position: fixed;
  z-index: 10;
`;

export const TitleText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: var(--rhino);
  line-height: 24px;
`;

export const AddPhotoIcon = styled.div`
  width: 48px;
  height: 48px;
  background: var(--white);
  border: 2px solid var(--mischka);
  border-radius: 6px;
  font-size: 26px;
`;

export const HeaderWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: var(--rhino);
  line-height: 28px;
  margin-bottom: 24px;
`;

export const Body = styled.div`
  height: 74%;
  font-size: 12px;

  img {
    border-radius: 4px;
  }
`;

export const ImageAddWrapper = styled.div`
  padding: 0px;
  gap: 8px;
  background: rgba(230, 252, 255, 0.4);
  border: 1px dashed var(--pacific-blue);
  border-radius: 4px;
  svg {
    margin-bottom: 6px;
  }
  p {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const DeleteIcon = styled.div`
  width: 32px;
  height: 32px;
  right: 32px;
  top: 80px;
  z-index: 2;
  border-radius: 4px;
  background: var(--input-disabled);
`;

export const EachImageWrapper = styled.div`
  height: 90%;
  img {
    height: 48px;
    width: 48px;
    border-radius: 4px;
  }
`;

export const EachDeleteIcon = styled.div`
  top: -8px;
  right: 8px;
  background-color: var(--white);
  border-radius: 24px;
  z-index: 2;
`;

export const Spinner = styled.div`
  height: 28px;
  width: 28px;
  font-size: 4px;
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  border: 4px solid var(--white);
  border-left-color: var(--rhino);
  border-radius: 50%;
`;

export const SaveImageButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  width: 160px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;
