import styled from 'styled-components';

export const TabWrapper = styled.div`
  ul {
    color: var(--rhino);
  }
`;

export const TabHeader = styled.div`
  position: sticky;
  top: 56px;
  left: 0;
`;

export const TabTitleWrapper = styled.div`
  border-radius: 0;
`;

export const TabTitleText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 24px;
  color: var(--rhino);
  text-align: center;
  text-decoration: none;

  &[data-is-active='true'] {
    color: var(--watermelon);
  }
`;

export const TabName = styled.div`
  color: var(--rhino);
`;

export const ActiveTabLine = styled.span`
  bottom: 0;
  height: 2px;
  background-color: var(--watermelon);
  transform: translateX(0);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;
