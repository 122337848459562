import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Toast from 'library/Toast';
import Checkbox from 'library/checkbox/checkbox';
import FileUpload from 'components/FileUpload';
import { ACCEPTED_SIZE } from 'components/ImageLinks/helperConstant';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { updateImageThunk } from 'actions/catalog';
import { uploadFile } from 'services/cloudinary';
import { modifyImageURl } from 'utils/utility/utils';
import ICONOGRAPHY from 'CustomConstant/icons';
import { IImage } from 'utils/types';
import {
  eachProductImageData,
  getImageMetaData,
  handleErrorForDefaultImageData,
  showErrorMessage,
} from './helper';
import { DEFAULT_EMPTY_VIEW } from '../constants';
import * as Styles from './styles';

const {
  add: AddIcon,
  'add-with-outline': AddIconWithOutline,
  close: CloseIcon,
  trash: TrashIcon,
} = ICONOGRAPHY;

interface IImageUpdationProps {
  rowId: number;
  existingImage: Array<IImage>;
  onClose(): void;
}

const ImageUpdation = ({
  rowId,
  existingImage,
  onClose,
}: IImageUpdationProps) => {
  const isSaveImageButtonLoading = useTypedSelector(
    store => store.catalog.isSaveImageButtonLoading
  );
  const dispatch = useDispatch();
  const [currentImages, setCurrentImages] = useState(existingImage);
  const [currentCount, setCurrentIndex] = useState(1);
  const [loaderStatus, setLoaderStatus] = useBoolean();
  const initialDefaultImage = currentImages.find(
    (image: IImage) => image.view === 'default'
  );
  let initialDefaultImageInForm = initialDefaultImage
    ? initialDefaultImage
    : DEFAULT_EMPTY_VIEW;
  const [selectedId, setSelectedId] = useState(initialDefaultImageInForm.id);
  const [defaultImage, setDefaultImage] = useState<IImage>(
    initialDefaultImageInForm
  );
  const [displayImage, setDisplayImage] = useState<IImage>(
    initialDefaultImageInForm
  );
  let totalCount = currentImages.length;

  useEffect(() => {
    const defaultImageInArray = currentImages.find(
      (image: IImage) => image.view === 'default'
    );
    let currentImage = defaultImageInArray
      ? defaultImageInArray
      : DEFAULT_EMPTY_VIEW;
    setDefaultImage(currentImage);
  }, [currentImages]);

  useEffect(() => {
    let currentIndex = -1;
    const imageToDisplay = currentImages.find(
      (image: IImage) => image.id === selectedId
    );

    currentImages.find((image: IImage, index: number) => {
      if (image.id === selectedId) {
        currentIndex = index;
      }
    });

    let imageToDisplayInForm = imageToDisplay
      ? imageToDisplay
      : DEFAULT_EMPTY_VIEW;
    setDisplayImage(imageToDisplayInForm);
    setCurrentIndex(currentIndex + 1);
  }, [currentImages, selectedId]);

  function handleEachDelete(id: string) {
    let currentIndex = -1;

    const updatedImageArray = currentImages.filter((item, index) => {
      if (item.id === id) {
        currentIndex = index;
        return false;
      }

      return true;
    });

    setCurrentImages(updatedImageArray);
    if (currentIndex < updatedImageArray.length) {
      setSelectedId(updatedImageArray[currentIndex].id);
    } else {
      setSelectedId(updatedImageArray[0].id);
    }
  }

  function handleCheckboxChange(id: string) {
    const UpdatedViewValue = currentImages.map(item => {
      let upadatedView = 'other';
      if (item.id === id)
        upadatedView = item.view === 'default' ? 'other' : 'default';

      return {
        url: item.url,
        id: item.id,
        view: upadatedView,
      };
    });
    setCurrentImages(UpdatedViewValue);
  }

  async function handleUploadChange(event: ChangeEvent<HTMLInputElement>) {
    setLoaderStatus.on();
    const { files } = event.target;
    if (files === null || files.length === 0) {
      return;
    }

    const imageToUpload = files[0];
    const { width, height } = await getImageMetaData(imageToUpload);
    if (
      imageToUpload.size / 1000000 <= ACCEPTED_SIZE.MinimumImageSize &&
      ((width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio1 &&
        width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio1) ||
        (width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio2 &&
          width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio2)) &&
      height >= ACCEPTED_SIZE.MinimumImageHeight &&
      height <= ACCEPTED_SIZE.MaximumImageHeight
    ) {
      setLoaderStatus.on();
      const res = await uploadFile(imageToUpload);

      const uploadedImageId = uuidv4();
      const updatedImageArray = [
        ...currentImages,
        { id: uploadedImageId, url: res?.link, view: 'other' },
      ];

      setCurrentImages(updatedImageArray);
      setSelectedId(uploadedImageId);
    } else {
      showErrorMessage(imageToUpload, width, height);
    }

    event.target.value = '';
    setLoaderStatus.off();
  }

  function SubmitHandler() {
    if (handleErrorForDefaultImageData(currentImages)) {
      Toast.WARN('Please make atleast one image as primary');
      return;
    }
    const data = eachProductImageData(rowId, existingImage, currentImages);
    dispatch(updateImageThunk(data, onClose));
  }

  return (
    <Styles.ImageWrapper className="full-view-dimensions center pos-a">
      <Styles.Wrapper>
        <Styles.HeaderWrapper className="space-between">
          <Styles.TitleText className="text-align-center">
            Upload Images
          </Styles.TitleText>
          <div className="cursor-pointer center" onClick={onClose}>
            <CloseIcon size={24} />
          </div>
        </Styles.HeaderWrapper>
        <Styles.Body>
          {currentImages.length === 0 ? (
            <FileUpload onChange={handleUploadChange}>
              <Styles.ImageAddWrapper className="v-center full-dimension cursor-pointer">
                <AddIconWithOutline stroke={'var(--tertiary-text)'} />
                Upload Images
              </Styles.ImageAddWrapper>
            </FileUpload>
          ) : (
            <div className="full-dimension">
              <img className="full-dimension" src={displayImage.url} alt="" />
              <Styles.ShowItemCount className="center cursor-pointer pos-a">
                <p>{`${currentCount} of ${totalCount}`}</p>
              </Styles.ShowItemCount>
              <Styles.CheckboxContainer className="pos-a align-center">
                <Checkbox
                  className="cursor-pointer"
                  item={{ value: currentImages }}
                  checked={defaultImage.id === selectedId}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleCheckboxChange(displayImage.id)
                  }
                  label={
                    defaultImage.id === selectedId
                      ? 'Primary image'
                      : 'Set as Primary'
                  }
                />
              </Styles.CheckboxContainer>
              <Styles.DeleteIcon
                className="center cursor-pointer pos-a"
                onClick={() => handleEachDelete(displayImage.id)}
              >
                <TrashIcon size={18} />
              </Styles.DeleteIcon>
            </div>
          )}
        </Styles.Body>
        <Styles.AllPhotosWrapper className="d-flex full-width">
          {currentImages.map(item => (
            <Styles.EachImageWrapper key={item.id} className="flex-start">
              <img
                className="full-width full-height cursor-pointer"
                src={modifyImageURl(item.url)}
                alt="eachitem"
                onClick={() => setSelectedId(item.id)}
              />
              <Styles.EachDeleteIcon
                className="center cursor-pointer pos-r"
                onClick={() => handleEachDelete(item.id)}
              >
                <TrashIcon size={18} />
              </Styles.EachDeleteIcon>
            </Styles.EachImageWrapper>
          ))}

          {currentImages.length < 6 && (
            <FileUpload onChange={handleUploadChange}>
              {loaderStatus === true ? (
                <Styles.Spinner />
              ) : (
                <Styles.AddPhotoIcon className="center cursor-pointer">
                  <AddIcon strokeWidth={1.75} />
                </Styles.AddPhotoIcon>
              )}
            </FileUpload>
          )}
        </Styles.AllPhotosWrapper>
        <div className="flex-end">
          <Styles.SaveImageButton
            className="center cursor-pointer full-width"
            onClick={SubmitHandler}
          >
            {isSaveImageButtonLoading ? (
              <Styles.Spinner className="pos-r" />
            ) : (
              <>Save Image</>
            )}
          </Styles.SaveImageButton>
        </div>
      </Styles.Wrapper>
    </Styles.ImageWrapper>
  );
};

export default ImageUpdation;
