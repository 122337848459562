import styled from "styled-components";

export const FabricFilterWrapper = styled.div`
  position: absolute;
  top: calc(100% + -8px);
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  background-color: var(--white);
  box-shadow: 0 16px 40px 0 rgba(9, 12, 11, 0.1);
  border: 1px solid var(--very-light-pink-two);
  border-radius: 4px;
  max-width: 745px;
  &:focus {
    outline: none;
  }

  & ::-webkit-scrollbar {
    height: 4px;
  }
`;

export const FabricFilterBody = styled.div`
  > div:nth-child(n) {
    padding: 0 16px;
  }
`;

export const DropboxHeaderWrapper = styled.div`
  padding: 16px 0;
  margin: 20px 10px;
`;

export const ContentWrapper = styled.div`
  height: 300px;
  overflow-x: auto;
  border-bottom: 1px solid var(--very-light-gray);
  padding: 7px 0;

  > div {
    white-space: nowrap;
    overflow: hidden;
    margin: 5px 0;
    padding: 0 12px;
    > label {
      width: auto;
    }
  }
`;

export const DropboxBottomCountContainer = styled.div`
  font-size: 12px;
  color: var(--rhino);
`;

export const DropboxBottomButtonContainer = styled.div`
  height: 56px;
  button:nth-child(1) {
    margin-right: 20px;
  }
`;

export const SearchBoxContainer = styled.div`
  margin-left: 16px;

  .list-item-search-box-class {
    height: 32px;

    input {
      height: 100%;
      font-size: 12px;
      color: var(--rhino);
      border: none;
      &::placeholder {
        font-size: 12px;
        letter-spacing: normal;
        color: var(--brown-grey);
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export const ClearButtonWrapper = styled.div<{ isDisabled: boolean }>`
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  color: ${(props) =>
    props.isDisabled ? "var(--very-light-pink)" : "var(--watermelon)"};
`;

export const FilterHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: var(--rhino);
`;
