const KebabIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={4}
    height={14}
    viewBox="0 0 4 14"
  >
    <path
      fill="#090C0B"
      d="M1.68 3.36c.453 0 .847-.167 1.18-.5.333-.333.5-.727.5-1.18 0-.453-.167-.847-.5-1.18C2.527.167 2.133 0 1.68 0 1.227 0 .833.167.5.5.167.833 0 1.227 0 1.68c0 .453.167.847.5 1.18.333.333.727.5 1.18.5zm0 5c.453 0 .847-.167 1.18-.5.333-.333.5-.727.5-1.18 0-.453-.167-.847-.5-1.18-.333-.333-.727-.5-1.18-.5-.453 0-.847.167-1.18.5-.333.333-.5.727-.5 1.18 0 .453.167.847.5 1.18.333.333.727.5 1.18.5zm0 5c.453 0 .847-.167 1.18-.5.333-.333.5-.727.5-1.18 0-.453-.167-.847-.5-1.18-.333-.333-.727-.5-1.18-.5-.453 0-.847.167-1.18.5-.333.333-.5.727-.5 1.18 0 .453.167.847.5 1.18.333.333.727.5 1.18.5z"
    />
  </svg>
);

export default KebabIcon;
