import { useDispatch } from 'react-redux';
import TextAreaInput from 'components/TextAreaInput';
import { useTypedSelector } from 'utils/hooks';
import { setStagingFeedbackData } from 'actions/ruleEngines';
import { IModifiedFeedback } from 'components/RuleEngineCollection/types';
import * as Styles from '../styles';

const DESCRIPTION_LIMIT = 100;

interface TextInputTypeFeedbackProps {
  eachFeedbackData: IModifiedFeedback;
  viewMode: boolean;
}

const TextInputTypeFeedback = ({
  eachFeedbackData,
  viewMode = false,
}: TextInputTypeFeedbackProps) => {
  const eachFeedbackDataInStaging = useTypedSelector(
    store =>
      store.ruleEngine.stagingfeedbackData[eachFeedbackData.attributeName]
  );
  const dispatch = useDispatch();

  function handleDescriptionInput(value: string) {
    if (value.length <= 100) {
      dispatch(setStagingFeedbackData(eachFeedbackData.attributeName, [value]));
    }
  }

  return (
    <Styles.StringInputWrapper>
      <TextAreaInput
        inputHeight={80}
        label={eachFeedbackData.attributeName}
        placeholder="Type Text"
        onChange={handleDescriptionInput}
        characterCount={DESCRIPTION_LIMIT}
        value={eachFeedbackDataInStaging.attributeOptions[0]}
        isDisabled={viewMode}
      />
    </Styles.StringInputWrapper>
  );
};

export default TextInputTypeFeedback;
