import {
  setCatalogId,
  setSelectedSecondaryFilterInfo,
  setTabId,
} from 'actions/catalog';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'utils/hooks';
import { setQueryStringValueToUrl } from 'utils/routing';
import { CATEGORY_NAVIGATION } from './constants';
import * as Styles from './styles';

const CategorySelector = () => {
  const tabId = useTypedSelector(state => state.catalog.tabId);
  const dispatch = useDispatch();
  const linkedSupplierId = useTypedSelector(
    store => store.user.linked_supplier
  )?.id;

  function handleTabSelect(tabId: string, catalogId?: string) {
    if (catalogId) {
      setQueryStringValueToUrl({});
      dispatch(setSelectedSecondaryFilterInfo('', []));
      dispatch(setCatalogId(catalogId));
    }
    dispatch(setTabId(tabId));
  }

  return (
    <Styles.Wrapper>
      {CATEGORY_NAVIGATION.map(eachCategory => (
        <Styles.EachButton
          key={eachCategory.tabId}
          isActive={eachCategory.tabId === tabId}
          className="cursor-pointer"
          onClick={() => {
            eachCategory.tabId !== tabId &&
              handleTabSelect(eachCategory.tabId, eachCategory.catalogId);
          }}
        >
          {eachCategory.tabName}
        </Styles.EachButton>
      ))}
    </Styles.Wrapper>
  );
};

export default CategorySelector;
