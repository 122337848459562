import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  sendCollectionFeedbackThunk,
  setDefaultStagingFeedback,
  updateCollectionFeedbackThunk,
} from 'actions/ruleEngines';
import ICONOGRAPHY from 'CustomConstant/icons';
import IntegerInput from './EachInputTypeFeedback/IntegerInputTypeFeedback';
import TextInput from './EachInputTypeFeedback/TextInputTypeFeedback';
import StringInput from './EachInputTypeFeedback/StringInputTypeFeedback';
import MultiSelect from './EachInputTypeFeedback/MultiSelectTypeFeedback';
import SingleSelect from './EachInputTypeFeedback/SingleSelectTypeFeedback';
import { useSetInitialViewFeedback } from './helperHooks';
import { checkForUnfilledFeedback } from './helper';
import * as Styles from './styles';

const { edit: EditIcon, close: CloseIcon } = ICONOGRAPHY;

interface IFeedbackProps {
  eachRuleId: string;
  feedbackExist: boolean;
  linkIdentifier: string;
  onClose(): void;
}

const SideMenuFeedback = ({
  eachRuleId,
  feedbackExist,
  onClose,
  linkIdentifier,
}: IFeedbackProps) => {
  const feedbackData = useTypedSelector(store => store.ruleEngine.feedbackData);
  const stagingfeedbackData = useTypedSelector(
    store => store.ruleEngine.stagingfeedbackData
  );
  const isSaveRuleButtonLoading = useTypedSelector(
    state => state.ruleEngine.isSaveRuleButtonLoading
  );
  const initialViewFeedback = useSetInitialViewFeedback();
  const [viewMode, viewModeAction] = useBoolean();
  const dispatch = useDispatch();

  useEffect(() => {
    if (feedbackExist) {
      viewModeAction.on();
      dispatch(setDefaultStagingFeedback(initialViewFeedback));
    }
  }, []);

  function submitHandler() {
    if (checkForUnfilledFeedback(stagingfeedbackData)) {
      return;
    }
    if (feedbackExist) {
      dispatch(updateCollectionFeedbackThunk(eachRuleId, stagingfeedbackData));
    } else {
      dispatch(
        sendCollectionFeedbackThunk(
          linkIdentifier,
          eachRuleId,
          stagingfeedbackData
        )
      );
    }
    viewModeAction.on();
  }

  return (
    <>
      <Styles.CatalogFeedbackHeader>
        <div className="flex-start-center">
          <Styles.CatalogFeedbackTitle>
            Catalog feedback
          </Styles.CatalogFeedbackTitle>
          {feedbackExist && viewMode && (
            <Styles.EditIcon
              className="cursor-pointer center"
              onClick={viewModeAction.off}
            >
              Edit
              <EditIcon size={18} />
            </Styles.EditIcon>
          )}
        </div>
        <Styles.CloseIcon className="pos-a cursor-pointer" onClick={onClose}>
          <CloseIcon />
        </Styles.CloseIcon>
        <p>Form to capture feedback on Designs from brands</p>
      </Styles.CatalogFeedbackHeader>
      <Styles.Body className="has-y-scroll">
        {Object.keys(feedbackData).map(item => {
          switch (feedbackData[item].dataType) {
            case 'integer':
              return (
                <IntegerInput
                  viewMode={viewMode && feedbackExist}
                  eachFeedbackData={feedbackData[item]}
                />
              );
            case 'text':
              return (
                <TextInput
                  viewMode={viewMode && feedbackExist}
                  eachFeedbackData={feedbackData[item]}
                />
              );
            case 'multi_select':
              return (
                <MultiSelect
                  viewMode={viewMode && feedbackExist}
                  eachFeedbackData={feedbackData[item]}
                />
              );
            case 'single_select':
              return (
                <SingleSelect
                  viewMode={viewMode && feedbackExist}
                  eachFeedbackData={feedbackData[item]}
                />
              );
            default:
              return (
                <StringInput
                  viewMode={viewMode && feedbackExist}
                  eachFeedbackData={feedbackData[item]}
                />
              );
          }
        })}
        {!viewMode ? (
          <div className="flex-end">
            <Styles.SaveImageButton
              className="center cursor-pointer full-width"
              onClick={submitHandler}
            >
              {isSaveRuleButtonLoading ? (
                <Styles.Spinner className="pos-r" />
              ) : (
                'Submit'
              )}
            </Styles.SaveImageButton>
          </div>
        ) : (
          ''
        )}
      </Styles.Body>
    </>
  );
};

export default SideMenuFeedback;
