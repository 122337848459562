export interface ITabType {
  tabName: string;
  tabId: string;
  catalogId?: string;
}

export const CATEGORY_NAVIGATION: Array<ITabType> = [
  { tabName: 'Clothing', tabId: 'clothing', catalogId: 'CAT001' },
  { tabName: 'Fabrics', tabId: 'fabrics', catalogId: 'CAT052' },
  { tabName: 'Graphics', tabId: 'graphics', catalogId: 'CAT089' },
  { tabName: 'Accessories', tabId: 'accessories', catalogId: 'CAT079' },
  { tabName: 'Footwear', tabId: 'footwear', catalogId: 'CAT094' },
];
