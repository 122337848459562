import React, { ReactNode } from 'react';
import * as Styles from './styles';

interface ITabTitleProps {
  tabTitleProps?: Record<string, unknown>;
  title: ReactNode;
  isSelected: boolean;
  className?: string;
}

const TabTitle = ({
  tabTitleProps = {},
  title,
  isSelected,
  className = '',
}: ITabTitleProps) => (
  <>
    <Styles.TabTitleWrapper
      className={`${isSelected ? 'active' : ''}  ${className}`}
      tabIndex={-1}
      data-active={isSelected}
      aria-selected={isSelected}
    >
      <Styles.TabTitleText data-is-active={isSelected} {...tabTitleProps}>
        {title}
      </Styles.TabTitleText>
      <Styles.ActiveTabLine data-type="active-tab-line" className="pos-a" />
    </Styles.TabTitleWrapper>
  </>
);

export default TabTitle;
