import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px 168px 40px;

  &.outer {
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    width: fit-content;
    margin: 40px auto;
  }

  .add-file-2 {
    padding: 0 20px;
    background-color: var(--watermelon) !important;
    .text {
      color: var(--white) !important;
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }

  &.progress-bar {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 8px;
  }
`;

export const ContentWrapper = styled.div`
  flex-direction: column;
  margin-top: 40px;
  .catalogue-upload-image {
    padding: 14px 20px;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: white;
    background-color: var(--watermelon);
    border-radius: 4px;
    cursor: pointer;
  }

  .label-disabled {
    font-weight: 500;
    background-color: var(--input-disabled);
    color: var(--manatee);
    border: 1px solid var(--athens-gray);
    cursor: not-allowed;
  }
`;

export const SecondaryText = styled.p`
  color: var(--tertiary-text);
  font-size: 14px;
  line-height: 17px;
  margin: 8px auto;
`;

export const Title = styled.span`
  color: var(--rhino);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;
