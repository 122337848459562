import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  align-items: flex-end;
  height: 100vh;
  pointer-events: none;
`;

export const List = styled.div<{ width: string; minWidth: string }>`
  position: relative;
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  width: ${props => props.width};
  min-width: ${props => props.minWidth};
  margin: 0;
  padding: 0;
  pointer-events: initial;
  background: var(--white);
`;
