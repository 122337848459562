import { Column } from 'react-table';
import { MdContentCopy } from 'react-icons/md';
import { convertToDate } from 'utils/helpers';
import { copyToClipboard } from 'utils/utility';
import FeedbackForm from './FeedBack';
import { CLUSTER_TYPE } from './constants';
import ToggleDropdownControl from '../ShareCatalogList/Table/ToggleDropdownControl';
import * as Styles from './styles';

type ColumnType = Column & {
  disableSortBy?: true | false;
};

export function getFields(): Array<ColumnType> {
  return [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row, value }) => {
        const data = row.original as { url: string };
        return (
          <Styles.TitleUrlWrapper
            className="align-center cursor-pointer"
            as="a"
            href={`${data.url}?default_view=Grid`}
            target="_blank"
          >
            {value}
          </Styles.TitleUrlWrapper>
        );
      },
    },
    {
      Header: 'Created By',
      accessor: 'addedBy',
      Cell: ({ value }) => (
        <Styles.TitleWrapper className="align-center">
          {value}
        </Styles.TitleWrapper>
      ),
    },
    {
      Header: 'Created Date',
      accessor: 'createdOn',
      Cell: ({ value }) => (
        <Styles.TitleWrapper className="align-center">
          {convertToDate(value)}
        </Styles.TitleWrapper>
      ),
    },
    {
      Header: 'CM',
      accessor: 'categoryManager',
      Cell: ({ value }) => (
        <Styles.TitleWrapper className="align-center">
          {value}
        </Styles.TitleWrapper>
      ),
    },
    {
      Header: 'Sales Rep',
      accessor: 'salesRepresentative',
      Cell: ({ value }) => (
        <Styles.TitleWrapper className="align-center">
          {value}
        </Styles.TitleWrapper>
      ),
    },
    {
      Header: 'Approached Brand',
      accessor: 'approachedBrand',
      Cell: ({ value }) => (
        <Styles.TitleWrapper className="align-center">
          {value}
        </Styles.TitleWrapper>
      ),
    },
    {
      Header: 'Link',
      accessor: 'url',
      Cell: ({ value, row }) => {
        const data = row.original as { linkIdentifier: string };
        return (
          <div className="flex-start-center">
            <Styles.TitleUrlWrapper
              as="a"
              href={`${value}?default_view=Grid`}
              target="_blank"
              className="align-center cursor-pointer"
            >
              {data.linkIdentifier}
            </Styles.TitleUrlWrapper>
            <MdContentCopy
              className="cursor-pointer"
              size="20px"
              fill="var(--tertiary-text)"
              onClick={() => copyToClipboard(value)}
            />
          </div>
        );
      },
    },
    {
      Header: 'Cluster',
      accessor: 'isCluster',
      Cell: ({ value, row }) => {
        const data = row.original as { clusterType: string };
        return (
          <Styles.TitleWrapper className="align-center">
            {value ? `Yes / ${CLUSTER_TYPE[data.clusterType]}` : 'No'}
          </Styles.TitleWrapper>
        );
      },
    },
    {
      Header: 'Feedback',
      accessor: 'feedbackExist',
      Cell: ({ row, value }) => {
        const data = row.original as { linkIdentifier: string; ruleId: string };
        return (
          <FeedbackForm
            linkIdentifier={data.linkIdentifier}
            eachRuleId={data.ruleId}
            feedbackExist={value}
          />
        );
      },
    },
    {
      Header: '',
      Cell: ({ value }) => <ToggleDropdownControl data={value} />,
      accessor: 'dots',
      disableSortBy: true,
    },
  ];
}
