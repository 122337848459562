import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 30px 0;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 40px;
  margin-bottom: 200px;

  .download-excel-template {
    width: auto;
    flex: 1 1 auto;
  }
  .generate-image-link {
    width: auto;
    flex: 1 1 auto;
  }
`;
