import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'utils/hooks';
import Dropdown from 'components/Dropdown';
import {
  addNewAttribute,
  removeEachAttribute,
  setCondition,
  setAttributeOption,
} from 'actions/ruleEngines';
import ICONOGRAPHY from 'CustomConstant/icons';
import AttributeOption from './attributeOption';
import { useConditonsProperties } from '../helperHooks';
import { getOperatorOptions } from '../helpers';
import * as Styles from './styles';

const { close: CloseIcon } = ICONOGRAPHY;

const ConditonData = () => {
  const stagingData = useTypedSelector(store => store.ruleEngine.ruleStaging);
  const conditionsData = useTypedSelector(
    store => store.ruleEngine.conditionsData
  );

  const dispatch = useDispatch();
  const attributeNameOptions = useConditonsProperties();

  function handleShowPropertyChange(
    event: ChangeEvent<HTMLInputElement>,
    identifier: string
  ) {
    const value = event.target.value;
    dispatch(setCondition(identifier, value, 'attributeName'));
    dispatch(setCondition(identifier, '', 'operator'));
    dispatch(setAttributeOption(identifier, []));
  }

  function handleFunctionChange(
    event: ChangeEvent<HTMLInputElement>,
    identifier: string
  ) {
    const value = event.target.value;
    dispatch(setCondition(identifier, value, 'operator'));
  }

  function addNewRow() {
    dispatch(addNewAttribute());
  }

  function handleCloseEachAttribute(identifier: string) {
    if (Object.keys(stagingData.condition).length === 1) {
      dispatch(setCondition(identifier, '', 'attributeName'));
      dispatch(setCondition(identifier, '', 'operator'));
      dispatch(setAttributeOption(identifier, []));
    } else {
      dispatch(removeEachAttribute(identifier));
    }
  }

  return (
    <Styles.Wrapper className="full-height">
      <Styles.Heading className="full-width spread">
        <Styles.SerialNoTitle>S. No.</Styles.SerialNoTitle>
        <Styles.ConditionTitle>Show Condition *</Styles.ConditionTitle>
        <Styles.FunctionTitle>Function *</Styles.FunctionTitle>
        <Styles.AttributeTitle>Attribute *</Styles.AttributeTitle>
      </Styles.Heading>
      <Styles.Body>
        {Object.keys(stagingData.condition).map((id, index) => (
          <div className="d-flex" key={id}>
            <Styles.SerialNumber className="flex-start-center">
              {index + 1}
            </Styles.SerialNumber>

            <Styles.ConditionContainer>
              <Dropdown
                options={attributeNameOptions}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleShowPropertyChange(event, id)
                }
                value={stagingData.condition[id].attributeName}
                dataname="condition"
                hideAllOption
                dropdownClass="dropdown-class"
                dropdownBoxClass="dropdownBox-class"
                dropdownItemClass="dropdown-item-class"
                openClass="openClass"
                itemClass="itemClass"
                placeholder="Select condition"
                isSearchBox
              />
            </Styles.ConditionContainer>
            <Styles.FunctionContainer>
              <Dropdown
                options={getOperatorOptions(
                  conditionsData,
                  stagingData.condition[id].attributeName
                )}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFunctionChange(event, id)
                }
                value={stagingData.condition[id].operator}
                dataname="function"
                hideAllOption
                dropdownClass="dropdown-class"
                dropdownBoxClass="dropdownBox-class"
                dropdownItemClass="dropdown-item-class"
                openClass="openClass"
                itemClass="itemClass"
                placeholder="Select"
                disable_edit={stagingData.condition[id].attributeName === ''}
              />
            </Styles.FunctionContainer>

            <AttributeOption identifier={id} />

            <Styles.CloseConditionIcon
              className="cursor-pointer center"
              onClick={() => handleCloseEachAttribute(id)}
            >
              <CloseIcon size={24} stroke={'var(--metallic-seaweed)'} />
            </Styles.CloseConditionIcon>
          </div>
        ))}
        <Styles.ConditionButton className="center pos-r" onClick={addNewRow}>
          + Add new condition
        </Styles.ConditionButton>
      </Styles.Body>
    </Styles.Wrapper>
  );
};

export default ConditonData;
