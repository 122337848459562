import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';
import { useTypedSelector } from 'utils/hooks';
import Checkbox from 'library/checkbox/checkbox';
import TextInput from 'components/textInput/textInput';
import { ToggleFilterEventType } from 'components/AddCatalogList/types';
import { insertOrRemove } from 'utils/utility/utils';
import { setAttributeOption } from 'actions/ruleEngines';
import { getAttributeOptions } from '../helpers';
import * as Styles from './styles';

interface IMultiDropdowmProps {
  identifier: string;
}

const MultiDropDownAttribute = ({ identifier }: IMultiDropdowmProps) => {
  const data = useTypedSelector(
    store => store.ruleEngine.ruleStaging.condition[identifier]
  );
  const conditionsData = useTypedSelector(
    store => store.ruleEngine.conditionsData
  );

  const dispatch = useDispatch();

  const selectedValues = data.attributeOptions;
  const [attributeOptionSearch, setAttributeOptionSearch] = useState('');
  const dropdownOptions = getAttributeOptions(
    conditionsData,
    data.attributeName
  );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setAttributeOptionSearch(event.target.value);
  }

  function matchQuery(value: string) {
    const newAttributeOptionslist: Array<Tag> = [];
    dropdownOptions.forEach((eachOption: Tag) => {
      if (String(eachOption).toLowerCase().startsWith(value.toLowerCase())) {
        newAttributeOptionslist.push(eachOption);
      }
    });
    return newAttributeOptionslist;
  }

  function handleOptionsCheck(
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    const updatedSelectedOptions = insertOrRemove(data.attributeOptions, value);
    dispatch(setAttributeOption(identifier, updatedSelectedOptions));
    event.stopPropagation();
  }

  const filteredDropdownOptions = matchQuery(attributeOptionSearch);

  return (
    <Styles.DropDownOptionsContainer
      className="pos-a"
      onClick={(event: ToggleFilterEventType) => {
        event.stopPropagation();
      }}
    >
      <Styles.DropDownSearchContainer>
        <TextInput
          placeholder="Search Name"
          dataname=""
          onChange={handleSearchChange}
          onClear={() => {}}
          value={attributeOptionSearch}
          listItemSearchBoxClass="list-item-search-box-class"
          showCrossButton={false}
        />
      </Styles.DropDownSearchContainer>
      <Styles.DropDownfilterResults className="fit-height has-scroll no-x-scroll">
        {filteredDropdownOptions.map((eachAttribute: Tag) => (
          <li key={String(eachAttribute)} className="full-width">
            <Checkbox
              className="flex-start"
              item={{
                value: eachAttribute,
              }}
              label={String(eachAttribute)}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleOptionsCheck(event, String(eachAttribute))
              }
              checked={selectedValues.includes(eachAttribute)}
            />
          </li>
        ))}
      </Styles.DropDownfilterResults>
    </Styles.DropDownOptionsContainer>
  );
};

export default MultiDropDownAttribute;
