import { IObjectType } from 'utils/types';

const ExcelUploadSuccess = (props: IObjectType) => (
  <svg
    width={113}
    height={110}
    viewBox="0 0 113 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={63.022} cy={44.279} rx={44.5} ry={44.279} fill="#FFFAE6" />
    <rect
      x={0.63}
      y={0.687}
      width={46.119}
      height={64.269}
      rx={4.613}
      transform="matrix(.99928 .04341 -.04383 .99882 56.875 34.513)"
      fill="#E6FCFF"
      stroke="#B3F5FF"
      strokeWidth={1.318}
    />
    <rect
      x={-0.74}
      y={-0.568}
      width={48.761}
      height={66.896}
      rx={5.931}
      transform="rotate(-7.463 259.541 -220.65) skewX(.074)"
      fill="#fff"
      stroke="#00A3BF"
      strokeWidth={1.318}
    />
    <path
      d="M31.482 37.17a5.264 5.264 0 0 1 4.536-5.911l36.587-4.793a5.28 5.28 0 0 1 5.919 4.541l1.287 9.726-47.042 6.163-1.287-9.726Z"
      fill="#B3F5FF"
    />
    <rect
      width={1.967}
      height={6.589}
      rx={0.984}
      transform="rotate(82.463 28.2 64.114) skewX(-.074)"
      fill="#FFC400"
    />
    <rect
      width={1.977}
      height={6.558}
      rx={0.988}
      transform="rotate(-7.463 164.445 -528.256) skewX(.074)"
      fill="#FFC400"
    />
    <rect
      width={1.972}
      height={6.574}
      rx={0.986}
      transform="matrix(.79533 .60724 -.61028 .79138 81.59 18.674)"
      fill="#FFC400"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 409.012 -269.9) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 509.956 -276.609) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 610.886 -283.325) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 449.396 -272.587) skewX(.074)"
      fill="#B3F5FF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 550.325 -279.305) skewX(.074)"
      fill="#B3F5FF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 651.254 -286.007) skewX(.074)"
      fill="#B3F5FF"
    />
    <path
      d="M25.5 54c13.807 0 25-11.193 25-25S39.307 4 25.5 4 .5 15.193.5 29s11.193 25 25 25Z"
      fill="#36B37E"
    />
    <path
      d="m46.438 15.367-22.11 22.891.04 2.46h1.015L49.33 21.423c-.704-2.149-1.68-4.219-2.891-6.055Z"
      fill="#37A18E"
    />
    <path
      d="m50.032 14.274-4.415-4.376c-.585-.586-1.562-.586-2.187 0L24.563 29.391l-8.165-8.047c-.585-.586-1.562-.586-2.187 0l-3.906 3.867a1.533 1.533 0 0 0 0 2.149l13.047 12.93c.351.35.82.468 1.289.429.469.039.937-.078 1.289-.43l24.102-23.828c.586-.625.586-1.602 0-2.187Z"
      fill="#F2F1EF"
    />
    <path
      d="m25.93 40.29 24.102-23.83a1.533 1.533 0 0 0 0-2.148l-.704-.665-24.726 24.297-13.672-13.32-.586.586a1.533 1.533 0 0 0 0 2.149L23.39 40.29c.351.35.82.468 1.289.429.43.039.898-.078 1.25-.43Z"
      fill="#E6E5E3"
    />
  </svg>
);

export default ExcelUploadSuccess;
