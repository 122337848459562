import { IconPropsType } from 'customTypes/icons';

interface IFunnelProps extends IconPropsType {}

const Funnel = ({ fill = 'var(--rhino)', ...props }: IFunnelProps) => (
  <svg
    width={14}
    height={12}
    viewBox="0 0 14 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.546448 0.289561C0.628377 0.112979 0.805343 0 1.00001 0H13C13.1947 0 13.3716 0.112979 13.4536 0.289561C13.5355 0.466143 13.5075 0.674223 13.3818 0.822862L8.7 6.35907V11.3C8.7 11.4733 8.61027 11.6342 8.46286 11.7253C8.31546 11.8164 8.13139 11.8247 7.97639 11.7472L5.5764 10.5472C5.407 10.4625 5.3 10.2894 5.3 10.1V6.35907L0.618222 0.822862C0.492523 0.674223 0.464519 0.466143 0.546448 0.289561ZM2.07766 0.999999L6.18179 5.85313C6.25812 5.94339 6.3 6.05778 6.3 6.17599V9.79097L7.7 10.491V6.17599C7.7 6.05778 7.74188 5.94339 7.81821 5.85313L11.9223 0.999999H2.07766Z"
      fill={fill}
    />
  </svg>
);

export default Funnel;
