export enum ApiResponseEnum {
  Success,
  Failure,
}

interface INetworkSuccess<T> {
  type: ApiResponseEnum.Success;
  data: T;
  msg: string;
}

interface INetworkFailure {
  type: ApiResponseEnum.Failure;
  error: string;
  source: 'response' | 'network';
}

export type APIResponseType<T> =
  | {
      msg: string;
      status: true;
      data: T;
    }
  | {
      status: false;
      msg: string;
    };

export interface IListRequest {
  page: number;
  qlQuery?: string;
}

export interface IListResponse<T> {
  results: Array<T>;
  count: number;
}

export type ServerFunctionType<T> = (
  argument: IListRequest
) => Promise<INetworkSuccess<IListResponse<T>> | INetworkFailure>;

export type DetailServerFunctionType<T> = (
  itemId: number
) => Promise<INetworkSuccess<T> | INetworkFailure>;

export type UpdateDetailServerFunctionType<T> = (
  itemId: number,
  data: Partial<T>
) => Promise<INetworkSuccess<T> | INetworkFailure>;

export type NonPaginatedListServerFunctionType<T> = () => Promise<
  INetworkSuccess<Array<T>> | INetworkFailure
>;

export type ApiResponseHandlerType<T> = INetworkSuccess<T> | INetworkFailure;
