import styled from 'styled-components';

export const Wrapper = styled.div<{ isDisabled?: boolean }>`
  pointer-events: ${props => (props.isDisabled ? 'none' : '')};
  background-color: ${props =>
    props.isDisabled ? 'var(--input-disabled)' : ''};
`;

export const CalenderDataContainer = styled.div`
  color: var(--rhino);
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  padding: 8px 12px;

  .day-picker-absolute {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 10;
    background-color: var(--white);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

    &:focus {
      outline: none;
    }

    .DayPicker {
      &:focus,
      .DayPicker-wrapper:focus {
        outline: none;
      }

      .DayPicker-Day {
        padding: 0.4rem;
      }
    }
  }
`;

export const DateText = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-left: 8px;
`;
