import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

export const RowData = styled.div`
  font-size: 14px;
  color: var(--rhino);
  max-width: 300px;
  overflow: hidden;
  height: 70px;
  vertical-align: middle;
  text-overflow: ellipsis;
`;

export const TitleWrapper = styled.div`
  height: 70px;
  font-size: 14px;
  color: var(--rhino);
`;

export const TitleUrlWrapper = styled.div`
  font-size: 14px;
  color: var(--metallic-seaweed);

  &:hover {
    color: var(--watermelon);
  }
`;

export const AddRuleButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweed,
}))`
  font-size: 14px;
  line-height: 22px;
  top: 13px;
  right: 42px;
  padding: 8px;
`;

export const Wrapper = styled.div`
  height: calc(100% - 100px);
  padding: 15px 30px 100px;
  background-color: var(--athens-gray);
`;
