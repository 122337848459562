import * as Styled from './styles';

interface ILayoutProps {
  navigation: JSX.Element;
  children: JSX.Element;
}

const Layout = ({ navigation, children }: ILayoutProps) => {
  return (
    <div className="full-view-dimensions d-flex">
      {navigation}
      <Styled.Content className="full-width">
        <>{children}</>
      </Styled.Content>
    </div>
  );
};

export default Layout;
