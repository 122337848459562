import styled, { keyframes } from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Body = styled.div`
  font-size: 14px;
  padding: 0 40px 24px 32px;
  color: var(--rhino);
`;

export const TitleWrapper = styled.div<{ feedbackExist: boolean }>`
  color: ${props =>
    props.feedbackExist ? 'var(--metallic-seaweed)' : 'var(--watermelon)'};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const StringInputWrapper = styled.div`
  padding-top: 16px;

  .TextInputWithLabel-wrapper label {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: var(--rhino);
  }
`;

export const InputTitleLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--rhino);
  padding-bottom: 16px;
`;

export const MutliSeclectOptions = styled.div`
  margin-bottom: 16px;

  label {
    justify-content: flex-start;

    p {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;

      &:hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }
`;

export const MultiSelectWrapper = styled.div`
  padding-top: 16px;
`;

export const SingleSelectOptions = styled.div`
  margin-bottom: 16px;

  label {
    justify-content: flex-start;

    p {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;

      &:hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }
`;

export const SingleSelectWrapper = styled.div`
  padding-top: 16px;
`;

export const IntegerInputWrapper = styled.div`
  padding-top: 16px;

  .TextInputWithLabel-wrapper label {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: var(--rhino);
  }
`;

export const CatalogFeedbackHeader = styled.div`
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid var(--athens-gray);

  p {
    font-size: 12px;
    line-height: 30px;
    font-weight: 500px;
  }
`;

export const CloseIcon = styled.div`
  top: 24px;
  right: 40px;
`;

export const EditIcon = styled.div`
  gap: 4px;
`;

export const CatalogFeedbackTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 20px;
  color: var(--rhino);
`;

export const Spinner = styled.div`
  font-size: 4px;
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  height: 28px;
  width: 28px;
  border: 4px solid var(--white);
  border-radius: 50%;
  border-left-color: var(--rhino);
`;

export const SaveImageButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  width: 160px;
  height: 40px;
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;
