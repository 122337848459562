import TRENDS_TYPES from 'actionTypes/trends';
import { ITags } from 'components/Trends/types';
import { IReduxActionType } from 'utils/types';
import { update } from './helpers';

export interface ITrendsInitialState {
  tags: Array<ITags>;
  retailers: Array<string>;
  chartTags: Array<string>;
  isLoading: false;
  loaderStatus: boolean;
  selectedFilters: Record<string, Array<any>>;
  chartData: Array<Record<string, string | number>>;
  trendsDates: Array<string>;
}

const initialState: ITrendsInitialState = {
  tags: [],
  retailers: [],
  chartTags: [],
  isLoading: false,
  loaderStatus: false,
  selectedFilters: {},
  chartData: [],
  trendsDates: [],
};

function trendsReducer(
  state: ITrendsInitialState = initialState,
  action: IReduxActionType
) {
  switch (action.type) {
    case TRENDS_TYPES.SET_LOADER_STATUS: {
      return update(state, { isLoading: action.payload });
    }

    case TRENDS_TYPES.SET_TAGS_DATA: {
      return update(state, { tags: action.payload });
    }

    case TRENDS_TYPES.SET_RETAILER_DATA: {
      return update(state, { retailers: action.payload });
    }

    case TRENDS_TYPES.SET_CHART_TAGS_DATA: {
      return update(state, { chartTags: action.payload });
    }

    case TRENDS_TYPES.SET_SELECTED_FILTERS: {
      let newFilters = { ...state.selectedFilters };
      if (
        action.payload.shouldReset ||
        Object.keys(action.payload.selectedFilters).length === 0
      ) {
        newFilters = {};
      } else {
        newFilters = {
          ...newFilters,
          ...action.payload.selectedFilters,
        };
      }
      return {
        ...state,
        selectedFilters: newFilters,
      };
    }

    case TRENDS_TYPES.SET_CHARTS_DATA: {
      return update(state, { chartData: action.payload });
    }

    case TRENDS_TYPES.SET_TRENDS_DATES: {
      return update(state, { trendsDates: action.payload });
    }

    default:
      return state;
  }
}

export default trendsReducer;
