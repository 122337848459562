import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BiErrorCircle } from 'react-icons/bi';
import { useTypedSelector } from 'utils/hooks';
import { updateSharedCatalogDetailsThunk } from 'actions/catalog';
import Button from 'library/Button';
import { copyToClipboard } from 'utils/utility';
import ICONOGRAPHY from 'CustomConstant/icons';
import { IShareableData } from 'components/ShareCatalogList/types';
import { SHAREABLE_FIELDS_ARRAY } from './constants';
import * as Styled from './styles';

const { close: CloseIcon } = ICONOGRAPHY;

interface ShareableLinkProps {
  onClose(): void;
  link: string;
  imageLink: string;
  listCount: number;
  selectedCount: number;
  linkIdentifier: string;
  inactiveCounts: number;
}

const getShareableLinkModalContent = (
  activeDesigns: number,
  inactiveCounts: number,
  selectedCount: number
) => {
  if (activeDesigns === 0) {
    return (
      <Styled.ErrorText>
        {selectedCount > 1 && 'All the'}
        <Styled.BoldTextWrapper>{selectedCount}&nbsp;</Styled.BoldTextWrapper>
        design{selectedCount > 1 ? 's' : ''} you have selected for sharing
        {selectedCount > 1 ? ' are ' : ' is '}
        inactive or unapproved.
      </Styled.ErrorText>
    );
  }

  return activeDesigns > 0 && inactiveCounts === 0 ? (
    <Styled.ShareableProductCount>
      Sharing&nbsp;
      <Styled.BoldTextWrapper>{selectedCount}</Styled.BoldTextWrapper>
      &nbsp;design{selectedCount > 1 ? 's' : ''}
    </Styled.ShareableProductCount>
  ) : (
    <Styled.MainWrapper>
      <Styled.ShareableProductCount>
        Sharing&nbsp;
        <Styled.BoldTextWrapper>{activeDesigns}</Styled.BoldTextWrapper>
        &nbsp;design{activeDesigns > 1 ? 's' : ''} out of&nbsp;
        <Styled.BoldTextWrapper>{selectedCount}</Styled.BoldTextWrapper>
      </Styled.ShareableProductCount>
      <Styled.UnshareableProductCount>
        {inactiveCounts} design{inactiveCounts > 1 ? 's' : ''}
        &nbsp;you selected {inactiveCounts > 1 ? 'are' : 'is'}
        &nbsp;inactive or unapproved.
      </Styled.UnshareableProductCount>
    </Styled.MainWrapper>
  );
};

const ShareableLink = ({
  onClose,
  link,
  imageLink,
  linkIdentifier,
  listCount,
  selectedCount,
  inactiveCounts,
}: ShareableLinkProps) => {
  const selectedProductIds = useTypedSelector(
    state => state.catalog.selectedProductIds
  );
  const [data, setData] = useState<IShareableData>({
    title: '',
    description: '',
  });
  const [sharedCatalogUrl, setSharedCatalogUrl] = useState('');
  const dispatch = useDispatch();
  const activeDesigns = selectedCount - inactiveCounts;

  const handleSave = () => {
    dispatch(
      updateSharedCatalogDetailsThunk(
        { ...data, imageLink },
        Array.from(selectedProductIds),
        (sharedCatalogUrl: string) => {
          setSharedCatalogUrl(sharedCatalogUrl);
        }
      )
    );
  };

  function handleInputChange(key: string, inputValue: string) {
    setData({ ...data, [key]: inputValue });
  }

  return (
    <Styled.Wrapper className="center">
      <Styled.Container className="v-d-flex">
        <Styled.HeaderSection className="align-center">
          {activeDesigns > 0 ? (
            <Styled.HeaderText>Share Catalog</Styled.HeaderText>
          ) : (
            <Styled.ErrorHeaderWrapper className="align-center">
              <BiErrorCircle size="1.3em" />
              <Styled.ErrorHeaderText>
                Can't share designs
              </Styled.ErrorHeaderText>
            </Styled.ErrorHeaderWrapper>
          )}

          <Styled.CloseModalButton onClick={onClose}>
            <CloseIcon size="20px" />
          </Styled.CloseModalButton>
        </Styled.HeaderSection>
        <Styled.HeaderLine />
        {activeDesigns > 0 && (
          <>
            <Styled.Content>
              <Styled.ImageWrapper>
                <Styled.ImageContentWrapper className="d-flex">
                  <div>
                    <Styled.Image src={imageLink} alt="fashinza" />
                  </div>
                  <Styled.TextWrapper className="d-flex">
                    {getShareableLinkModalContent(
                      activeDesigns,
                      inactiveCounts,
                      selectedCount
                    )}
                    <Styled.MainWrapper>
                      <Styled.CustomWrapper className="d-flex">
                        {SHAREABLE_FIELDS_ARRAY.map(item => (
                          <Styled.BlockWrapper flex={1} key={item.key}>
                            <Styled.AlignWrapper>
                              <Styled.BoldTextWrapper>
                                {item.fieldName}
                              </Styled.BoldTextWrapper>
                            </Styled.AlignWrapper>
                            <Styled.DataContainer
                              type="text"
                              data-name={item.key}
                              value={data[item.key as keyof typeof data] || ''}
                              onChange={event =>
                                handleInputChange(item.key, event.target.value)
                              }
                            />
                          </Styled.BlockWrapper>
                        ))}
                      </Styled.CustomWrapper>
                      <Styled.LinkContainer className="align-center">
                        <Styled.Link>{sharedCatalogUrl}</Styled.Link>
                      </Styled.LinkContainer>
                    </Styled.MainWrapper>
                  </Styled.TextWrapper>
                </Styled.ImageContentWrapper>
              </Styled.ImageWrapper>
            </Styled.Content>
            <Styled.HeaderLine />
            <Styled.ButtonGroup className="end-align-center">
              {sharedCatalogUrl && (
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => copyToClipboard(sharedCatalogUrl)}
                >
                  Copy Link
                </Button>
              )}
              <Styled.AlignWrapper>
                <Button variant="primary" size="medium" onClick={handleSave}>
                  Save
                </Button>
              </Styled.AlignWrapper>
            </Styled.ButtonGroup>
          </>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default ShareableLink;
