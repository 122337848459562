import { getFileExt } from '../utils/utility';
import { getHashedFileName } from '../utils/utility/hash';

export const _upload_file = ({
  awscredentials,
  Bucket,
  Key,
  Body,
  ACL,
  ContentType,
}) => {
  window.AWS.config.update({
    accessKeyId: awscredentials.AWS_ACCESS_KEY_ID,
    secretAccessKey: awscredentials.AWS_SECRET_ACCESS_KEY,
    region: awscredentials.AWS_REGION,
  });

  var objectParams = {
    Bucket, // name of the bucket
    Key, // basically filename
    Body, // compressed file
    ACL: ACL || 'public-read', // public access or not
    ContentType, // file type
  };
  var uploadObj = new window.AWS.S3({ apiVersion: '2006-03-01' });

  return new Promise(async (resolve, reject) => {
    try {
      const res = await uploadObj.putObject(objectParams).promise();
      return resolve(`https://${Bucket}.s3.amazonaws.com/${Key}`);
    } catch (err) {
      console.log(err);
      return reject('Rejected in _upload_file');
    }
  });
};

export const uploadFileAws = ({ file, credentials, key = '' }) => {
  if (
    !credentials.AWS_ACCESS_KEY_ID ||
    !credentials.AWS_REGION ||
    !credentials.AWS_SECRET_ACCESS_KEY ||
    !credentials.S3_BUCKET
  ) {
    return;
  }

  return new Promise(async (resolve, reject) => {
    const { name, type } = file;
    try {
      const hashedKey = getHashedFileName(name, getFileExt(name));
      // upload compressed image
      const imagelink = await _upload_file({
        awscredentials: credentials,
        Bucket: credentials.S3_BUCKET,
        Key: key ? key : `public/${hashedKey}`,
        Body: file,
        ContentType: type,
      });
      return resolve({ url: imagelink, name: name, mimeType: type });
    } catch (err) {
      console.log('upload err: ', err);
      return reject({ err });
    }
  });
};
