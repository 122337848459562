import React from 'react';
import { isStrDecimal, isStrNumber } from 'utils/utility/utils';
import './textInputWithLabel.scss';

const TextInputWithLabel = ({
  className = '',
  datalabel,
  placeholder,
  dataname,
  value,
  max = 0,
  min = 0,
  onChange,
  isDisabled = false,
  errorClass = '',
  isNumber = false,
  isDecimal = false,
  warning_text = null,
  onKeyUp = event => {},
  inputProps = {},
  shouldAutoFocus = true,
  focusColor = 'watermelon',
}) => (
  <div className={`TextInputWithLabel-wrapper ${className}`}>
    <label>
      {datalabel}
      <input
        type="text"
        placeholder={placeholder}
        data-name={dataname}
        value={value}
        onChange={
          isDecimal
            ? e => isStrDecimal(e, onChange, max, min)
            : isNumber
            ? e => isStrNumber(e, onChange, max, min)
            : onChange
        }
        disabled={isDisabled}
        className={[errorClass, focusColor].join(' ')}
        onKeyUp={onKeyUp}
        autoFocus={shouldAutoFocus}
        {...inputProps}
      />
    </label>
    {warning_text && <div className="warning-text">{warning_text}</div>}
  </div>
);

TextInputWithLabel.defaultProps = {
  value: '',
  inputProps: {},
};

export default TextInputWithLabel;
