import { FILE_NAME_REGEX } from 'utils/constants';
import { sanitizeFileName } from 'utils/utility/utils';
import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET } from '../config';

const CLOUDINARY_UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`;

export const uploadFile = file => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
  formData.append(
    'public_id',
    `${sanitizeFileName(file.name, FILE_NAME_REGEX)}_${Date.now()}`
  );

  return fetch(CLOUDINARY_UPLOAD_URL, {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then(data => {
      if (data.secure_url !== '') {
        return {
          id: data.asset_id,
          name: data.original_filename,
          link: data.secure_url,
          ext: data.original_extension || data.format,
        };
      }
      return null;
    })
    .catch(err => {
      console.error({ err });
      return Promise.reject('Network Error');
    });
};
