import styled from 'styled-components';

export const MultiDropDownWrapper = styled.div`
  bottom: 52px;
  left: 110px;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  z-index: 1;

  &::after {
    content: '';
    height: 17px;
    position: absolute;
    width: 17px;
    transform: rotate(45deg);
    background-color: var(--white);
    left: 10px;
    bottom: -8px;
  }
`;

export const DropDownOption = styled.div`
  margin: 8px 0;
  background-color: var(--input-disabled);

  label {
    justify-content: flex-start;
    margin-left: 10px;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 34px;
      color: var(--rhino);

      :hover {
        background-color: unset;
        color: var(--watermelon);
      }
    }
  }
`;

export const BottomTabButtons = styled.div`
  height: 40px;

  button {
    min-width: 96px;
    height: 30px;
  }

  button:first-child {
    background-color: var(--white);
    color: var(--watermelon);
  }
`;
