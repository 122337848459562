import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { setSelectedCheckboxesForFabricClusters } from 'actions/ruleEngines';
import { covertToTimeFormat } from 'utils/helpers';
import { insertOrRemove } from 'utils/utility/utils';
import { DEFAULT_VALUE } from 'utils/defaults';
import { IEachFabricCluster } from '../types';
import * as Styles from './styles';

interface ITableProps {
  list: Array<IEachFabricCluster>;
  paginationHandler(): void;
  currentPage: number;
  loaderStatus: boolean;
}

const Table = ({
  list,
  paginationHandler,
  currentPage,
  loaderStatus,
}: ITableProps) => {
  const admins = useTypedSelector(state => state.catalog.admins);
  const selectedFabricClusterIds = useTypedSelector(
    state => state.ruleEngine.selectedFabricClusterIds
  );
  const dispatch = useDispatch();
  const observer = useRef<any>(null);

  function lastItemRef(node: HTMLTableRowElement) {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !loaderStatus) {
        paginationHandler();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }

  const getAdminName = useCallback(
    (id: number) => {
      const admin = admins.find(eachAdmin => eachAdmin.id === id);
      return admin ? `${admin?.first_name} ${admin?.last_name}` : DEFAULT_VALUE;
    },
    [admins]
  );

  function handleSelectSingleCluster(id: string) {
    dispatch(setSelectedCheckboxesForFabricClusters(id));
  }

  return (
    <Styles.Table className="full-width">
      <Styles.TableHead>
        <th></th>
        <th>Cluster Name</th>
        <th>Link</th>
        <th>Created By</th>
        <th>Date</th>
      </Styles.TableHead>
      <Styles.TableBody
        className="full-width"
        oddRowBackgroundColor={'var(--white)'}
        evenRowBackgroundColor={'var(--input-disabled)'}
      >
        {list.map((eachItem, index) => {
          return (
            <Styles.TableRow
              key={eachItem.link_identifier}
              ref={index === list.length - 1 ? lastItemRef : null}
            >
              <td>
                <Checkbox
                  onChange={() =>
                    handleSelectSingleCluster(eachItem.link_identifier)
                  }
                  checked={
                    eachItem.link_identifier === selectedFabricClusterIds
                  }
                />
              </td>
              <td>{eachItem.title}</td>
              <td className="truncated-text">{eachItem.link_identifier}</td>
              <td>{getAdminName(eachItem.added_by)}</td>
              <td>{covertToTimeFormat(eachItem.created_on)}</td>
            </Styles.TableRow>
          );
        })}
        {loaderStatus && currentPage > 1 && (
          <Styles.TableRow>
            <Styles.Spinner />
          </Styles.TableRow>
        )}
      </Styles.TableBody>
    </Styles.Table>
  );
};

export default Table;
