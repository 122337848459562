import aT from "../actionTypes/user";
import { IObjectType, IReduxActionType } from "../utils/types";
import { update } from "./helpers";

export interface IInitialState {
  token: string | null;
  name: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  user_id: number | null;
  is_admin: boolean;
  is_supplier: boolean;
  is_brand: boolean;
  brand_id: number | undefined;
  brand_name: string | null;
  show_otp: boolean;
  login_identifier: string;
  is_checking_auth: boolean;
  // supplier object if user is supplier
  linked_supplier: IObjectType;
  permissions: IObjectType;
  isLoading: boolean;
  username: string;
  team: Array<any>;
}

const initialState: IInitialState = {
  token: null,
  name: "",
  first_name: "",
  last_name: "",
  full_name: "",
  email: "",
  user_id: null,
  is_admin: false,
  is_supplier: false,
  is_brand: false,
  brand_id: undefined,
  brand_name: null,
  show_otp: false,
  login_identifier: "",
  is_checking_auth: true,
  // supplier object if user is supplier
  linked_supplier: {},
  permissions: {},
  isLoading: false,
  username: "",
  team: [],
};

function userReducer(state = initialState, action: IReduxActionType) {
  switch (action.type) {
    case aT.SET_USER:
      return update(state, {
        name: action.payload.name,
        first_name: action.payload.first_name || "",
        last_name: action.payload.last_name || "",
        full_name: action.payload.full_name,
        email: action.payload.email,
        user_id: action.payload.user_id,
        is_admin: action.payload.is_admin,
        is_brand: action.payload.is_brand,
        is_supplier: action.payload.is_supplier,
        linked_supplier: action.payload.linked_supplier,
        username: action.payload.username,
        brand_id: action.payload.brand_id,
        brand_name: action.payload.brand_name,
        team: action.payload.team,
      });

    case aT.RESET_USER:
      return update(state, {
        token: initialState.token,
        name: "",
        first_name: "",
        last_name: "",
        full_name: "",
        email: "",
        user_id: null,
        is_admin: false,
        is_brand: false,
        is_supplier: false,
        linked_supplier: {},
        username: "",
        brand_id: undefined,
        brand_name: null,
        team: [],
      });

    case aT.SET_USER_PERMISSION: {
      // Do not set permissions for now
      // return { ...state, permissions: action.payload };
      return state;
    }

    case aT.SET_TOKEN:
      return { ...state, token: action.payload };

    default:
      return state;
  }
}

export default userReducer;
