import { Tag } from 'react-tag-autocomplete';
import { v4 as uuidv4 } from 'uuid';
import RULE_ENGINE_TYPES from 'actionTypes/ruleEngine';
import {
  getAttributeName,
  getAttributeOptionsInTag,
} from 'components/CatalogRules/helpers';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import {
  DEFAULT_CONDITION,
  DEFAULT_SORTING,
} from 'components/CatalogRules/constants';
import {
  IConditionPropertiesData,
  IConditionProperties,
  IRuleProperties,
  ISortingProperties,
  IRuleCollectionData,
  ISortingPropertiesData,
  StagingModeEnum,
} from 'components/CatalogRules/type';
import { IEachFabricCluster } from 'components/FabricClusters/types';
import { IModifiedFeedback } from 'components/RuleEngineCollection/types';
import { convertArrayToObject } from 'utils/utility/array';
import { update } from './helpers';
import { IReduxActionType } from '../utils/types';

export interface IRuleEngineInitialState {
  conditionsData: Record<string, IConditionPropertiesData>;
  sortingsData: Record<string, ISortingPropertiesData>;
  ruleCollection: Record<string, IRuleCollectionData>;
  ruleStaging: IRuleProperties;
  stagingMode: StagingModeEnum;
  isAddRuleOpen: boolean;
  ruleCollectionCount: Number;
  isSaveRuleButtonLoading: boolean;
  loaderStatus: boolean;
  attributeOptionsProductID: Array<Tag>;
  ruleCollectionPaginationInfo: Record<string, number>;
  shouldShowUrl: boolean;
  feedbackData: Record<string, IModifiedFeedback>;
  viewfeedbackData: Record<string, IModifiedFeedback>;
  stagingfeedbackData: Record<string, IModifiedFeedback>;
  fabricClustersPagination: Record<string, number>;
  fabricClusterList: Array<IEachFabricCluster>;
  isFabricClusterSidebarOpen: boolean;
  isFabricClustersListLoading: boolean;
  selectedFabricClusterIds: string;
  collectionClusterData: Array<Record<string, string>>;
}

const initialState: IRuleEngineInitialState = {
  conditionsData: {},
  sortingsData: {},
  ruleCollection: {},
  ruleStaging: {
    id: uuidv4(),
    ruleName: '',
    salesRepresentative: 0,
    approachedBrand: '',
    categoryManager: 0,
    condition: {
      [DEFAULT_CONDITION.id]: DEFAULT_CONDITION,
    },
    sortings: {
      [DEFAULT_SORTING.id]: DEFAULT_SORTING,
    },
    images: [],
    isCluster: false,
    clusterType: '',
  },
  stagingMode: StagingModeEnum.CREATE,
  isAddRuleOpen: false,
  ruleCollectionCount: 0,
  isSaveRuleButtonLoading: false,
  loaderStatus: false,
  attributeOptionsProductID: [],
  ruleCollectionPaginationInfo: {
    count: 0,
    totalPages: 0,
    currentPage: 0,
  },
  shouldShowUrl: false,
  feedbackData: {},
  viewfeedbackData: {},
  stagingfeedbackData: {},
  fabricClustersPagination: {
    count: 0,
    totalPages: 0,
    page: 0,
  },
  fabricClusterList: [],
  isFabricClusterSidebarOpen: false,
  isFabricClustersListLoading: true,
  selectedFabricClusterIds: '',
  collectionClusterData: [],
};

function ruleEngineReducer(state = initialState, action: IReduxActionType) {
  switch (action.type) {
    case RULE_ENGINE_TYPES.SET_ADD_RULE_WITH_PRODUCTS: {
      const newCondition = action.payload;

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          condition: { [newCondition.id]: newCondition },
        },
      };
    }

    case RULE_ENGINE_TYPES.SET_CONDITION: {
      const { value, key, identifier } = action.payload;

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          condition: {
            ...state.ruleStaging.condition,
            [identifier]: {
              ...state.ruleStaging.condition[identifier],
              [key]: value,
            },
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.RULE_COLLECTION_COUNT:
      return { ...state, ruleCollectionCount: action.payload };

    case RULE_ENGINE_TYPES.ADD_ALL_CONDITION_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        conditionsData: data,
      };
    }

    case RULE_ENGINE_TYPES.ADD_ALL_SORTING_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        sortingsData: data,
      };
    }

    case RULE_ENGINE_TYPES.ADD_ALL_RULE_BASED_DATA: {
      const { results, mode } = action.payload;
      const conditionDataToObject: Record<string, IRuleCollectionData> =
        convertArrayToObject(results, 'link_identifier');
      if (mode === OperatorEnum.Append) {
        return update(state, {
          ruleCollection: {
            ...state.ruleCollection,
            ...conditionDataToObject,
          },
        });
      }
      return update(state, {
        ruleCollection: conditionDataToObject,
      });
    }

    case RULE_ENGINE_TYPES.SET_SORTING: {
      const { value, key, identifier } = action.payload;
      const setKey = value ? state.sortingsData[value].key : '';
      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          sortings: {
            ...state.ruleStaging.sortings,
            [identifier]: {
              ...state.ruleStaging.sortings[identifier],
              [key]: value,
              key: setKey,
            },
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.MAKE_DEFAULT_RULE_STAGING: {
      return {
        ...state,
        ruleStaging: {
          id: uuidv4(),
          ruleName: '',
          salesRepresentative: 0,
          approachedBrand: '',
          categoryManager: 0,
          condition: {
            [DEFAULT_CONDITION.id]: DEFAULT_CONDITION,
          },
          sortings: {
            [DEFAULT_SORTING.id]: DEFAULT_SORTING,
          },
          images: [],
          isCluster: false,
          clusterType: '',
        },
      };
    }

    case RULE_ENGINE_TYPES.ADD_NEW_SORTING: {
      const newId = uuidv4();

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          sortings: {
            ...state.ruleStaging.sortings,
            [newId]: {
              ...DEFAULT_SORTING,
              id: newId,
            },
          },
        },
      };
    }
    case RULE_ENGINE_TYPES.REMOVE_EACH_SORTING: {
      const { identifier } = action.payload;
      const newRows: Record<string, ISortingProperties> = {};
      Object.keys(state.ruleStaging.sortings).forEach(key => {
        if (key !== identifier) {
          newRows[key] = state.ruleStaging.sortings[key];
        }
      });
      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          sortings: newRows,
        },
      };
    }

    case RULE_ENGINE_TYPES.REMOVE_EACH_ATTRIBUTE: {
      const { identifier } = action.payload;
      const newRows: Record<string, IConditionProperties> = {};
      Object.keys(state.ruleStaging.condition).forEach(key => {
        if (key !== identifier) {
          newRows[key] = state.ruleStaging.condition[key];
        }
      });
      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          condition: newRows,
        },
      };
    }

    case RULE_ENGINE_TYPES.ADD_NEW_ATTRIBUTE: {
      const newId = uuidv4();

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          condition: {
            ...state.ruleStaging.condition,
            [newId]: {
              ...DEFAULT_CONDITION,
              id: newId,
            },
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.FILL_EDIT_DATA_TO_STAGING: {
      const { key, value } = action.payload;
      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          [key]: value,
        },
      };
    }

    case RULE_ENGINE_TYPES.TOGGLE_ADD_RULE: {
      return update(state, { isAddRuleOpen: action.payload });
    }

    case RULE_ENGINE_TYPES.HANDLE_ADD_OR_EDIT_RULE: {
      return update(state, { stagingMode: action.payload });
    }

    case RULE_ENGINE_TYPES.SET_ATTRIBUTE_OPTION_TAG: {
      const { key, identifier, productTags } = action.payload;
      const newKey = key as keyof Pick<
        IRuleProperties,
        'condition' | 'sortings'
      >;
      const newEnteredTags = [...productTags];

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          [newKey]: {
            ...state.ruleStaging[newKey],
            [identifier]: {
              ...state.ruleStaging[newKey][identifier],
              attributeOptions: newEnteredTags,
            },
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.REMOVE_ATTRIBUTE_OPTION_TAG: {
      const { key, identifier, trendingTag } = action.payload;
      const newKey = key as keyof Pick<
        IRuleProperties,
        'condition' | 'sortings'
      >;

      const newEnteredTags = state.ruleStaging[newKey][
        identifier
      ].attributeOptions.filter(eachTag => eachTag.name !== trendingTag.name);
      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          [newKey]: {
            ...state.ruleStaging[newKey],
            [identifier]: {
              ...state.ruleStaging[newKey][identifier],
              attributeOptions: newEnteredTags,
            },
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.FILL_STAGING_DATA: {
      const id = action.payload;
      const setCondition: Record<string, IConditionProperties> = {};

      state.ruleCollection[id].conditions.forEach(item => {
        const newId = uuidv4();
        if (item.attr_name === 'product_id') {
          setCondition[newId] = {
            id: newId,
            attributeName: item.attr_name,
            operator: item.operator,
            attributeOptions: getAttributeOptionsInTag(item.attr_value),
          };
        } else {
          setCondition[newId] = {
            id: newId,
            attributeName: item.attr_name,
            operator: item.operator,
            attributeOptions: item.attr_value,
          };
        }
      });

      const newSorting: Record<string, ISortingProperties> = {};
      state.ruleCollection[id].sorting.forEach(item => {
        const newId = uuidv4();
        let attr_name = getAttributeName(state.sortingsData, item.key);
        newSorting[newId] = {
          id: newId,
          attributeName: attr_name,
          attributeOptions: getAttributeOptionsInTag(item.value),
          key: item.key,
        };
      });

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          ruleName: state.ruleCollection[id].title,
          condition: setCondition,
          sortings: newSorting,
        },
      };
    }

    case RULE_ENGINE_TYPES.UPDATE_RULE_COLLECTION: {
      const data = action.payload;
      const collection = { ...state.ruleCollection };
      collection[data.link_identifier].title = data.title;
      collection[data.link_identifier].conditions = data.conditions;
      collection[data.link_identifier].sorting = data.sorting;
      return update(state, {
        ruleCollection: collection,
      });
    }

    case RULE_ENGINE_TYPES.TOGGLE_RULE_COLLECTION_LOADER: {
      return update(state, {
        isSaveRuleButtonLoading: action.payload,
      });
    }

    case RULE_ENGINE_TYPES.SET_ATTRIBUTE_OPTION: {
      const { identifier, values } = action.payload;

      return {
        ...state,
        ruleStaging: {
          ...state.ruleStaging,
          condition: {
            ...state.ruleStaging.condition,
            [identifier]: {
              ...state.ruleStaging.condition[identifier],
              attributeOptions: values,
            },
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.COLLECTION_PAGINATION_INFO: {
      return update(state, { ruleCollectionPaginationInfo: action.payload });
    }

    case RULE_ENGINE_TYPES.TOGGLE_SHOW_URL: {
      return update(state, { shouldShowUrl: action.payload });
    }

    case RULE_ENGINE_TYPES.GET_COLLECTION_FEEDBACK_INFO: {
      return update(state, { feedbackData: action.payload });
    }

    case RULE_ENGINE_TYPES.GET_VIEW_COLLECTION_FEEDBACK_INFO: {
      return update(state, { viewfeedbackData: action.payload });
    }

    case RULE_ENGINE_TYPES.SET_STAGING_FEEDBACK_DATA: {
      const { key, value } = action.payload;

      return {
        ...state,
        stagingfeedbackData: {
          ...state.stagingfeedbackData,
          [key]: {
            ...state.stagingfeedbackData[key],
            attributeOptions: value,
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.TOGGLE_FEEDBACK_EXIST_STATUS_IN_RULE_COLLECTION: {
      const { data } = action.payload;
      return {
        ...state,
        ruleCollection: {
          ...state.ruleCollection,
          [data]: {
            ...state.ruleCollection[data],
            feedback_exist: true,
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.SET_STAGING_FEEDBACK_DATA_OPTION: {
      const { key, value } = action.payload;

      return {
        ...state,
        stagingfeedbackData: {
          ...state.stagingfeedbackData,
          [key]: {
            ...state.stagingfeedbackData[key],
            otherInput: value,
          },
        },
      };
    }

    case RULE_ENGINE_TYPES.SET_DEFAULT_STAGING_FEEDBACK_DATA: {
      const data = action.payload;

      return {
        ...state,
        stagingfeedbackData: data,
      };
    }

    case RULE_ENGINE_TYPES.SET_FABRIC_CLUSTER_LIST_PAGINATION_DATA: {
      return {
        ...state,
        fabricClustersPagination: action.payload,
      };
    }

    case RULE_ENGINE_TYPES.SET_FABRIC_CLUSTERS_LIST_DATA: {
      let newFabricClusterList = [];

      if (action.payload.mode === OperatorEnum.Modify) {
        newFabricClusterList = action.payload.data;
      } else if (action.payload.mode === OperatorEnum.Append) {
        newFabricClusterList = [
          ...state.fabricClusterList,
          ...action.payload.data,
        ];
      }

      return {
        ...state,
        fabricClusterList: newFabricClusterList,
      };
    }

    case RULE_ENGINE_TYPES.SET_FABRIC_CLUSTER_SIDEBAR_OPEN: {
      return {
        ...state,
        isFabricClusterSidebarOpen: action.payload,
      };
    }

    case RULE_ENGINE_TYPES.SET_FABRIC_CLUSTER_LIST_LOADER: {
      return {
        ...state,
        isFabricClustersListLoading: action.payload,
      };
    }

    case RULE_ENGINE_TYPES.SET_SELECTED_CHECKBOXS_FOR_FABRIC_CLUSTERS: {
      return {
        ...state,
        selectedFabricClusterIds: action.payload,
      };
    }

    case RULE_ENGINE_TYPES.SET_COLLECTION_CLUSTER_DATA: {
      return update(state, { collectionClusterData: action.payload });
    }

    default:
      return state;
  }
}

export default ruleEngineReducer;
