import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Button = styled.button<{ disabled: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 9px 24px;
  width: fit-content;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  border: ${props =>
    props.disabled
      ? '1px solid var(--mischka)'
      : '1px solid var(--watermelon)'};
  border-radius: 4px;
  background-color: ${props =>
    props.disabled ? 'var(--mischka)' : 'var(--watermelon)'};
  color: var(--white);
`;

export const Loader = styled.div`
  position: relative;
  font-size: 4px;
  border: 1.1em solid rgba(255, 255, 255, 0.4);
  border-left-color: var(--white);
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  height: 28px;
  width: 28px;
  border-radius: 50%;
`;
