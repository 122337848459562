import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PRODUCT_IMAGE } from 'utils/defaults';

export enum StatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  APPROVED = 'approved',
  NOT_APPROVED = 'notapproved',
}

export const DEFAULT_EMPTY_VIEW = {
  id: uuidv4(),
  url: DEFAULT_PRODUCT_IMAGE,
  view: 'other',
};
