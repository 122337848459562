const nsp = 'ruleEngine/';

const RULE_ENGINE_TYPES = {
  ADD_ALL_CONDITION_DATA: `${nsp}ADD_ALL_CONDITION_DATA`,
  ADD_ALL_SORTING_DATA: `${nsp}ADD_ALL_SORTING_DATA`,
  FILL_EDIT_DATA_TO_STAGING: `${nsp}FILL_EDIT_DATA_TO_STAGING`,
  SET_CONDITION: `${nsp}SET_CONDITION`,
  UPDATE_RULE_COLLECTION: `${nsp}UPDATE_RULE_COLLECTION`,
  TOGGLE_RULE_COLLECTION_LOADER: `${nsp}TOGGLE_RULE_COLLECTION_LOADER`,
  ADD_ALL_RULE_BASED_DATA: `${nsp}ADD_ALL_RULE_BASED_DATA`,
  SET_ATTRIBUTE_OPTION: `${nsp}SET_ATTRIBUTE_OPTION`,
  SET_SORTING: `${nsp}SET_SORTING`,
  RULE_COLLECTION_COUNT: `${nsp}RULE_COLLECTION_COUNT`,
  ADD_NEW_ATTRIBUTE: `${nsp}ADD_NEW_ATTRIBUTE`,
  REMOVE_ATTRIBUTE_OPTION_TAG: `${nsp}REMOVE_ATTRIBUTE_OPTION_TAG`,
  SET_ATTRIBUTE_OPTION_TAG: `${nsp}SET_ATTRIBUTE_OPTION_TAG`,
  ADD_NEW_SORTING: `${nsp}ADD_NEW_SORTING`,
  MAKE_DEFAULT_RULE_STAGING: `${nsp}MAKE_DEFAULT_RULE_STAGING`,
  REMOVE_EACH_ATTRIBUTE: `${nsp}REMOVE_EACH_ATTRIBUTE`,
  REMOVE_EACH_SORTING: `${nsp}REMOVE_EACH_SORTING`,
  TOGGLE_ADD_RULE: `${nsp}TOGGLE_ADD_RULE`,
  FILL_STAGING_DATA: `${nsp}FILL_STAGING_DATA`,
  HANDLE_ADD_OR_EDIT_RULE: `${nsp}HANDLE_ADD_OR_EDIT_RULE`,
  SET_ADD_RULE_WITH_PRODUCTS: `${nsp}SET_ADD_RULE_WITH_PRODUCTS`,
  COLLECTION_PAGINATION_INFO: `${nsp}COLLECTION_PAGINATION_INFO`,
  TOGGLE_SHOW_URL: `${nsp}TOGGLE_SHOW_URL`,
  GET_COLLECTION_FEEDBACK_INFO: `${nsp}GET_COLLECTION_FEEDBACK_INFO`,
  TOGGLE_FEEDBACK_EXIST_STATUS_IN_RULE_COLLECTION: `${nsp}TOGGLE_FEEDBACK_EXIST_STATUS_IN_RULE_COLLECTION`,
  GET_VIEW_COLLECTION_FEEDBACK_INFO: `${nsp}GET_VIEW_COLLECTION_FEEDBACK_INFO`,
  SET_STAGING_FEEDBACK_DATA: `${nsp}SET_STAGING_FEEDBACK_DATA`,
  SET_DEFAULT_STAGING_FEEDBACK_DATA: `${nsp}SET_DEFAULT_STAGING_FEEDBACK_DATA`,
  SET_STAGING_FEEDBACK_DATA_OPTION: `${nsp}SET_STAGING_FEEDBACK_DATA_OPTION`,
  SET_FABRIC_CLUSTER_LIST_PAGINATION_DATA: `${nsp}SET_FABRIC_CLUSTER_LIST_PAGINATION_DATA`,
  SET_FABRIC_CLUSTERS_LIST_DATA: `${nsp}SET_FABRIC_CLUSTERS_LIST_DATA`,
  SET_FABRIC_CLUSTER_SIDEBAR_OPEN: `${nsp}SET_FABRIC_CLUSTER_SIDEBAR_OPEN`,
  SET_FABRIC_CLUSTER_LIST_LOADER: `${nsp}SET_FABRIC_CLUSTER_LIST_LOADER`,
  SET_SELECTED_CHECKBOXS_FOR_FABRIC_CLUSTERS: `${nsp}SET_SELECTED_CHECKBOXS_FOR_FABRIC_CLUSTERS`,
  SET_COLLECTION_CLUSTER_DATA: `${nsp}SET_COLLECTION_CLUSTER_DATA`,
};

export default RULE_ENGINE_TYPES;
