import React from 'react';
import * as Styles from './styles';

interface IToggleSwitchProps {
  name: string;
  id: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  optionLabels?: Array<string>;
  disabled?: boolean;
}

const ToggleSwitch = ({
  name,
  id,
  checked,
  onChange,
  optionLabels = [],
  disabled = false,
}: IToggleSwitchProps) => (
  <Styles.ToggleSwitch className="pos-r">
    <Styles.CheckboxInput
      type="checkbox"
      checked={checked}
      onChange={event => onChange(event.target.checked)}
      name={name}
      id={id}
      disabled={disabled}
    />
    <Styles.ToggleSwitchLabel
      htmlFor={name}
      className="cursor-pointer no-scroll"
    >
      <Styles.ToggleSwitchInner
        data-yes={optionLabels?.length > 0 ? optionLabels[0] : ''}
        data-no={optionLabels?.length > 0 ? optionLabels[1] : ''}
      />
      <Styles.ToggleSwitchSwitch className="pos-a" />
    </Styles.ToggleSwitchLabel>
  </Styles.ToggleSwitch>
);

export default ToggleSwitch;
