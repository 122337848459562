import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: var(--white);
  padding: 32px 160px;
  height: 260px;
  width: 432px;
  border-radius: 4px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: var(--rhino);
  margin-top: 32px;
`;

export const Spinner = styled.span`
  width: 48px;
  height: 48px;
  border: 3px solid var(--watermelon);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
`;
