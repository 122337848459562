import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import SideMenu from 'components/SideMenu';
import Table from 'components/ShareCatalogList/Table';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  deleteCollectionThunk,
  editCollectionThunk,
  getCollectionsDataThunk,
} from 'actions/catalog';
import { setQueryStringValueToUrl } from 'utils/routing';
import { removeFalsyKeys } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { DEFAULT_PRODUCT_IMAGE, DEFAULT_VALUE } from 'utils/defaults';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import EditCollection from './EditCollection';
import { getFields } from './Fields';
import Filters from './Filters';
import { COLLECTION_SIDEBAR } from './constants';
import { FilterTypes } from 'components/Filters/type';
import {
  ICollection,
  IModifiedData,
  ISaveCollection,
  ISelectedCollection,
} from './types';
import * as Styles from './styles';

const Collections = () => {
  const collectionData =
    useTypedSelector(state => state.catalog.collectionData) ?? [];
  const collectionPaginationInfo = useTypedSelector(
    state => state.catalog.collectionPaginationInfo
  );
  const brands = useTypedSelector(state => state.catalog.brands);
  const admins = useTypedSelector(state => state.catalog.admins);
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const [search, setSearch] = useSearchParams(new URLSearchParams(''));
  const [searchParam, setSearchParam] = useState('');
  const [currentCollection, setCurrentCollection] =
    useState<ISelectedCollection>({
      description: '',
      title: '',
      url: '',
      brands: [],
      products: [],
      linkIdentifier: '',
    });
  const [showEditCollectionModal, setShowEditCollectionModal] = useBoolean();

  useEffect(() => {
    dispatch(getCollectionsDataThunk(1, OperatorEnum.Modify, searchParam));
  }, [search, searchParam]);

  function getBrandName(id: number) {
    return brands.find(eachBrand => eachBrand.id === id);
  }

  function handleFilterChange(
    param: Partial<Record<FilterTypes, Array<string>>>,
    reset = false
  ) {
    const prevQueryParams = getQueryParams(queryParams);
    let queryParamObject: Record<string, Array<string>> = {};

    if (!reset) {
      let newQueryParams = {
        ...prevQueryParams,
      };
      for (let filter in param) {
        newQueryParams = {
          ...newQueryParams,
          [filter]: param[filter as keyof typeof param] ?? [],
        };
      }
      queryParamObject = newQueryParams;
    }

    let currentParam = '';
    Object.keys(queryParamObject).forEach((item: string) => {
      if (queryParamObject[item].length > 0) {
        return (currentParam +=
          item + '=' + queryParamObject[item].join() + '&');
      }
    });

    const sanitizedFilters = removeFalsyKeys(queryParamObject);
    const queryString = setQueryStringValueToUrl(sanitizedFilters);
    setSearchParam(currentParam.slice(0, currentParam.length - 1));
    setSearch(new URLSearchParams(queryString));
  }

  function getAdminName(id: number) {
    const admin = admins.find(eachAdmin => eachAdmin.id === id);
    return `${admin?.first_name} ${admin?.last_name}`;
  }

  function fetchNextSetOfCatalogs() {
    if (
      collectionPaginationInfo.currentPage >=
      collectionPaginationInfo.totalPages
    )
      return;

    dispatch(
      getCollectionsDataThunk(
        collectionPaginationInfo.currentPage + 1,
        OperatorEnum.Append,
        searchParam
      )
    );
  }

  function handleEditCollection(data: ISaveCollection) {
    const apiBody = {
      link_identifier: currentCollection.linkIdentifier,
      title: data.title,
      description: data.description,
      brand_ids: data.brandIds,
      add: data.add,
      delete: data.delete,
    };
    dispatch(editCollectionThunk(apiBody, data.currentProductIds));
  }

  const columns = useMemo(() => getFields(), []);

  const collections = useMemo(() => {
    return collectionData.map((row: ICollection) => {
      function makeCollection() {
        setCurrentCollection({
          title: modifiedData.title,
          description: modifiedData.description,
          url: modifiedData.url,
          brands: modifiedData.brands,
          products: modifiedData.products,
          linkIdentifier: modifiedData.linkIdentifier,
        });
        setShowEditCollectionModal.on();
      }

      function handleDeleteCollection() {
        dispatch(
          deleteCollectionThunk({ link_identifier: [row.linkIdentifier] })
        );
      }

      const dropdownOptions = [
        { value: 'Edit', handler: makeCollection },
        { value: 'Delete as collection', handler: handleDeleteCollection },
      ];

      const modifiedData: IModifiedData = {
        title: row.title || DEFAULT_VALUE,
        imageUrl: row.imageUrl || DEFAULT_PRODUCT_IMAGE,
        linkIdentifier: row.linkIdentifier || DEFAULT_VALUE,
        description: row.description || DEFAULT_VALUE,
        brands: row.brandIds.map(eachBrandId => ({
          id: eachBrandId,
          name: String(getBrandName(eachBrandId)?.name),
        })),
        addedBy: getAdminName(row.addedBy),
        createdOn: row.createdOn || DEFAULT_VALUE,
        url: row.url || DEFAULT_VALUE,
        dots: dropdownOptions,
        products: row.productsData.map(eachProduct => ({
          id: eachProduct,
          name: eachProduct,
        })),
      };

      return modifiedData;
    });
  }, [collectionData]);

  return (
    <>
      <Filters onFilterChange={handleFilterChange} />
      <Styles.Wrapper className="has-scroll">
        {showEditCollectionModal && (
          <SideMenu>
            <EditCollection
              collectionInfo={COLLECTION_SIDEBAR.editCollection}
              collectionTitle={currentCollection.title}
              collectionDescription={currentCollection.description}
              brands={brands}
              collectionBrands={currentCollection.brands}
              onClose={() => setShowEditCollectionModal.off()}
              productIds={currentCollection.products}
              onEditCollection={handleEditCollection}
              url={currentCollection.url}
            />
          </SideMenu>
        )}
        <Table
          columns={columns}
          maxWidth="100vw"
          data={collections}
          paginationHandler={fetchNextSetOfCatalogs}
        />
      </Styles.Wrapper>
    </>
  );
};

export default Collections;
