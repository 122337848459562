import { MdOutlineClose } from "react-icons/md";
import { TagComponentProps } from "react-tag-autocomplete";
import * as Styles from "./styles";

const TagComponent = ({ tag, onDelete }: TagComponentProps) => (
  <Styles.TagContainer className="align-center">
    <p>{tag.name}</p>
    <Styles.SvgButton onClick={onDelete}>
      <MdOutlineClose />
    </Styles.SvgButton>
  </Styles.TagContainer>
);

export default TagComponent;
