import { Tag } from 'react-tag-autocomplete';
import Toast from 'library/Toast';
import { ACCEPTED_SIZE } from 'components/ImageLinks/helperConstant';
import {
  IConditionPropertiesData,
  IEachCondition,
  IConditionProperties,
  IEachSorting,
  ISortingProperties,
  ISortingPropertiesData,
  IEachRule,
  IMoodboardImagesUploadError,
} from './type';

export function getOperatorOptions(
  conditionsData: Record<string, IConditionPropertiesData>,
  attributeName: string
) {
  if (attributeName === '') {
    return [];
  }

  return conditionsData[attributeName].operators.map((operator: string) => ({
    name: operator,
    id: operator,
  }));
}

export function getAttributeOptions(
  conditionsData: Record<string, IConditionPropertiesData>,
  attributeName: string
) {
  if (attributeName === '') {
    return [];
  }

  return conditionsData[attributeName].attr_options;
}

export function getDataType(
  conditionsData: Record<string, IConditionPropertiesData>,
  attributeName: string
) {
  if (attributeName === '') {
    return 'array';
  }

  return conditionsData[attributeName].data_type;
}

export function getSortingAttributeOptions(
  SortingData: Record<string, ISortingPropertiesData>,
  value: string
) {
  if (value === '') {
    return [];
  }

  return SortingData[value].attr_options.map((attr_option: Tag) => ({
    name: attr_option,
    id: attr_option,
  }));
}

export function getCondtionInArray(
  condition: Record<string, IConditionProperties>
) {
  let newCollection: Array<IEachCondition> = [];

  Object.keys(condition).forEach(id => {
    if (condition[id].attributeName === 'product_id') {
      let attributeOption: Array<any> = condition[id].attributeOptions.map(
        (item: Tag) => item.name
      );
      newCollection.push({
        attr_name: condition[id].attributeName,
        operator: condition[id].operator,
        attr_value: attributeOption,
      });
    } else {
      newCollection.push({
        attr_name: condition[id].attributeName,
        operator: condition[id].operator,
        attr_value: condition[id].attributeOptions,
      });
    }
  });

  newCollection = newCollection.filter(
    (item: IEachCondition) => item.attr_name !== ''
  );
  return newCollection;
}

export function getSortingInArray(
  sortings: Record<string, ISortingProperties>
) {
  let newSorting: Array<IEachSorting> = [];
  Object.keys(sortings).forEach(id => {
    let attributeOption: Array<string> = sortings[id].attributeOptions.map(
      (item: Tag) => item.name
    );
    newSorting.push({
      key: sortings[id].key,
      value: attributeOption,
    });
  });
  newSorting = newSorting.filter((item: IEachSorting) => item.key !== '');
  return newSorting;
}

export function getAttributeName(
  sortingsData: Record<string, ISortingPropertiesData>,
  key: string
) {
  let attributeName: string = '';
  Object.keys(sortingsData).forEach(id => {
    if (sortingsData[id].key === key) {
      attributeName = sortingsData[id].attr_name;
      return attributeName;
    }
  });
  return attributeName;
}

export function getAttributeOptionsInTag(ProductID: Array<Tag | string>) {
  let brandIds: Array<string> = [];
  brandIds = ProductID.map(eachBrand => String(eachBrand));

  const newCommaSeparatedTagsMap = new Map<String, Tag>();
  brandIds.forEach(eachTag => {
    const tagObj = {
      id: eachTag,
      name: eachTag,
    };
    newCommaSeparatedTagsMap.set(eachTag, tagObj);
  });
  return Array.from(newCommaSeparatedTagsMap.values());
}

export function checkForUnfilledData(data: IEachRule) {
  let hasUnfilledData = true;

  if (!data.title) {
    hasUnfilledData = false;
    Toast.INFO('Please add rule name');
    return hasUnfilledData;
  }
  if (data.conditions.length === 0) {
    hasUnfilledData = false;
    Toast.INFO('Please add atleast one condition');
    return hasUnfilledData;
  } else {
    data.conditions.forEach(item => {
      if (item.operator === '') {
        hasUnfilledData = false;
        Toast.INFO('Please add operator');
        return hasUnfilledData;
      } else if (item.attr_value.length === 0) {
        hasUnfilledData = false;
        Toast.INFO('Please add attribute option');
        return hasUnfilledData;
      }
      if (!hasUnfilledData) {
        return false;
      }
    });
  }

  data.sorting.forEach(item => {
    if (item.value.length === 0) {
      hasUnfilledData = false;
      Toast.INFO('Please add sorting value');
      return hasUnfilledData;
    }
    if (!hasUnfilledData) {
      return false;
    }
  });
  return hasUnfilledData;
}

export function showMoodboardImagesUploadErrorMsg({
  width,
  height,
  imageSize,
  minHeight = ACCEPTED_SIZE.MoodboardImageMinimumHeight,
  minWidth = ACCEPTED_SIZE.MoodboardImageMinimumWidth,
  maxHeight = ACCEPTED_SIZE.MoodboardImageMaximumHeight,
  maxWidth = ACCEPTED_SIZE.MoodboardImageMaximumWidth,
}: IMoodboardImagesUploadError) {
  let fileSize = '',
    sizeCorrectionMessage = '',
    imageHeight = '',
    imageWidth = '',
    widthCorrectionMsg = '',
    heightCorrectionMessage = '';
  let errorMessage = 'Image parameters not met.\nIncorrect Parameters :';

  if (imageSize > ACCEPTED_SIZE.MinimumImageSize * 1000000) {
    fileSize =
      ' File size : ' + Number((imageSize / 1000000).toFixed(2)) + ' MB\n';
    sizeCorrectionMessage =
      'File size should be less than ' +
      ACCEPTED_SIZE.MinimumImageSize +
      ' MB!\n';
  }

  if (height < minHeight || height > maxHeight) {
    imageHeight = 'Image height : ' + height + 'px\n';
    heightCorrectionMessage = `Image height should be in the range [${minHeight}-${maxHeight}] px!\n`;
  }

  if (width < minWidth || width > maxWidth) {
    imageWidth = 'Image width : ' + width + 'px\n';
    widthCorrectionMsg = `Image width should be in the range [${minWidth}-${maxWidth}] px!\n`;
  }

  let correctionMessage = errorMessage.concat(
    fileSize,
    sizeCorrectionMessage,
    imageHeight,
    heightCorrectionMessage,
    imageWidth,
    widthCorrectionMsg
  );
  Toast.ERROR(correctionMessage);
}
