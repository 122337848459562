import { Tag } from 'react-tag-autocomplete';
import { Column } from 'react-table';
import { MdContentCopy } from 'react-icons/md';
import ToggleDropdownControl from 'components/ShareCatalogList/Table/ToggleDropdownControl';
import { convertToDate } from 'utils/helpers';
import { copyToClipboard } from 'utils/utility';
import * as Styled from '../ShareCatalogList/styles';

type ColumnType = Column & {
  disableSortBy?: true | false;
};

export function getFields(): Array<ColumnType> {
  return [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row, value }) => {
        const data = row.original as { url: string; imageUrl: string };

        return (
          <Styled.TitleWrapper className="align-center">
            <Styled.Image src={data.imageUrl} />
            <Styled.TitleText as="a" href={data.url} target="_blank">
              {value}
            </Styled.TitleText>
          </Styled.TitleWrapper>
        );
      },
    },
    {
      Header: 'Link ID',
      accessor: 'linkIdentifier',
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Brands',
      accessor: 'brands',
      Cell: ({ value }) => (
        <Styled.RowData>
          {value
            .map((eachValue: Tag) => eachValue.name)
            .toString()
            .replace(',', ', ')}
        </Styled.RowData>
      ),
    },
    {
      Header: 'Created By',
      accessor: 'addedBy',
      Cell: ({ value }) => <Styled.RowData>{value}</Styled.RowData>,
    },
    {
      Header: 'Created Date',
      accessor: 'createdOn',
      Cell: ({ value }) => (
        <Styled.RowData>{convertToDate(value)}</Styled.RowData>
      ),
    },
    {
      Header: 'Link',
      accessor: 'url',
      Cell: ({ value }) => (
        <Styled.TitleWrapper className="align-center">
          <Styled.RowDataUrl
            as="a"
            href={value}
            target="_blank"
            className="cursor-pointer no-scroll"
          >
            {value}
          </Styled.RowDataUrl>

          <Styled.CopyToClipboardIcon>
            <MdContentCopy size="20px" onClick={() => copyToClipboard(value)} />
          </Styled.CopyToClipboardIcon>
        </Styled.TitleWrapper>
      ),
    },
    {
      Header: '',
      Cell: ({ value }) => <ToggleDropdownControl data={value} />,
      accessor: 'dots',
      disableSortBy: true,
    },
  ];
}
