const PdfIllustration = () => {
  return (
    <svg
      width={40}
      height={41}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y={0.5} width={40} height={40} rx={2} fill="#FFEBE6" />
      <g clipPath="url(#clip0_10492_158315)">
        <path
          d="M11.3602 23.0583C10.3692 22.2143 11.4414 21.0546 14.3511 19.8231L16.182 19.0482L16.8953 17.7192C17.2876 16.9882 17.8731 15.7955 18.1964 15.0687L18.7843 13.7472L18.3792 12.7693C17.8811 11.5668 17.7034 9.75984 18.0195 9.11008C18.4475 8.22996 19.8497 8.32026 20.4048 9.2637C20.8383 10.0005 20.794 11.3349 20.2801 13.0177L19.8588 14.3973L20.2299 14.9338C20.434 15.2289 21.0302 15.9296 21.5548 16.4909L22.5407 17.5353L23.7676 17.399C27.6651 16.9658 29 17.702 29 18.7574C29 20.0895 25.9396 20.1995 23.3697 18.6623C22.7915 18.3164 22.3944 17.9728 22.3944 17.9728C22.3944 17.9728 20.7844 18.252 19.9917 18.4339C19.1735 18.6217 18.7653 18.7393 17.5668 19.0839C17.5668 19.0839 17.1462 19.604 16.8722 19.9821C15.8527 21.3887 14.6624 22.5545 13.8122 22.9787C12.8604 23.4538 11.8626 23.4862 11.3602 23.0583ZM12.9154 22.5851C13.4725 22.2919 14.6 21.1563 15.3804 20.1022L15.6964 19.6755L14.2576 20.2916C12.0353 21.2431 11.0187 22.14 11.5474 22.6824C11.8443 22.9872 12.1996 22.9619 12.9154 22.5851ZM27.3502 19.1345C27.8951 18.8095 27.816 18.1546 27.2 17.8905C26.7206 17.685 26.3342 17.6428 25.0889 17.6584C24.3236 17.7029 23.093 17.8342 22.8845 17.8742C22.8845 17.8742 23.5605 18.2721 23.8607 18.4183C24.2602 18.6127 25.2311 18.9734 25.94 19.1582C26.6393 19.3405 27.0438 19.3213 27.3502 19.1345V19.1345ZM21.5462 17.0801C21.2164 16.7849 20.6557 16.1688 20.3002 15.711C19.8352 15.1916 19.602 14.8252 19.602 14.8252C19.602 14.8252 19.2621 15.7562 18.9833 16.3164L18.1133 18.1477L17.8611 18.5631C17.8611 18.5631 19.202 18.1886 19.8842 18.0369C20.6068 17.8761 22.0734 17.6305 22.0734 17.6305L21.5462 17.0801ZM19.6758 10.6922C19.7599 10.0911 19.7956 9.49085 19.5688 9.18818C18.9398 8.60257 18.1806 9.09092 18.3092 10.4832C18.3524 10.9515 18.4891 11.7522 18.6717 12.2457L19.0038 13.1429L19.2376 12.4671C19.3661 12.0955 19.5633 11.2967 19.6758 10.6922Z"
          fill="#DE350B"
        />
        <path
          d="M13.0449 26.9668H14.6537C15.1677 26.9668 15.585 27.0087 15.9055 27.0925C16.226 27.1735 16.4957 27.356 16.7146 27.6397C16.9336 27.9208 17.043 28.26 17.043 28.6572C17.043 29.0221 16.9542 29.3356 16.7765 29.5977C16.5988 29.8599 16.3592 30.0491 16.0578 30.1653C15.7595 30.2815 15.2994 30.3396 14.6775 30.3396H14.1206V32.5003H13.0449V26.9668ZM14.1206 27.6762V29.6059H14.6537C15.1297 29.6059 15.4581 29.5302 15.6389 29.3788C15.823 29.2275 15.915 28.9816 15.915 28.641C15.915 28.387 15.8547 28.1816 15.7341 28.0248C15.6135 27.8654 15.4803 27.7681 15.3343 27.733C15.1915 27.6951 14.9646 27.6762 14.6537 27.6762L14.1206 27.6762Z"
          fill="#DE350B"
        />
        <path
          d="M17.8665 26.9668H19.3277C20.0353 26.9668 20.6001 27.0735 21.0221 27.287C21.4473 27.5005 21.7678 27.8181 21.9836 28.2397C22.2025 28.6613 22.312 29.1288 22.312 29.6423C22.312 30.1828 22.2136 30.6653 22.0169 31.0896C21.8233 31.5112 21.525 31.8517 21.1221 32.1111C20.7222 32.3706 20.1511 32.5003 19.4086 32.5003H17.8665V26.9668ZM18.9421 27.7005V31.7666H19.3895C20.0146 31.7666 20.4684 31.5828 20.7508 31.2152C21.0332 30.845 21.1744 30.3518 21.1744 29.7356C21.1744 28.3789 20.5795 27.7005 19.3895 27.7005H18.9421Z"
          fill="#DE350B"
        />
        <path
          d="M23.3467 26.9668H26.9545V27.7005H24.4224V29.3545H26.45V30.0883H24.4224V32.5003H23.3467V26.9668Z"
          fill="#DE350B"
        />
      </g>
      <defs>
        <clipPath id="clip0_10492_158315">
          <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(8 8.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PdfIllustration;
