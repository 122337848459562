import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Tag } from 'react-tag-autocomplete';
import SideDrawer from 'library/SideDrawer';
import AddRules from 'components/CatalogRules';
import SideMenu from 'components/SideMenu';
import EditCollection from 'components/Collections/EditCollection';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  fillStagingData,
  fillDataToStaging,
  getAddRuleDataThunk,
  getRuleCollectionDataThunk,
  handleAddOrEditRule,
  toggleAddRule,
  getCollectionFeedbackFillThunk,
  fetchAllCollectionClusterThunk,
} from 'actions/ruleEngines';
import { createCollectionFromSharedCatalog } from 'actions/catalog';
import { removeFalsyKeys } from 'utils/utility/utils';
import { setQueryStringValueToUrl } from 'utils/routing';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { COLLECTION_SIDEBAR } from 'components/Collections/constants';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import { ISaveCollection } from 'components/Collections/types';
import { ICollectionData } from 'components/ShareCatalogList/types';
import { StagingModeEnum } from 'components/CatalogRules/type';
import { FilterTypes } from 'components/Filters/type';
import { DEFAULT_VALUE } from 'utils/defaults';
import Filters from './Filters';
import DownloadPptSidebar from './DownloadPptSidebar';
import { getFields } from './Fields';
import Table from '../ShareCatalogList/Table/index';
import * as Styles from './styles';

const RuleEngineContainer = () => {
  const admins = useTypedSelector(state => state.catalog.admins);
  const categoryManagerUsers = useTypedSelector(
    state => state.catalog.categoryManagersData
  );
  const salesRepUsers = useTypedSelector(
    state => state.catalog.salesRepresentativesData
  );
  const ruleCollection = useTypedSelector(
    store => store.ruleEngine.ruleCollection
  );
  const ruleCollectionPaginationInfo = useTypedSelector(
    state => state.ruleEngine.ruleCollectionPaginationInfo
  );
  const isAddRuleOpen = useTypedSelector(
    state => state.ruleEngine.isAddRuleOpen
  );
  const brands = useTypedSelector(state => state.catalog.brands);
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const [search, setSearch] = useSearchParams(new URLSearchParams(''));
  const [searchParam, setSearchParam] = useState('');
  const [editCollectionData, setEditCollectionData] = useState<ICollectionData>(
    {
      title: '',
      description: '',
      products: [],
      ruleId: -1,
      imageUrl: '',
    }
  );
  const [isMakeACollectionSidebarOpen, makeACollectionSidebarAction] =
    useBoolean();
  const [isDownloadPptSidebarOpen, setDownloadPptSidebarOpen] = useBoolean();
  const [currentRule, setCurrentRule] = useState<number>(-1);

  function handleAddRule(isOpen: boolean) {
    dispatch(handleAddOrEditRule(StagingModeEnum.CREATE));
    dispatch(toggleAddRule(isOpen));
  }

  useEffect(() => {
    dispatch(getAddRuleDataThunk());
    dispatch(fetchAllCollectionClusterThunk());
  }, []);

  useEffect(() => {
    dispatch(getRuleCollectionDataThunk(1, OperatorEnum.Modify, searchParam));
  }, [search, searchParam]);

  useEffect(() => {
    dispatch(getCollectionFeedbackFillThunk());
  }, []);

  function fetchNextSetOfRuleCollection() {
    if (
      ruleCollectionPaginationInfo.currentPage >=
      ruleCollectionPaginationInfo.totalPages
    )
      return;
    dispatch(
      getRuleCollectionDataThunk(
        ruleCollectionPaginationInfo.currentPage + 1,
        OperatorEnum.Append,
        searchParam
      )
    );
  }

  function handleFilterChange(
    param: Partial<Record<FilterTypes, Array<string>>>,
    reset = false
  ) {
    const prevQueryParams = getQueryParams(queryParams);
    let queryParamObject: Record<string, Array<string>> = {};

    if (!reset) {
      let newQueryParams = {
        ...prevQueryParams,
      };
      for (let filter in param) {
        newQueryParams = {
          ...newQueryParams,
          [filter]: param[filter as keyof typeof param] ?? [],
        };
      }
      queryParamObject = newQueryParams;
    }
    let currentParam = '';
    Object.keys(queryParamObject).forEach((item: string) => {
      if (queryParamObject[item].length > 0) {
        return (currentParam +=
          item + '=' + queryParamObject[item].join() + '&');
      }
    });

    const sanitizedFilters = removeFalsyKeys(queryParamObject);
    const queryString = setQueryStringValueToUrl(sanitizedFilters);
    setSearchParam(currentParam.slice(0, currentParam.length - 1));
    setSearch(new URLSearchParams(queryString));
  }

  function handleCreateCollection(data: ISaveCollection) {
    const apiBody = {
      title: data.title,
      description: data.description,
      add: data.currentProductIds,
      brand_ids: data.brandIds,
      cloned_by: editCollectionData.ruleId,
      image_url: editCollectionData.imageUrl,
    };
    dispatch(
      createCollectionFromSharedCatalog(apiBody, data.currentProductIds)
    );
  }

  function getAdminName(id: number) {
    const admin = admins.find(eachAdmin => eachAdmin.id === id);
    return admin ? `${admin?.first_name} ${admin?.last_name}` : DEFAULT_VALUE;
  }

  function getCmName(category_manager: number) {
    const categoryManagerDetail = categoryManagerUsers.find(
      eachItem => eachItem.id === category_manager
    );
    return categoryManagerDetail
      ? `${categoryManagerDetail?.full_name}`
      : DEFAULT_VALUE;
  }

  function getSaleRepName(sales_representative: number) {
    const salesRepDetails = salesRepUsers.find(
      eachItem => eachItem.id === sales_representative
    );
    return salesRepDetails ? `${salesRepDetails?.full_name}` : DEFAULT_VALUE;
  }

  const columns = useMemo(() => getFields(), []);

  const ruleCollections = useMemo(
    () =>
      Object.keys(ruleCollection).map(id => {
        const productIdCondition = ruleCollection[id].conditions.find(
          eachCondition => eachCondition.attr_name === 'product_id'
        );

        function editRule() {
          dispatch(
            fillDataToStaging(
              'link_identifier',
              ruleCollection[id].link_identifier
            )
          );
          dispatch(fillDataToStaging('images', ruleCollection[id].images));
          dispatch(
            fillDataToStaging(
              'salesRepresentative',
              ruleCollection[id].sales_representative
            )
          );
          dispatch(
            fillDataToStaging(
              'categoryManager',
              ruleCollection[id].category_manager
            )
          );
          dispatch(
            fillDataToStaging(
              'approachedBrand',
              ruleCollection[id].approached_brand
            )
          );
          dispatch(
            fillDataToStaging('isCluster', ruleCollection[id].is_cluster)
          );
          dispatch(
            fillDataToStaging('clusterType', ruleCollection[id].cluster_type)
          );
          dispatch(fillDataToStaging('url', ruleCollection[id].url));
          dispatch(fillStagingData(id));
          dispatch(toggleAddRule(true));
          dispatch(handleAddOrEditRule(StagingModeEnum.EDIT));
        }

        function makeCollection() {
          const productIds: Array<Tag> =
            productIdCondition?.attr_value.map(eachProduct => {
              return {
                id: String(eachProduct),
                name: String(eachProduct),
              };
            }) ?? [];
          setEditCollectionData({
            title: modifiedData.title,
            description: '',
            products: productIds,
            ruleId: ruleCollection[id].id,
            imageUrl: '',
          });
          makeACollectionSidebarAction.on();
        }

        function downloadPpt() {
          setDownloadPptSidebarOpen.on();
          setCurrentRule(ruleCollection[id].id);
        }

        const dropdownOptions = [
          { value: 'Edit Rule', handler: editRule },
          {
            value: 'Download PPT',
            handler: downloadPpt,
          },
        ];
        if (productIdCondition) {
          dropdownOptions.push({
            value: 'Make it a collection',
            handler: makeCollection,
          });
        }

        const modifiedData = {
          ruleId: ruleCollection[id].id || DEFAULT_VALUE,
          title: ruleCollection[id].title || DEFAULT_VALUE,
          linkIdentifier: ruleCollection[id].link_identifier || DEFAULT_VALUE,
          addedBy: getAdminName(ruleCollection[id].added_by),
          createdOn: ruleCollection[id].created_on || DEFAULT_VALUE,
          url: ruleCollection[id].url || DEFAULT_VALUE,
          salesRepresentative: getSaleRepName(
            ruleCollection[id].sales_representative
          ),
          approachedBrand: ruleCollection[id].approached_brand || DEFAULT_VALUE,
          categoryManager: getCmName(ruleCollection[id].category_manager),
          isCluster: ruleCollection[id].is_cluster,
          clusterType: ruleCollection[id].cluster_type,
          feedbackExist: ruleCollection[id].feedback_exist,
          dots: dropdownOptions,
        };
        return modifiedData;
      }),
    [ruleCollection]
  );

  return (
    <>
      {isAddRuleOpen && <AddRules />}
      {isMakeACollectionSidebarOpen && (
        <SideMenu>
          <EditCollection
            collectionInfo={COLLECTION_SIDEBAR.makeCollection}
            collectionTitle={editCollectionData.title}
            collectionDescription={editCollectionData.description}
            brands={brands}
            onClose={makeACollectionSidebarAction.off}
            productIds={editCollectionData.products}
            onEditCollection={handleCreateCollection}
          />
        </SideMenu>
      )}
      <SideDrawer isOpen={isDownloadPptSidebarOpen} width="calc(30vw)">
        <DownloadPptSidebar
          currentRule={currentRule}
          setCurrentRule={setCurrentRule}
          onClose={() => setDownloadPptSidebarOpen.off()}
        />
      </SideDrawer>
      <Filters onFilterChange={handleFilterChange} />
      <Styles.Wrapper className="has-scroll">
        <Styles.AddRuleButton
          className="cursor-pointer pos-a"
          onClick={() => {
            handleAddRule(true);
          }}
        >
          + Add Rules
        </Styles.AddRuleButton>
        <Table
          columns={columns}
          maxWidth="100vw"
          data={ruleCollections}
          paginationHandler={fetchNextSetOfRuleCollection}
        />
      </Styles.Wrapper>
    </>
  );
};

export default RuleEngineContainer;
