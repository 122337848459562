import styled from 'styled-components';

export const FiltersContainer = styled.div`
  height: 72px;
  flex-shrink: 0;
`;

export const FiltersContainerLeftPart = styled.div`
  gap: 16px;
`;

export const FiltersContainerRightPart = styled.div`
  gap: 30px;
`;

export const DropdownWrapperTitle = styled.p`
  color: var(--manatee);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const SelectedCountIndicator = styled.span`
  color: var(--pacific-blue);
  background: var(--bubbles);
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 20px;
  white-space: nowrap;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  position: relative;

  .dropdown2-wrapper {
    width: 150px;
    height: 40px;
    border: 1px solid var(--mischka);
    border-radius: 4px;
    flex: none;

    span {
      border: none !important;
    }
  }

  .dropdown-class {
    border: none;
    height: 100%;
    width: auto;
    padding: 8px 12px;

    .label-value {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .label-name {
      color: var(--manatee);
      font-size: 13px;
      line-height: 16px;
    }
  }

  .dropdownBox-class {
    padding: 16px 0 !important;
    max-height: 30vh;
    min-width: max-content;

    li div[role='menuitem'] {
      padding: 0 20px !important;
    }

    .dropdownBox-input {
      padding: 0;

      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      padding: 0 !important;
    }
  }

  .dropdown-placeholder {
    padding-right: 240px;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }

  &.modal-dropdown {
    height: fit-content;
    width: 100%;
    .dropdown2-wrapper {
      width: 100%;
    }
  }
`;

export const MoreFilter = styled.div`
  width: 132px;
  height: 40px;
  font-size: 16px;
  padding: 0 8px;
  margin-top: 12%;
  border-radius: 4px;
  border: 1px solid var(--mischka);

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const MultiSelectDropdownWrapper = styled.div<{ isDisabled: boolean }>`
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  .m-dropdown {
    top: 48px;
    z-index: 1;
    display: ${props => (props.isDisabled ? 'none' : 'block')};
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }

  p,
  svg,
  div {
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`;
