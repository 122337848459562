import { useLocation } from 'react-router-dom';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import LinkIdFilter from 'components/Filters/LinkIdFilter';
import CreatedByFilter from 'components/Filters/CreatedByFilter';
import FilterSearchBox from 'components/Filters/CreatedByFilter/FilterSearchBox';
import { useBoolean } from 'utils/hooks/useBoolean';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import {
  getSelectOptionNames,
  useAllAdminDetail,
} from 'components/Filters/helper';
import { FilterTypes } from 'components/Filters/type';
import * as Styles from './styles';

interface IFilterSearchBarProps {
  onFilterChange(param: Partial<Record<FilterTypes, Array<string>>>): void;
}

const Filters = ({ onFilterChange }: IFilterSearchBarProps) => {
  const admins = useAllAdminDetail();
  const [isCreatedByFilterOpen, createdByFilterActions] = useBoolean();
  const { search: queryParams } = useLocation();
  const currentQueryParams = getQueryParams(queryParams);

  return (
    <Styles.FilterWrapper className="d-flex">
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>Search Link Ids</Styles.FilterViewBarTitle>
        <LinkIdFilter onFilterChange={onFilterChange} />
      </Styles.FilterViewBarWrapper>
      <Styles.FilterViewBarWrapper className="pos-r">
        <Styles.FilterViewBarTitle>Created By</Styles.FilterViewBarTitle>
        <FilterSearchBox
          value={getSelectOptionNames(admins, currentQueryParams.added_by__in)}
          isFilterSearchBarOpen={isCreatedByFilterOpen}
          toggleFilterSearchBox={() => createdByFilterActions.toggle()}
          placeholder="Created By"
        />
        {isCreatedByFilterOpen && (
          <ClickOutside
            onClose={() => createdByFilterActions.off()}
            disableClickHandler={false}
          >
            <CreatedByFilter
              onClose={() => createdByFilterActions.off()}
              onFilterChange={onFilterChange}
            />
          </ClickOutside>
        )}
      </Styles.FilterViewBarWrapper>
    </Styles.FilterWrapper>
  );
};
export default Filters;
