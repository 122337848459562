export const COLLECTION_SIDEBAR = Object.freeze({
  makeCollection: {
    heading: 'Make collection',
    buttonText: 'Create collection',
    successText: 'Collection created',
  },
  editCollection: {
    heading: 'Edit collection',
    buttonText: 'Save',
    successText: 'Saved',
  },
});
