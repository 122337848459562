import * as Styled from './styles.js';

const Checkbox = ({
  onChange,
  label = '',
  checked,
  item = {},
  dataProps = {},
  isDisabled = false,
  className = '',
}) => {
  const disabledClassname = isDisabled ? 'disabled' : '';
  return (
    <Styled.Label className={className}>
      <Styled.Input
        type="checkbox"
        checked={checked}
        onChange={event => onChange && onChange(event, item)}
        disabled={isDisabled}
        {...dataProps} // do not change order. It is important
      />
      {/* empty tag is important, it contains the checkbox */}
      <Styled.Span />
      {label && (
        <Styled.CkLabel className={disabledClassname}>
          <p>{label}</p>
        </Styled.CkLabel>
      )}
    </Styled.Label>
  );
};

export default Checkbox;
