import React, { ReactNode, useEffect } from 'react';
import { detectKeyPress } from 'utils/utility/utils';

interface IClickOutsideProps {
  children: ReactNode;
  disableClickHandler: boolean;
  onClose(
    event: React.MouseEvent<HTMLElement> | KeyboardEvent | MouseEvent
  ): void;
}

const ClickOutside = ({
  disableClickHandler,
  children,
  onClose,
}: IClickOutsideProps) => {
  let containerRef: HTMLDivElement | null;

  useEffect(() => {
    if (!disableClickHandler)
      document.addEventListener('click', handleClickEvent);

    document.addEventListener('keydown', handleKeyEvent, true);
    return () => {
      if (!disableClickHandler)
        document.removeEventListener('click', handleClickEvent);
      document.removeEventListener('keydown', handleKeyEvent, true);
    };
  }, [disableClickHandler]);

  function handleClickEvent(event: MouseEvent) {
    const target = event.target as Element;
    if (!containerRef?.contains(target)) {
      onClose(event);
    }
  }

  function handleKeyEvent(event: KeyboardEvent) {
    if (detectKeyPress(event).escape) {
      onClose(event);
    }
  }

  return (
    <div role="presentation" ref={el => (containerRef = el)}>
      {children}
    </div>
  );
};

export default ClickOutside;
