import styled from 'styled-components';

export const SecondaryFilter = styled.ul<{ isClose: boolean }>`
  margin-bottom: ${props => (props.isClose ? 0 : '12px')};
`;

export const FilterTagName = styled.li<{ isSelectedFilters: boolean }>`
  font-size: 12px;
  font-weight: 400;
  padding: 7px 12px 6px 12px;
  margin-bottom: 5px;
  margin-left: 5px;
  color: var(--rhino);
  ${props =>
    props.isSelectedFilters
      ? `
      background-color: var(--athens-gray);
      border-radius: 30px;
    `
      : ''}

  &:hover {
    background-color: var(--athens-gray);
    border-radius: 30px;
  }

  span:first-child {
    color: var(--rhino);
  }
`;

export const FilterTagIcon = styled.div`
  padding-left: 4px;
`;

export const FilterMoreButton = styled.li`
  font-weight: 500;
  padding: 7px 12px 6px 12px;
  margin-bottom: 7px;
  color: var(--deep-cerulean);

  span {
    font-size: 14px;
  }
`;

export const FilterOptionContainer = styled.div`
  box-orient: horizontal;
  box-direction: normal;
  text-transform: capitalize;
  padding: 0 17px;
`;

export const FilterOption = styled.div`
  margin: 0 20px 10px 0;
  width: 146px;

  label {
    justify-content: flex-start;

    p {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
