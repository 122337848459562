import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import { ReactTagsWrapper } from 'components/TrendingModal/styles';

export const IconWrapper = styled.div`
  padding: 8px;
`;

export const Wrapper = styled.div`
  background: var(--white);
`;

export const Body = styled.div`
  padding: 9px 16px;
  overflow: overlay;
  height: 65vh;
`;

export const Heading = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  padding: 13px 16px;
  border-bottom: 1px solid var(--athens-gray);
`;

export const SerialNoTitle = styled.div`
  width: 8%;
  text-align: left;
`;

export const SerialNumber = styled.div`
  width: 8%;
  height: 40px;
  color: var(--rhino);
`;

export const ConditionTitle = styled.div`
  width: 30%;
  text-align: left;
`;

export const ConditionContainer = styled.div`
  width: 30%;
  padding-right: 16px;

  .dropdown2-wrapper {
    width: 100%;
    margin-bottom: 24px;

    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;
      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }

  .dropdown-placeholder {
    padding-right: 80%;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }
`;

export const FunctionTitle = styled.div`
  width: 20%;
  text-align: left;
`;

export const FunctionContainer = styled.div`
  width: 20%;
  padding-right: 16px;

  .dropdown2-wrapper {
    width: 100%;
    margin-bottom: 24px;

    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdownBox-class {
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;
      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }

  .dropdown-placeholder {
    padding-right: 80%;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }
`;

export const AttributeTitle = styled.div`
  width: 42%;
  text-align: left;
`;

export const CloseConditionIcon = styled.div`
  width: 5%;
  height: 40px;
`;

export const ConditionButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweedOutLined,
}))`
  width: 180px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const MultiDropDownWrapper = styled.div<{ isDisabled: boolean }>`
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--mischka);
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  background-color: ${props =>
    props.isDisabled ? 'var(--input-disabled)' : 'var(--white)'};
`;

export const AttributeDisplayed = styled.div`
  color: var(--rhino);
  padding-left: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

export const NameWrapper = styled.div`
  font-size: 14px;
  padding: 2px;
  p {
    color: var(--manatee);
  }
`;

export const Input = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--athens-gray);
`;

export const InputPrice = styled.input`
  border: none;
  border-radius: 0 4px 4px 0;
  align-items: center;

  &:focus {
    outline-width: 0;
  }
`;

export const CurrencySymbol = styled.p`
  width: 10%;
  padding: 10px;
  border-radius: 4px 0 0 4px;
  color: var(--rhino);
`;

export const AttributeContainer = styled.div`
  width: 37%;
  margin-bottom: 19px;

  .dropdown2-wrapper {
    width: 100%;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    flex: none;
    align-items: center;

    span {
      border: none !important;
    }
    svg {
      color: var(--manatee);
    }
  }
`;

export const DropDownfilterResults = styled.div`
  max-height: 250px;

  li {
    margin: 0 20px 10px 0;
    list-style: none;

    label {
      justify-content: flex-start;

      p {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 14px !important;
        color: var(--rhino) !important;

        :hover {
          background-color: unset !important;
          color: var(--watermelon) !important;
        }
      }
    }
  }
`;

export const DropDownSearchContainer = styled.div`
  margin-bottom: 16px;
  input {
    border: none;
  }
  input:focus {
    outline-width: 0;
  }
`;

export const DropDownOptionsContainer = styled.div`
  width: 100%;
  z-index: 11;
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
  left: 0;
  top: 40px;
  background-color: var(--white);
  padding: 20px 16px;
`;

export const InputAttributeOptions = styled.div`
  input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    color: var(--rhino);

    &:focus {
      outline: none;
    }
  }

  input::placeholder {
    color: var(--manatee);
  }
`;

export const MutliDropdown = styled.div`
  .dropdown2-wrapper {
    width: 100%;
    border: 1px solid var(--mischka);
    border-radius: 4px;
    flex: none;

    span {
      border: none !important;
      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdown-class {
    border: none;
    height: 100%;
    width: auto;
    padding: 8px 12px;

    .label-value {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .label-name {
      color: var(--manatee);
      font-size: 14px;
      line-height: 16px;
    }
  }

  .dropdownBox-class {
    padding: 16px 0 !important;
    max-height: 30vh;
    min-width: max-content;

    li div[role='menuitem'] {
      padding: 0 20px !important;
    }

    .dropdownBox-input {
      padding: 0;

      .list-item-search-box-class {
        input {
          height: 30px;
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      padding: 0 !important;
    }
  }

  .dropdown-placeholder {
    padding-right: 240px;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }

  &.modal-dropdown {
    height: fit-content;
    width: 100%;
    .dropdown2-wrapper {
      width: 100%;
    }
  }

  .list-item-search-box-class {
    height: 30px;
    background-color: var(--input-disabled);
    border: none;

    input {
      background-color: var(--input-disabled);
      font-size: 14px;
      color: var(--rhino);
    }
  }
`;

export const ReactTagsWrappers = styled(ReactTagsWrapper)`
  .react-tags {
    width: 100%;
    height: 90px;
    padding: 8px 0 8px 8px;
  }
`;
