import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import TextInputWithLabel from 'components/textInputWithLabel/TextInputWithLabel';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import {
  setStagingFeedbackData,
  setStagingFeedbackDataOption,
} from 'actions/ruleEngines';
import { IModifiedFeedback } from 'components/RuleEngineCollection/types';
import * as Styles from '../styles';

interface SingleSelectTypeFeedbackProps {
  eachFeedbackData: IModifiedFeedback;
  viewMode: boolean;
}

const SingleSelectTypeFeedback = ({
  eachFeedbackData,
  viewMode = false,
}: SingleSelectTypeFeedbackProps) => {
  const eachFeedbackDataInStaging = useTypedSelector(
    store =>
      store.ruleEngine.stagingfeedbackData[eachFeedbackData.attributeName]
  );
  const dispatch = useDispatch();
  const shouldShow =
    viewMode && eachFeedbackDataInStaging.attributeOptions[0] !== 'Other'
      ? false
      : true;

  function handleChange(value: string) {
    const newValue =
      eachFeedbackDataInStaging.attributeOptions[0] !== value ? [value] : [];
    dispatch(setStagingFeedbackData(eachFeedbackData.attributeName, newValue));
    dispatch(setStagingFeedbackDataOption(eachFeedbackData.attributeName, []));
  }

  function feedbackOther(event: ChangeEvent<HTMLInputElement>) {
    dispatch(
      setStagingFeedbackDataOption(eachFeedbackData.attributeName, [
        event.target.value,
      ])
    );
  }

  return (
    <Styles.SingleSelectWrapper className="fit-height">
      <Styles.InputTitleLabel>
        {eachFeedbackData.attributeName}
      </Styles.InputTitleLabel>
      {viewMode
        ? eachFeedbackDataInStaging.attributeOptions.map(eachAttribute => {
            if (eachAttribute !== 'Other') {
              return (
                <Styles.MutliSeclectOptions
                  key={eachAttribute}
                  className="full-width"
                >
                  {eachAttribute}
                </Styles.MutliSeclectOptions>
              );
            }
          })
        : eachFeedbackData.attributeOptions.map(eachAttribute => (
            <Styles.SingleSelectOptions
              key={eachAttribute}
              className="full-width"
            >
              <Checkbox
                item={{
                  value: eachAttribute,
                }}
                label={eachAttribute}
                onChange={() => handleChange(eachAttribute)}
                checked={eachFeedbackDataInStaging.attributeOptions.includes(
                  eachAttribute
                )}
              />
            </Styles.SingleSelectOptions>
          ))}
      {shouldShow && (
        <TextInputWithLabel
          datalabel=""
          placeholder="Other Reasons"
          dataname={eachFeedbackData.attributeName}
          value={eachFeedbackDataInStaging.otherInput[0]}
          onChange={feedbackOther}
          shouldAutoFocus={false}
          isDisabled={
            viewMode ||
            eachFeedbackDataInStaging.attributeOptions[0] !== 'Other'
          }
        />
      )}
    </Styles.SingleSelectWrapper>
  );
};

export default SingleSelectTypeFeedback;
