import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

export const DownloadPptText = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: var(--rhino);
`;

export const EachFilter = styled.div`
  margin: 22px 0;
`;

export const FilterName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--rhino);
`;

export const PptFilters = styled.div`
  border-bottom: 1px solid var(--athens-gray);

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const DownloadButtonWrapper = styled.div`
  border-top: 1px solid var(--athens-gray);
  padding: 24px;
  position: fixed;
  bottom: 0;
`;

export const DownloadPptButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  width: calc(30vw - 48px);
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const DownloadIconText = styled.span`
  margin-top: 4px;
`;

export const DownloadHeaderWrapper = styled.div`
  padding: 24px;
  border-bottom: 1px solid var(--athens-gray);
`;

export const FiltersWrapper = styled.div`
  padding: 0 24px 24px 24px;
  height: calc(100vh - 200px);
`;

export const PptTypeDropdown = styled.div`
  margin-top: 24px;
`;

export const CollectionClusterDropdown = styled.div`
  margin-top: 24px;
  padding-bottom: 8px;
`;

export const LabelName = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--rhino);
  margin-bottom: 8px;
`;
