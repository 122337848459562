import { Tag } from 'react-tag-autocomplete';

export function getAttributeOptionsInTags(productId: Array<string>) {
  if (productId.length === 0) {
    return [];
  }

  const newCommaSeparatedTagsMap = new Map<String, Tag>();
  productId.forEach(eachTag => {
    const tagObj = {
      id: eachTag,
      name: eachTag,
    };
    newCommaSeparatedTagsMap.set(eachTag, tagObj);
  });

  return Array.from(newCommaSeparatedTagsMap.values());
}
