import React from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import * as Styles from './styles';

interface IFilterSearchBarProps {
  value: number;
  toggleFilter(event: React.MouseEvent<HTMLElement>): void;
  isFilterOpen: boolean;
  placeholder: string;
  width?: number;
}

const FilterSearchBar = ({
  value,
  toggleFilter,
  isFilterOpen,
  placeholder,
  width = 150,
}: IFilterSearchBarProps) => (
  <Styles.MaterialFilterWrapper>
    <Styles.InnerWrapper
      width={width}
      className={`spread ${isFilterOpen ? 'highlight' : ''}`}
      onClick={toggleFilter}
    >
      <Styles.FilterNameAndCountWrapper className="align-center">
        <Styles.FilterNameWrapper className="center">
          <p>{placeholder}</p>
        </Styles.FilterNameWrapper>
        {value > 0 && (
          <Styles.CountWrapper className="center">
            <p>{value}</p>
          </Styles.CountWrapper>
        )}
      </Styles.FilterNameAndCountWrapper>

      <Styles.IconWrapper className="center">
        {isFilterOpen ? <BiChevronUp /> : <BiChevronDown />}
      </Styles.IconWrapper>
    </Styles.InnerWrapper>
  </Styles.MaterialFilterWrapper>
);

export default FilterSearchBar;
