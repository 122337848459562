import { IconPropsType } from 'customTypes/icons';

interface IMagnifyingGlassProps extends IconPropsType {}

const MagnifyingGlass = ({
  size = 12,
  strokeWidth = 1.25,
  stroke = 'var(--rhino)',
  ...props
}: IMagnifyingGlassProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MagnifyingGlass;
