import { customFormatDate } from 'utils/utility/utils';
import { DEFAULT_VALUE } from 'utils/defaults';
import { FilterSlugType } from 'components/AddCatalogList/types';
import { IAdminObject } from 'components/ShareCatalogList/types';

export function modifyFilterTagValue(
  key: FilterSlugType,
  filter: string,
  supplierOptions: Array<any>,
  approvedActiveKeys: Map<string, string>,
  admins: Array<IAdminObject>,
  designers: Array<{ id: string; name: string }>
) {
  let prefix = '';
  let value = '';

  switch (key) {
    case 'supplier_id': {
      value = supplierOptions.filter(
        eachSupplier => eachSupplier.id === Number(filter)
      )[0]?.name;
      break;
    }
    case 'is_approved': {
      value = approvedActiveKeys.get(`${key}=${filter}`) ?? '';
      break;
    }
    case 'is_active': {
      value = approvedActiveKeys.get(`${key}=${filter}`) ?? '';
      break;
    }
    case 'min_price': {
      prefix = 'Min price:';
      value = filter;
      break;
    }
    case 'max_price': {
      prefix = 'Max price:';
      value = filter;
      break;
    }
    case 'min_created_on_timestamp': {
      prefix = 'Start Date:';
      value = customFormatDate(new Date(Number(filter)));
      break;
    }
    case 'max_created_on_timestamp': {
      prefix = 'End Date:';
      value = customFormatDate(new Date(Number(filter)));
      break;
    }
    case 'added_by': {
      const admin = admins.find(eachAdmin => eachAdmin.id === Number(filter));
      prefix = 'Added by:';
      value = admin ? `${admin.first_name} ${admin.last_name}` : DEFAULT_VALUE;
      break;
    }
    case 'designer_id': {
      const designerName = designers.find(
        eachAdmin => eachAdmin.id === filter
      )?.name;
      prefix = 'Designer:';
      value = designerName || DEFAULT_VALUE;
      break;
    }
    default: {
      value = filter;
      break;
    }
  }

  return `${prefix} ${value}`;
}
