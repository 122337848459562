import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { Document, Page } from 'react-pdf';
import { uploadFileAws } from 'services/aws';
import Dropdown from 'components/Dropdown';
import FileUpload from 'components/FileUpload';
import TextAreaInput from 'components/TextAreaInput';
import TagComponent from 'components/TrendingModal/TagComponent';
import {
  useAllCategoryManagerDetail,
  useAllDesignerDetail,
  useAllSaleRepresentativeDetail,
  useFabricTypeData,
  useSeasonsData,
} from 'components/Filters/helper';
import Toast from 'library/Toast';
import DropdownWithTags from 'library/DropdownWithTags';
import PdfWrapperSetup from 'library/PdfWrapperSetup';
import {
  postDesignLibraryData,
  updateDesignLibraryThunk,
} from 'actions/designLibrarys';
import PdfIllustration from 'assets/Illustrations/PdfIllustration';
import ICONOGRAPHY from 'CustomConstant/icons';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import PageLoadingSpinner from '../PageLoadingSpinner';
import {
  DEFAULT_DROPDOWN_PROPS,
  DESCRIPTION_LIMIT,
  INITIAL_STATE,
  TITLE_LIMIT,
} from '../constants';
import SpinnerLoader from '../SpinnerLoader';
import { IDesignLibrary } from '../types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import * as Styles from './styles';

const { close: CloseIcon, upload: UploadIcon } = ICONOGRAPHY;

interface IAddDesignProps {
  onClose: () => void;
  rowId: number | null;
}

const AddDesign = ({ onClose, rowId }: IAddDesignProps) => {
  const dispatch = useDispatch();
  const designCategories = useTypedSelector(
    state => state.catalog.filters.category
  );
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState(1);
  const designMaterials = useTypedSelector(
    state => state.catalog.filters.material
  );
  const credentials = useTypedSelector(
    store => store.awsCredentials.catalogExcel
  );
  const isSaveDesignLoading = useTypedSelector(
    state => state.designLibrary.isSaveDesignSuccessful
  );
  const designLibraryData =
    useTypedSelector(state => state.designLibrary.designLibraryData) ?? [];
  const [designForm, setDesignForm] = useState<IDesignLibrary>({
    ...INITIAL_STATE,
  });
  const [isUploadingFile, setIsUploadingFile] = useBoolean();
  const saleRepresentative = useAllSaleRepresentativeDetail();
  const categoryManager = useAllCategoryManagerDetail();
  const seasonsData = useSeasonsData();
  const fabricTypeData = useFabricTypeData();
  const designerData = useAllDesignerDetail();
  const brandsList = useTypedSelector(state => state.catalog.brands);
  const [selectedBrands, setSelectedBrands] = useState<Array<Tag>>([]);
  const [selectedMaterialValues, setSelectedMaterialValues] = useState<
    Array<string>
  >([]);
  const [selectedMaterials, setSelectedMaterials] = useState<Array<Tag>>([]);
  const [designTags, setDesignTags] = useState<Array<Tag>>([]);
  const [brandTags, setBrandTags] = useState<Array<Tag>>([]);
  const [uploadedPdf, setUploadedPdf] = useState<string>('');

  useEffect(() => {
    if (rowId) {
      let prefillData = {} as IDesignLibrary;
      if (designLibraryData) {
        prefillData = designLibraryData.filter(each => each.id === rowId)[0];
      }
      setDesignForm(prefillData);
      setUploadedPdf(prefillData.pdf_url);
      if (prefillData.material && prefillData.material[0] !== null) {
        let prefillMaterial =
          prefillData?.material?.map(each => {
            return {
              id: each,
              name: each,
            };
          }) ?? [];
        setSelectedMaterials(prefillMaterial);
      } else {
        setSelectedMaterials([]);
      }
      if (prefillData.brand_ids && prefillData.brand_ids[0] !== null) {
        let prefillBrands =
          prefillData?.brand_ids?.map(each => {
            return {
              id: each,
              name: each,
            };
          }) ?? [];
        setBrandTags(prefillBrands);
      } else {
        setBrandTags([]);
      }
      if (prefillData.tags && prefillData.tags[0] !== null) {
        let prefillTags =
          prefillData?.tags?.map(each => {
            return {
              id: each,
              name: each,
            };
          }) ?? [];
        setDesignTags(prefillTags);
      } else {
        setDesignTags([]);
      }
    } else {
      setDesignForm({ ...INITIAL_STATE });
      setSelectedBrands([]);
      setSelectedMaterials([]);
      setDesignTags([]);
      setBrandTags([]);
    }
  }, [rowId]);

  function handleTitleInput(value: string) {
    if (value.length <= TITLE_LIMIT) {
      handleDesignFormChange('name', value);
    }
  }

  function handleDescriptionInput(value: string) {
    if (value.length <= DESCRIPTION_LIMIT) {
      handleDesignFormChange('description', value);
    }
  }

  function handleDesignFormChange(
    key: string,
    value: string | Array<number> | number | Array<string>
  ) {
    setDesignForm(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }

  function handleDesignFormSave() {
    if (designForm.pdf_url === '') {
      Toast.ERROR('Please upload Pdf');
      return;
    }
    if (rowId) {
      dispatch(updateDesignLibraryThunk(designForm));
    } else {
      dispatch(postDesignLibraryData(designForm));
      setDesignForm({ ...INITIAL_STATE });
      setSelectedMaterials([]);
      setDesignTags([]);
      setBrandTags([]);
      setUploadedPdf('');
    }
  }

  function handleMaterialSelection(id: string) {
    if (
      selectedMaterials.some(eachMaterial => eachMaterial.id === Number(id))
    ) {
      return;
    }

    const materialName =
      designMaterials.find(eachMaterial => eachMaterial.id === Number(id))
        ?.name ?? '';

    setSelectedMaterialValues(prev => {
      handleDesignFormChange('material', [...prev, materialName]);
      return [...prev, materialName];
    });
    const selectedMaterial = {
      id: Number(id),
      name: materialName,
    };
    setSelectedMaterials([...selectedMaterials, selectedMaterial]);
  }

  function handleMaterialTagDelete(tag: Tag) {
    const filteredSelectedTags = selectedMaterials.filter(
      eachMaterial => eachMaterial.id !== tag.id
    );
    const modifiedNames = filteredSelectedTags.map(each => each.name);
    setSelectedMaterialValues(modifiedNames);
    setSelectedMaterials(filteredSelectedTags);
  }

  function onTagAddition(tag: Tag) {
    let productIdsString = tag.name;
    if (productIdsString.startsWith(',')) {
      productIdsString = productIdsString.slice(1);
    }
    if (productIdsString.endsWith(',')) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }
    const newTags = productIdsString.split(',');
    const newCommaSeparatedTagsMap = new Map<String, Tag>();
    newTags.forEach(eachTag => {
      const tagObj = {
        id: eachTag.trim(),
        name: eachTag.trim(),
      };
      newCommaSeparatedTagsMap.set(eachTag, tagObj);
    });
    setDesignTags([
      ...designTags,
      ...Array.from(newCommaSeparatedTagsMap.values()),
    ]);
    const modifiedIds = [
      ...designTags,
      ...Array.from(newCommaSeparatedTagsMap.values()),
    ].map(each => each.name);

    handleDesignFormChange('tags', modifiedIds);
  }

  function onBrandAddition(tag: Tag) {
    let productIdsString = tag.name;
    if (productIdsString.startsWith(',')) {
      productIdsString = productIdsString.slice(1);
    }
    if (productIdsString.endsWith(',')) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }
    const newTags = productIdsString.split(',');
    const newCommaSeparatedTagsMap = new Map<String, Tag>();
    newTags.forEach(eachTag => {
      const tagObj = {
        id: eachTag.trim(),
        name: eachTag.trim(),
      };
      newCommaSeparatedTagsMap.set(eachTag, tagObj);
    });
    setBrandTags([
      ...brandTags,
      ...Array.from(newCommaSeparatedTagsMap.values()),
    ]);
    const modifiedIds = [
      ...brandTags,
      ...Array.from(newCommaSeparatedTagsMap.values()),
    ].map(each => each.name);

    handleDesignFormChange('brand_ids', modifiedIds);
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = designTags[tagIndex];
    const newCollectionProductsIds = designTags.filter(
      eachProduct => eachProduct.id !== tagToDelete.id
    );
    const modifiedIds = newCollectionProductsIds.map(each => each.name);
    handleDesignFormChange('tags', modifiedIds);
    setDesignTags(newCollectionProductsIds);
  }

  function onBrandDeletion(tagIndex: number) {
    const tagToDelete = brandTags[tagIndex];
    const newCollectionProductsIds = brandTags.filter(
      eachProduct => eachProduct.id !== tagToDelete.id
    );
    const modifiedIds = newCollectionProductsIds.map(each => each.name);
    handleDesignFormChange('brand_ids', modifiedIds);
    setBrandTags(newCollectionProductsIds);
  }

  function handleSideDrawerClose() {
    onClose();
    setIsUploadingFile.off();
    setDesignForm({ ...INITIAL_STATE });
    setUploadedPdf('');
  }

  async function fileUploadHandler(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files === null) {
      return;
    }
    setIsUploadingFile.on();
    const file = files[0];
    if (file.size > 52428800) {
      Toast.ERROR('Exceeded maximum file size limit');
      setIsUploadingFile.off();
      return;
    }
    try {
      const response = await uploadFileAws({
        file,
        credentials,
        key: `design-library/pdf/${file.name}`,
      });
      setIsUploadingFile.off();
      handleDesignFormChange('pdf_url', response.url);
      setUploadedPdf(response.url);
    } catch (error) {
      setIsUploadingFile.off();
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  }

  return (
    <div className="full-height v-d-flex">
      <Styles.Header className="spread">
        <Styles.Title>{rowId ? 'Edit design' : 'Add design'}</Styles.Title>
        <Styles.CloseWrapper className="center cursor-pointer">
          <CloseIcon onClick={handleSideDrawerClose} />
        </Styles.CloseWrapper>
      </Styles.Header>
      <Styles.BodyWrapper className="d-flex">
        <Styles.UploadSection className="full-height">
          <Styles.UploadWrapper className="has-scroll">
            {(uploadedPdf.length === 0 || isUploadingFile) && (
              <Styles.UploadModal className="v-center pos-r">
                <PdfIllustration />
                <Styles.PdfText>Upload .pdf file only</Styles.PdfText>
                <Styles.InformationText className="center">
                  ** Maximum file size can be 50MB
                </Styles.InformationText>
                <FileUpload
                  accept=".pdf"
                  onChange={event => fileUploadHandler(event)}
                  className="upload-excel-button file-upload center cursor-pointer"
                >
                  {isUploadingFile ? (
                    <Styles.UploadSpinnerWrapper className="pos-a">
                      <SpinnerLoader />
                    </Styles.UploadSpinnerWrapper>
                  ) : (
                    <UploadIcon stroke="var(--white)" />
                  )}
                  <Styles.UploadText>Upload</Styles.UploadText>
                </FileUpload>
                <Styles.ExtraInfo>
                  File size is more than 50MB?
                  <Styles.Compress
                    href="https://smallpdf.com/compress-pdf"
                    target="_blank"
                  >
                    Compress Now!
                  </Styles.Compress>
                </Styles.ExtraInfo>
              </Styles.UploadModal>
            )}
            {uploadedPdf.length > 0 && (
              <Styles.PdfWrapper>
                <PdfWrapperSetup>
                  <Document
                    file={uploadedPdf}
                    onLoadSuccess={({ numPages }: any) => setNumPages(numPages)}
                    loading={<PageLoadingSpinner />}
                    className="pdf-upload"
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map(page => (
                        <Page
                          pageNumber={page}
                          loading={<PageLoadingSpinner />}
                          renderTextLayer
                          renderAnnotationLayer
                          devicePixelRatio={window.devicePixelRatio}
                        />
                      ))}
                  </Document>
                </PdfWrapperSetup>
              </Styles.PdfWrapper>
            )}
          </Styles.UploadWrapper>
        </Styles.UploadSection>
        <Styles.DesignFormWrapper className="fit-height">
          <Styles.SideWrapper>
            <Styles.FormWrapper>
              <Styles.FormTitle>Product details</Styles.FormTitle>
              <Styles.CollectionName>
                <TextAreaInput
                  inputHeight={40}
                  label="Collection name"
                  placeholder="Enter title"
                  onChange={handleTitleInput}
                  characterCount={TITLE_LIMIT}
                  value={designForm.name}
                />
              </Styles.CollectionName>
              <Styles.CategoryDropdown>
                <Styles.LabelName>Category</Styles.LabelName>
                <Dropdown
                  options={designCategories}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleDesignFormChange(
                      'category',
                      Number(event.target.value)
                    )
                  }
                  value={designForm.category}
                  dataname="category"
                  hideAllOption
                  {...DEFAULT_DROPDOWN_PROPS}
                  isSearchBox={false}
                />
              </Styles.CategoryDropdown>
              <Styles.CategoryDropdown>
                <Styles.LabelName>Season</Styles.LabelName>
                <Dropdown
                  options={seasonsData}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleDesignFormChange('season', event.target.value)
                  }
                  value={designForm.season}
                  dataname="season"
                  hideAllOption
                  {...DEFAULT_DROPDOWN_PROPS}
                  isSearchBox={false}
                />
              </Styles.CategoryDropdown>
              <Styles.CategoryDropdown>
                <DropdownWithTags
                  label="Material"
                  options={designMaterials}
                  onChange={handleMaterialSelection}
                  selectedOptions={selectedMaterials}
                  handleTagDelete={handleMaterialTagDelete}
                  isSearchBox={false}
                  hideAllOption={true}
                />
              </Styles.CategoryDropdown>
              <Styles.CategoryDropdown>
                <Styles.LabelName>Fabric Type</Styles.LabelName>
                <Dropdown
                  options={fabricTypeData}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleDesignFormChange('fabric_type', event.target.value)
                  }
                  value={designForm.fabric_type}
                  dataname="fabric_type"
                  hideAllOption
                  {...DEFAULT_DROPDOWN_PROPS}
                  isSearchBox={false}
                />
              </Styles.CategoryDropdown>
              <Styles.CollectionName>
                <TextAreaInput
                  inputHeight={80}
                  label="Collection description"
                  placeholder="Enter description"
                  onChange={handleDescriptionInput}
                  characterCount={DESCRIPTION_LIMIT}
                  value={designForm.description}
                />
              </Styles.CollectionName>
              <Styles.TagsInput className="pos-r">
                <Styles.LabelName>Tags</Styles.LabelName>
                <ReactTags
                  tags={designTags}
                  tagComponent={TagComponent}
                  onAddition={onTagAddition}
                  onDelete={onTagDeletion}
                  addOnBlur
                  allowNew
                  placeholderText="Add Tags..."
                />
              </Styles.TagsInput>
              <Styles.FormTitle>Other details</Styles.FormTitle>
              <Styles.TagsInput className="pos-r">
                <Styles.LabelName>Brands</Styles.LabelName>
                <ReactTags
                  tags={brandTags}
                  tagComponent={TagComponent}
                  onAddition={onBrandAddition}
                  onDelete={onBrandDeletion}
                  addOnBlur
                  allowNew
                  placeholderText="Add Brands..."
                />
              </Styles.TagsInput>
              <Styles.CategoryDropdown>
                <Styles.LabelName>Sales representative</Styles.LabelName>
                <Dropdown
                  options={saleRepresentative}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleDesignFormChange(
                      'sales_representative',
                      Number(event.target.value)
                    )
                  }
                  value={designForm.sales_representative}
                  hideAllOption
                  dataname="sales_representative"
                  {...DEFAULT_DROPDOWN_PROPS}
                  isSearchBox={false}
                />
              </Styles.CategoryDropdown>
              <Styles.CategoryDropdown>
                <Styles.LabelName>Category Manager</Styles.LabelName>
                <Dropdown
                  options={categoryManager}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleDesignFormChange(
                      'category_manager',
                      Number(event.target.value)
                    )
                  }
                  value={designForm.category_manager}
                  dataname="categoryManager"
                  hideAllOption
                  {...DEFAULT_DROPDOWN_PROPS}
                  isSearchBox={false}
                />
              </Styles.CategoryDropdown>
              <Styles.CategoryDropdown>
                <Styles.LabelName>Designer</Styles.LabelName>
                <Dropdown
                  options={designerData}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleDesignFormChange(
                      'designer',
                      Number(event.target.value)
                    )
                  }
                  value={designForm.designer}
                  dataname="designer"
                  hideAllOption
                  {...DEFAULT_DROPDOWN_PROPS}
                  isSearchBox={false}
                />
              </Styles.CategoryDropdown>
            </Styles.FormWrapper>
            <Styles.SaveLibraryButtonWrapper>
              <Styles.SaveButton
                className="full-width"
                onClick={handleDesignFormSave}
              >
                {isSaveDesignLoading ? (
                  <Styles.Spinner />
                ) : (
                  <p>{rowId ? 'Update' : 'Save'}</p>
                )}
              </Styles.SaveButton>
            </Styles.SaveLibraryButtonWrapper>
          </Styles.SideWrapper>
        </Styles.DesignFormWrapper>
      </Styles.BodyWrapper>
    </div>
  );
};

export default AddDesign;
