import { MouseEvent } from 'react';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const { 'chevron-down': CheveronDownIcon, 'chevron-up': CheveronUpIcon } =
  ICONOGRAPHY;

interface IFilterSearchBoxProps {
  value: Array<string>;
  isFilterSearchBarOpen: boolean;
  placeholder: string;
  toggleFilterSearchBox(event: MouseEvent<HTMLElement>): void;
}

const FilterSearchBox = ({
  value,
  isFilterSearchBarOpen,
  placeholder,
  toggleFilterSearchBox,
}: IFilterSearchBoxProps) => (
  <Styles.FilterSearchBoxWrapper
    className={`spread full-width cursor-pointer ${
      isFilterSearchBarOpen ? 'highlight' : ''
    }`}
    onClick={toggleFilterSearchBox}
  >
    <Styles.FilterSearchDisplayed className="align-center no-scroll">
      <Styles.NameWrapper className="center">
        {value.length === 0 && <p> {placeholder}</p>}
        {value.length !== 0 &&
          value.map((eachValue, index) => (
            <>
              {eachValue}
              {index !== value.length - 1 && ', '}
            </>
          ))}
      </Styles.NameWrapper>
    </Styles.FilterSearchDisplayed>
    <Styles.IconWrapper className="center">
      {isFilterSearchBarOpen ? <CheveronUpIcon /> : <CheveronDownIcon />}
    </Styles.IconWrapper>
  </Styles.FilterSearchBoxWrapper>
);

export default FilterSearchBox;
