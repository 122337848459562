import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Toast from 'library/Toast';
import ToggleSwitch from 'components/Common/ToggleSwitch';
import Dropdown from 'components/Dropdown';
import { PptDataType } from 'components/ShareCatalogList/types';
import { downloadSharableLinkPpt } from 'actions/ruleEngines';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import ICONOGRAPHY from 'CustomConstant/icons';
import { DOWNLOAD_PPT_FILTERS, DOWNLOAD_PPT_TYPE } from '../constants';
import * as Styles from './styles';

const { download: DownloadIcon, close: CloseIcon } = ICONOGRAPHY;

interface IDownloadPptSidebar {
  currentRule: number;
  setCurrentRule: (value: number) => void;
  onClose: () => void;
}

const DownloadPptSidebar = ({
  currentRule,
  setCurrentRule,
  onClose,
}: IDownloadPptSidebar) => {
  const dispatch = useDispatch();
  const collectionClusterOptions = useTypedSelector(
    state => state.ruleEngine.collectionClusterData
  );
  const [pptData, setPptData] = useState<PptDataType>({
    show_fabric: true,
    show_fit: true,
    show_wash_type: false,
    show_fabric_type: true,
    show_occasion: false,
    show_gsm: false,
    show_oz: false,
  });
  const [pptTemplate, setPptTemplate] = useState<number>(3);
  const [clusterId, setClusterId] = useState<number | null>(null);
  const [disablePPTDownload, setDisablePPTDownload] = useBoolean();

  function handleCheckChange(key: string, value: boolean) {
    setPptData(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }

  useEffect(() => {
    if (Object.values(pptData).filter(Boolean)?.length > 4) {
      setDisablePPTDownload.on();
      Toast.ERROR('You can select maximum 4 attributes');
    } else {
      setDisablePPTDownload.off();
    }
  }, [pptData]);

  function handlePptDownload(id: number) {
    dispatch(
      downloadSharableLinkPpt({
        ...pptData,
        collection_id: id,
        ppt_template: pptTemplate,
        cluster_id: clusterId ?? null,
      })
    );
    onClose();
  }

  function closeDownloadPptSidebar() {
    onClose();
    setCurrentRule(-1);
  }

  return (
    <>
      <Styles.DownloadHeaderWrapper className="spread">
        <Styles.DownloadPptText>Download as PPT</Styles.DownloadPptText>
        <CloseIcon
          stroke="var(--tertiary-text)"
          className="cursor-pointer"
          onClick={() => closeDownloadPptSidebar()}
        />
      </Styles.DownloadHeaderWrapper>
      <Styles.FiltersWrapper className="has-y-scroll">
        <Styles.PptTypeDropdown>
          <Styles.LabelName>PPT Template Type</Styles.LabelName>
          <Dropdown
            options={DOWNLOAD_PPT_TYPE}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPptTemplate(Number(event.target.value))
            }
            value={pptTemplate}
            dataname="ppt_template"
            hideAllOption
            datalabel=""
            dropdownClass="dropdown-class"
            dropdownBoxClass="dropdownBox-class"
            dropdownItemClass="dropdown-item-class"
            openClass="openClass"
            itemClass="itemClass"
            isSearchBox={false}
          />
        </Styles.PptTypeDropdown>
        <Styles.CollectionClusterDropdown>
          <Styles.LabelName>Cluster</Styles.LabelName>
          <Dropdown
            options={collectionClusterOptions}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setClusterId(Number(event.target.value))
            }
            value={clusterId}
            dataname="cluster_id"
            hideAllOption
            datalabel="Select"
            dropdownClass="dropdown-class"
            dropdownBoxClass="dropdownBox-class"
            dropdownItemClass="dropdown-item-class"
            openClass="openClass"
            itemClass="itemClass"
            isSearchBox={false}
          />
        </Styles.CollectionClusterDropdown>
        {DOWNLOAD_PPT_FILTERS.map(item => {
          return (
            <Styles.PptFilters key={item.id}>
              <Styles.EachFilter className="spread">
                <Styles.FilterName>{item.name}</Styles.FilterName>
                <ToggleSwitch
                  id={item.id}
                  name={item.id}
                  checked={pptData[item.id]}
                  onChange={() => handleCheckChange(item.id, !pptData[item.id])}
                />
              </Styles.EachFilter>
            </Styles.PptFilters>
          );
        })}
      </Styles.FiltersWrapper>
      <Styles.DownloadButtonWrapper>
        <Styles.DownloadPptButton
          disabled={disablePPTDownload}
          className="center"
          onClick={() => handlePptDownload(currentRule)}
        >
          Download as PPT&nbsp;
          <Styles.DownloadIconText className="center">
            <DownloadIcon stroke="var(--white)" />
          </Styles.DownloadIconText>
        </Styles.DownloadPptButton>
      </Styles.DownloadButtonWrapper>
    </>
  );
};

export default DownloadPptSidebar;
