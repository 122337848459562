import { MouseEvent } from 'react';
import { Tag } from 'react-tag-autocomplete';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const { 'chevron-down': CheveronDownIcon, 'chevron-up': CheveronUpIcon } =
  ICONOGRAPHY;

interface IDropDownBarProps {
  value: Array<Tag>;
  isDropDownOpen: boolean;
  placeholder: string;
  isDisabled: boolean;
  toggleDropDown(event: MouseEvent<HTMLElement>): void;
}

const DropDownBar = ({
  value,
  isDropDownOpen,
  placeholder,
  isDisabled = false,
  toggleDropDown,
}: IDropDownBarProps) => (
  <Styles.MultiDropDownWrapper
    isDisabled={isDisabled}
    className={`spread full-width cursor-pointer ${
      isDropDownOpen ? 'highlight' : ''
    }`}
    onClick={toggleDropDown}
  >
    <Styles.AttributeDisplayed className="align-center">
      <Styles.NameWrapper className="center">
        {value.length === 0 && <p> {placeholder}</p>}
        {value.length !== 0 &&
          value.map((eachValue, index) => (
            <>
              {eachValue}
              {index !== value.length - 1 && ', '}
            </>
          ))}
      </Styles.NameWrapper>
    </Styles.AttributeDisplayed>
    <Styles.IconWrapper className="center">
      {isDropDownOpen ? <CheveronUpIcon /> : <CheveronDownIcon />}
    </Styles.IconWrapper>
  </Styles.MultiDropDownWrapper>
);

export default DropDownBar;
