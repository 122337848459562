const nsp = 'imageLink/';

const IMAGE_LINK_ACTION_TYPES = {
  FETCH_UPLOADED_IMAGE_LINKS_REQUEST: `${nsp}FETCH_UPLOADED_IMAGE_LINKS_REQUEST`,
  FETCH_UPLOADED_IMAGE_LINKS_SUCCESS: `${nsp}FETCH_UPLOADED_IMAGE_LINKS_SUCCESS`,
  FETCH_UPLOADED_IMAGE_LINKS_FAILURE: `${nsp}FETCH_UPLOADED_IMAGE_LINKS_FAILURE`,
  UPDATE_UPLOADED_IMAGES_LINKS_REQUEST: `${nsp}UPDATE_UPLOADED_IMAGES_LINKS_REQUEST`,
  UPDATE_UPLOADED_IMAGES_LINKS_SUCCESS: `${nsp}UPDATE_UPLOADED_IMAGES_LINKS_SUCCESS`,
  UPDATE_UPLOADED_IMAGES_LINKS_FAILURE: `${nsp}UPDATE_UPLOADED_IMAGES_LINKS_FAILURE`,
  RESET_UPLOADED_IMAGES: `${nsp}RESET_UPLOADED_IMAGES`,
  SET_IMAGES_IN_UPLOAD_QUEUE: `${nsp}SET_IMAGES_IN_UPLOAD_QUEUE`,
  SET_REJECTED_IMAGES: `${nsp}SET_REJECTED_IMAGES`,
  SET_SELECTED_SUPPLIER: `${nsp}SET_SELECTED_SUPPLIER`,
  SET_CURRENT_PAGE: `${nsp}SET_CURRENT_PAGE`,
};

export default IMAGE_LINK_ACTION_TYPES;
