import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

export const Wrapper = styled.div`
  padding: 16px 32px 100px;
  background-color: var(--input-disabled);

  header {
    justify-content: flex-start !important;
    padding-left: 16px;
  }

  .body-content {
    height: 160px !important;
  }
`;

export const DesignCardsWrapper = styled.div`
  background-color: var(--white);
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  padding: 24px;
  gap: 24px;
  min-height: 90vh;
`;

export const CardWrapper = styled.div`
  min-width: 280px;
  width: 280px;
  transition: all 0.2s ease-out;
  border-radius: 4px;
  flex-basis: 23%;

  &:hover {
    box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  }
`;

export const ActionsWrapper = styled.div`
  visibility: hidden;
  padding: 8px;
  background: linear-gradient(
    0deg,
    rgba(12, 16, 33, 0.7),
    rgba(12, 16, 33, 0.7)
  );
  opacity: 0.1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  transition: opacity 0.3s ease-in-out;
`;

export const ThumbnailWrapper = styled.div`
  height: 180px;

  img {
    object-fit: cover;
    width: 310px;
  }

  &:hover ${ActionsWrapper} {
    visibility: visible;
    opacity: 1;
  }
`;

export const DownloadWrapper = styled.a`
  padding: 4px;
  margin-top: 5px;
`;

export const TrashIconWrapper = styled.div`
  padding: 4px;
`;

export const EditIconWrapper = styled.div`
  padding: 4px;
`;

export const ActionsSection = styled.div`
  height: 36px;
`;

export const CardInformationWrapper = styled.div`
  padding: 24px;
`;

export const SeasonText = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: var(--rhino);
  margin-bottom: 16px;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--rhino);
`;

export const Action = styled.a`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--rhino);
  text-decoration: none;
`;

export const AddDesignButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Subtle,
}))`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  top: 13px;
  right: 42px;
  padding: 6px 16px;
  border: 1px solid var(--loblolly);
  margin-top: 3px;

  &,
  &:focus {
    background-color: var(--white) !important;
  }
`;

export const AddDesign = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 6px 16px;
`;

export const ExploreNowButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Tertiary,
}))`
  font-size: 14px;
  line-height: 22px;
  top: 5px;
  right: 42px;
  padding: 8px;
  border: none;
  padding-left: 0;

  &,
  &:hover {
    color: var(--rhino);
  }
`;

export const AddNewDesignWrapper = styled.div`
  height: calc(100vh - 100px);
`;

export const NoDesignText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: var(--rhino);
  margin: 32px 0;
`;

export const ConfirmButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  padding: 9px 24px;
  font-weight: 600;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
`;

export const SureText = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--rhino);
  margin-bottom: 8px;
`;

export const SureDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
`;
