import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  .ms-container {
    padding: 20px 16px;
    box-shadow: 0 32px 64px rgb(44 53 86 / 25%);
    background-color: var(--white);
  }

  .ms-header {
    margin-bottom: 8px;
  }

  .ms-header-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--rhino);
  }

  .ms-header-clear {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: var(--watermelon);
  }

  .ms-search-bar {
    padding: 5px 8px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid var(--very-light-pink);
  }

  .ms-search-input {
    border: none;
    margin-left: 4px;
    color: var(--rhino);

    &:focus {
      outline: none;
    }
  }

  .ms-options-list {
    height: 180px;
    margin-bottom: 32px;
  }

  .ms-option {
    margin: 0 20px 10px 0;

    label {
      justify-content: flex-start;

      p {
        font-size: 12px;
      }
    }
  }

  .ms-button-container {
    bottom: 0;
    left: 0;
    padding: 8px 16px;
    background-color: white;
  }

  .ms-button-cancel {
    padding: 8px 16px;
    background-color: var(--white);
    border: 1px solid var(--watermelon);
    border-radius: 4px;
    color: var(--watermelon);
    font-weight: 500;
  }

  .ms-button-apply {
    padding: 8px 16px;
    background-color: var(--watermelon);
    border-radius: 4px;
    color: var(--white);
    border: none;
    font-weight: 500;
  }
`;
