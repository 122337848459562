import React from 'react';
import { BsUpload } from 'react-icons/bs';
import { BiFileBlank } from 'react-icons/bi';
import { useAllDesignerDetail } from 'components/Filters/helper';
import DesignersFilter from 'components/Filters/MoreFilterModal/DesignersFilter';
import FilterSearchBox from 'components/Filters/CreatedByFilter/FilterSearchBox';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import LocationFilter from 'components/Filters/MoreFilterModal/LocationFilter';
import { useBoolean } from 'utils/hooks/useBoolean';
import { CATALOG_SHEET_FILE_TYPES } from '../constant';
import FileUpload from '../../FileUpload';
import Dropdown from '../../Dropdown';
import { useTypedSelector } from '../../../utils/hooks';
import { AttachmentType } from '../../../utils/types';
import * as Styles from './styles';

interface IUploadExcelProps {
  handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    field?: string,
    data?: Array<AttachmentType>
  ): void;
  selectedSupplier: string | number;
  setSelectedLocations: (item: string) => void;
  selectedLocations: Array<string>;
  setSelectedDesigner: (item: string) => void;
  selectedDesigner: Array<string>;
}

const UploadExcel = ({
  handleChange,
  selectedSupplier,
  setSelectedLocations,
  selectedLocations,
  setSelectedDesigner,
  selectedDesigner,
}: IUploadExcelProps) => {
  const allSuppliers = useTypedSelector(
    store => store.catalog.filters.supplier
  );
  const isSupplier = useTypedSelector(store => store.user.is_supplier);
  const [isLocationFilterOpen, locationFilterAction] = useBoolean();
  const designersData = useAllDesignerDetail();

  return (
    <Styles.UploadExcel className="center">
      <BiFileBlank size="4em" fill="var(--pacific-blue)" />
      <Styles.SecondaryText>
        Upload {CATALOG_SHEET_FILE_TYPES.join(', ')} files
      </Styles.SecondaryText>
      {!isSupplier && (
        <div className="d-flex center">
          <div className="v-d-flex">
            <Styles.HeadingText>Supplier</Styles.HeadingText>
            <Dropdown
              options={allSuppliers}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, 'selectUploadSheetCategory')
              }
              value={selectedSupplier}
              dataname={'type'}
              datalabel="Select Supplier"
              dropdownClass="dropdown-class"
              dropdownBoxClass="dropdownBox-class"
              dropdownItemClass="dropdown-item-class"
              openClass="openClass"
              itemClass="itemClass "
              isSearchBox
              hideAllOption
            />
          </div>
          <Styles.LocationWrapper
            className="pos-r full-height"
            onClick={e => e.stopPropagation()}
          >
            <Styles.HeadingText>Location</Styles.HeadingText>
            <FilterSearchBox
              value={selectedLocations}
              isFilterSearchBarOpen={isLocationFilterOpen}
              toggleFilterSearchBox={() => locationFilterAction.toggle()}
              placeholder="Select Location"
            />
            {isLocationFilterOpen && (
              <ClickOutside
                onClose={() => locationFilterAction.off()}
                disableClickHandler={false}
              >
                <LocationFilter
                  selectedLocations={selectedLocations}
                  onChange={setSelectedLocations}
                />
              </ClickOutside>
            )}
          </Styles.LocationWrapper>
          <div className="v-d-flex">
            <Styles.HeadingText>Designer</Styles.HeadingText>
            <Dropdown
              options={designersData}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedDesigner(e.target.value)
              }
              value={selectedDesigner}
              dataname={'designer_id'}
              datalabel="Select Designer"
              dropdownClass="dropdown-class"
              dropdownBoxClass="dropdownBox-class"
              dropdownItemClass="dropdown-item-class"
              openClass="openClass"
              itemClass="itemClass "
              isSearchBox
              hideAllOption
            />
          </div>
        </div>
      )}
      <Styles.NoteText>
        Note - Selecting supplier from dropdown will override any supplier IDs
        present in the excel sheet
      </Styles.NoteText>
      <FileUpload
        accept={CATALOG_SHEET_FILE_TYPES.join(',')}
        onChange={event => handleChange(event, 'uploadedExcelLink')}
        className="upload-excel-button center"
      >
        <BsUpload fill="var(--white)" className="bs-upload" />
        &nbsp;&nbsp;Upload Excel file
      </FileUpload>
    </Styles.UploadExcel>
  );
};

export default UploadExcel;
