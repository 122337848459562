import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 72vh;
  width: 56vw;
  border-radius: 4px;
  background-color: var(--white);
`;

export const Spinner = styled.span`
  width: 48px;
  height: 48px;
  border: 3px solid var(--watermelon);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
`;
