import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import Dropdown from 'components/Dropdown';
import ButtonWithLoader from 'components/ButtonWithLoader';
import FashinzaIconLoader from 'library/IconLoader';
import Toast from 'library/Toast';
import { useTypedSelector } from 'utils/hooks';
import {
  changeTrendingCategoryThunk,
  deleteEnteredTagForCategory,
  setEnteredTagsForCategory,
  setSelectedTrendingCategoryData,
  setTrendingCategoryTitle,
  submitTreningCategoryDataThunk,
  toggleTrendingModal,
} from 'actions/catalog';
import TagComponent from './TagComponent';
import * as Styles from './styles';

const MAX_TAGS = 10;

const TrendingModal = () => {
  const categories =
    useTypedSelector(state => state.catalog.filters.category) ?? [];
  const {
    trendingCategoryTitle,
    trendingTags,
    trendingCategory,
    submitTrendingDataLoader,
    isTrendingCategoryLoading,
  } = useTypedSelector(state => state.catalog);
  const dispatch = useDispatch();

  function handleModalClose() {
    dispatch(toggleTrendingModal(false));
  }

  function handleTitleChange(value: string) {
    dispatch(setTrendingCategoryTitle(value));
  }

  function onTagAddition(tag: Tag) {
    if (trendingTags.length === MAX_TAGS) {
      Toast.WARN(`Cannot add more than ${MAX_TAGS} product IDs`);
      return;
    }

    let productIdsString = tag.name;
    if (productIdsString.startsWith(',')) {
      productIdsString = productIdsString.slice(1);
    }
    if (productIdsString.endsWith(',')) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }
    const newTags: Array<string> = productIdsString.split(',');
    const newCommaSeparatedTagsMap = new Map<String, Record<string, string>>();
    newTags.forEach(eachTag => {
      const tagObj = {
        id: eachTag.trim(),
        name: eachTag.trim(),
      };
      newCommaSeparatedTagsMap.set(eachTag, tagObj);
    });
    dispatch(
      setEnteredTagsForCategory([
        ...trendingTags,
        ...Array.from(newCommaSeparatedTagsMap.values()),
      ])
    );
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = trendingTags[tagIndex];
    dispatch(deleteEnteredTagForCategory(tagToDelete));
  }

  function handleCategoryChange(event: ChangeEvent<HTMLInputElement>) {
    const filteredCategory = categories.filter(
      eachCategory => eachCategory.category_code === event.target.value
    );
    const trendingCategory = {
      categoryId: event.target.value,
      name: filteredCategory[0]?.name ?? '',
    };
    dispatch(setSelectedTrendingCategoryData(trendingCategory));
    dispatch(setTrendingCategoryTitle(''));
    dispatch(setEnteredTagsForCategory([]));
    dispatch(changeTrendingCategoryThunk(trendingCategory.categoryId));
  }

  function handleSaveTrending() {
    const trendingTagsMap = new Map<string, string>();
    trendingTags.forEach(eachTag => {
      trendingTagsMap.set(String(eachTag.id), eachTag.name);
    });
    const trendingCategoryData = {
      title: trendingCategoryTitle.trim(),
      product_ids: Array.from(trendingTagsMap.values()),
    };

    dispatch(
      submitTreningCategoryDataThunk(
        trendingCategoryData,
        trendingCategory.categoryId
      )
    );
  }

  return (
    <Styles.TrendingModalContainer>
      <Styles.TrendingModalHeader className="spread">
        <p>Trending Products</p>
        <Styles.CloseIcon onClick={handleModalClose} />
      </Styles.TrendingModalHeader>
      <Styles.InnerContainer>
        <Styles.DropdownWrapper>
          <Styles.DropdownWrapperTitle>Category*</Styles.DropdownWrapperTitle>
          <Dropdown
            options={categories.filter(
              eachCategory => eachCategory.is_leaf_node
            )}
            onChange={handleCategoryChange}
            value={trendingCategory.name}
            dataname="categories"
            hideAllOption
            dropdownClass="dropdown-class"
            dropdownBoxClass="dropdownBox-class"
            dropdownItemClass="dropdown-item-class"
            openClass="openClass"
            itemClass="itemClass"
            placeholder="Select Category"
          />
        </Styles.DropdownWrapper>
        {isTrendingCategoryLoading ? (
          <Styles.ThreeDotsLoaderContainer>
            <FashinzaIconLoader show />
          </Styles.ThreeDotsLoaderContainer>
        ) : (
          <>
            <Styles.TitleText>
              <Styles.DropdownWrapperTitle>Title*</Styles.DropdownWrapperTitle>
              <input
                value={trendingCategoryTitle}
                type="text"
                placeholder="Give custom title"
                onChange={event => handleTitleChange(event.target.value)}
              />
            </Styles.TitleText>
            <Styles.ReactTagsWrapper>
              <Styles.DropdownWrapperTitle>
                {`Add product ID (maximum ${MAX_TAGS} allowed)`}
              </Styles.DropdownWrapperTitle>
              <ReactTags
                tags={trendingTags}
                tagComponent={TagComponent}
                onAddition={onTagAddition}
                onDelete={onTagDeletion}
                addOnBlur
                allowNew
                placeholderText="Product ID"
              />
              <Styles.TagContainerBottomText>
                Enter a comma (,) after each tag
              </Styles.TagContainerBottomText>
            </Styles.ReactTagsWrapper>
          </>
        )}
      </Styles.InnerContainer>
      <Styles.TrendingFooter className="d-flex">
        <ButtonWithLoader
          text="Save Trending"
          onClick={handleSaveTrending}
          showLoader={submitTrendingDataLoader}
          isDisabled={submitTrendingDataLoader || isTrendingCategoryLoading}
        />
      </Styles.TrendingFooter>
    </Styles.TrendingModalContainer>
  );
};

export default TrendingModal;
