export enum OperatorEnum {
  Modify = 'MODIFY',
  Append = 'APPEND',
}

export enum ModifyEnum {
  Add = 'Add',
  Remove = 'Remove',
  MakeCollection = 'make collection',
}

export const STARTING_PAGE_NUM = 1;
