const NoTrendsDataIllustration = () => {
  return (
    <svg
      width={247}
      height={209}
      viewBox="0 0 247 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11012_233517)">
        <path
          d="M8.60477 105.43C23.4948 93.71 46.9148 103.67 63.4448 94.4C73.4648 88.78 78.6348 77.28 81.4348 66.13C84.2348 54.98 85.3948 43.27 90.4248 32.93C95.4548 22.6 105.845 13.65 117.265 14.91C125.805 15.85 132.675 22.08 140.445 25.74C161.425 35.63 187.045 26.22 209.045 33.55C225.225 38.95 237.565 53.24 242.915 69.44C248.265 85.64 247.205 103.46 242.425 119.84C233.615 150.03 211.995 176.26 184.035 190.67C153.875 206.22 117.375 207.75 85.0648 197.39C66.4748 191.43 49.2148 181.68 34.0648 169.41C21.9248 159.57 5.79478 146.55 1.47478 130.82C-0.995225 121.84 0.984774 111.42 8.59477 105.43H8.60477Z"
          fill="#F7F8FA"
        />
        <path
          d="M27.8447 86.6301C32.6247 87.6501 37.8347 86.5001 41.6747 83.4801C46.7947 79.4701 49.1047 72.2101 47.2347 65.9801C43.6447 53.9701 26.0047 50.1301 17.4247 59.2601C8.82472 68.4101 16.6747 84.2601 27.8447 86.6301Z"
          fill="#F7F8FA"
        />
        <path
          d="M64.9049 77.3101C69.0049 78.0301 73.3749 75.3601 74.8049 71.4501C77.4649 64.1501 69.1049 54.9601 61.6149 58.7301C53.9149 62.6101 56.7949 75.8801 64.9049 77.3101Z"
          fill="#F7F8FA"
        />
        <path
          d="M231.495 16.2C233.704 16.2 235.495 14.4091 235.495 12.2C235.495 9.99081 233.704 8.19995 231.495 8.19995C229.286 8.19995 227.495 9.99081 227.495 12.2C227.495 14.4091 229.286 16.2 231.495 16.2Z"
          fill="#E8EAEE"
        />
        <path
          d="M235.305 35.36L227.675 30.96V39.77L235.305 35.36Z"
          stroke="#E8EAEE"
          strokeWidth={0.66}
          strokeMiterlimit={10}
        />
        <path
          d="M235.465 27.5798V19.5798H227.465V27.5798H235.465Z"
          fill="#E8EAEE"
        />
        <path
          d="M215.565 198.15C216.67 198.15 217.565 197.254 217.565 196.15C217.565 195.045 216.67 194.15 215.565 194.15C214.46 194.15 213.565 195.045 213.565 196.15C213.565 197.254 214.46 198.15 215.565 198.15Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.555 156.4V164.4"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.555 160.4H231.555"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4448 184.25C25.6548 184.25 27.4448 182.46 27.4448 180.25C27.4448 178.04 25.6548 176.25 23.4448 176.25C21.2348 176.25 19.4448 178.04 19.4448 180.25C19.4448 182.46 21.2348 184.25 23.4448 184.25Z"
          fill="#E8EAEE"
        />
        <path
          d="M19.6348 203.41L27.2648 199V207.81L19.6348 203.41Z"
          stroke="#E8EAEE"
          strokeWidth={0.66}
          strokeMiterlimit={10}
        />
        <path
          d="M27.4749 195.62V187.62H19.4749V195.62H27.4749Z"
          fill="#E8EAEE"
        />
        <path
          d="M39.5649 8.5C41.7741 8.5 43.5649 6.70914 43.5649 4.5C43.5649 2.29086 41.7741 0.5 39.5649 0.5C37.3558 0.5 35.5649 2.29086 35.5649 4.5C35.5649 6.70914 37.3558 8.5 39.5649 8.5Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4548 22.1699C24.5594 22.1699 25.4548 21.2745 25.4548 20.1699C25.4548 19.0654 24.5594 18.1699 23.4548 18.1699C22.3503 18.1699 21.4548 19.0654 21.4548 20.1699C21.4548 21.2745 22.3503 22.1699 23.4548 22.1699Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.375 5.37997C114.735 10.96 113.065 21.67 118.645 29.31C120.155 31.37 122.035 33 124.125 34.16C123.655 36.04 122.365 38.2 121.235 39.21C124.485 40.89 128.895 38.07 131.195 36.28C135.115 36.58 139.155 35.53 142.575 33.03C150.215 27.45 151.885 16.74 146.305 9.09997C140.725 1.45997 130.015 -0.210033 122.375 5.36997V5.37997Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M130.275 22.47V21.98C130.275 20.18 130.995 18.85 132.985 17.08C134.105 16.08 134.435 15.27 134.435 14.32C134.435 13.29 133.845 12.46 132.545 12.46C131.155 12.46 130.605 13.34 130.415 14.49C130.385 14.67 130.235 14.81 130.055 14.81H126.985C126.765 14.81 126.595 14.63 126.615 14.41C126.885 12.2 128.405 9.45999 132.615 9.48999C137.035 9.50999 138.325 12.37 138.325 14.46C138.325 16.15 137.775 17.32 135.825 19.12C134.675 20.2 134.285 21.08 134.285 22.19V22.47H130.255H130.275ZM130.275 26.31V25.87C130.275 24.76 131.175 23.86 132.285 23.86C133.395 23.86 134.295 24.76 134.295 25.87V26.31C134.295 27.42 133.395 28.32 132.285 28.32C131.175 28.32 130.275 27.42 130.275 26.31Z"
          fill="#FFA100"
        />
        <path
          d="M161.365 62.0901L155.495 67.5201"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M157.535 69.72L164.605 69.99"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M153.455 65.32L153.725 58.26"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M160.455 122.89C166.815 122.77 171.945 116.34 171.755 109.99C171.565 103.64 166.975 97.9501 161.235 95.2101C155.495 92.4701 148.825 92.2801 142.545 93.3001C136.265 94.3201 130.235 96.4701 124.125 98.2401C108.625 102.73 92.4148 104.78 76.2848 104.29C71.9848 104.16 66.9248 104.19 64.3748 107.66C61.5448 111.51 64.3648 117.66 68.9448 119.01C73.5248 120.36 78.6148 117.7 81.2348 113.7C83.8548 109.7 84.4148 104.68 84.1648 99.9101C83.9148 95.1401 82.9048 90.4201 82.6948 85.6501C82.2348 75.2901 85.6548 64.8001 92.1248 56.7001C98.5948 48.6001 108.065 42.9501 118.265 41.1001"
          stroke="#FFA100"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M158.715 137.31C158.465 145.85 157.115 150.97 142.995 150.83C131.995 150.72 128.765 150.71 128.585 150.71C128.375 150.71 125.125 150.71 114.165 150.83C100.045 150.96 98.6948 145.84 98.4448 137.31C98.6448 123.38 113.425 111.6 113.545 104.42C113.795 95.6301 107.855 86.7201 103.765 80.7801C103.705 80.6901 103.655 80.6101 103.605 80.5201C101.025 76.2601 103.095 70.9701 107.635 68.9201C111.665 67.0901 117.105 64.3801 119.485 63.0401C120.195 62.6401 120.645 61.8801 120.635 61.0501C120.635 59.5301 120.965 57.1301 123.115 57.1201C126.145 57.1201 128.425 57.1201 128.585 57.1201C128.745 57.1201 131.035 57.1201 134.055 57.1201C136.205 57.1201 136.545 59.5301 136.535 61.0501C136.535 61.8801 136.965 62.6301 137.685 63.0401C140.055 64.3701 145.495 67.0801 149.535 68.9201C154.065 70.9801 156.145 76.2601 153.565 80.5201C153.505 80.6101 153.465 80.6901 153.405 80.7801C149.315 86.7201 143.375 95.6301 143.625 104.42C143.755 111.6 158.525 123.38 158.725 137.31H158.715Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M128.575 150.79V184.38"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M116.565 184.34H140.585"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M170.608 146.953C169.582 145.494 167.569 145.141 166.112 146.166C164.655 147.19 164.306 149.204 165.332 150.663L178.146 168.889C179.172 170.348 181.185 170.701 182.642 169.677C184.099 168.652 184.448 166.639 183.422 165.179L170.608 146.953Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M160.848 153.973C172.473 151.372 179.789 139.84 177.188 128.214C174.588 116.589 163.055 109.273 151.43 111.874C139.804 114.474 132.488 126.007 135.089 137.632C137.69 149.258 149.222 156.574 160.848 153.973Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M156.135 149.5C165.292 149.5 172.715 142.077 172.715 132.92C172.715 123.763 165.292 116.34 156.135 116.34C146.978 116.34 139.555 123.763 139.555 132.92C139.555 142.077 146.978 149.5 156.135 149.5Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M151.245 132.89C152.09 132.89 152.775 132.205 152.775 131.36C152.775 130.515 152.09 129.83 151.245 129.83C150.4 129.83 149.715 130.515 149.715 131.36C149.715 132.205 150.4 132.89 151.245 132.89Z"
          fill="#929BAA"
        />
        <path
          d="M160.435 132.8C161.28 132.8 161.965 132.115 161.965 131.27C161.965 130.425 161.28 129.74 160.435 129.74C159.59 129.74 158.905 130.425 158.905 131.27C158.905 132.115 159.59 132.8 160.435 132.8Z"
          fill="#929BAA"
        />
        <path
          d="M152.815 136.26C152.815 136.26 155.765 133.91 158.945 136.2"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11012_233517">
          <rect
            width={246.03}
            height={208.38}
            fill="white"
            transform="translate(0.484863)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoTrendsDataIllustration;
