import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from 'utils/hooks';
import { removeMultipleFromArray } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import { modifyFilterTagValue } from './helper';
import { useAllDesignerDetail } from '../helper';
import * as Styles from './styles';

const approvedActiveKeys = new Map<string, string>([
  ['is_approved=true', 'Approved'],
  ['is_approved=false', 'Approval Pending'],
  ['is_active=true', 'Active'],
  ['is_active=false', 'Inactive'],
]);

interface IHeaderFiltersProps {
  onFilterChange(
    params: Partial<Record<FilterSlugType, Array<any> | string | number>>,
    reset: boolean
  ): void;
}

const HeaderFiltersView = ({ onFilterChange }: IHeaderFiltersProps) => {
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const supplierOptions = useTypedSelector(
    state => state.catalog.filters.supplier
  );
  const admins = useTypedSelector(state => state.catalog.admins);
  const designers = useAllDesignerDetail();

  function handleFilterRemove(
    filtersArr: Array<string>,
    value: string,
    filterTag: string
  ) {
    const newFilterArr = removeMultipleFromArray(filtersArr, value);
    onFilterChange({ [filterTag]: newFilterArr }, false);
  }

  return (
    <Styles.TagsWrapper className="align-center flex-wrap">
      {Object.entries(currentQueryParams).length > 0 && (
        <Styles.ClearAllName onClick={() => onFilterChange({}, true)}>
          Clear All
        </Styles.ClearAllName>
      )}
      {Object.entries(currentQueryParams).map((eachPair: any) => {
        if (eachPair[0] === 'Token') {
          return null;
        }

        return (
          <Fragment key={eachPair[0]}>
            {eachPair[1].map((eachValue: string) => {
              return (
                <Styles.Tag key={eachValue} className="align-center">
                  <Styles.TagName>
                    {modifyFilterTagValue(
                      eachPair[0],
                      eachValue,
                      supplierOptions,
                      approvedActiveKeys,
                      admins,
                      designers
                    )}
                  </Styles.TagName>
                  <Styles.Cross
                    onClick={() =>
                      handleFilterRemove(eachPair[1], eachValue, eachPair[0])
                    }
                  />
                </Styles.Tag>
              );
            })}
          </Fragment>
        );
      })}
    </Styles.TagsWrapper>
  );
};

export default HeaderFiltersView;
