import styled from 'styled-components';

export const EditHeader = styled.div`
  padding: 24px;
  height: 10%;
  border-bottom: 1px solid var(--athens-gray);
  p {
    font-size: 20px;
    font-weight: 600;
    color: var(--rhino);
  }
`;

export const EditCollectionBodyContainer = styled.div`
  padding: 24px;
  height: 78%;
  overflow-y: scroll;
`;

export const TextAreaContainer = styled.div`
  padding-bottom: 24px;
`;

export const BrandDropdownLabel = styled.label`
  line-height: 20px;
  font-size: 13px;
  font-weight: 500;
  color: var(--rhino);
  margin-bottom: 4px;
`;

export const BrandsDropdown = styled.div`
  .dropdown2-wrapper {
    width: 100%;
    height: 40px;
    border: 1px solid var(--mischka);
    border-radius: 4px;
    cursor: pointer;
    flex: none;

    span {
      border: none !important;
    }
  }
  .dropdownBox-class {
    padding: 16px 0 !important;
    max-height: 30vh;
    min-width: 100%;

    li div[role='menuitem'] {
      padding: 0 20px !important;
    }

    .dropdownBox-input {
      padding: 0;

      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      padding: 0 !important;
    }
  }

  .dropdown-placeholder {
    padding-right: 240px;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }

  &.modal-dropdown {
    height: fit-content;
    width: 100%;
    .dropdown2-wrapper {
      width: 100%;
    }
  }
`;

export const TagContainer = styled.div`
  background-color: var(--athens-gray);
  padding: 4px 10px;
  border-radius: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  width: fit-content;
  cursor: default;

  p {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
    color: var(--rhino);
  }
`;

export const SvgButton = styled.button`
  all: unset;
  svg {
    cursor: pointer;
    color: var(--tertiary-text);
    font-size: 16px;
  }
`;

export const SelectedBrandsContainer = styled.div`
  margin-top: 8px;
`;

export const ReactTagsWrapper = styled.div`
  margin-top: 12px;
  input {
    height: auto;
  }

  .react-tags {
    cursor: text;
    position: relative;
    width: 100%;
    height: 140px;
    min-height: 48px;
    padding: 16px;
    border-radius: 4px;
    font-size: 14px;
    background: inherit;
    border: 1px solid var(--athens-gray);
    overflow: scroll;
    overflow-x: hidden;
  }

  .react-tags__selected {
    display: flex;
    flex-wrap: wrap;
  }

  .react-tags__selected-tag {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 12px;
    padding: 2px 8px;
    background: var(--athens-gray);
    border-radius: 20px;
    border: none;
    font-size: inherit;
    line-height: inherit;
    span {
      font-size: 12px;
      font-weight: 600;
      color: var(--rhino);
      pointer-events: none;
    }
  }

  .react-tags__selected-tag:after {
    content: 'x';
    font-size: 16px;
    cursor: pointer;
    color: var(--tertiary-text);
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .react-tags__search {
    display: inline-block;
    max-width: 100%;
    padding: 7px 2px;
    margin-bottom: 6px;
  }

  .react-tags__search-input {
    max-width: 100%;
    min-width: 156px;
    height: 100%;
    background-color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
    &:focus {
      border: 0;
      box-shadow: none;
    }
    &::-ms-clear {
      display: none;
    }
    &::placeholder {
      color: var(--manatee);
    }
  }
`;

export const DropdownWrapperTitle = styled.p`
  color: var(--rhino);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const TagContainerBottomText = styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
`;

export const FooterContainer = styled.div`
  height: 12%;
  padding: 24px;
  bottom: 0;
  border-top: 1px solid var(--athens-gray);
`;

export const CopyLinkContainer = styled.div`
  margin-top: 24px;
`;

export const LinkLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  color: var(--manatee);
  line-height: 20px;
  margin-bottom: 4px;
`;

export const CopyLink = styled.div`
  p {
    height: 39px;
    width: 80%;
    padding: 11px 18px;
    color: var(--manatee);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    background-color: var(--input-disabled);
    border-radius: 4px;
    border: 1px solid var(--athens-gray);
  }
  button {
    width: 18%;
    height: 40px;
    border: 1px solid var(--watermelon);
    border-radius: 4px;
    color: var(--watermelon);
    background-color: var(--white);
  }
`;

export const SaveSuccessful = styled.span`
  p {
    color: var(--deep-cerulean);
    font-size: 14px;
    margin-left: 6px;
    font-weight: 600;
  }
`;
