import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(25, 25, 25, 0.5);
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  height: fit-content;
  width: 300px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 18px;
    font-weight: 500;
  }

  button {
    cursor: pointer;
    position: absolute;
    border: none;
    outline: none;
    background: var(--white);
    right: 10px;
  }
`;

export const Body = styled.div`
  padding: 30px 20px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const Footer = styled.footer`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  padding: 0 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
