import Toast from '../library/Toast';
import { IObjectType, IReduxActionType, IRootState } from '../utils/types';
import { getUploadedImagesApi, updateUploadedImagesApi } from '../utils/api';
import IMAGE_LINK_ACTION_TYPES from '../actionTypes/imageLink';

const fetchUploadedImageRequest = (): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.FETCH_UPLOADED_IMAGE_LINKS_REQUEST,
});

const fetchUploadedImageSuccess = (payload: any): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.FETCH_UPLOADED_IMAGE_LINKS_SUCCESS,
  payload,
});

const fetchUploadedImageFailure = (): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.FETCH_UPLOADED_IMAGE_LINKS_FAILURE,
});

export const fetchUploadedImageThunk =
  (id: number | string, currentPage: number | string) =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    const totalPages = getState().imageLinks.totalPages;
    if (totalPages < currentPage) return;
    dispatch(fetchUploadedImageRequest());
    try {
      const response = await getUploadedImagesApi(id, currentPage);
      if (!response.status) {
        dispatch(fetchUploadedImageFailure());
        return;
      }
      dispatch(fetchUploadedImageSuccess(response.data));
    } catch (err) {
      dispatch(fetchUploadedImageFailure());
      if (err instanceof Error) {
        Toast.ERROR(err.message);
      }
    }
  };

const uploadImageLinksRequest = (): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.UPDATE_UPLOADED_IMAGES_LINKS_REQUEST,
});

const uploadImageLinksSuccess = (): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.UPDATE_UPLOADED_IMAGES_LINKS_SUCCESS,
});

const uploadImageLinksFailure = (): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.UPDATE_UPLOADED_IMAGES_LINKS_FAILURE,
});

export const uploadImageLinkThunk =
  (id: number | string, data: IObjectType) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(uploadImageLinksRequest());
    try {
      const response = await updateUploadedImagesApi({ supplierId: id, data });
      if (!response.status) {
        dispatch(uploadImageLinksFailure());
      }
      dispatch(uploadImageLinksSuccess());
    } catch (err) {
      dispatch(uploadImageLinksFailure());
      if (err instanceof Error) {
        Toast.ERROR(err.message);
      }
    }
  };

export const resetUploadedImages = (): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.RESET_UPLOADED_IMAGES,
});

export const setImagesInUploadQueue = (
  payload: Array<IObjectType>
): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.SET_IMAGES_IN_UPLOAD_QUEUE,
  payload,
});

export const setRejectedImages = (
  payload: Array<IObjectType>
): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.SET_REJECTED_IMAGES,
  payload,
});

export const setSelectedSupplier = (
  payload: string | number
): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.SET_SELECTED_SUPPLIER,
  payload,
});

export const setCurrentPage = (payload?: number): IReduxActionType => ({
  type: IMAGE_LINK_ACTION_TYPES.SET_CURRENT_PAGE,
  payload,
});
