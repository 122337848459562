import { useRef } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ClickOutside from "components/Filters/clickOutside/ClickOutside";
import { useBoolean } from "utils/hooks/useBoolean";
import * as Styled from "./styles";

interface IToggleDropdownControlProps {
  data: Array<{ value: string; handler: () => void }>;
}

const ToggleDropdownControl = ({ data }: IToggleDropdownControlProps) => {
  const [isVisible, setVisibility] = useBoolean();
  const toggleRef = useRef<HTMLDivElement>(null);

  function handleItemClick(handler: () => void) {
    handler();
    setVisibility.off();
  }

  return (
    <Styled.ToggleWrapper className="end-align-center" ref={toggleRef}>
      <BiDotsVerticalRounded
        cursor="pointer"
        onClick={() => setVisibility.on()}
      />

      {isVisible && (
        <ClickOutside
          onClose={() => setVisibility.off()}
          disableClickHandler={false}
        >
          <Styled.DropdownStyle>
            {data.map((row, index) => (
              <Styled.DropdownListItemStyle
                key={row.value}
                onClick={() => handleItemClick(row["handler"])}
              >
                {row["value"]}
              </Styled.DropdownListItemStyle>
            ))}
          </Styled.DropdownStyle>
        </ClickOutside>
      )}
    </Styled.ToggleWrapper>
  );
};

export default ToggleDropdownControl;
