import { useEffect, useState } from 'react';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { useDispatch } from 'react-redux';
import { TbCircleCheck } from 'react-icons/tb';
import { IoCloseOutline } from 'react-icons/io5';
import TagComponent from 'components/TrendingModal/TagComponent';
import ButtonWithLoader from 'components/ButtonWithLoader';
import TextAreaInput from 'components/TextAreaInput';
import { setCollectionSaveSucess, setCollectionUrl } from 'actions/catalog';
import { useTypedSelector } from 'utils/hooks';
import { copyToClipboard } from 'utils/utility';
import { getSetDifference } from 'utils/utility/utils';
import { IBrand } from 'utils/types';
import BrandDropdown from './BrandDropdown';
import { ISaveCollection, ISelectedBrand } from '../types';
import * as Styles from './styles';

interface IEditCollectionProps {
  collectionTitle: string;
  collectionDescription: string;
  brands: Array<IBrand>;
  productIds: Array<Tag>;
  collectionInfo: Record<string, string>;
  onClose(): void;
  onEditCollection(data: ISaveCollection): void;
  collectionBrands?: Array<Tag>;
  url?: string;
}

const TITLE_LIMIT = 64;
const DESCRIPTION_LIMIT = 256;

const EditCollection = ({
  collectionTitle = '',
  collectionDescription = '',
  brands,
  productIds,
  url = '',
  collectionBrands = [],
  collectionInfo,
  onClose,
  onEditCollection,
}: IEditCollectionProps) => {
  const collectionUrl = useTypedSelector(state => state.catalog.collectionUrl);
  const isSaveCollectionButtonLoading = useTypedSelector(
    state => state.catalog.isSaveCollectionButtonLoading
  );
  const isSaveCollectionSuccessfull = useTypedSelector(
    state => state.catalog.isSaveCollectionSuccessfull
  );
  const dispatch = useDispatch();
  const [title, setTitle] = useState(collectionTitle);
  const [description, setDescription] = useState(collectionDescription);
  const [selectedBrands, setSelectedBrands] =
    useState<Array<Tag>>(collectionBrands);
  const [collectionProductIds, setCollectionProductIds] = useState(productIds);
  const sharedCollectionUrl = url || collectionUrl;

  useEffect(() => {
    return () => {
      dispatch(setCollectionUrl(''));
      dispatch(setCollectionSaveSucess(false));
    };
  }, []);

  function handleTitleInput(value: string) {
    if (value.length <= TITLE_LIMIT) {
      setTitle(value);
    }
  }

  function handleDescriptionInput(value: string) {
    if (value.length <= DESCRIPTION_LIMIT) {
      setDescription(value);
    }
  }

  function handleBrandSelection(id: string) {
    if (selectedBrands.some(eachBrand => eachBrand.id === Number(id))) {
      return;
    }
    const brandName =
      brands.find(eachBrand => eachBrand.id === Number(id))?.name ?? '';
    const selectedBrand: ISelectedBrand = {
      id: Number(id),
      name: brandName,
    };
    setSelectedBrands([...selectedBrands, selectedBrand]);
  }

  function handleBrandTagDelete(tag: Tag) {
    const filteredSelectedTags = selectedBrands.filter(
      eachBrand => eachBrand.id !== tag.id
    );
    setSelectedBrands(filteredSelectedTags);
  }

  function onTagAddition(tag: Tag) {
    let productIdsString = tag.name;
    if (productIdsString.startsWith(',')) {
      productIdsString = productIdsString.slice(1);
    }
    if (productIdsString.endsWith(',')) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }
    const newTags = productIdsString.split(',');
    const newCommaSeparatedTagsMap = new Map<String, Tag>();
    newTags.forEach(eachTag => {
      const tagObj = {
        id: eachTag.trim(),
        name: eachTag.trim(),
      };
      newCommaSeparatedTagsMap.set(eachTag, tagObj);
    });
    setCollectionProductIds([
      ...collectionProductIds,
      ...Array.from(newCommaSeparatedTagsMap.values()),
    ]);
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = collectionProductIds[tagIndex];
    const newCollectionProductsIds = collectionProductIds.filter(
      eachProduct => eachProduct.id !== tagToDelete.id
    );
    setCollectionProductIds(newCollectionProductsIds);
  }

  function handleCreateCollection() {
    const originalProductIds = new Set(productIds);
    const updatedProductIds = new Set(collectionProductIds);
    const deletedProductIds = getSetDifference(
      originalProductIds,
      updatedProductIds
    );
    const addedProductIds = getSetDifference(
      updatedProductIds,
      originalProductIds
    );
    const data: ISaveCollection = {
      title,
      description,
      add: Array.from(addedProductIds).map(eachId => eachId.id),
      delete: Array.from(deletedProductIds).map(eachId => eachId.id),
      brandIds: selectedBrands.map(eachBrand => Number(eachBrand.id)),
      currentProductIds: collectionProductIds.map(eachId => String(eachId.id)),
    };
    onEditCollection(data);
  }

  return (
    <div className="pos-r full-height">
      <Styles.EditHeader className="spread">
        <p>{collectionInfo.heading}</p>
        <IoCloseOutline
          size={24}
          stroke="var(--tertiary-text)"
          className="cursor-pointer"
          onClick={onClose}
        />
      </Styles.EditHeader>
      <Styles.EditCollectionBodyContainer>
        <Styles.TextAreaContainer>
          <TextAreaInput
            inputHeight={40}
            label="Collection title*"
            placeholder="Enter title"
            onChange={handleTitleInput}
            characterCount={TITLE_LIMIT}
            value={title}
          />
        </Styles.TextAreaContainer>
        <Styles.TextAreaContainer>
          <TextAreaInput
            inputHeight={80}
            label="Collection description"
            placeholder="Enter description"
            onChange={handleDescriptionInput}
            characterCount={DESCRIPTION_LIMIT}
            value={description}
          />
        </Styles.TextAreaContainer>
        <BrandDropdown
          label="Brands*"
          brands={brands}
          onChange={handleBrandSelection}
          selectedBrands={selectedBrands}
          handleBrandTagDelete={handleBrandTagDelete}
        />
        <Styles.ReactTagsWrapper>
          <Styles.DropdownWrapperTitle>
            Product Ids*
          </Styles.DropdownWrapperTitle>
          <ReactTags
            tags={collectionProductIds}
            tagComponent={TagComponent}
            onAddition={onTagAddition}
            onDelete={onTagDeletion}
            addOnBlur
            allowNew
            placeholderText="Product ID"
          />
          <Styles.TagContainerBottomText>
            Enter a comma (,) after each tag
          </Styles.TagContainerBottomText>
        </Styles.ReactTagsWrapper>
        {sharedCollectionUrl && (
          <Styles.CopyLinkContainer className="v-d-flex cursor-pointer full-width">
            <Styles.LinkLabel>Collection link</Styles.LinkLabel>
            <Styles.CopyLink className="spread full-width">
              <p>{sharedCollectionUrl}</p>
              <button
                className="cursor-pointer"
                onClick={() => copyToClipboard(sharedCollectionUrl)}
              >
                Copy
              </button>
            </Styles.CopyLink>
          </Styles.CopyLinkContainer>
        )}
      </Styles.EditCollectionBodyContainer>
      <Styles.FooterContainer className="pos-a end-align-center full-width">
        {isSaveCollectionSuccessfull ? (
          <Styles.SaveSuccessful className="align-center">
            <TbCircleCheck size={18} stroke="var(--deep-cerulean)" />
            <p>{collectionInfo.successText}</p>
          </Styles.SaveSuccessful>
        ) : (
          <ButtonWithLoader
            text={collectionInfo.buttonText}
            onClick={handleCreateCollection}
            showLoader={isSaveCollectionButtonLoading}
            isDisabled={isSaveCollectionButtonLoading}
          />
        )}
      </Styles.FooterContainer>
    </div>
  );
};

export default EditCollection;
