import { MouseEvent, ReactElement, ReactNode, ReactText } from 'react';
import StyledButton from './styles';
import Spinner from '../Spinner';
import { ColorSchemeEnum } from './constants';

interface IButtonProps {
  children: ReactText | ReactNode;
  size: 'large' | 'medium' | 'small';
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick: (event: MouseEvent<HTMLElement>) => void;
  colorScheme?: ColorSchemeEnum;
  icon?: ReactElement;
  iconPosition?: 'left' | 'right';
}

/*
 * Reference : https://www.figma.com/file/wFrz3MBlCdGI71wMkyAzRT/Fashinza-Design-System?node-id=50%3A0
 */
const Button = ({
  children,
  size,
  isDisabled,
  isLoading = false,
  variant = 'primary',
  onClick,
  colorScheme = ColorSchemeEnum.RED,
  icon,
}: IButtonProps) => {
  const ButtonContent = (
    <>
      {icon}
      {children}
    </>
  );

  return (
    <StyledButton
      type="button"
      colorScheme={colorScheme}
      variant={variant}
      size={size}
      disabled={isDisabled}
      isLoading={isLoading}
      onClick={isDisabled || isLoading ? undefined : onClick}
    >
      {isLoading ? <Spinner /> : ButtonContent}
    </StyledButton>
  );
};

export default Button;
