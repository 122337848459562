import React, { PureComponent } from 'react';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const { 'magnifying-glass': MagnifyingGlass, 'search-box': SearchBoxCross } =
  ICONOGRAPHY;

class TextInput extends PureComponent {
  inputRef = React.createRef();

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const {
      dataname,
      placeholder = '',
      onChange,
      onClear,
      value,
      listItemSearchBoxClass = '',
      showCrossButton = true,
    } = this.props;
    let clearClass = 'text-input-clear center';
    if (!value) clearClass += ' hide';
    return (
      <Styles.Wrapper className={listItemSearchBoxClass}>
        <div className="text-input-search center">
          <MagnifyingGlass />
        </div>
        <input
          ref={this.inputRef}
          type="text"
          data-name={dataname}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {showCrossButton && (
          <div className={clearClass} onClick={onClear}>
            <SearchBoxCross />
          </div>
        )}
      </Styles.Wrapper>
    );
  }
}

TextInput.defaultProps = {
  placeholder: '',
  listItemSearchBoxClass: '',
};

export default TextInput;
