import styled from 'styled-components';
import { BiLoaderAlt } from 'react-icons/bi';
import StyledButton from 'components/StyledButton';

export const UploadImageTab = styled(StyledButton)`
  font-size: 14px;
  height: 40px;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(25, 25, 25, 0.5);
  z-index: 999;
`;

export const UploadImageModal = styled.div`
  width: 500px;
  background-color: var(--white);
  border-radius: 8px;
  padding-bottom: 10px;
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--athens-gray);
  padding: 12px 24px;
  margin-bottom: 10px;
`;

export const ModalTitle = styled.p`
  color: var(--rhino);
  font-weight: 500;
`;

export const UploadImageContainer = styled.div`
  width: 200px;
  height: 40px;
  margin: 16px auto;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--watermelon);
`;

export const UploadText = styled.p`
  color: var(--watermelon);
  font-size: 14px;
  font-weight: 600;
  margin-left: 12px;
`;

export const ImageLinkContainer = styled.div`
  border-radius: 4px;
  margin-top: 4px;
  border: 1px solid var(--athens-gray);
`;

export const ImageLink = styled.p`
  flex: 9;
  color: var(--rhino);
  font-size: 14px;
  padding: 4px;
  margin-top: 4px;
`;

export const Copy = styled.div`
  flex: 1;
  border-left: 1px solid var(--athens-gray);
  padding: 4px;
`;

export const UrlLabel = styled.label`
  font-size: 12px;
  color: var(--rhino);
`;

export const LinkContainer = styled.div`
  width: 400px;
  margin: 10px auto;
`;

export const Spinner = styled(BiLoaderAlt)`
  animation: rotate360 1s linear infinite;
  margin-right: 0;
  width: inherit;
  color: var(--watermelon);
`;

export const ScalesSelectWrapper = styled.div`
  margin: 8px 0;
`;

export const PrefixInputWrapper = styled.div`
  width: 200px;
  margin: 8px auto;
`;
