import Toast from 'library/Toast';
import { ITags } from 'components/Trends/types';
import {
  getChartApi,
  getChartTagsApi,
  getRetailersApi,
  getTagsApi,
} from 'utils/api';
import { IReduxActionType } from 'utils/types';
import TRENDS_TYPES from 'actionTypes/trends';

export const setLoaderStatus = (payload: boolean): IReduxActionType => ({
  type: TRENDS_TYPES.SET_LOADER_STATUS,
  payload,
});

const setTagsData = (payload: Array<ITags>) => {
  return {
    type: TRENDS_TYPES.SET_TAGS_DATA,
    payload,
  };
};

export const fetchTagsThunk =
  (filterParams: string = '') =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getTagsApi(filterParams);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(setTagsData(response.data));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

const setRetailerData = (payload: Array<string>) => {
  return {
    type: TRENDS_TYPES.SET_RETAILER_DATA,
    payload,
  };
};

export const fetchRetailersThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getRetailersApi();
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(setRetailerData(response.data));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const setSelectedFilters = (
  selectedFilters: Record<string, Array<any>>,
  shouldReset: boolean = false
) => ({
  type: TRENDS_TYPES.SET_SELECTED_FILTERS,
  payload: {
    selectedFilters,
    shouldReset,
  },
});

export const setChartsData = (
  payload: Array<Record<string, string | number>>
) => {
  return {
    type: TRENDS_TYPES.SET_CHARTS_DATA,
    payload,
  };
};

export const fetchChartThunk =
  (endpoint: string) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getChartApi(endpoint);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(setChartsData(response.data));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const setChartTagsData = (payload: Array<string>) => {
  return {
    type: TRENDS_TYPES.SET_CHART_TAGS_DATA,
    payload,
  };
};

export const fetchChartTagsThunk =
  (filters: Record<string, string>) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const { retailer, category, region, gender, date } = filters;
      const response = await getChartTagsApi(
        retailer,
        category,
        region,
        gender,
        date
      );
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(setChartTagsData(response.data));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const setTrendsDates = (payload: Array<string>) => {
  return {
    type: TRENDS_TYPES.SET_TRENDS_DATES,
    payload,
  };
};
