import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BiLoaderAlt } from 'react-icons/bi';
import Close from 'assets/icons/Close';
import { StyledButtonVariant } from 'components/StyledButton';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import {
  getAllFabricClustersThunk,
  postProductsFabricClusters,
  setFabricClusterSidebarOpen,
} from 'actions/ruleEngines';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import Table from './ClusterTable';
import { IProductFabricClusters } from './types';
import * as Styles from './styles';

const TAB_SWITCHER = [
  {
    name: 'fabric',
    label: 'Fabric',
  },
  {
    name: 'digital_print',
    label: 'Digital Print',
  },
];

const FabricClusters = () => {
  const fabricClusterList = useTypedSelector(
    state => state.ruleEngine.fabricClusterList
  );
  const isFabricClustersListLoading = useTypedSelector(
    state => state.ruleEngine.isFabricClustersListLoading
  );
  const selectedFabricClusterIds = useTypedSelector(
    state => state.ruleEngine.selectedFabricClusterIds
  );
  const fabricClustersPagination = useTypedSelector(
    state => state.ruleEngine.fabricClustersPagination
  );
  const selectedProductIds = useTypedSelector(
    state => state.catalog.selectedProductIds
  );
  const [isSubmitButtonLoading, submitButtonLoaderActions] = useBoolean();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<string>('fabric');

  useEffect(() => {
    dispatch(
      getAllFabricClustersThunk(
        1,
        `is_cluster=true&cluster_type=${selectedTab}`,
        OperatorEnum.Modify
      )
    );
  }, [selectedTab]);

  function handleSubmitProductClusters() {
    const apiBody: IProductFabricClusters = {
      product_ids: Array.from(selectedProductIds),
      collection_ids: [selectedFabricClusterIds],
    };

    dispatch(
      postProductsFabricClusters(apiBody, () =>
        submitButtonLoaderActions.toggle()
      )
    );
  }

  function fetchNextProducts() {
    if (fabricClustersPagination.page >= fabricClustersPagination.totalPages)
      return;

    dispatch(
      getAllFabricClustersThunk(
        fabricClustersPagination.page + 1,
        `is_cluster=true&cluster_type=${selectedTab}`,
        OperatorEnum.Append
      )
    );
  }

  return (
    <div className="full-dimension pos-r">
      <Styles.FabricClusterHeader className="spread">
        <Styles.FabricClusterTitle>Add Cluster</Styles.FabricClusterTitle>
        <Close
          className="cursor-pointer"
          size={20}
          onClick={() => dispatch(setFabricClusterSidebarOpen(false))}
        />
      </Styles.FabricClusterHeader>
      <Styles.TabSwitcher className="d-flex">
        {TAB_SWITCHER.map(each => (
          <Styles.EachTab
            key={each.name}
            isActive={each.name === selectedTab}
            onClick={() => setSelectedTab(each.name)}
            className="cursor-pointer"
          >
            <Styles.ClusterText>{each.label}</Styles.ClusterText>
          </Styles.EachTab>
        ))}
      </Styles.TabSwitcher>
      {isFabricClustersListLoading && fabricClustersPagination.page === 1 ? (
        <div className="full-dimension center">
          <BiLoaderAlt className="infinite-loader" />
        </div>
      ) : (
        <Styles.FabricClusterTable className="has-y-scroll">
          <Table
            list={fabricClusterList}
            paginationHandler={fetchNextProducts}
            currentPage={fabricClustersPagination.page}
            loaderStatus={isFabricClustersListLoading}
          />
          <Styles.ButtonFooter className="pos-a end-align-center full-width">
            <Styles.SubmitButton
              className="center"
              variant={StyledButtonVariant.MetallicSeaweed}
              onClick={handleSubmitProductClusters}
              disabled={isSubmitButtonLoading}
            >
              {isSubmitButtonLoading ? (
                <BiLoaderAlt className="infinite-loader" />
              ) : (
                'Submit'
              )}
            </Styles.SubmitButton>
          </Styles.ButtonFooter>
        </Styles.FabricClusterTable>
      )}
    </div>
  );
};

export default FabricClusters;
