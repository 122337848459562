import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ACCEPTED_SIZE } from 'components/ImageLinks/helperConstant';
import { useTypedSelector } from '../../utils/hooks';
import {
  uploadImageLinkThunk,
  resetUploadedImages,
  setImagesInUploadQueue,
  setSelectedSupplier,
  setRejectedImages,
} from '../../actions/imageLinks';
import { uploadFile } from '../../services/cloudinary';
import { getFileNameWithoutExt, getFileExt } from '../../utils/utility';
import { IObjectType } from '../../utils/types';
import { FileUploadStatusEnum } from './helperConstant';
import { IResponseType } from './types';

const dataTransformation = (
  imageData: Array<IObjectType>
): Array<IObjectType> => {
  if (!imageData || imageData.length === 0) return [];
  const newData = imageData.map(item => ({
    id: item.id,
    name: '',
    link: item.image_url,
    status: FileUploadStatusEnum.Success,
  }));
  return newData;
};

export function useImageLink() {
  const dispatch = useDispatch();
  const previouslyUploadedImages = dataTransformation(
    useTypedSelector(store => store.imageLinks.uploadedImages)
  );
  const imagesInUploadQueue: Array<IObjectType> = useTypedSelector(
    store => store.imageLinks.imagesToBeUploaded
  );
  const imagesInRejectQueue: Array<IObjectType> = useTypedSelector(
    store => store.imageLinks.rejectedImages
  );
  const selectedSupplier = useTypedSelector(
    store => store.imageLinks.selectedSupplier
  );
  const currentPage = useTypedSelector(store => store.imageLinks.currentPage);

  function handleSupplierChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value !== selectedSupplier) {
      dispatch(resetUploadedImages());
      dispatch(setSelectedSupplier(value));
    }
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    //function to get meta data (height&width) for an image
    const getImageMetaData = async (
      files: File
    ): Promise<{
      width: number;
      height: number;
    }> => {
      // reading a file to get height and width
      async function getImageParams(files: File): Promise<{
        width: number;
        height: number;
      }> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = async (e: any) => {
            const image = new Image();
            image.src = URL.createObjectURL(files);
            await image.decode();

            resolve({ width: image.width, height: image.height });
          };
          reader.readAsDataURL(files);
        });
      }
      const { width, height } = await getImageParams(files);
      return { width, height };
    };
    // Check so that type of files is not null afterwards
    if (files === null || files.length === 0) {
      return;
    }
    // unable to use spread operator, either upgrade target to es2015 or use below method
    let sizeExceedingFiles = [];
    let filesForUpload = [];
    // segregating files into 2 classes: can be uploaded & can't be
    for (let i = 0; i < files.length; i++) {
      const { width, height } = await getImageMetaData(files[i]);
      if (
        files[i].size / 1000000 <= ACCEPTED_SIZE.MinimumImageSize &&
        ((width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio1 &&
          width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio1) ||
          (width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio2 &&
            width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio2)) &&
        height >= ACCEPTED_SIZE.MinimumImageHeight &&
        height <= ACCEPTED_SIZE.MaximumImageHeight
      ) {
        filesForUpload.push(files[i]);
      } else {
        sizeExceedingFiles.push({
          height: height,
          width: width,
          files: files[i],
        });
      }
    }
    // setting loading status for file that can be uploaded
    const filesArray = filesForUpload.map(item => {
      return {
        name: item.name,
        link: '',
        id: uuidv4(),
        status: FileUploadStatusEnum.Loading,
      };
    });
    // setting rejected status for file that can't be uploaded
    const rejectedFilesArray = sizeExceedingFiles.map(item => {
      {
        let errorMessage =
          'Error, Image parameters not met.\nIncorrect Parameters :';
        let { height, width, files } = item;
        let fileSize = '',
          imageHeight = '',
          imageWidth = '',
          aspectRatio = '',
          message = '',
          sizeCorrectionMessage = '',
          heightCorrectionMessage = '',
          aspectRatioCorrectionMessage = '';
        if (files.size > ACCEPTED_SIZE.MinimumImageSize * 1000000) {
          fileSize =
            ' File size : ' +
            Number((files.size / 1000000).toFixed(2)) +
            +' MB\n';
          sizeCorrectionMessage =
            'File size should be less than' +
            ACCEPTED_SIZE.MinimumImageSize +
            ' MB!\n';
        }
        if (
          height < ACCEPTED_SIZE.MinimumImageHeight ||
          height > ACCEPTED_SIZE.MaximumImageHeight
        ) {
          imageHeight = ' Image height : ' + height + 'px\n';
          heightCorrectionMessage =
            'Image Height should be in the range [720-2400] px!\n';
        }
        if (
          !(
            (width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio1 &&
              width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio1) ||
            (width / height >= 0.95 * ACCEPTED_SIZE.AspectRatio2 &&
              width / height <= 1.05 * ACCEPTED_SIZE.AspectRatio2)
          )
        ) {
          aspectRatio =
            ' Aspect Ratio : ' + Number((width / height).toFixed(2)) + '\n';
          aspectRatioCorrectionMessage =
            'Width / Height ratio should be 1:1 or 3:4!';
        }
        return {
          name: files.name,
          id: uuidv4(),
          link: URL.createObjectURL(files),
          status: FileUploadStatusEnum.SizeError,
          sizeErrorType: errorMessage.concat(
            fileSize,
            imageHeight,
            imageWidth,
            aspectRatio
          ),
          correctionMessage: message.concat(
            sizeCorrectionMessage,
            heightCorrectionMessage,
            aspectRatioCorrectionMessage
          ),
        };
      }
    });
    const newImageRejectQueue = [...rejectedFilesArray, ...imagesInRejectQueue];
    dispatch(setRejectedImages(newImageRejectQueue));
    const newImageUploadQueue = [...imagesInUploadQueue, ...filesArray];
    dispatch(setImagesInUploadQueue(newImageUploadQueue));

    for (const item of filesForUpload) {
      let resWithStatus: IResponseType = {
        name: '',
        id: '',
        link: '',
        ext: '',
        status: FileUploadStatusEnum.Loading,
      };
      try {
        const res = await uploadFile(item);
        resWithStatus = {
          id: res?.id,
          name: res?.name,
          link: res?.link,
          ext: res?.ext,
          status: FileUploadStatusEnum.Success,
        };
        dispatch(
          uploadImageLinkThunk(selectedSupplier, { image_url: res?.link })
        );
      } catch (err) {
        resWithStatus = {
          id: uuidv4(),
          name: item.name,
          link: URL.createObjectURL(item),
          status: FileUploadStatusEnum.Failed,
          blob: item,
        };
        console.log(err);
      } finally {
        const indexInUploadedImageState = newImageUploadQueue.findIndex(
          imageItem => {
            if (
              resWithStatus.status === FileUploadStatusEnum.Failed &&
              imageItem.name === resWithStatus.name
            )
              return true;
            if (
              resWithStatus.status === FileUploadStatusEnum.Success &&
              getFileNameWithoutExt(imageItem.name) === resWithStatus.name &&
              getFileExt(imageItem.name) === resWithStatus.ext
            )
              return true;
            return false;
          }
        );
        newImageUploadQueue[indexInUploadedImageState] = resWithStatus;

        dispatch(setImagesInUploadQueue([...newImageUploadQueue]));
      }
    }
    event.target.value = '';
  }

  async function handleRetry(id: number | string) {
    let indexOfFile = -1;
    const file = imagesInUploadQueue.filter((item, index) => {
      if (item.id === id) indexOfFile = index;
      return item.id === id;
    });
    let resWithStatus = imagesInUploadQueue[indexOfFile];
    resWithStatus.status = FileUploadStatusEnum.Loading;
    const uploadedImagesCopy = imagesInUploadQueue;
    uploadedImagesCopy[indexOfFile] = resWithStatus;
    dispatch(setImagesInUploadQueue([...uploadedImagesCopy]));
    try {
      const res = await uploadFile(file[0].blob);
      resWithStatus = {
        id: res?.id,
        name: res?.name,
        link: res?.link,
        ext: res?.ext,
        status: FileUploadStatusEnum.Success,
      };
      dispatch(
        uploadImageLinkThunk(selectedSupplier, { image_url: res?.link })
      );
    } catch (err) {
      resWithStatus.status = FileUploadStatusEnum.Failed;
      console.log(err);
    } finally {
      uploadedImagesCopy[indexOfFile] = resWithStatus;
      dispatch(setImagesInUploadQueue([...uploadedImagesCopy]));
    }
  }

  async function handleDelete(id: number | string) {
    let imagesInRejectQueuecopy;
    let indexToDelete = -1;
    imagesInRejectQueue.map(
      (item, index) => (indexToDelete = id == item.id ? index : indexToDelete)
    );
    imagesInRejectQueuecopy = imagesInRejectQueue.filter(function (
      item,
      index
    ) {
      return index !== indexToDelete;
    });
    dispatch(setRejectedImages(imagesInRejectQueuecopy));
  }

  return {
    uploadedImages: imagesInUploadQueue,
    failedImages: imagesInRejectQueue,
    handleChange,
    handleRetry,
    handleDelete,
    previouslyUploadedImages,
    currentPage,
    supplierFilter: {
      handleSupplierChange,
      selectedSupplier,
    },
  };
}
