import React, { ReactNode } from "react";
import Tooltip from "react-tooltip-lite";
import { DEFAULT_PRODUCT_IMAGE } from "../../utils/defaults";
import * as Styled from "./styles";
import "./styles.scss";

interface IImageWithTooltipProps {
  children: ReactNode;
  imageSrc: string;
  altText: string;
}

const ImageWithTooltip = ({
  children,
  imageSrc,
  altText,
}: IImageWithTooltipProps) => {
  return (
    <Tooltip
      content={
        <Styled.ImagePreviewWrapper>
          <img src={imageSrc || DEFAULT_PRODUCT_IMAGE} alt={altText} />
        </Styled.ImagePreviewWrapper>
      }
      direction="right"
      className="image-preview-tooltip"
      background="var(--white)"
    >
      {children}
    </Tooltip>
  );
};

export default ImageWithTooltip;
