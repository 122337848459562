import Toast from 'library/Toast';
import { IEmailers } from 'components/Emailers/types';
import { getEmailerDataApi, updateEmailerDataApi } from 'utils/api';
import { IReduxActionType } from 'utils/types';
import EMAILER_TYPES from 'actionTypes/emailer';

export const setLoaderStatus = (payload: boolean): IReduxActionType => ({
  type: EMAILER_TYPES.SET_LOADER_STATUS,
  payload,
});

export const setEmailerData = (data: IEmailers) => ({
  type: EMAILER_TYPES.SET_EMAILER_DATA,
  payload: data,
});

export const getEmailerDataThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await getEmailerDataApi();
      dispatch(setEmailerData(response.data[0]));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

export const updateEmailersDataThunk =
  (data: IEmailers) => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await updateEmailerDataApi({
        subject: data.subject,
        secondary_button_background_color:
          data.secondary_button_background_color,
        secondary_description_text_color: data.secondary_description_text_color,
        description_text_color: data.description_text_color,
        description: data.description,
        first_collection_url: data.first_collection_url,
        secondary_description: data.secondary_description,
        primary_button_text_color: data.primary_button_text_color,
        hero_image_link: data.hero_image_link,
        secondary_button_title: data.secondary_button_title,
        secondary_title: data.secondary_title,
        collection_description_text_color:
          data.collection_description_text_color,
        title_text_color: data.title_text_color,
        primary_button_background_color: data.primary_button_background_color,
        secondary_title_text_color: data.secondary_title_text_color,
        secondary_section_background_color:
          data.secondary_section_background_color,
        primary_button_url: data.primary_button_url,
        collection_products_background_color:
          data.collection_products_background_color,
        primary_section_background_color: data.primary_section_background_color,
        secondary_button_url: data.secondary_button_url,
        secondary_button_title_color: data.secondary_button_title_color,
        primary_button_text: data.primary_button_text,
        collection_description: data.collection_description,
        collection_products_name_text_color:
          data.collection_products_name_text_color,
        config_key: 'trends-mail-config',
      });
      if (response.status) {
        Toast.SUCCESS(
          'Updated Successfully!. You will receive a slack notification shortly.'
        );
      } else {
        Toast.ERROR(response.msg);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };
