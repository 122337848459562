import styled from 'styled-components';

export const FilterWrapper = styled.div`
  padding: 16px 24px;
`;

export const FilterViewBarTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 4px;
  color: var(--manatee);
`;

export const FilterViewBarWrapper = styled.div`
  width: 15%;
  margin-right: 20px;
`;
