import React, { MouseEvent } from 'react';
import FilterSearchBar from 'components/Filters/ActiveApprovedFilter/FilterSearchBar';
import Checkbox from 'library/checkbox/checkbox';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import { useBoolean } from 'utils/hooks/useBoolean';
import * as Styles from './styles';

interface IMultiSelectProps {
  values: Array<string>;
  onChange(value: string, event: MouseEvent<HTMLElement>): void;
  selectedValues: Array<string | null>;
  width?: number;
  placeholder?: string;
  label?: string;
  showLabel?: boolean;
}

const MultiSelect = ({
  values,
  onChange,
  selectedValues,
  width = 200,
  placeholder = 'Select',
  label = 'select',
  showLabel = true,
}: IMultiSelectProps) => {
  const [isDropdownOpen, dropdownActions] = useBoolean();

  return (
    <Styles.MultiSelectContainer width={width} className="pos-r fit-height">
      {showLabel && (
        <Styles.DropdownWrapperTitle>{label}</Styles.DropdownWrapperTitle>
      )}
      <FilterSearchBar
        width={width}
        value={selectedValues.length}
        isFilterOpen={isDropdownOpen}
        toggleFilter={() => dropdownActions.on()}
        placeholder={placeholder}
      />
      {isDropdownOpen && (
        <ClickOutside
          onClose={() => dropdownActions.off()}
          disableClickHandler={false}
        >
          <div
            onClick={event => event.stopPropagation()}
            className="pos-a full-width m-dropdown"
          >
            {values.map(eachValue => (
              <Styles.ListItemWrapper key={eachValue} className="full-width">
                <Checkbox
                  item={{
                    value: eachValue,
                  }}
                  label={eachValue}
                  onChange={(event: MouseEvent<HTMLElement>) => {
                    onChange(eachValue, event);
                  }}
                  checked={selectedValues.includes(eachValue)}
                />
              </Styles.ListItemWrapper>
            ))}
          </div>
        </ClickOutside>
      )}
    </Styles.MultiSelectContainer>
  );
};

export default MultiSelect;
