import React, { ReactNode, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styles from './styles';

const { 'arrow-left': ArrowLeftIcon } = ICONOGRAPHY;

interface IHeaderToolbarProps {
  title: ReactNode;
  onBackClick?: () => void;
  hasDefaultBack?: boolean;
  fallbackUrl?: string;
  rightNode?: ReactNode;
  className?: string;
  children?: ReactNode;
}

const HeaderToolbar = ({
  title,
  onBackClick,
  hasDefaultBack = false,
  fallbackUrl,
  rightNode,
  className = '',
  children,
}: IHeaderToolbarProps) => {
  const dispatch = useDispatch();
  const hasTraceInBrowser = window.history.length > 1;

  const backFunction = useMemo(() => {
    if (onBackClick) {
      return onBackClick;
    }
    if (!hasDefaultBack) {
      return null;
    }
    if (hasTraceInBrowser) {
      return () => dispatch(goBack());
    }
    if (fallbackUrl) {
      return () => dispatch(push(fallbackUrl));
    }
    return null;
  }, [onBackClick, hasTraceInBrowser, fallbackUrl]);

  return (
    <Styles.SuperWrapper className={className}>
      <Styles.Wrapper className="spread">
        <div className="full-flex align-center">
          {backFunction && (
            <Styles.BackIconWrapper
              onClick={backFunction}
              className="center cursor-pointer"
            >
              <ArrowLeftIcon viewType="left" size={16} />
            </Styles.BackIconWrapper>
          )}
          <Styles.Title>{title}</Styles.Title>
        </div>
        {rightNode && <div className="align-center">{rightNode}</div>}
      </Styles.Wrapper>
      {children}
    </Styles.SuperWrapper>
  );
};

export default HeaderToolbar;
