const nsp = 'designLibrary/';

const DESIGN_LIBRARY_TYPES = {
  SET_LOADER_STATUS: `${nsp}SET_LOADER_STATUS`,
  SET_DESIGN_LIBRARY_DATA: `${nsp}SET_DESIGN_LIBRARY_DATA`,
  DESIGN_LIBRARY_PAGINATION_INFO: `${nsp}DESIGN_LIBRARY_PAGINATION_INFO`,
  SET_DESIGN_LIBRARY_URL: `${nsp}SET_DESIGN_LIBRARY_URL`,
  SET_SEASON_AND_FABRIC_DATA: `${nsp}SET_SEASON_AND_FABRIC_DATA`,
  SET_SAVE_DESIGN_LIBRARY_LOADER: `${nsp}SET_SAVE_DESIGN_LIBRARY_LOADER`,
  SET_SAVE_DESIGN_LIBRARY_SUCCESS: `${nsp}SET_SAVE_DESIGN_LIBRARY_SUCCESS`,
};

export default DESIGN_LIBRARY_TYPES;
