import styled, { css } from 'styled-components';
import { ColorSchemeEnum } from './constants';

interface IStyledButtonProps {
  size: 'large' | 'medium' | 'small';
  variant: 'primary' | 'secondary' | 'tertiary';
  isLoading: boolean;
  colorScheme: ColorSchemeEnum;
}

const BUTTON_HEIGHTS = {
  large: 48,
  medium: 40,
  small: 32,
};

const BUTTON_FONT_SIZES = {
  large: 16,
  medium: 14,
  small: 12,
};

const getPrimaryButtonCss = (isLoading: boolean) => css`
  background: var(--watermelon);
  color: var(--white);

  :hover {
    background: ${isLoading ? css`var(--watermelon)` : css`var(--coral-red)`};
  }

  :disabled {
    color: var(--manatee);
    background: var(--input-disabled);
    border: 1px solid var(--mischka);
  }

  svg circle.path {
    stroke: var(--white);
  }
`;

const getSecondaryButtonCss = (
  isLoading: boolean,
  colorScheme: ColorSchemeEnum
) => css`
  background: var(--white);

  ${colorScheme === ColorSchemeEnum.RED &&
  css`
    color: var(--watermelon);
    border: 1px solid var(--watermelon);

    :hover {
      background: ${isLoading ? css`var(--white)` : css`var(--pipin)`};
    }
  `}

  ${colorScheme === ColorSchemeEnum.BLUE &&
  css`
    color: var(--pacific-blue);
    border: 1px solid var(--pacific-blue);

    :hover {
      background: ${isLoading ? css`var(--white)` : css`var(--bubbles)`};
    }
  `}

  :disabled {
    color: var(--manatee);
    background: var(--white);
    border: 1px solid var(--mischka);
  }
`;

const getTertiaryButtonCss = (
  isLoading: boolean,
  colorScheme: ColorSchemeEnum
) => css`
  color: var(--watermelon);
  background: ${isLoading ? css`var(--chablis)` : css`var(--white)`};

  :hover {
    background: ${isLoading ? css`var(--pipin)` : css`var(--chablis)`};
  }

  :disabled {
    color: var(--manatee);
    background: var(--very-light-pink-two);
  }

  gap: 10px;

  svg.icon {
    height: 16px;
    width: 16px;
  }
`;

const StyledButton = styled.button<IStyledButtonProps>`
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-weight: 600;
  padding: 14px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  white-space: nowrap;

  svg {
    height: ${props => BUTTON_HEIGHTS[props.size] - 10}px;
    width: ${props => BUTTON_HEIGHTS[props.size] - 10}px;
  }

  :disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  height: ${props => BUTTON_HEIGHTS[props.size]}px;
  font-size: ${props => BUTTON_FONT_SIZES[props.size]}px;

  ${props =>
    props.isLoading &&
    css`
      cursor: progress;
    `}

  ${props =>
    props.variant === 'primary' && getPrimaryButtonCss(props.isLoading)}
  
  ${props =>
    props.variant === 'secondary' &&
    getSecondaryButtonCss(props.isLoading, props.colorScheme)}
  
  ${props =>
    props.variant === 'tertiary' &&
    getTertiaryButtonCss(props.isLoading, props.colorScheme)}
`;

export default StyledButton;
