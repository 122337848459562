import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import {
  useAllCategoryManagerDetail,
  useAllDesignerDetail,
  useAllSaleRepresentativeDetail,
  useBuHeadDetails,
  useFabricTypeData,
} from 'components/Filters/helper';
import PdfWrapperSetup from 'library/PdfWrapperSetup';
import { useTypedSelector } from 'utils/hooks';
import { DEFAULT_VALUE } from 'utils/defaults';
import ICONOGRAPHY from 'CustomConstant/icons';
import PageLoadingSpinner from '../PageLoadingSpinner';
import { IDesignLibrary } from '../types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import * as Styles from './styles';

const { close: CloseIcon } = ICONOGRAPHY;

interface IProductDescription {
  data: IDesignLibrary;
  onClose: () => void;
}

const ProductDescription = ({ data, onClose }: IProductDescription) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const categoryManagerData = useAllCategoryManagerDetail();
  const designerData = useAllDesignerDetail();
  const buHeadData = useBuHeadDetails();
  const designCategories = useTypedSelector(
    state => state.catalog.filters.category
  );
  const brandsList = useTypedSelector(state => state.catalog.brands);
  const fabricTypeData = useFabricTypeData();
  const saleRepresentativeData = useAllSaleRepresentativeDetail();

  function getCategories(id: string | number) {
    const designCategory = designCategories.find(
      eachDesignCategory => eachDesignCategory.id === id
    );
    return designCategory ? designCategory.name : DEFAULT_VALUE;
  }

  function getFabricType(id: string | null) {
    const fabricType = fabricTypeData.find(
      eachFabricType => eachFabricType.id === id
    );
    return fabricType ? fabricType.name : DEFAULT_VALUE;
  }

  function getSalesRepresentativeName(id: number | null) {
    const saleRepresentative = saleRepresentativeData.find(
      eachSaleRepresentative => eachSaleRepresentative.id === String(id)
    );
    return saleRepresentative ? saleRepresentative.name : DEFAULT_VALUE;
  }

  function getCategoryManagerName(id: number | null) {
    const categoryManager = categoryManagerData.find(
      eachCategoryManager => eachCategoryManager.id === String(id)
    );
    return categoryManager ? categoryManager.name : DEFAULT_VALUE;
  }

  function getDesignerName(id: number | null) {
    const designer = designerData.find(
      eachDesigner => eachDesigner.id === String(id)
    );
    return designer ? designer.name : DEFAULT_VALUE;
  }

  function getBuHeadName(id: number | null) {
    const buHead = buHeadData.find(eachBuHead => eachBuHead.id === String(id));
    return buHead ? buHead.name : DEFAULT_VALUE;
  }

  function getBrandsList(brandIds: Array<string> | null) {
    if (brandIds !== null) {
      return brandIds.map(eachBrandId => ({
        id: eachBrandId,
        name: eachBrandId,
      }));
    }
  }

  function getFirstLetters(name: string) {
    const firstLetters = name
      .split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();

    return firstLetters;
  }

  return (
    <div className="full-height v-d-flex">
      <Styles.Header className="spread">
        <Styles.Title>{data.name}</Styles.Title>
        <Styles.CloseWrapper className="center cursor-pointer">
          <CloseIcon
            onClick={() => {
              onClose();
              setPageNumber(1);
            }}
          />
        </Styles.CloseWrapper>
      </Styles.Header>
      <Styles.BodyWrapper className="d-flex has-scroll">
        <Styles.UploadSection className="full-height">
          <Styles.UploadWrapper className="has-scroll">
            <Styles.PdfWrapper>
              <PdfWrapperSetup>
                <Document
                  file={data.pdf_url}
                  onLoadSuccess={({ numPages }: any) => setNumPages(numPages)}
                  loading={<PageLoadingSpinner />}
                  className="pdf-upload"
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => (
                      <Page
                        pageNumber={page}
                        loading={<PageLoadingSpinner />}
                        renderTextLayer
                        renderAnnotationLayer
                        devicePixelRatio={window.devicePixelRatio}
                      />
                    ))}
                </Document>
              </PdfWrapperSetup>
            </Styles.PdfWrapper>
          </Styles.UploadWrapper>
        </Styles.UploadSection>
        <Styles.DesignFormWrapper className="fit-height">
          <Styles.FormWrapper>
            <Styles.FormTitle>Product details</Styles.FormTitle>
            <Styles.DownloadPdf href={data.pdf_url} target="_blank">
              Download PDF
            </Styles.DownloadPdf>
            <Styles.Heading>Collection name</Styles.Heading>
            <Styles.TitleSubHeading>
              {data.name.length > 0 ? data.name : DEFAULT_VALUE}
            </Styles.TitleSubHeading>
            <Styles.Heading>Category</Styles.Heading>
            <Styles.SubHeading>
              {getCategories(data.category)}
            </Styles.SubHeading>
            <Styles.Heading>Season</Styles.Heading>
            <Styles.SubHeading>
              {data.season.length > 0 ? data.season : DEFAULT_VALUE}
            </Styles.SubHeading>
            <Styles.Heading>Material</Styles.Heading>
            <Styles.SubHeading>
              {data.material?.join(', ')
                ? data.material?.join(', ')
                : DEFAULT_VALUE}
            </Styles.SubHeading>
            <Styles.Heading>Fabric Type</Styles.Heading>
            <Styles.SubHeading>
              {getFabricType(data.fabric_type)}
            </Styles.SubHeading>
            <Styles.Heading>Collection description</Styles.Heading>
            <Styles.TitleSubHeading>
              {data.description.length > 0 ? data.description : DEFAULT_VALUE}
            </Styles.TitleSubHeading>
            <Styles.Heading>Tags</Styles.Heading>
            {data.tags && data.tags[0] === null ? (
              <Styles.DefaultText>{DEFAULT_VALUE}</Styles.DefaultText>
            ) : (
              <div className="d-flex flex-wrap">
                {data.tags?.map(tag => (
                  <Styles.Tags key={tag} className="fit-width">
                    {tag}
                  </Styles.Tags>
                ))}
                {data.tags?.length === 0 && (
                  <Styles.DefaultHeading>{DEFAULT_VALUE}</Styles.DefaultHeading>
                )}
              </div>
            )}
            <Styles.OtherDetails>Other details</Styles.OtherDetails>
            <Styles.Heading>Brands</Styles.Heading>
            {data.brand_ids && data.brand_ids[0] === null ? (
              <Styles.DefaultText>{DEFAULT_VALUE}</Styles.DefaultText>
            ) : (
              <Styles.BrandsTags className="d-flex flex-wrap">
                {getBrandsList(data.brand_ids)?.map(tag => {
                  return <Styles.Tags key={tag.id}>{tag.name}</Styles.Tags>;
                })}
                {data.brand_ids?.length === 0 && (
                  <Styles.DefaultHeading>{DEFAULT_VALUE}</Styles.DefaultHeading>
                )}
              </Styles.BrandsTags>
            )}
            <Styles.Heading>Sales representative</Styles.Heading>
            <Styles.SalesWrapper className="flex-start-center">
              <Styles.NameWrapper className="center">
                {getFirstLetters(
                  getSalesRepresentativeName(data.sales_representative)
                )}
              </Styles.NameWrapper>
              <Styles.Text>
                {getSalesRepresentativeName(data.sales_representative)}
              </Styles.Text>
            </Styles.SalesWrapper>
            <Styles.Heading>Category Manager</Styles.Heading>
            <Styles.SalesWrapper className="flex-start-center">
              <Styles.NameWrapper className="center">
                {getFirstLetters(getCategoryManagerName(data.category_manager))}
              </Styles.NameWrapper>
              <Styles.Text>
                {getCategoryManagerName(data.category_manager)}
              </Styles.Text>
            </Styles.SalesWrapper>
            <Styles.Heading>BU Head</Styles.Heading>
            <Styles.SalesWrapper className="flex-start-center">
              <Styles.NameWrapper className="center">
                {getFirstLetters(getBuHeadName(data.bu_head))}
              </Styles.NameWrapper>
              <Styles.Text>{getBuHeadName(data.bu_head)}</Styles.Text>
            </Styles.SalesWrapper>
            <Styles.Heading>Designer</Styles.Heading>
            <Styles.SalesWrapper className="flex-start-center">
              <Styles.NameWrapper className="center">
                {getFirstLetters(getDesignerName(data.designer))}
              </Styles.NameWrapper>
              <Styles.Text>{getDesignerName(data.designer)}</Styles.Text>
            </Styles.SalesWrapper>
          </Styles.FormWrapper>
        </Styles.DesignFormWrapper>
      </Styles.BodyWrapper>
    </div>
  );
};

export default ProductDescription;
