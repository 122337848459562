import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  border: 1px solid var(--very-light-pink);
  border-radius: 4px;
  padding: 0 8px;

  @media only screen and (min-width: 1024px) {
    padding: 5px 8px;
  }

  input {
    flex: 1;
    font-family: inherit;
    padding: 0 12px;
    &:focus {
      border: none;
    }
  }

  .text-input-clear {
    cursor: pointer;
  }
`;
