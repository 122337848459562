import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import { ReactTagsWrapper } from 'components/TrendingModal/styles';

export const Wrapper = styled.div`
  background: var(--white);
`;

export const Body = styled.div`
  height: 65vh;
  padding: 9px 16px;
  overflow: overlay;
`;

export const Heading = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  padding: 13px 16px;
  border-bottom: 1px solid var(--athens-gray);
`;

export const SerialNoTitle = styled.div`
  width: 12%;
  text-align: left;
`;

export const SerialNumber = styled.div`
  width: 12%;
  height: 40px;
  color: var(--rhino);
`;

export const PropertyTitle = styled.div`
  width: 40%;
  text-align: left;
`;

export const SortingContainer = styled.div`
  width: 40%;
  padding-right: 20px;

  .dropdown2-wrapper {
    width: 100%;
    margin-bottom: 24px;

    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;
    .dropdownBox-input {
      padding: 0;
      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }

  .dropdown-placeholder {
    padding-right: 240px;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }
`;

export const ValueTitle = styled.div`
  width: 50%;
`;

export const ReactTagsWrappers = styled(ReactTagsWrapper)`
  padding-bottom: 10px;

  .react-tags {
    width: 100%;
    height: 90px;
    padding: 8px 0 8px 8px;
  }
`;

export const ValueContainer = styled.div`
  width: 40%;

  .dropdown2-wrapper {
    width: 100%;
    margin-bottom: 24px;

    span {
      border: 1px solid var(--mischka);
      border-radius: 4px;

      :focus {
        outline: var(--rhino) !important;
      }
    }
  }

  .dropdownBox-class {
    padding: 16px 20px;
    max-height: 30vh;

    .dropdownBox-input {
      padding: 0;

      .list-item-search-box-class {
        input {
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }

    .dropdown-item-class {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      color: var(--rhino) !important;
      :hover {
        background-color: unset !important;
        color: var(--watermelon) !important;
      }
    }
  }

  .dropdown-placeholder {
    padding-right: 240px;
    color: var(--manatee);
    font-size: 13px;
    line-height: 16px;
  }
`;

export const CloseSortingIcon = styled.div`
  width: 10%;
  height: 40px;
`;

export const SortingButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweedOutLined,
}))`
  width: 180px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;
