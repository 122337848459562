import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import * as Styles from './styles';

interface IDesignTypeFilterProps {
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const DesignTypeFilter = ({ onFilterChange }: IDesignTypeFilterProps) => {
  const designType =
    useTypedSelector(state => state.catalog.primaryFilters.design_type) ?? [];
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedDesignType, setSelectedDesignType] = useState<Array<string>>(
    currentQueryParams?.design_type ?? []
  );

  function handleDesignTypeCheck(design_type: string) {
    const newSelectedDesignType = insertOrRemove(
      selectedDesignType,
      design_type
    );
    onFilterChange({ design_type: newSelectedDesignType });
  }

  function handleDesignTypeClear() {
    onFilterChange({ design_type: [] });
  }

  return (
    <Styles.DesignTypeContainer className="pos-a">
      <Styles.DesignTypeFilter className="spread">
        <Styles.DesignTypeFilterText>Design Type</Styles.DesignTypeFilterText>
        <Styles.DesignTypeFilterClearAll
          className="cursor-pointer"
          onClick={handleDesignTypeClear}
        >
          Clear
        </Styles.DesignTypeFilterClearAll>
      </Styles.DesignTypeFilter>
      <Styles.DesignTypeFilterResults>
        {designType?.map(eachDesign => (
          <Styles.FilterOption key={eachDesign.value}>
            <Checkbox
              item={{
                value: eachDesign.value,
              }}
              label={eachDesign.value}
              onChange={() => handleDesignTypeCheck(eachDesign.value)}
              checked={selectedDesignType.includes(eachDesign.value)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.DesignTypeFilterResults>
    </Styles.DesignTypeContainer>
  );
};

export default DesignTypeFilter;
