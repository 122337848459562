import styled from 'styled-components';

export const MultiSelectContainer = styled.div<{ width: number }>`
  width: ${props => props.width}px;

  .m-dropdown {
    box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
    background-color: var(--white);
    padding: 20px 16px;
    left: 0;
    top: 64px;
  }
`;

export const ListItemWrapper = styled.div`
  margin: 0 20px 10px 0;
  width: 146px;

  label {
    justify-content: flex-start;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const DropdownWrapperTitle = styled.p`
  color: var(--rhino);
  line-height: 14px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
`;
