import styled from 'styled-components';

export const Body = styled.div`
  overflow: overlay;
  flex-grow: 1;
`;

export const HeaderLine = styled.div`
  border-top: 1px solid var(--athens-gray);
`;
