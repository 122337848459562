import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import { ReactTagsWrapper } from 'components/TrendingModal/styles';
import { DropdownWrapper } from '../PrimaryFilterView/styles';

export const MoreFilterContainer = styled.div`
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  background-color: var(--white);
  position: relative;
  z-index: 10;
  padding: 16px 0;
`;

export const ReactTagsWrappers = styled(ReactTagsWrapper)`
  margin-bottom: 16px;

  .react-tags {
    height: 90px;
    padding: 8px 0 8px 8px;
  }
`;

export const MoreFilterBody = styled.div`
  padding: 0 16px 16px 16px;
`;

export const Back = styled.div`
  border-bottom: 1px solid;
  color: var(--athens-gray);
  margin: 15px 0 20px 0;
`;

export const PriceFilterContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

export const CategoryFilterHeaderText = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--rhino);
  margin-bottom: 8px;
  padding-left: 20px;
  margin-top: 10px;
`;

export const PriceTag = styled.div`
  width: 48%;
  padding-top: 10px;
`;

export const InputContainer = styled.div`
  margin: 10px 0 15px 0;
`;

export const CurrencyCategory = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--rhino);
  margin-bottom: 4px;
`;

export const Input = styled.div`
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--athens-gray);
`;

export const CurrencySymbol = styled.p`
  width: 20%;
  border-radius: 4px 0 0 4px;
  font-size: 15px;
  font-weight: 400;
`;

export const CloseIcon = styled.div`
  font-size: 24px;
  margin-right: 16px;
`;

export const InputPrice = styled.input`
  width: 80%;
  border: none;
  &:focus {
    outline-width: 0;
  }
`;

export const BottomTabButtons = styled.div`
  bottom: 24px;
  gap: 20px;
  width: 25vw;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--white);
  border-top: 1px solid var(--athens-gray);
  padding-top: 16px;
`;

export const ApplyButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweed,
}))`
  width: 90px;
  height: 40px;
  margin-right: 24px;
`;

export const ClearButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.MetallicSeaweedOutLined,
}))`
  width: 110px;
  height: 40px;
`;

export const LocationFilterContainer = styled.div`
  left: 0;
  top: 70px;
  z-index: 1;
  background-color: var(--white);
  padding: 16px 16px 0 16px;
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
`;

export const FilterOption = styled.div`
  margin: 0 16px 16px 0;

  label {
    justify-content: flex-start;

    p {
      font-size: 14px;
    }
  }
`;

export const DropdownWrapperAddedBy = styled(DropdownWrapper)`
  margin-top: 16px;

  .dropdown2-wrapper {
    width: 100%;
  }
`;

export const DropdownWrapperTitle = styled.label`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--rhino);
  margin-bottom: 4px;
`;

export const StartDateWrapper = styled.div`
  margin-bottom: 16px;
  width: 140px;
`;

export const DateWrapper = styled.div`
  gap: 16px;

  .day-picker-absolute {
    left: -80px !important;
  }
`;

export const DesignerWrapper = styled.div`
  margin-top: 16px;
`;
