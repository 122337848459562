export enum TabHeaderEnum {
  Catalog = 'catalog',
  SharedCatalog = 'sharedCatalog',
}

export const KEYS_CONTAINING_CATEGORY_MANAGER = ['Category Manager'];

export const KEYS_CONTAINING_DESIGNER = ['Designer'];

export const KEYS_CONTAINING_SALES_REPRESENTATIVE = [
  'BD Associate',
  'BD Head',
  'BD Manager',
];

export const KEYS_CONTAINING_BU_HEAD = ['BU Head'];
