export function isObject(obj: unknown): boolean {
  // The method can't do a type cast since there are type (like strings) which
  // are subclasses of any put not positvely matched by the function. Hence type
  // narrowing results in wrong results.
  return (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof RegExp) &&
    !(obj instanceof Date)
  );
}

export function isEmptyObject(value: any): boolean {
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return true;
}

export function isEmptyArray(value: Array<String>): boolean {
  return value.length === 0;
}
