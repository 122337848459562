export const ERROR_LIST = [
  {
    error: "Invalid value for attribute:colour_map",
    replacement: "Invalid value for color_map",
  },
  {
    error: "Invalid value for attribute: fabric1",
    replacement: "Invalid value for fabric1",
  },
];
