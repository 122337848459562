import { IObjectType } from 'utils/types';

const ExcelUploadFailure = (props: IObjectType) => (
  <svg
    width={103}
    height={110}
    viewBox="0 0 103 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={53.022} cy={44.279} rx={44.5} ry={44.279} fill="#FFFAE6" />
    <rect
      x={0.63}
      y={0.687}
      width={46.119}
      height={64.269}
      rx={4.613}
      transform="matrix(.99928 .04341 -.04383 .99882 46.875 34.513)"
      fill="#E6FCFF"
      stroke="#B3F5FF"
      strokeWidth={1.318}
    />
    <rect
      x={-0.74}
      y={-0.568}
      width={48.761}
      height={66.896}
      rx={5.931}
      transform="rotate(-7.463 254.541 -143.988) skewX(.074)"
      fill="#fff"
      stroke="#00A3BF"
      strokeWidth={1.318}
    />
    <path
      d="M21.482 37.17a5.264 5.264 0 0 1 4.536-5.911l36.587-4.793a5.28 5.28 0 0 1 5.919 4.541l1.287 9.726-47.042 6.163-1.287-9.726Z"
      fill="#B3F5FF"
    />
    <rect
      width={1.967}
      height={6.589}
      rx={0.984}
      transform="rotate(82.463 23.2 58.41) skewX(-.074)"
      fill="#FFC400"
    />
    <rect
      width={1.977}
      height={6.558}
      rx={0.988}
      transform="rotate(-7.463 159.446 -451.593) skewX(.074)"
      fill="#FFC400"
    />
    <rect
      width={1.972}
      height={6.574}
      rx={0.986}
      transform="matrix(.79533 .60724 -.61028 .79138 71.59 18.674)"
      fill="#FFC400"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 404.012 -193.237) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 504.956 -199.946) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={38.369}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 605.886 -206.662) skewX(.074)"
      fill="#00A3BF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 444.396 -195.924) skewX(.074)"
      fill="#B3F5FF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 545.325 -202.642) skewX(.074)"
      fill="#B3F5FF"
    />
    <rect
      width={13.892}
      height={2.633}
      rx={1.317}
      transform="rotate(-7.463 646.255 -209.345) skewX(.074)"
      fill="#B3F5FF"
    />
    <path
      d="M20 44c11.046 0 20-8.954 20-20S31.046 4 20 4 0 12.954 0 24s8.954 20 20 20Z"
      fill="#DE350B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.78 24.188a1 1 0 0 1 0 1.412l-5.488 5.496a1 1 0 0 0 0 1.413l2.026 2.028a1 1 0 0 0 1.415 0l5.481-5.489a1 1 0 0 1 1.416 0l5.642 5.655a1 1 0 0 0 1.415 0l2.025-2.03a1 1 0 0 0 0-1.412l-5.646-5.659a1 1 0 0 1 0-1.413l5.447-5.454a1 1 0 0 0 0-1.413l-2.026-2.029a1 1 0 0 0-1.415 0l-5.44 5.449a1 1 0 0 1-1.416 0l-5.277-5.29a1 1 0 0 0-1.416 0l-2.024 2.03a1 1 0 0 0 0 1.412l5.281 5.294Z"
      fill="#E6E5E3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.78 23.188a1 1 0 0 1 0 1.412l-5.488 5.496a1 1 0 0 0 0 1.413l2.026 2.028a1 1 0 0 0 1.415 0l5.481-5.489a1 1 0 0 1 1.416 0l5.642 5.655a1 1 0 0 0 1.415 0l2.025-2.03a1 1 0 0 0 0-1.412l-5.646-5.659a1 1 0 0 1 0-1.413l5.447-5.454a1 1 0 0 0 0-1.413l-2.026-2.029a1 1 0 0 0-1.415 0l-5.44 5.449a1 1 0 0 1-1.416 0l-5.277-5.29a1 1 0 0 0-1.416 0L9.5 16.482a1 1 0 0 0 0 1.412l5.281 5.294Z"
      fill="#F2F1EF"
    />
  </svg>
);

export default ExcelUploadFailure;
