import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

export const CategoryFilterContainer = styled.div`
  height: 366px;
  width: 240px;
  left: 0;
  top: 70px;
  padding: 20px 16px;
  z-index: 11;
  background-color: var(--white);
  box-shadow: 0 32px 64px rgba(44, 53, 86, 0.25);
`;

export const CategoryHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const CategoryHeaderText = styled.p`
  color: var(--rhino);
`;

export const CategoryHeaderClearAll = styled.p`
  color: var(--watermelon);
`;

export const CategoryContainer = styled.div`
  margin-bottom: 16px;

  input {
    border: none;
  }

  input:focus {
    outline-width: 0;
  }
`;

export const FilterOption = styled.div`
  margin: 0 20px 10px 0;

  label {
    justify-content: flex-start;

    p {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const CategoryFilterResults = styled.div`
  height: 68%;
`;

export const BottomTabButtons = styled.div`
  line-height: 22px;
  font-size: 14px;
  bottom: 0;
  margin-top: 8px;
`;

export const ApplyButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Primary,
}))`
  width: 40%;
  min-width: 48px;
  height: 32px;
`;

export const ClearButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Tertiary,
}))`
  width: 40%;
  min-width: 48px;
  height: 32px;
`;
