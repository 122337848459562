import qs from "qs";

export const sanitizeUrl = (url: string) =>
  url.startsWith("?") ? url.slice(1) : "";

function setQueryStringWithoutPageReload(qsValue: string) {
  let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  if (qsValue.length > 0) {
    newUrl = `${newUrl}?${qsValue}`;
  }
  // window.history.pushState({ path: newUrl }, "", newUrl);
  return newUrl
}

export function setQueryStringValueToUrl(selectedFilters: any) {
  let selectedFilterSearchObject: Record<string, string> = {};
  for (const key in selectedFilters) {
    selectedFilterSearchObject[key] = selectedFilters[key].toString();
  }
  const queryString = `${qs.stringify(selectedFilterSearchObject)}`;
  return queryString;
}
