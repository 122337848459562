const FZ = () => (
  <svg
    width={30}
    height={18}
    viewBox="0 0 30 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1385 0.830566V4.12595H4.21745V7.63318H12.0793V10.9286H4.21745V17.3075H0.592529V0.830566H13.1385ZM29.8128 0.830566V3.58457L20.7505 14.1298H29.8128V17.3075H16.0899V14.5535L25.1522 4.00826H16.3724V0.830566H29.8128Z"
      fill="url(#paint0_linear_3607_3703)"
    />
    <mask
      id="mask0_3607_3703"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={30}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1385 0.830566V4.12595H4.21745V7.63318H12.0793V10.9286H4.21745V17.3075H0.592529V0.830566H13.1385ZM29.8128 0.830566V3.58457L20.7505 14.1298H29.8128V17.3075H16.0899V14.5535L25.1522 4.00826H16.3724V0.830566H29.8128Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3607_3703)" />
    <defs>
      <linearGradient
        id="paint0_linear_3607_3703"
        x1={8.18271}
        y1={20.7701}
        x2={27.6143}
        y2={4.08008}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.00065984} stopColor="#FF2360" />
        <stop offset={1} stopColor="#FF710A" />
      </linearGradient>
    </defs>
  </svg>
);

export default FZ;
