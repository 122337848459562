import { Tag } from 'react-tag-autocomplete';
import Toast from 'library/Toast';
import CATALOG_ACTION_TYPES from 'actionTypes/catalog';
import RULE_ENGINE_TYPES from 'actionTypes/ruleEngine';
import {
  getCondtionInArray,
  checkForUnfilledData,
  getSortingInArray,
} from 'components/CatalogRules/helpers';
import { modifiedFeedbackDataInRequriedForm } from 'components/RuleEngineCollection/FeedBack/helper';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import {
  IConditionPropertiesData,
  IEachRule,
  IRuleProperties,
  ISortingPropertiesData,
  StagingModeEnum,
  IConditionProperties,
  IRuleCollectionData,
} from 'components/CatalogRules/type';
import {
  IGetFeedback,
  IGetViewFeedback,
  IModifiedFeedback,
} from 'components/RuleEngineCollection/types';
import {
  createRuleCollectionApi,
  generateOfflinePPT,
  getAddRuleDataApi,
  getAllRuleCollectionApi,
  getCollectionClusterApi,
  getCollectionFeedbackFilledApi,
  getEachCollectionFeedbackApi,
  postProductsFabricClustersApi,
  sendEachCollectionFeedbackApi,
  updateEachCollectionFeedbackApi,
  updateRuleCollectionApi,
} from 'utils/api';
import { convertArrayToObject } from 'utils/utility/array';
import { IReduxActionType, ThunkActionType } from 'utils/types';
import {
  IEachFabricCluster,
  IProductFabricClusters,
} from 'components/FabricClusters/types';
import { IDownloadPptType } from 'components/AddCatalogList/ImageUpdation/types';
import { ApiResponseEnum } from 'customTypes/callbackActionTypes';
import {
  IGetRuleCollectionData,
  IRuleEngine,
} from 'customTypes/ruleEngineTypes';
import apiResponseHandler from './apiResponseHandler';

export const setCondition = (
  identifier: string,
  value: string,
  key: string
) => ({
  type: RULE_ENGINE_TYPES.SET_CONDITION,
  payload: {
    identifier,
    value,
    key,
  },
});

export const setAttributeOption = (
  identifier: string,
  values: Array<string | number>
) => ({
  type: RULE_ENGINE_TYPES.SET_ATTRIBUTE_OPTION,
  payload: { identifier, values },
});

export const setAttributeOptionTag = (
  key: string,
  identifier: string,
  productTags: Array<Tag>
) => ({
  type: RULE_ENGINE_TYPES.SET_ATTRIBUTE_OPTION_TAG,
  payload: { key, identifier, productTags },
});

export const RemoveAttributeOptionTag = (
  key: string,
  identifier: string,
  trendingTag: Tag
) => ({
  type: RULE_ENGINE_TYPES.REMOVE_ATTRIBUTE_OPTION_TAG,
  payload: { key, identifier, trendingTag },
});

export const fillStagingData = (identifier: string) => ({
  type: RULE_ENGINE_TYPES.FILL_STAGING_DATA,
  payload: identifier,
});

export const fillDataToStaging = (key: string, value: any) => ({
  type: RULE_ENGINE_TYPES.FILL_EDIT_DATA_TO_STAGING,
  payload: { key, value },
});

export const toggleShowUrl = (isOpen: boolean) => ({
  type: RULE_ENGINE_TYPES.TOGGLE_SHOW_URL,
  payload: isOpen,
});

export const toggleAddRule = (isOpen: boolean) => ({
  type: RULE_ENGINE_TYPES.TOGGLE_ADD_RULE,
  payload: isOpen,
});

export const handleAddOrEditRule = (addOrEditMode: StagingModeEnum) => ({
  type: RULE_ENGINE_TYPES.HANDLE_ADD_OR_EDIT_RULE,
  payload: addOrEditMode,
});

export const makeDefaultRuleStaging = () => ({
  type: RULE_ENGINE_TYPES.MAKE_DEFAULT_RULE_STAGING,
});

export const addNewSorting = () => ({
  type: RULE_ENGINE_TYPES.ADD_NEW_SORTING,
});

export const removeEachSortingRow = (identifier: string) => ({
  type: RULE_ENGINE_TYPES.REMOVE_EACH_SORTING,
  payload: { identifier },
});

export const setSorting = (identifier: string, value: string, key: string) => ({
  type: RULE_ENGINE_TYPES.SET_SORTING,
  payload: {
    identifier,
    value,
    key,
  },
});

export const addNewAttribute = () => ({
  type: RULE_ENGINE_TYPES.ADD_NEW_ATTRIBUTE,
});

export const removeEachAttribute = (identifier: string) => ({
  type: RULE_ENGINE_TYPES.REMOVE_EACH_ATTRIBUTE,
  payload: { identifier },
});

export const setAllConditonData = (
  data: Record<string, IConditionPropertiesData>
) => ({
  type: RULE_ENGINE_TYPES.ADD_ALL_CONDITION_DATA,
  payload: { data },
});

export const setAllSortingData = (
  data: Record<string, ISortingPropertiesData>
) => ({
  type: RULE_ENGINE_TYPES.ADD_ALL_SORTING_DATA,
  payload: { data },
});

export const getAddRuleDataThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    const promise = getAddRuleDataApi();
    const response = await apiResponseHandler<IGetRuleCollectionData>(promise);

    try {
      if (response.type === ApiResponseEnum.Success) {
        response.data.conditions_json.map(item => item.attr_options.sort());
        response.data.sorting_json.map(item => item.attr_options.sort());
        dispatch(
          setAllConditonData(
            convertArrayToObject(response.data.conditions_json, 'attr_name')
          )
        );
        dispatch(
          setAllSortingData(
            convertArrayToObject(response.data.sorting_json, 'attr_name')
          )
        );
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const getRuleCollectionData = (
  results: Array<IRuleCollectionData>,
  mode: OperatorEnum
): IReduxActionType => ({
  type: RULE_ENGINE_TYPES.ADD_ALL_RULE_BASED_DATA,
  payload: { results, mode },
});

export const ruleCollectionCount = (data: number) => ({
  type: RULE_ENGINE_TYPES.RULE_COLLECTION_COUNT,
  payload: data,
});

export const setLoaderStatus = (payload: boolean) => ({
  type: CATALOG_ACTION_TYPES.SET_LOADER_STATUS,
  payload,
});

export const ruleCollectionPaginationInfo = (data: Record<string, number>) => ({
  type: RULE_ENGINE_TYPES.COLLECTION_PAGINATION_INFO,
  payload: data,
});

export const getRuleCollectionDataThunk =
  (pageNumber: number, mode: OperatorEnum, filterParams: string = '') =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));

    const promise = getAllRuleCollectionApi(pageNumber, filterParams);
    const response = await apiResponseHandler<IRuleEngine>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        const { data } = response;
        dispatch(
          ruleCollectionPaginationInfo({
            count: data.count,
            totalPages: data.total_pages,
            currentPage: pageNumber,
          })
        );
        dispatch(getRuleCollectionData(data.results, mode));
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setLoaderStatus(false));
  };

export const setSaveRuleCollectionLoader = (isLoading: boolean) => ({
  type: RULE_ENGINE_TYPES.TOGGLE_RULE_COLLECTION_LOADER,
  payload: isLoading,
});

export const updateRuleCollection = (data: IEachRule) => ({
  type: RULE_ENGINE_TYPES.UPDATE_RULE_COLLECTION,
  payload: data,
});

export const createRuleCollection =
  (data: IRuleProperties) =>
  async (dispatch: (action: IReduxActionType | ThunkActionType) => void) => {
    const newRuleCollectiondata: IEachRule = {
      title: data.ruleName,
      category_manager: data.categoryManager,
      sales_representative: data.salesRepresentative,
      approached_brand: data.approachedBrand,
      conditions: getCondtionInArray(data.condition),
      sorting: getSortingInArray(data.sortings),
      images: data.images,
      is_cluster: data.isCluster,
      cluster_type: data.clusterType,
    };

    let hasUnfilledData: boolean = checkForUnfilledData(newRuleCollectiondata);
    if (!hasUnfilledData) return;

    dispatch(setSaveRuleCollectionLoader(true));

    const promise = createRuleCollectionApi(newRuleCollectiondata);
    const response = await apiResponseHandler<string>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        dispatch(getRuleCollectionDataThunk(1, OperatorEnum.Modify));
        dispatch(makeDefaultRuleStaging());
        dispatch(toggleAddRule(false));
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setSaveRuleCollectionLoader(false));
  };

export const editRuleCollectionThunk =
  (data: IRuleProperties) =>
  async (dispatch: (action: IReduxActionType | ThunkActionType) => void) => {
    const newRuleCollectiondata: IEachRule = {
      title: data.ruleName,
      category_manager: data.categoryManager,
      sales_representative: data.salesRepresentative,
      approached_brand: data.approachedBrand,
      conditions: getCondtionInArray(data.condition),
      sorting: getSortingInArray(data.sortings),
      link_identifier: data.link_identifier,
      images: data.images,
      is_cluster: data.isCluster,
      cluster_type: data.clusterType,
    };

    let hasUnfilledData: boolean = checkForUnfilledData(newRuleCollectiondata);
    if (!hasUnfilledData) return;

    dispatch(setSaveRuleCollectionLoader(true));

    const promise = updateRuleCollectionApi(newRuleCollectiondata);
    const response = await apiResponseHandler<string>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        dispatch(getRuleCollectionDataThunk(1, OperatorEnum.Modify));
        dispatch(updateRuleCollection(newRuleCollectiondata));
        dispatch(toggleShowUrl(true));
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setSaveRuleCollectionLoader(false));
  };

export const setAddRuleWithProductIds = (data: IConditionProperties) => ({
  type: RULE_ENGINE_TYPES.SET_ADD_RULE_WITH_PRODUCTS,
  payload: data,
});

export const setFeedbackConditonInRuleCollection = (data: string) => ({
  type: RULE_ENGINE_TYPES.TOGGLE_FEEDBACK_EXIST_STATUS_IN_RULE_COLLECTION,
  payload: { data },
});

export const setStagingFeedbackData = (key: string, value: Array<string>) => ({
  type: RULE_ENGINE_TYPES.SET_STAGING_FEEDBACK_DATA,
  payload: { key, value },
});

export const setStagingFeedbackDataOption = (
  key: string,
  value: Array<string>
) => ({
  type: RULE_ENGINE_TYPES.SET_STAGING_FEEDBACK_DATA_OPTION,
  payload: { key, value },
});

export const setDefaultStagingFeedback = (
  data: Record<
    string,
    Pick<IModifiedFeedback, 'attributeName' | 'attributeOptions' | 'otherInput'>
  >
) => ({
  type: RULE_ENGINE_TYPES.SET_DEFAULT_STAGING_FEEDBACK_DATA,
  payload: data,
});

export const getCollectionFillFeedbackData = (
  data: Record<string, IModifiedFeedback>
): IReduxActionType => ({
  type: RULE_ENGINE_TYPES.GET_COLLECTION_FEEDBACK_INFO,
  payload: data,
});

export const getCollectionFeedbackFillThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    const promise = getCollectionFeedbackFilledApi();
    const response = await apiResponseHandler<Array<IGetFeedback>>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        const { data } = response;
        const modifiedResults = data.map(eachResult => ({
          attributeName: eachResult.attr_name,
          attributeOptions: eachResult.attr_options,
          dataType: eachResult.data_type,
          otherInput: eachResult.other_input,
        }));
        dispatch(
          getCollectionFillFeedbackData(
            convertArrayToObject(modifiedResults, 'attributeName')
          )
        );
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setLoaderStatus(false));
  };

export const sendCollectionFeedbackThunk =
  (
    linkIdentifier: string,
    eachRuleId: string,
    data: Record<
      string,
      Pick<
        IModifiedFeedback,
        'attributeName' | 'attributeOptions' | 'otherInput'
      >
    >
  ) =>
  async (dispatch: (action: IReduxActionType | ThunkActionType) => void) => {
    dispatch(setSaveRuleCollectionLoader(true));
    try {
      const modifiedData = modifiedFeedbackDataInRequriedForm(eachRuleId, data);
      const promise = sendEachCollectionFeedbackApi(modifiedData);
      const response = await apiResponseHandler<Array<IGetFeedback>>(promise);
      if (response.type === ApiResponseEnum.Success) {
        dispatch(setFeedbackConditonInRuleCollection(linkIdentifier));
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setSaveRuleCollectionLoader(false));
  };

export const updateCollectionFeedbackThunk =
  (
    id: string,
    data: Record<
      string,
      Pick<
        IModifiedFeedback,
        'attributeName' | 'attributeOptions' | 'otherInput'
      >
    >
  ) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setSaveRuleCollectionLoader(true));
    try {
      const modifiedData = modifiedFeedbackDataInRequriedForm(id, data);
      const promise = updateEachCollectionFeedbackApi(modifiedData);
      const response = await apiResponseHandler<Array<IGetFeedback>>(promise);
      if (response.type === ApiResponseEnum.Success) {
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setSaveRuleCollectionLoader(false));
  };

export const getEachCollectionFeedback = (
  data: Record<
    string,
    Pick<IModifiedFeedback, 'attributeName' | 'attributeOptions' | 'otherInput'>
  >
): IReduxActionType => ({
  type: RULE_ENGINE_TYPES.GET_VIEW_COLLECTION_FEEDBACK_INFO,
  payload: data,
});

export const getEachCollectionFeedbackThunk =
  (id: string) => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    const promise = getEachCollectionFeedbackApi(id);
    const response = await apiResponseHandler<IGetViewFeedback>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        const { data } = response;
        const modifiedResults = data.feedback.map(eachResult => ({
          attributeName: eachResult.attr_name,
          attributeOptions: eachResult.attr_value,
          otherInput: eachResult.other_input,
        }));
        dispatch(
          getEachCollectionFeedback(
            convertArrayToObject(modifiedResults, 'attributeName')
          )
        );
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(setLoaderStatus(false));
  };

export const setFabricClusterListLoader = (isLoading: boolean) => ({
  type: RULE_ENGINE_TYPES.SET_FABRIC_CLUSTER_LIST_LOADER,
  payload: isLoading,
});

export const setFabricClusterSidebarOpen = (isOpen: boolean) => ({
  type: RULE_ENGINE_TYPES.SET_FABRIC_CLUSTER_SIDEBAR_OPEN,
  payload: isOpen,
});

const setFabricClusterListPaginationData = (
  totalPages: number,
  count: number,
  page: number
) => ({
  type: RULE_ENGINE_TYPES.SET_FABRIC_CLUSTER_LIST_PAGINATION_DATA,
  payload: { totalPages, count, page },
});

const setFabricClusterListData = (
  data: Array<IEachFabricCluster>,
  mode: OperatorEnum
) => ({
  type: RULE_ENGINE_TYPES.SET_FABRIC_CLUSTERS_LIST_DATA,
  payload: { data, mode },
});

export const setSelectedCheckboxesForFabricClusters = (
  selectedIds: string
) => ({
  type: RULE_ENGINE_TYPES.SET_SELECTED_CHECKBOXS_FOR_FABRIC_CLUSTERS,
  payload: selectedIds,
});

export const getAllFabricClustersThunk =
  (page: number, filters = '', mode: OperatorEnum) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getAllRuleCollectionApi(page, filters);
      const { data, status } = response;
      if (status === true) {
        dispatch(
          setFabricClusterListPaginationData(data.total_pages, data.count, page)
        );
        dispatch(setFabricClusterListData(data.results, mode));
        dispatch(setFabricClusterListLoader(false));
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const postProductsFabricClusters =
  (data: IProductFabricClusters, callback: () => void) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    callback();
    try {
      const response = await postProductsFabricClustersApi(data);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      Toast.SUCCESS('Products linked to clusters successfully');
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      callback();
    }
  };

export const downloadSharableLinkPpt =
  (data: IDownloadPptType) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    const promise = generateOfflinePPT(data);
    try {
      const response = await apiResponseHandler<IDownloadPptType>(promise);
      if (response.type === ApiResponseEnum.Success) {
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

const setCollectionCluster = (
  allCollectionClusterData: Array<Record<string, string>>
): IReduxActionType => ({
  type: RULE_ENGINE_TYPES.SET_COLLECTION_CLUSTER_DATA,
  payload: allCollectionClusterData,
});

export const fetchAllCollectionClusterThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    const promise = getCollectionClusterApi();
    const response = await apiResponseHandler<Array<Record<string, string>>>(
      promise
    );
    try {
      if (response.type === ApiResponseEnum.Success) {
        const { data } = response;
        const modifiedResults = data.map(eachResult => ({
          id: eachResult.id,
          name: eachResult.title,
        }));
        dispatch(setCollectionCluster(modifiedResults));
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };
