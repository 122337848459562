import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import TextInputWithLabel from 'components/textInputWithLabel/TextInputWithLabel';
import { useTypedSelector } from 'utils/hooks';
import { setStagingFeedbackData } from 'actions/ruleEngines';
import { IModifiedFeedback } from 'components/RuleEngineCollection/types';
import * as Styles from '../styles';

interface IntegerInputTypeFeedbackProps {
  eachFeedbackData: IModifiedFeedback;
  viewMode: boolean;
}

const IntegerInputTypeFeedback = ({
  eachFeedbackData,
  viewMode = false,
}: IntegerInputTypeFeedbackProps) => {
  const eachFeedbackDataInStaging = useTypedSelector(
    store =>
      store.ruleEngine.stagingfeedbackData[eachFeedbackData.attributeName]
  );
  const dispatch = useDispatch();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    dispatch(
      setStagingFeedbackData(eachFeedbackData.attributeName, [
        event.target.value,
      ])
    );
  }

  return (
    <Styles.IntegerInputWrapper>
      <TextInputWithLabel
        datalabel={eachFeedbackData.attributeName}
        placeholder="Type Number"
        dataname={eachFeedbackData.attributeName}
        value={eachFeedbackDataInStaging.attributeOptions[0]}
        onChange={handleChange}
        shouldAutoFocus={false}
        isDisabled={viewMode}
        isNumber
      />
    </Styles.IntegerInputWrapper>
  );
};

export default IntegerInputTypeFeedback;
