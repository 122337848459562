import MainLogo from 'assets/icons/MainLogo2';
import { IconPropsType } from 'customTypes/icons';
import * as Styles from './styles';

interface IFashinzaIconLoaderProps extends IconPropsType {
  show?: boolean;
  className?: string;
}

const FashinzaIconLoader = ({
  show = false,
  className = '',
}: IFashinzaIconLoaderProps) => {
  return (
    <Styles.WrapperCircular className={`${!show ? 'display-none' : 'center'}`}>
      <Styles.ProfileMainLoader>
        <div>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#FA5762"
              strokeWidth="2px"
            />
          </svg>
          <Styles.Image>
            <MainLogo height={46} width={46} showFullIcon={false} />
          </Styles.Image>
        </div>
      </Styles.ProfileMainLoader>
    </Styles.WrapperCircular>
  );
};

export default FashinzaIconLoader;
