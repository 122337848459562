import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import { FilterTypes } from 'components/Filters/type';
import {
  fetchMaterialsThunk,
  getCategoryManagerAndSalesRepThunk,
} from 'actions/catalog';
import { fetchAWSCredentialsThunk } from 'actions/credential';
import {
  deleteDesignLibraryThunk,
  getDesignLibraryDataThunk,
  getSeasonAndFabricDataThunk,
} from 'actions/designLibrarys';
import FashinzaIconLoader from 'library/IconLoader';
import SideDrawer from 'library/SideDrawer';
import Modal from 'library/Modal';
import AddDesignIllustration from 'assets/Illustrations/AddDesignIllustration';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { setQueryStringValueToUrl } from 'utils/routing';
import { removeFalsyKeys } from 'utils/utility/utils';
import { DEFAULT_VALUE } from 'utils/defaults';
import ICONOGRAPHY from 'CustomConstant/icons';
import AddDesign from './AddDesign';
import ProductDescription from './ProductDescription';
import Filters from './Filters';
import { DEFAULT_IMAGE, INITIAL_STATE } from './constants';
import { IDesignLibrary } from './types';
import * as Styles from './styles';

const {
  'chevron-right': CheveronRightIcon,
  download: DownloadIcon,
  trash: TrashIcon,
  edit: EditIcon,
} = ICONOGRAPHY;

const DesignLibrary = () => {
  const { search: queryParams } = useLocation();
  const [search, setSearch] = useSearchParams(new URLSearchParams(''));
  const [searchParam, setSearchParam] = useState('');
  const dispatch = useDispatch();
  const [isOpenDesignSideDrawer, setOpenSideDrawer] = useBoolean();
  const [productDescriptionSidebarOpen, setProductDescriptionSidebar] =
    useBoolean();
  const designLibraryData =
    useTypedSelector(state => state.designLibrary.designLibraryData) ?? [];
  const isLoading = useTypedSelector(state => state.designLibrary.isLoading);
  const designLibraryPaginationInfo = useTypedSelector(
    state => state.designLibrary.designLibraryPaginationInfo
  );
  const [selectedDesign, setSelectedDesign] = useState<IDesignLibrary>({
    ...INITIAL_STATE,
  });
  const [showDeleteModal, setDeleteModal] = useBoolean();
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [editRowId, setEditRowId] = useState<number | null>(null);
  const observer = useRef<any>(null);

  function lastItemRef(node: HTMLDivElement) {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchNextSetOfDesigns();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }

  function handleExploreNowClick(each: IDesignLibrary) {
    setSelectedDesign(each);
    setProductDescriptionSidebar.on();
  }

  useEffect(() => {
    dispatch(fetchMaterialsThunk());
    dispatch(getCategoryManagerAndSalesRepThunk());
    dispatch(getSeasonAndFabricDataThunk());
    dispatch(fetchAWSCredentialsThunk());
  }, []);

  useEffect(() => {
    dispatch(getDesignLibraryDataThunk(1, OperatorEnum.Modify, searchParam));
  }, [search, searchParam]);

  function fetchNextSetOfDesigns() {
    if (
      designLibraryPaginationInfo.currentPage >=
      designLibraryPaginationInfo.totalPages
    )
      return;

    dispatch(
      getDesignLibraryDataThunk(
        designLibraryPaginationInfo.currentPage + 1,
        OperatorEnum.Append,
        searchParam
      )
    );
  }

  function handleDeleteModal(designId: number | null) {
    setCurrentId(designId);
    setDeleteModal.on();
  }

  function handleDesignFormSave() {
    dispatch(deleteDesignLibraryThunk({ id: currentId }));
    setDeleteModal.off();
  }

  function handleEditDesignForm(designId: number | null) {
    setEditRowId(designId);
    setOpenSideDrawer.on();
  }

  function handleFilterChange(
    param: Partial<Record<FilterTypes, Array<string>>>,
    reset = false
  ) {
    const prevQueryParams = getQueryParams(queryParams);
    let queryParamObject: Record<string, Array<string>> = {};

    if (!reset) {
      let newQueryParams = {
        ...prevQueryParams,
      };
      for (let filter in param) {
        newQueryParams = {
          ...newQueryParams,
          [filter]: param[filter as keyof typeof param] ?? [],
        };
      }
      queryParamObject = newQueryParams;
    }
    let currentParam = '';
    Object.keys(queryParamObject).forEach((item: string) => {
      if (queryParamObject[item].length > 0) {
        return (currentParam +=
          item + '=' + queryParamObject[item].join() + '&');
      }
    });

    const sanitizedFilters = removeFalsyKeys(queryParamObject);
    const queryString = setQueryStringValueToUrl(sanitizedFilters);
    setSearchParam(currentParam.slice(0, currentParam.length - 1));
    setSearch(new URLSearchParams(queryString));
  }

  return (
    <>
      <Filters onFilterChange={handleFilterChange} />
      <Styles.Wrapper className="has-scroll full-height">
        {showDeleteModal && currentId && (
          <Modal
            heading="Remove Design"
            footer={
              <Styles.ConfirmButton
                className="full-width"
                onClick={handleDesignFormSave}
              >
                Confirm
              </Styles.ConfirmButton>
            }
            onClose={() => setDeleteModal.off()}
          >
            <div className="v-d-flex">
              <Styles.SureText>Are you sure ?</Styles.SureText>
              <Styles.SureDescription>
                Do you really want to delete these file? This process cannot be
                undone.
              </Styles.SureDescription>
            </div>
          </Modal>
        )}
        <Styles.DesignCardsWrapper className="d-flex flex-wrap">
          {isLoading ? (
            <FashinzaIconLoader show />
          ) : (
            <>
              {designLibraryData.length > 0 && !isLoading ? (
                <>
                  {designLibraryData.map((each, index) => (
                    <Styles.CardWrapper
                      onClick={() => handleExploreNowClick(each)}
                      key={each.id}
                      className="fit-height pos-r"
                      ref={
                        index === designLibraryData.length - 1
                          ? lastItemRef
                          : null
                      }
                    >
                      <Styles.ThumbnailWrapper className="no-scroll pos-r cursor-pointer">
                        <img
                          src={each.thumbnail_url ?? DEFAULT_IMAGE}
                          alt="Default"
                        />
                        <Styles.ActionsWrapper className="flex-end pos-a">
                          <Styles.ActionsSection className="align-center">
                            <Styles.DownloadWrapper
                              className="center cursor-pointer"
                              href={each.pdf_url}
                              target="_blank"
                              onClick={event => event.stopPropagation()}
                            >
                              <DownloadIcon stroke="var(--white)" size="20" />
                            </Styles.DownloadWrapper>
                            <Styles.EditIconWrapper>
                              <EditIcon
                                stroke="var(--white)"
                                size="20"
                                onClick={event => {
                                  event.stopPropagation();
                                  handleEditDesignForm(each.id);
                                }}
                              />
                            </Styles.EditIconWrapper>
                            <Styles.TrashIconWrapper>
                              <TrashIcon
                                className="cursor-pointer"
                                size="20"
                                stroke="var(--white)"
                                onClick={event => {
                                  event.stopPropagation();
                                  handleDeleteModal(each.id);
                                }}
                              />
                            </Styles.TrashIconWrapper>
                          </Styles.ActionsSection>
                        </Styles.ActionsWrapper>
                      </Styles.ThumbnailWrapper>
                      <Styles.CardInformationWrapper className="cursor-pointer">
                        <Styles.SeasonText>
                          {each.season.length > 0 ? each.season : DEFAULT_VALUE}
                        </Styles.SeasonText>
                        <Styles.Title className="truncated-text">
                          {each.name.length > 0 ? each.name : DEFAULT_VALUE}
                        </Styles.Title>
                        <Styles.Description className="truncated-text">
                          {each.description.length > 0
                            ? each.description
                            : DEFAULT_VALUE}
                        </Styles.Description>
                        <Styles.ExploreNowButton className="center">
                          Explore Now
                          <CheveronRightIcon />
                        </Styles.ExploreNowButton>
                      </Styles.CardInformationWrapper>
                    </Styles.CardWrapper>
                  ))}
                </>
              ) : (
                <Styles.AddNewDesignWrapper className="v-d-flex full-width center">
                  <AddDesignIllustration />
                  <Styles.NoDesignText>No designs is added</Styles.NoDesignText>
                  <Styles.AddDesign
                    className="cursor-pointer"
                    onClick={() => setOpenSideDrawer.on()}
                  >
                    Add Design
                  </Styles.AddDesign>
                </Styles.AddNewDesignWrapper>
              )}
            </>
          )}
        </Styles.DesignCardsWrapper>
      </Styles.Wrapper>
      <Styles.AddDesignButton
        className="cursor-pointer center pos-a"
        onClick={() => {
          setEditRowId(null);
          setOpenSideDrawer.on();
        }}
      >
        Add Design
      </Styles.AddDesignButton>
      <SideDrawer isOpen={isOpenDesignSideDrawer} width="calc(100vw - 2px)">
        {isOpenDesignSideDrawer && (
          <AddDesign
            onClose={() => setOpenSideDrawer.off()}
            rowId={editRowId}
          />
        )}
      </SideDrawer>
      <SideDrawer
        width="calc(100vw - 2px)"
        isOpen={productDescriptionSidebarOpen}
      >
        <ProductDescription
          data={selectedDesign}
          onClose={() => setProductDescriptionSidebar.off()}
        />
      </SideDrawer>
    </>
  );
};

export default DesignLibrary;
