import { ChangeEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextInput from 'components/textInput/textInput';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import Checkbox from 'library/checkbox/checkbox';
import { insertOrRemove } from 'utils/utility/utils';
import { useAllSaleRepresentativeDetail } from '../helper';
import { IGetEachOption, FilterTypes } from '../type';
import * as Styles from './styles';

interface ISaleRepresentativeFilterProps {
  onClose(): void;
  onFilterChange(param: Partial<Record<FilterTypes, Array<string>>>): void;
}

const SaleRepresentativeFilter = ({
  onClose,
  onFilterChange,
}: ISaleRepresentativeFilterProps) => {
  const saleRepresentative = useAllSaleRepresentativeDetail();
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [createdBySearch, setCreatedBySearch] = useState('');
  const [list, setCreatedByList] =
    useState<Array<IGetEachOption>>(saleRepresentative);
  const [selectedCheckbox, setSelectedCheckbox] = useState<Array<string>>(
    currentQueryParams?.sales_representative__in ?? []
  );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    setCreatedBySearch(value);
    setCreatedByList(matchQuery(value, saleRepresentative));
  }

  function matchQuery(value: string, options: Array<any>) {
    const newCategories: Array<IGetEachOption> = [];
    options.forEach(eachOption => {
      if (eachOption.name.toLowerCase().startsWith(value.toLowerCase())) {
        newCategories.push(eachOption);
      }
    });

    return newCategories;
  }

  function handleCategoryCheck(value: string) {
    const updatedSelectedOptions = insertOrRemove(selectedCheckbox, value);
    setSelectedCheckbox(updatedSelectedOptions);
  }

  function handleApplyFilter() {
    onFilterChange({ sales_representative__in: selectedCheckbox });
  }

  function handleClearOption() {
    onFilterChange({ sales_representative__in: [] });
  }

  return (
    <Styles.SaleRepresentativeFilterContainer className="pos-a full-width">
      <Styles.SaleRepresentativeHeader className="spread">
        <Styles.SaleRepresentativeHeaderText>
          Sales Representatives
        </Styles.SaleRepresentativeHeaderText>
        <Styles.SaleRepresentativeHeaderClearAll
          className="cursor-pointer"
          onClick={handleClearOption}
        >
          Clear
        </Styles.SaleRepresentativeHeaderClearAll>
      </Styles.SaleRepresentativeHeader>
      <Styles.SaleRepresentativeContainer>
        <TextInput
          placeholder="Search Name"
          onChange={handleSearchChange}
          onClear={() => null}
          value={createdBySearch}
          listItemSearchBoxClass="list-item-search-box-class"
          showCrossButton={false}
        />
      </Styles.SaleRepresentativeContainer>
      <Styles.SaleRepresentativeFilterResults className="has-y-scroll">
        {list?.map(item => (
          <Styles.FilterOption key={item.id}>
            <Checkbox
              item={{
                value: item.id,
              }}
              label={item.name}
              onChange={() => handleCategoryCheck(item.id)}
              checked={selectedCheckbox.includes(item.id)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.SaleRepresentativeFilterResults>
      <Styles.BottomTabButtons className="spread">
        <Styles.ClearButton onClick={onClose}>Cancel</Styles.ClearButton>
        <Styles.ApplyButton
          className="cursor-pointer"
          onClick={handleApplyFilter}
        >
          Apply
        </Styles.ApplyButton>
      </Styles.BottomTabButtons>
    </Styles.SaleRepresentativeFilterContainer>
  );
};

export default SaleRepresentativeFilter;
