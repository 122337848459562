import { IEmailers } from './types';

interface IFormItem {
  id: number;
  key: keyof IEmailers;
}

export const EMAILER_DATA: Array<IFormItem> = [
  {
    id: 1,
    key: 'subject',
  },
  {
    id: 2,
    key: 'hero_image_link',
  },
  {
    id: 3,
    key: 'primary_section_background_color',
  },
  {
    id: 4,
    key: 'title_text_color',
  },
  {
    id: 5,
    key: 'description_text_color',
  },
  {
    id: 6,
    key: 'description',
  },
  {
    id: 7,
    key: 'secondary_button_background_color',
  },
  {
    id: 8,
    key: 'secondary_description_text_color',
  },
  {
    id: 9,
    key: 'first_collection_url',
  },
  {
    id: 10,
    key: 'secondary_description',
  },
  {
    id: 11,
    key: 'primary_button_text_color',
  },

  {
    id: 12,
    key: 'secondary_button_title',
  },
  {
    id: 13,
    key: 'secondary_title',
  },
  {
    id: 14,
    key: 'collection_description_text_color',
  },

  {
    id: 15,
    key: 'primary_button_background_color',
  },
  {
    id: 16,
    key: 'secondary_title_text_color',
  },
  {
    id: 17,
    key: 'secondary_section_background_color',
  },
  {
    id: 18,
    key: 'primary_button_url',
  },
  {
    id: 19,
    key: 'collection_products_background_color',
  },
  {
    id: 20,
    key: 'secondary_button_url',
  },
  {
    id: 21,
    key: 'secondary_button_title_color',
  },
  {
    id: 22,
    key: 'primary_button_text',
  },
  {
    id: 23,
    key: 'collection_description',
  },
  {
    id: 24,
    key: 'collection_products_name_text_color',
  },
];
