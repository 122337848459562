import styled from 'styled-components';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

export const Header = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid var(--athens-gray);
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  height: 64px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--rhino);
`;

export const CloseWrapper = styled.div`
  background-color: var(--input-disabled);
  width: 34px;
  height: 34px;
  border-radius: 50%;
`;

export const BodyWrapper = styled.div`
  background-color: var(--input-disabled);
  flex: 1;
`;

export const UploadSection = styled.div`
  width: 65%;
`;

export const DesignFormWrapper = styled.div`
  width: 35%;
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  height: auto;
  align-self: flex-start;
  top: 0;
`;

export const FormWrapper = styled.div`
  padding: 24px;
`;

export const UploadWrapper = styled.div`
  padding: 24px;
  height: 92vh;
`;

export const FormTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--manatee);
  margin-bottom: 20px;
`;

export const Heading = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
`;

export const SubHeading = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
  margin-bottom: 20px;
`;

export const DefaultHeading = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
`;

export const Tags = styled.div`
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
  background-color: var(--athens-gray);
  margin-right: 8px;
  margin-top: 4px;
`;

export const OtherDetails = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--manatee);
  margin-bottom: 20px;
  margin-top: 32px;
`;

export const BrandsTags = styled.div`
  margin-bottom: 32px;
`;

export const SalesWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const NameWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 70px;
  background-color: var(--athens-gray);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
  margin-right: 12px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
`;

export const PdfWrapper = styled.div`
  padding: 24px;

  .react-pdf__Page__canvas {
    margin: 16px auto;
    width: 90% !important;
    height: 100% !important;
  }

  .react-pdf__Page__annotations {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  margin-top: 32px;
`;

export const ActionButton = styled(StyledButton).attrs(() => ({
  variant: StyledButtonVariant.Subtle,
}))`
  width: 100px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  padding: 12px;

  &:focus {
    background-color: var(--input-disabled);
  }
`;

export const PageNumbers = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--rhino);
`;

export const DownloadPdf = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--watermelon);
  margin-bottom: 16px;
  display: inline-block;
`;

export const DefaultText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
  margin: 8px 0 16px 0;
`;

export const TitleSubHeading = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
  margin-bottom: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
