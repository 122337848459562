import React, { Component } from 'react';
import { Button, Wrapper, Menu, MenuItem } from 'react-aria-menubutton';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import TextInput from '../textInput/textInput';
import { createElementWithEvent, stopEvent } from '../../utils/utility';
import * as Styles from './styles';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      search: '',
      searchOptions: [],
    };
  }

  componentDidMount() {
    const { options } = this.props;
    if (options.length) {
      this.setState({
        searchOptions: options,
      });
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.options !== this.props.options) {
      this.setState({
        searchOptions: this.matchQuery(this.state.search),
      });
    }
  };

  onChange = (val, event) => {
    const { dataProps = {}, dataname, onChange } = this.props;
    createElementWithEvent({
      value: val,
      dataname,
      event: 'change',
      onChange,
      dataProps,
    });
    stopEvent(event);
  };

  matchQuery = value => {
    const { options } = this.props;
    if (!value) {
      return options;
    }
    const newOptions = [];
    for (let i = 0; i < options.length; i++) {
      const option = { ...options[i] };
      if (option.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        newOptions.push(option);
    }
    return newOptions;
  };

  onChangeSearch = event => {
    const { value } = event.target;
    const newOptions = this.matchQuery(value);
    this.setState({
      search: value,
      searchOptions: newOptions,
    });
  };

  onClear = () => {
    this.setState({
      search: '',
      searchOptions: this.props.options,
    });
  };

  render() {
    const {
      onChange,
      value,
      dataname,
      options,
      datalabel,
      placeholder,
      isError,
      dropdownClass,
      dropdownBoxClass,
      dropdownItemClass,
      errorClass,
      openClass,
      customLabel,
      hideAllOption,
      disable_edit = false,
      listItemSearchBoxClass = '',
      isSearchBox = false,
      fixedWidth = '',
      className = '',
    } = this.props;
    const { isOpen, search, searchOptions } = this.state;

    let itemLabel = value || placeholder || '';
    if (customLabel) {
      itemLabel = customLabel;
    }

    if (options.length) {
      const items = options.filter(item => String(item.id) === String(value));
      if (items.length === 1) {
        itemLabel = items[0].name;
      }
    }

    return (
      <Styles.StyledWrapper
        className={className}
        isPlaceholderPresent={Boolean(value) ? false : Boolean(placeholder)}
      >
        <Wrapper
          onSelection={this.onChange}
          className="dropdown2-wrapper"
          onMenuToggle={menuToggleProps => {
            this.setState({ isOpen: menuToggleProps.isOpen });
          }}
        >
          <Button
            className={[
              dropdownClass,
              isError ? errorClass : '',
              isOpen ? openClass || '' : '',
            ].join(' ')}
            disabled={!!disable_edit}
          >
            {!itemLabel && <div className="label-name">{datalabel}</div>}

            {itemLabel && <div className="label-value">{itemLabel}</div>}
            {!disable_edit && (
              <div className="label-downarrow">
                {isOpen ? <BiChevronUp /> : <BiChevronDown />}
              </div>
            )}
          </Button>
          <Menu
            className={['Menu-dropdown-box', dropdownBoxClass, fixedWidth].join(
              ' '
            )}
          >
            {isSearchBox && (
              <div className="dropdownBox-input">
                <TextInput
                  placeholder={placeholder || 'Search'}
                  dataname="dataname"
                  onChange={this.onChangeSearch}
                  onClear={this.onClear}
                  value={search}
                  listItemSearchBoxClass={
                    listItemSearchBoxClass || 'list-item-search-box-class'
                  }
                />
              </div>
            )}
            {!hideAllOption && (
              <li>
                <MenuItem
                  className={[
                    dropdownItemClass || '',
                    'MyMenuButton-menuItem',
                    itemLabel === '' ? 'activeClass' : '',
                  ].join(' ')}
                  data-name={dataname}
                  data-label=""
                  data-value=""
                  value=""
                >
                  {datalabel || 'All'}
                </MenuItem>
              </li>
            )}
            {searchOptions.map(item => {
              if (item.type === 'line_break') {
                return (
                  <div
                    className="line-break center"
                    key={`pg-fl-linebr-${item.id}-${dataname}`}
                  >
                    <div className="line-break-inner" />
                  </div>
                );
              }
              return (
                <li key={`pg-fl-dd-${item.id}-${dataname}`}>
                  <MenuItem
                    className={[
                      dropdownItemClass || '',
                      'MyMenuButton-menuItem',
                      itemLabel === item.name ? 'activeClass' : '',
                    ].join(' ')}
                    data-name={dataname}
                    data-label={item.name}
                    data-value={item.id}
                    value={
                      dataname === 'categories' ? item.category_code : item.id
                    }
                  >
                    {item.name}
                  </MenuItem>
                </li>
              );
            })}
          </Menu>
        </Wrapper>
      </Styles.StyledWrapper>
    );
  }
}

Dropdown.defaultProps = {
  placeholder: '',
  dropdownClass: '',
  dropdownBoxClass: '',
};

export default Dropdown;
