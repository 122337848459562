import styled from "styled-components";

export const DropdownStyle = styled.div`
  position: absolute;
  right: 10px;
  margin-top: 10px;
  z-index: 10;
  padding: 12px 1px;
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
  border: 1px solid var(--very-light-pink-two);
  border-radius: 6px;
  background-color: var(--white);
  height: fit-content;
`;

export const DropdownListItemStyle = styled.div`
  cursor: pointer;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.57;
  color: var(--blue-grey);
  white-space: nowrap;
  &:hover {
    background-color: var(--gray-primary);
  }
`;

export const ToggleWrapper = styled.div`
  position: relative;
`;
