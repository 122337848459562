import styled from "styled-components";

export const StyledWrapper = styled.div<{ isPlaceholderPresent: boolean }>`
  .dropdown2-wrapper {
    flex: 1;
    position: relative;
    height: 40px;

    span {
      border-radius: 8px;
      border: solid 1px #f1f1f1;
      padding: 0 8px 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      height: 100%;
      width: 100%;

      &[aria-disabled="true"] {
        color: var(--black-primary);
        background-color: var(--input-disabled);
        cursor: auto;
      }

      .label-name {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        color: var(--manatee);
      }

      .label-value {
        white-space: nowrap;
        overflow: hidden;
        padding-left: 5px;
        font-size: 14px;
        color: ${(props) =>
          props.isPlaceholderPresent ? "var(--manatee)" : "var(--rhino)"};
        padding: 2px;
      }

      .label-downarrow {
        margin-left: 16px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        img {
          max-width: 24px;
          margin-left: auto;
        }
      }

      &:focus {
        outline: none;
      }

      &:active {
        box-shadow: 3px 3px var(--mischka);
      }
    }

    .Menu-dropdown-box {
      position: absolute;
      z-index: 10;
      top: calc(100% + 8px);
      left: 0;
      width: 100%;
      background-color: white;
      padding: 12px 0.1rem;
      box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);
      max-height: 40vh;
      overflow-y: scroll;

      .dropdownBox-input {
        padding: 16px;
        padding-bottom: 8px;

        .list-item-search-box-class {
          height: 40px;
          background-color: #f8f8f8;
          border: none;

          input {
            background-color: #f8f8f8;
            font-size: 14px;
            color: var(--almost-black);
          }
        }
      }

      .line-break {
        .line-break-inner {
          display: flex;
          height: 1px;
          margin: 6px 0;
          width: 87%;
          background: var(--brown-grey);
        }
      }

      li {
        list-style: none;

        .MyMenuButton-menuItem {
          width: 100%;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          line-height: 2.57;
          color: #8288a2;

          &.activeClass {
            color: var(--watermelon);
            font-weight: 600;
          }

          &:focus {
            outline: none;
            background-color: var(--gray-primary);
          }

          &:hover {
            background-color: var(--gray-primary);
          }
        }
      }
    }

    .capitalize {
      text-transform: capitalize;
    }
  }
`;
