import { ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { useAllDesignerDetail } from 'components/Filters/helper';
import UploadExcel from './UploadExcel';
import DownloadExcelTemplate from './DownloadExcelTemplate';
import GenerateImageLink from './GenerateImageLink';
import UploadSuccess from './UploadSuccess';
import UploadFailed from './UploadFailed';
import UploadInProgress from './UploadInProgress';
import { fetchAWSCredentialsThunk } from '../../actions/credential';
import {
  fetchExcelTemplateCategoriesThunk,
  uploadCatalogueSheetFailure,
  uploadCatalogueSheetThunk,
  uploadCatalogueSheetIdle,
  uploadCatalogueSheetRequest,
  fetchMaterialsThunk,
  fetchCategoriesThunk,
  fetchSuppliersThunk,
  fetchAllLocationRegionThunk,
  getCategoryManagerAndSalesRepThunk,
} from '../../actions/catalog';
import { useTypedSelector } from '../../utils/hooks';
import { createSheetUploadStatusEnum } from '../../utils/constants';
import { AttachmentType } from '../../utils/types';
import { uploadFileAws } from '../../services/aws';
import { IDownloadExcelState } from './types';
import * as Styles from './styles';

interface IAddCatalogueProps {
  isAdmin: boolean;
}

interface IAddCatalogueState {
  uploadedExcelLink: string;
  excelTemplateCategory: Array<IDownloadExcelState>;
  uploadedImages: Array<AttachmentType>;
  level: string | number;
  createSheetUploadFilename: string;
  selectedSupplier: string | number;
}

const initialState: IAddCatalogueState = {
  uploadedExcelLink: '',
  excelTemplateCategory: [],
  uploadedImages: [],
  level: 0,
  createSheetUploadFilename: '',
  selectedSupplier: '',
};

const LEVEL_ZERO = 'root';

const AddCatalogue = ({ isAdmin }: IAddCatalogueProps) => {
  const dispatch = useDispatch();
  const credentials = useTypedSelector(
    store => store.awsCredentials.catalogExcel
  );
  const excelTemplateCategories = useTypedSelector(
    store => store.catalog.excelTemplateCategories
  );
  const regionData = useTypedSelector(
    store => store.catalog.allLocationRegionData
  );
  const createSheetUploadStatus = useTypedSelector(
    store => store.catalog.createSheetUploadStatus
  );
  const createSheetUploadErrorMessage = useTypedSelector(
    store => store.catalog.createSheetUploadErrorMessage
  );
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedLocations, setSelectedLocations] = useState(
    currentQueryParams.region ? currentQueryParams.region : []
  );
  const [selectedDesigner, setSelectedDesigner] = useState(
    currentQueryParams.designer_id && currentQueryParams.designer_id[0]
  );
  const linkedSupplier = useTypedSelector(store => store.user.linked_supplier);
  const isSupplier = useTypedSelector(store => store.user.is_supplier);
  const designersData = useAllDesignerDetail();
  const [state, setState] = useState<IAddCatalogueState>(initialState);

  function getSelectedLocationIds(data: Array<string>) {
    return regionData
      .filter(each => data.includes(each.name))
      .map(each => each.id);
  }

  async function handleChange(
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    data?: Array<AttachmentType> | string | number
  ) {
    if (field === 'uploadedExcelLink') {
      dispatch(uploadCatalogueSheetRequest());
      const { files } = event.target;
      if (files === null) {
        return;
      }
      const file = files[0];
      setState(prevState => ({
        ...prevState,
        createSheetUploadFilename: file.name,
      }));
      try {
        const filePromise = await uploadFileAws({ file, credentials });
        setState(prevState => ({
          ...prevState,
          [field]: filePromise.url,
        }));
        dispatch(
          uploadCatalogueSheetThunk(
            getSelectedLocationIds(selectedLocations),
            Number(selectedDesigner),
            state.selectedSupplier,
            filePromise.url
          )
        );
      } catch (err) {
        console.log(err);
        dispatch(uploadCatalogueSheetFailure(''));
      }
    } else if (field === 'excelTemplateCategory') {
      if (data === undefined) {
        return;
      }
      if (typeof data === 'number' || typeof data === 'string') {
        const isLeafNode = state.excelTemplateCategory[
          Number(data)
        ].levelOptions.filter(item => item.id === Number(event.target.value))[0]
          .is_leaf_node;
        const newExcelTemplateCategory = state.excelTemplateCategory;
        //modifying selectedProductId for current level
        newExcelTemplateCategory[Number(data)].selectedProductId =
          event.target.value;
        //fetch if not leaf node
        if (!isLeafNode) {
          // fetching next dropdown options
          dispatch(fetchExcelTemplateCategoriesThunk(event.target.value));
          // setting value of next level,
          setState(prevState => ({
            ...prevState,
            level: Number(data) + 1,
            excelTemplateCategory: [...newExcelTemplateCategory],
          }));
        } else {
          setState(prevState => ({
            ...prevState,
            level: Number(data),
            excelTemplateCategory: [...newExcelTemplateCategory],
          }));
        }
      }
    } else if (field === 'selectUploadSheetCategory') {
      const { value } = event.target;
      setState({ ...state, selectedSupplier: value });
    }
  }

  useEffect(() => {
    dispatch(fetchAWSCredentialsThunk());
    dispatch(fetchExcelTemplateCategoriesThunk(LEVEL_ZERO));
    dispatch(fetchCategoriesThunk());
    dispatch(fetchSuppliersThunk());
    dispatch(fetchMaterialsThunk());
    dispatch(fetchAllLocationRegionThunk());
    dispatch(getCategoryManagerAndSalesRepThunk());
    setState(prevState => ({
      ...prevState,
      level: 0,
      selectedSupplier: linkedSupplier?.id || '',
    }));
    dispatch(uploadCatalogueSheetIdle());
  }, []);

  useEffect(() => {
    if (isSupplier) {
      setState(prevState => ({
        ...prevState,
        selectedSupplier: linkedSupplier.id,
      }));
    }
  }, [linkedSupplier.id, isSupplier]);

  useEffect(() => {
    if (state.level < state.excelTemplateCategory.length - 1) {
      const newArray = state.excelTemplateCategory.slice(
        0,
        Number(state.level) + 1
      );
      setState(prevState => ({
        ...prevState,
        excelTemplateCategory: [...newArray],
      }));
      return;
    }
    const newDropdownData = state.excelTemplateCategory.filter(
      item => item.levelId < state.level
    );
    const newDropdownDataObj = {
      levelId: Number(state.level),
      selectedProductId: '',
      levelOptions: excelTemplateCategories,
    };
    newDropdownData.push(newDropdownDataObj);
    setState(prevState => ({
      ...prevState,
      excelTemplateCategory: newDropdownData,
    }));
  }, [excelTemplateCategories, state.level]);

  return (
    <Styles.Wrapper className="page-container full-view-dimensions has-scroll">
      {createSheetUploadStatus === createSheetUploadStatusEnum.IDLE && (
        <>
          <UploadExcel
            handleChange={handleChange}
            selectedSupplier={state.selectedSupplier}
            setSelectedLocations={setSelectedLocations}
            selectedLocations={selectedLocations}
            setSelectedDesigner={setSelectedDesigner}
            selectedDesigner={selectedDesigner}
          />
          <Styles.RowWrapper>
            <DownloadExcelTemplate
              handleChange={handleChange}
              excelTemplateCategory={state.excelTemplateCategory}
              className="download-excel-template"
            />
            <GenerateImageLink className="generate-image-link" />
          </Styles.RowWrapper>
        </>
      )}
      {createSheetUploadStatus === createSheetUploadStatusEnum.SUCCESS && (
        <UploadSuccess
          filename={state.createSheetUploadFilename}
          fileUrl={state.uploadedExcelLink}
        />
      )}
      {createSheetUploadStatus === createSheetUploadStatusEnum.FAILED && (
        <UploadFailed
          error={createSheetUploadErrorMessage}
          filename={state.createSheetUploadFilename}
        />
      )}
      {createSheetUploadStatus === createSheetUploadStatusEnum.LOADING && (
        <UploadInProgress fileName={state.createSheetUploadFilename} />
      )}
    </Styles.Wrapper>
  );
};

export default AddCatalogue;
