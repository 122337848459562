import styled from 'styled-components';

export const LinkIdWrapper = styled.div`
  border: 1px solid var(--mischka);
  border-radius: 4px;
  padding: 8px;

  input {
    width: 80%;
    font-size: 14px;
    line-height: 22px;
    flex: 1;
    font-family: inherit;
    padding: 0 12px;
    outline: none;
    border: none;
  }
`;
