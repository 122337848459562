const AddDesignIllustration = () => {
  return (
    <svg
      width={248}
      height={209}
      viewBox="0 0 248 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_428_18404)">
        <path
          d="M9.10307 105.739C23.9931 94.0194 47.4131 103.979 63.9431 94.7094C73.9631 89.0894 79.1331 77.5894 81.9331 66.4394C84.7331 55.2894 85.8931 43.5794 90.9231 33.2394C95.9531 22.9094 106.343 13.9594 117.763 15.2194C126.303 16.1594 133.173 22.3894 140.943 26.0494C161.923 35.9394 187.543 26.5294 209.543 33.8594C225.723 39.2594 238.063 53.5494 243.413 69.7494C248.763 85.9494 247.703 103.769 242.923 120.149C234.113 150.339 212.493 176.569 184.533 190.979C154.373 206.529 117.873 208.059 85.5631 197.699C66.9731 191.739 49.7131 181.989 34.5631 169.719C22.4231 159.879 6.29307 146.859 1.97307 131.129C-0.496934 122.149 1.48307 111.729 9.09307 105.739H9.10307Z"
          fill="#F7F8FA"
        />
        <path
          d="M28.343 86.9397C33.123 87.9597 38.333 86.8097 42.173 83.7897C47.293 79.7797 49.603 72.5197 47.733 66.2897C44.143 54.2797 26.503 50.4397 17.923 59.5697C9.32301 68.7197 17.173 84.5697 28.343 86.9397Z"
          fill="#F7F8FA"
        />
        <path
          d="M65.4041 77.6192C69.5041 78.3392 73.8741 75.6692 75.3041 71.7592C77.9641 64.4592 69.6041 55.2692 62.1141 59.0392C54.4141 62.9192 57.2941 76.1892 65.4041 77.6192V77.6192Z"
          fill="#F7F8FA"
        />
        <path
          d="M231.996 16.5098C234.205 16.5098 235.996 14.7189 235.996 12.5098C235.996 10.3006 234.205 8.50977 231.996 8.50977C229.787 8.50977 227.996 10.3006 227.996 12.5098C227.996 14.7189 229.787 16.5098 231.996 16.5098Z"
          fill="#E8EAEE"
        />
        <path
          d="M235.806 35.6695L228.176 31.2695V40.0795L235.806 35.6695Z"
          stroke="#E8EAEE"
          strokeWidth={0.66}
          strokeMiterlimit={10}
        />
        <path
          d="M235.965 27.8896V19.8896H227.965V27.8896H235.965Z"
          fill="#E8EAEE"
        />
        <path
          d="M216.066 198.46C217.171 198.46 218.066 197.565 218.066 196.46C218.066 195.355 217.171 194.46 216.066 194.46C214.962 194.46 214.066 195.355 214.066 196.46C214.066 197.565 214.962 198.46 216.066 198.46Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M228.055 156.71V164.71"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.055 160.71H232.055"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.9453 184.56C26.1545 184.56 27.9453 182.769 27.9453 180.56C27.9453 178.35 26.1545 176.56 23.9453 176.56C21.7362 176.56 19.9453 178.35 19.9453 180.56C19.9453 182.769 21.7362 184.56 23.9453 184.56Z"
          fill="#E8EAEE"
        />
        <path
          d="M20.1367 203.72L27.7667 199.31V208.12L20.1367 203.72Z"
          stroke="#E8EAEE"
          strokeWidth={0.66}
          strokeMiterlimit={10}
        />
        <path
          d="M27.9766 195.93V187.93H19.9766V195.93H27.9766Z"
          fill="#E8EAEE"
        />
        <path
          d="M40.0664 8.80957C42.2755 8.80957 44.0664 7.01871 44.0664 4.80957C44.0664 2.60043 42.2755 0.80957 40.0664 0.80957C37.8573 0.80957 36.0664 2.60043 36.0664 4.80957C36.0664 7.01871 37.8573 8.80957 40.0664 8.80957Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.957 22.4795C25.0616 22.4795 25.957 21.5841 25.957 20.4795C25.957 19.3749 25.0616 18.4795 23.957 18.4795C22.8525 18.4795 21.957 19.3749 21.957 20.4795C21.957 21.5841 22.8525 22.4795 23.957 22.4795Z"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.0742 47.6094L88.6342 55.1794"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.4609 52.7196L92.0909 43.8896"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.7927 57.63L76.9727 57"
          stroke="#FF305D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M126.346 19.8096C125.076 20.3696 123.896 21.1096 122.876 22.0696C118.586 26.1396 119.656 31.6396 121.586 34.5196C122.656 36.1096 124.416 37.1296 126.266 37.6596C128.116 38.1896 130.046 38.2796 131.956 38.3696C139.516 38.7196 147.076 37.8696 154.626 38.3996C168.916 39.4096 176.876 41.5496 187.836 45.2296C196.366 48.0996 204.656 52.8896 209.466 60.4996C214.276 68.1096 214.726 78.9096 208.766 85.6596C205.116 89.7896 198.806 91.9296 193.866 89.4996C188.926 87.0696 187.086 79.3696 191.246 75.7496C195.656 71.9096 203.306 75.2696 205.256 80.7896C207.206 86.2996 204.896 92.4796 201.466 97.2096C192.516 109.55 176.596 115.2 161.356 115.72C146.116 116.24 131.126 112.37 116.206 109.19C101.286 106.01 85.7857 103.5 70.8857 106.71C63.3457 108.33 55.8957 111.57 50.7757 117.33C45.6557 123.09 43.3257 131.72 46.3957 138.79C49.9757 147.05 60.9857 151.1 69.0657 147.13C77.1457 143.16 80.6657 131.96 76.3057 124.08C74.8357 121.43 72.5557 119.14 69.6957 118.13C66.8357 117.12 63.3957 117.56 61.1757 119.63C58.6357 122 58.1557 125.99 59.2157 129.3C60.2857 132.6 62.6357 135.33 65.1257 137.74C73.7757 146.1 84.9357 151.85 96.7657 154.03"
          stroke="#FFA100"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M91.1641 166.23L91.2341 59.4198C91.2341 58.1598 92.2541 57.1298 93.5141 57.1098L149.044 56.5098C149.694 56.5098 150.304 56.7698 150.754 57.2398L174.014 81.9498C174.414 82.3798 174.644 82.9398 174.644 83.5298L174.784 166.23C174.784 167.54 173.694 168.6 172.374 168.54H93.3841C92.1441 168.49 91.1741 167.47 91.1741 166.23H91.1641Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M149.544 59.0293L149.164 83.6593L172.714 83.3193L149.544 59.0293Z"
          fill="#E8EAEE"
        />
        <path
          d="M126.033 104.829C127.308 104.829 128.343 103.795 128.343 102.519C128.343 101.243 127.308 100.209 126.033 100.209C124.757 100.209 123.723 101.243 123.723 102.519C123.723 103.795 124.757 104.829 126.033 104.829Z"
          fill="#929BAA"
        />
        <path
          d="M139.923 104.559C141.199 104.559 142.233 103.525 142.233 102.249C142.233 100.974 141.199 99.9395 139.923 99.9395C138.648 99.9395 137.613 100.974 137.613 102.249C137.613 103.525 138.648 104.559 139.923 104.559Z"
          fill="#929BAA"
        />
        <path
          d="M128.492 109.899C128.492 109.899 132.912 106.309 137.752 109.719"
          stroke="#929BAA"
          strokeWidth={0.95}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M101.992 133.63H166.562"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M101.992 140.67H166.562"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M101.992 147.7H166.562"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M101.992 154.729H166.562"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M148.068 29.4292C147.38 28.5162 146.082 28.3338 145.169 29.0218C144.256 29.7098 144.073 31.0077 144.761 31.9208L153.361 43.3333C154.049 44.2463 155.347 44.4287 156.26 43.7407C157.173 43.0527 157.356 41.7548 156.668 40.8417L148.068 29.4292Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M141.956 34.1287C149.35 32.2163 153.795 24.6713 151.882 17.2765C149.97 9.88166 142.425 5.43732 135.03 7.34975C127.635 9.26217 123.191 16.8072 125.103 24.202C127.016 31.5968 134.561 36.0411 141.956 34.1287Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M138.493 31.3801C144.364 31.3801 149.123 26.6209 149.123 20.7501C149.123 14.8793 144.364 10.1201 138.493 10.1201C132.622 10.1201 127.863 14.8793 127.863 20.7501C127.863 26.6209 132.622 31.3801 138.493 31.3801Z"
          fill="white"
          stroke="#929BAA"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_428_18404">
          <rect
            width={246.03}
            height={208.38}
            fill="white"
            transform="translate(0.984375 0.30957)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddDesignIllustration;
