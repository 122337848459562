import DESIGN_LIBRARY_TYPES from 'actionTypes/designLibrary';
import { IDesignLibrary } from 'components/DesignLibrary/types';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import { IBrand, IReduxActionType } from 'utils/types';
import { update } from './helpers';

export interface IDesignLibraryInitialState {
  list: Array<any>;
  isLoading: boolean;
  uploadedSheetUrl: string;
  loaderStatus: boolean;
  brands: Array<IBrand>;
  designLibraryPaginationInfo: Record<string, number>;
  designLibraryData: Array<IDesignLibrary>;
  seasons: Array<string>;
  fabricType: Array<string>;
  designLibraryUrl: string;
  isSaveDesignButtonLoading: boolean;
  isSaveDesignSuccessful: boolean;
}

const initialState: IDesignLibraryInitialState = {
  list: [],
  isLoading: false,
  uploadedSheetUrl: '',
  loaderStatus: false,
  brands: [],
  designLibraryPaginationInfo: {
    count: 0,
    totalPages: 0,
    currentPage: 0,
  },
  designLibraryData: [],
  seasons: [],
  fabricType: [],
  designLibraryUrl: '',
  isSaveDesignButtonLoading: false,
  isSaveDesignSuccessful: false,
};

function designLibraryReducer(
  state: IDesignLibraryInitialState = initialState,
  action: IReduxActionType
) {
  switch (action.type) {
    case DESIGN_LIBRARY_TYPES.SET_LOADER_STATUS: {
      return update(state, { isLoading: action.payload });
    }

    case DESIGN_LIBRARY_TYPES.SET_SAVE_DESIGN_LIBRARY_SUCCESS: {
      return update(state, { isSaveDesignSuccessful: action.payload });
    }

    case DESIGN_LIBRARY_TYPES.DESIGN_LIBRARY_PAGINATION_INFO: {
      return update(state, { designLibraryPaginationInfo: action.payload });
    }

    case DESIGN_LIBRARY_TYPES.SET_DESIGN_LIBRARY_DATA: {
      const { data, mode } = action.payload;
      if (mode === OperatorEnum.Append) {
        return update(state, {
          designLibraryData: [...state.designLibraryData, ...data],
        });
      }
      return update(state, { designLibraryData: data });
    }

    case DESIGN_LIBRARY_TYPES.SET_SEASON_AND_FABRIC_DATA:
      const { data, value } = action.payload;
      return update(state, { [value]: data });

    default:
      return state;
  }
}

export default designLibraryReducer;
