import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'utils/hooks';
import Dropdown from 'components/Dropdown';
import {
  addNewSorting,
  removeEachSortingRow,
  setSorting,
  setAttributeOptionTag,
} from 'actions/ruleEngines';
import ICONOGRAPHY from 'CustomConstant/icons';
import ValueOption from './ValueOptions';
import { useSortingProperties } from '../helperHooks';
import * as Styles from './styles';

const { close: CloseIcon } = ICONOGRAPHY;

const SortingData = () => {
  const stagingData = useTypedSelector(store => store.ruleEngine.ruleStaging);

  const dispatch = useDispatch();
  const attributeNameOptions = useSortingProperties();

  function handleShowPropertyChange(
    event: ChangeEvent<HTMLInputElement>,
    identifier: string
  ) {
    const value = event.target.value;
    dispatch(setSorting(identifier, value, 'attributeName'));
    dispatch(setAttributeOptionTag('sortings', identifier, []));
  }

  function addNewRow() {
    dispatch(addNewSorting());
  }

  function handleCloseEachSorting(identifier: string) {
    if (Object.keys(stagingData.sortings).length === 1) {
      dispatch(setSorting(identifier, '', 'attributeName'));
      dispatch(setAttributeOptionTag('sortings', identifier, []));
    } else {
      dispatch(removeEachSortingRow(identifier));
    }
  }

  return (
    <Styles.Wrapper>
      <Styles.Heading className="spread">
        <Styles.SerialNoTitle>S. No.</Styles.SerialNoTitle>
        <Styles.PropertyTitle>Sorting property</Styles.PropertyTitle>
        <Styles.ValueTitle>Value</Styles.ValueTitle>
      </Styles.Heading>
      <Styles.Body>
        {Object.keys(stagingData.sortings).map((id, index) => (
          <div className="d-flex" key={id}>
            <Styles.SerialNumber className="flex-start-center">
              {index + 1}
            </Styles.SerialNumber>
            <Styles.SortingContainer>
              <Dropdown
                options={attributeNameOptions}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleShowPropertyChange(event, id)
                }
                value={stagingData.sortings[id].attributeName}
                dataname="attribute"
                hideAllOption
                dropdownClass="dropdown-class"
                dropdownBoxClass="dropdownBox-class"
                dropdownItemClass="dropdown-item-class"
                openClass="openClass"
                itemClass="itemClass"
                placeholder="Select"
              />
            </Styles.SortingContainer>
            <ValueOption identifier={id} />
            <Styles.CloseSortingIcon
              className="cursor-pointer center"
              onClick={() => handleCloseEachSorting(id)}
            >
              <CloseIcon size={24} stroke={'var(--metallic-seaweed)'} />
            </Styles.CloseSortingIcon>
          </div>
        ))}
        <Styles.SortingButton className="center pos-r" onClick={addNewRow}>
          + Add new sorting
        </Styles.SortingButton>
      </Styles.Body>
    </Styles.Wrapper>
  );
};

export default SortingData;
