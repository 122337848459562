import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { setSelectedSecondaryFilterInfo } from 'actions/catalog';
import { insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import ICONOGRAPHY from 'CustomConstant/icons';
import { FilterSlugType } from 'components/AddCatalogList/types';
import * as Styles from './styles';

const VISIBLE_FILTER_COUNT = 7;
const { 'chevron-down': CheveronDownIcon, 'chevron-up': CheveronUpIcon } =
  ICONOGRAPHY;

interface ISecondaryFilters {
  onFiltersChange(
    params: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const SecodaryFiltersView = ({ onFiltersChange }: ISecondaryFilters) => {
  const secondaryFilter = useTypedSelector(
    state => state.catalog.secondaryFilters
  );
  const secondaryFilterSelectedTag = useTypedSelector(
    state => state.catalog.secondaryFilterSelectedTag
  );
  const secondaryFilterSelectedTagOptions = useTypedSelector(
    state => state.catalog.secondaryFilterSelectedTagOptions
  );
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const selectedFiltersOptions =
    currentQueryParams[secondaryFilterSelectedTag] ?? [];
  let visibleSecondaryFilter = secondaryFilter.slice(0, VISIBLE_FILTER_COUNT);
  const [isMoreButtonOpen, moreButtonActions] = useBoolean();
  const dispatch = useDispatch();

  useEffect(() => {
    if (secondaryFilter.length === 0) {
      dispatch(setSelectedSecondaryFilterInfo('', []));
    }
    let selectedOptions: Array<any> = [];
    secondaryFilter.forEach(eachFilter => {
      if (eachFilter.key === secondaryFilterSelectedTag) {
        selectedOptions = eachFilter.options;
      }
    });
    dispatch(
      setSelectedSecondaryFilterInfo(
        secondaryFilterSelectedTag,
        selectedOptions
      )
    );
  }, [secondaryFilter]);

  function handleFilterClick(key: string, options: Array<any>) {
    if (key === secondaryFilterSelectedTag) {
      dispatch(setSelectedSecondaryFilterInfo('', []));
    } else {
      dispatch(setSelectedSecondaryFilterInfo(key, options));
    }
  }

  function handleOptionCheck(value: string) {
    const updatedSelectedOptions = insertOrRemove(
      selectedFiltersOptions,
      value
    );
    onFiltersChange({ [secondaryFilterSelectedTag]: updatedSelectedOptions });
  }

  return (
    <div>
      <Styles.SecondaryFilter
        className="align-center flex-wrap"
        isClose={secondaryFilterSelectedTag === ''}
      >
        {(isMoreButtonOpen ? secondaryFilter : visibleSecondaryFilter).map(
          eachFilter => {
            return (
              <Styles.FilterTagName
                key={eachFilter.key}
                className="center cursor-pointer"
                isSelectedFilters={
                  secondaryFilterSelectedTag === eachFilter.key
                }
                onClick={() =>
                  handleFilterClick(eachFilter.key, eachFilter.options)
                }
              >
                {eachFilter.key
                  .split('_')
                  .map(
                    (word: string) =>
                      word.charAt(0).toUpperCase() + word.slice(1)
                  )
                  .join(' ')}
                <Styles.FilterTagIcon>
                  {secondaryFilterSelectedTag === eachFilter.key ? (
                    <CheveronUpIcon size={16} />
                  ) : (
                    <CheveronDownIcon size={16} />
                  )}
                </Styles.FilterTagIcon>
              </Styles.FilterTagName>
            );
          }
        )}
        {!isMoreButtonOpen && secondaryFilter.length > VISIBLE_FILTER_COUNT && (
          <Styles.FilterMoreButton
            className="cursor-pointer"
            onClick={() => moreButtonActions.toggle()}
          >
            <span>+{secondaryFilter.length - VISIBLE_FILTER_COUNT}</span>
            <span> more</span>
          </Styles.FilterMoreButton>
        )}
      </Styles.SecondaryFilter>
      <Styles.FilterOptionContainer className="d-flex flex-wrap">
        {secondaryFilterSelectedTagOptions.map(eachOption => (
          <Styles.FilterOption key={String(eachOption.value)}>
            <Checkbox
              item={{
                value: eachOption.value,
              }}
              label={eachOption.value}
              onChange={() => handleOptionCheck(String(eachOption.value))}
              checked={selectedFiltersOptions.includes(eachOption.value)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.FilterOptionContainer>
    </div>
  );
};

export default SecodaryFiltersView;
