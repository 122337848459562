import aT from '../actionTypes/user';
import { TOKEN } from '../config';
import { ObjectType } from '../customTypes/defined';
import { setInStorage } from '../services/localStorage';
import { verifyExistingTokenApi } from '../utils/api';
import { IReduxActionType } from '../utils/types';

export const verifyExistingToken = (
  token: string,
  onSuccess: any,
  onFail: any
) => {
  return (dispatch: any) => {
    return verifyExistingTokenApi({ Token: token })
      .then(res => {
        const { status, data } = res;
        if (!status) {
          dispatch(resetUser());
          if (onFail) onFail();
        } else {
          dispatch(setAuthToken(token));
          dispatch(setAuthCredentials(data));
          if (onSuccess) onSuccess(data);
        }
      })
      .catch(err => {
        if (onFail) onFail();

        console.log('[error][onVerifyExistingToken]: ', 'Unable to verify');
        console.log('[error][onVerifyExistingToken]: ', err);
      });
  };
};

export const setAuthCredentials = (data: ObjectType) => {
  return (dispatch: (action: IReduxActionType) => void) => {
    if (data.user) {
      // set role
      const {
        name = String(),
        email = String(),
        id: userId,
        first_name,
        last_name,
        full_name,
        is_admin,
        is_brand,
        is_supplier,
        linked_supplier,
        username,
        brand,
        brand_name,
        team,
      } = data.user;
      dispatch(
        setUser({
          name,
          first_name,
          last_name,
          full_name,
          email,
          is_admin,
          is_brand,
          is_supplier,
          user_id: userId || null,
          linked_supplier,
          username,
          brand_id: brand,
          brand_name,
          team,
        })
      );
      dispatch(setUserPermissions(data.permissions));
      // dispatch(get_essential_info());
      // registerSuperPropertiesOnce();
      // const { user } = getState();
      // SentryActions.setUser(user);
      // if (is_brand) {
      //   dispatch(getBrandUserDetails());
      // }
    }
  };
};

export const setUser = (payload = {}): IReduxActionType => ({
  type: aT.SET_USER,
  payload,
});

export const setUserPermissions = (payload = {}): IReduxActionType => ({
  type: aT.SET_USER_PERMISSION,
  payload,
});

export const setAuthToken = (value: string) => {
  return (dispatch: (action: IReduxActionType) => void) => {
    setInStorage(TOKEN, value);
    dispatch(setToken(value));
  };
};

export const setToken = (payload: string): IReduxActionType => ({
  type: aT.SET_TOKEN,
  payload,
});

export const resetUser = (): IReduxActionType => {
  setInStorage(TOKEN, '');
  return {
    type: aT.RESET_USER,
  };
};
