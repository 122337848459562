import { useEffect, useRef, forwardRef } from 'react';
import { useBlockLayout, useRowSelect, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useTypedSelector } from 'utils/hooks';
import { STANDARD_PAGE_SIZE } from 'utils/constants';
import Checkbox from 'library/checkbox/checkbox';
import FashinzaIconLoader from 'library/IconLoader';
import ICONOGRAPHY from 'CustomConstant/icons';
import * as Styled from './styles';

const { 'empty-view': EmptyView } = ICONOGRAPHY;

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      dataProps={{
        ref: resolvedRef,
        ...rest,
      }}
    />
  );
});

const Table = ({
  className = '',
  columns,
  data,
  toggleAllRows = false,
  onSelectRowHeader = (productIds, isAllRowsSelected) => {},
  onSelectRow = (productId, isSelected) => {},
  hasCheckbox,
  autoResetSelectedRows = true,
  isLoading = false,
  fetchNextSetOfProducts,
  selectedRowIds = {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows,
      initialState: { selectedRowIds },
      getRowId: row => row.product_id,
    },
    useBlockLayout,
    useSticky,
    useRowSelect,

    hooks => {
      hooks.visibleColumns.push(columns => {
        let initialColumns = [...columns];

        if (hasCheckbox) {
          initialColumns = [
            {
              id: 'selection',
              sticky: 'left',
              width: 45,
              Header: row => {
                const rowProps = row.getToggleAllRowsSelectedProps();

                function onChange(event) {
                  const currentProductIds = row.data.map(
                    eachProduct => eachProduct.product_id
                  );
                  onSelectRowHeader(currentProductIds, !row.isAllRowsSelected);
                  rowProps.onChange(event);
                }

                return (
                  <Styled.CheckboxWrapper isHeader>
                    <IndeterminateCheckbox {...rowProps} onChange={onChange} />
                  </Styled.CheckboxWrapper>
                );
              },
              Cell: ({ row }) => {
                const rowSelectedProps = row.getToggleRowSelectedProps();

                function onChange(event) {
                  onSelectRow(row.values.product_id, !row.isSelected);
                  rowSelectedProps.onChange(event);
                }

                return (
                  <Styled.CheckboxWrapper>
                    <IndeterminateCheckbox
                      {...rowSelectedProps}
                      onChange={onChange}
                    />
                  </Styled.CheckboxWrapper>
                );
              },
            },
            ...initialColumns,
            // ? MIGHT COME HANDY IN FUTURE
            // {
            //   id: "edit",
            //   width: 40,
            //   Header: () => <Styled.CellHeaderWrapper />,

            //   Cell: () => {
            //     return (
            //       <Styled.CellBodyWrapper centerEverything>
            //         <Styled.EditButton>
            //           <KebabIcon />
            //         </Styled.EditButton>
            //       </Styled.CellBodyWrapper>
            //     );
            //   },
            // },
          ];
        }
        return initialColumns;
      });
    }
  );
  const isTableEmpty = rows.length === 0;
  const paginationInfo = useTypedSelector(
    store => store.catalog.paginationInfo
  );

  useEffect(() => {
    if (toggleAllRows) {
      toggleAllRowsSelected(false);
    }
  }, [toggleAllRows]);

  const observer = useRef(null);
  const lastItemRef = node => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (
          paginationInfo.count > STANDARD_PAGE_SIZE &&
          paginationInfo.currentPage < paginationInfo.totalPages
        ) {
          fetchNextSetOfProducts();
        }
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  };

  if (isTableEmpty && !isLoading) {
    return (
      <Styled.EmptyViewContainer className="full-height text-align-center justify-center">
        <EmptyView />
      </Styled.EmptyViewContainer>
    );
  }

  //
  return (
    <Styled.Table {...getTableProps()} className={className}>
      <Styled.THead>
        {headerGroups.map(headerGroup => (
          <Styled.TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Styled.TableHead {...column.getHeaderProps()}>
                {column.render('Header')}
              </Styled.TableHead>
            ))}
          </Styled.TableRow>
        ))}
      </Styled.THead>
      {isLoading && isTableEmpty ? (
        <Styled.SpinnerContainer className="center">
          <FashinzaIconLoader show />
        </Styled.SpinnerContainer>
      ) : (
        <>
          <Styled.TBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              if (index === rows.length - 1) {
                // last row of the table putting intersection observer here
                prepareRow(row);
                return (
                  <Styled.TableRow
                    {...row.getRowProps()}
                    className={`${row.isSelected ? 'selected' : ''}`}
                    ref={lastItemRef}
                  >
                    {row.cells.map(cell => (
                      <Styled.TableData
                        {...cell.getCellProps()}
                        className={`${row.isSelected ? 'selected' : ''}`}
                      >
                        {cell.render('Cell')}
                      </Styled.TableData>
                    ))}
                  </Styled.TableRow>
                );
              } else {
                prepareRow(row);
                return (
                  <Styled.TableRow
                    {...row.getRowProps()}
                    className={`${row.isSelected ? 'selected' : ''}`}
                  >
                    {row.cells.map(cell => (
                      <Styled.TableData
                        {...cell.getCellProps()}
                        className={`${row.isSelected ? 'selected' : ''}`}
                      >
                        {cell.render('Cell')}
                      </Styled.TableData>
                    ))}
                  </Styled.TableRow>
                );
              }
            })}
          </Styled.TBody>
          {isLoading && (
            <Styled.SpinnerContainer className="center">
              <FashinzaIconLoader show />
            </Styled.SpinnerContainer>
          )}
        </>
      )}
    </Styled.Table>
  );
};

export default Table;
