import { useDispatch } from 'react-redux';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { setStagingFeedbackData } from 'actions/ruleEngines';
import { insertOrRemove } from 'utils/utility/utils';
import { IModifiedFeedback } from 'components/RuleEngineCollection/types';
import * as Styles from '../styles';

interface MultiSelectTypeFeedbackProps {
  eachFeedbackData: IModifiedFeedback;
  viewMode: boolean;
}

const MultiSelectTypeFeedback = ({
  eachFeedbackData,
  viewMode = false,
}: MultiSelectTypeFeedbackProps) => {
  const eachFeedbackDataInStaging = useTypedSelector(
    store =>
      store.ruleEngine.stagingfeedbackData[eachFeedbackData.attributeName]
  );
  const dispatch = useDispatch();

  function handleChange(value: string) {
    let updatedSelectedOptions: Array<string> = [];

    if (eachFeedbackDataInStaging.attributeOptions[0] === '') {
      updatedSelectedOptions = [value];
    } else {
      updatedSelectedOptions = insertOrRemove(
        eachFeedbackDataInStaging.attributeOptions,
        value
      );
    }

    dispatch(
      setStagingFeedbackData(
        eachFeedbackData.attributeName,
        updatedSelectedOptions
      )
    );
  }

  return (
    <Styles.MultiSelectWrapper className="fit-height">
      <Styles.InputTitleLabel>
        {eachFeedbackData.attributeName}
      </Styles.InputTitleLabel>
      {viewMode
        ? eachFeedbackDataInStaging.attributeOptions.map(eachAttribute => (
            <Styles.MutliSeclectOptions
              key={eachAttribute}
              className="full-width"
            >
              {eachAttribute}
            </Styles.MutliSeclectOptions>
          ))
        : eachFeedbackData.attributeOptions.map(eachAttribute => (
            <Styles.MutliSeclectOptions
              key={eachAttribute}
              className="full-width"
            >
              <Checkbox
                item={{
                  value: eachAttribute,
                }}
                label={eachAttribute}
                onChange={() => handleChange(eachAttribute)}
                checked={eachFeedbackDataInStaging.attributeOptions.includes(
                  eachAttribute
                )}
              />
            </Styles.MutliSeclectOptions>
          ))}
    </Styles.MultiSelectWrapper>
  );
};

export default MultiSelectTypeFeedback;
