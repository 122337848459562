import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import TagComponent from 'components/TrendingModal/TagComponent';
import ClickOutside from 'components/Filters/clickOutside/ClickOutside';
import DateInput from 'components/DateSelector';
import {
  RemoveAttributeOptionTag,
  setAttributeOption,
  setAttributeOptionTag,
} from 'actions/ruleEngines';
import DropDownBar from './DropDownBar';
import MultiDropDownAttribute from './MultidropdownAttributes';
import { getDataType } from '../helpers';
import { ToggleAttributeEventType } from '../type';
import * as Styles from './styles';

interface IAttributeOptionsProps {
  identifier: string;
}

const AttributeOption = ({ identifier }: IAttributeOptionsProps) => {
  const eachCondition = useTypedSelector(
    store => store.ruleEngine.ruleStaging.condition[identifier]
  );
  const conditionsData = useTypedSelector(
    store => store.ruleEngine.conditionsData
  );
  const dispatch = useDispatch();
  const [isAttributeOptionOpen, attributeOptionAction] = useBoolean();
  const [isCalendarOpen, calendarActions] = useBoolean();

  function handleIntegerChange(
    event: ChangeEvent<HTMLInputElement>,
    identifier: string
  ) {
    dispatch(setAttributeOption(identifier, [parseInt(event.target.value)]));
  }

  function handleFloatChange(
    event: ChangeEvent<HTMLInputElement>,
    identifier: string
  ) {
    dispatch(setAttributeOption(identifier, [parseFloat(event.target.value)]));
  }

  function onTagAddition(tag: Tag) {
    let productIds = tag.name;
    if (productIds.startsWith(',')) {
      productIds = productIds.slice(1);
    }
    if (productIds.endsWith(',')) {
      productIds = productIds.slice(0, productIds.length - 1);
    }
    const newTags: Array<string> = productIds.split(',');
    const newCommaSeparatedTagsMap = new Map<string, Tag>();
    newTags.forEach(eachTag => {
      const tagObj = {
        id: eachTag.trim(),
        name: eachTag.trim(),
      };
      newCommaSeparatedTagsMap.set(eachTag, tagObj);
    });
    dispatch(
      setAttributeOptionTag('condition', identifier, [
        ...eachCondition.attributeOptions,
        ...Array.from(newCommaSeparatedTagsMap.values()),
      ])
    );
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = eachCondition.attributeOptions[tagIndex];
    dispatch(RemoveAttributeOptionTag('condition', identifier, tagToDelete));
  }

  function toggleAttributeOptions(event: ToggleAttributeEventType) {
    attributeOptionAction.toggle();
  }

  function handleDateChange(identifier: string, day: string) {
    dispatch(setAttributeOption(identifier, [new Date(day).getTime()]));
  }

  return (
    <Styles.AttributeContainer>
      {getDataType(conditionsData, eachCondition.attributeName) ===
        'integer' && (
        <Styles.InputAttributeOptions>
          <input
            type="number"
            min="1"
            max="20"
            step="1"
            value={String(eachCondition.attributeOptions[0])}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleIntegerChange(event, identifier)
            }
            placeholder="Type attribute"
            disabled={eachCondition.attributeName === ''}
          />
        </Styles.InputAttributeOptions>
      )}
      {getDataType(conditionsData, eachCondition.attributeName) === 'float' &&
        eachCondition.attributeName !== 'price' &&
        eachCondition.attributeName !== 'created_on_timestamp' && (
          <Styles.InputAttributeOptions>
            <input
              type="number"
              step="0.1"
              min="0"
              max="20"
              value={String(eachCondition.attributeOptions[0])}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFloatChange(event, identifier)
              }
              placeholder="Type attribute"
              disabled={eachCondition.attributeName === ''}
            />
          </Styles.InputAttributeOptions>
        )}

      {eachCondition.attributeName !== 'product_id' &&
        getDataType(conditionsData, eachCondition.attributeName) ===
          'array' && (
          <Styles.MutliDropdown className="v-justify-center pos-r">
            <DropDownBar
              value={eachCondition.attributeOptions}
              isDropDownOpen={isAttributeOptionOpen}
              toggleDropDown={toggleAttributeOptions}
              placeholder="Select Attribute Options"
              isDisabled={eachCondition.attributeName === ''}
            />
            {isAttributeOptionOpen && (
              <ClickOutside
                onClose={attributeOptionAction.off}
                disableClickHandler={false}
              >
                <MultiDropDownAttribute identifier={identifier} />
              </ClickOutside>
            )}
          </Styles.MutliDropdown>
        )}

      {eachCondition.attributeName === 'product_id' && (
        <Styles.ReactTagsWrappers>
          <ReactTags
            tags={eachCondition.attributeOptions}
            tagComponent={TagComponent}
            onAddition={onTagAddition}
            onDelete={onTagDeletion}
            addOnBlur
            allowNew
            placeholderText="PRODUCT ID"
          />
        </Styles.ReactTagsWrappers>
      )}

      {eachCondition.attributeName === 'price' && (
        <Styles.Input className="d-flex">
          <Styles.CurrencySymbol>$</Styles.CurrencySymbol>
          <Styles.InputPrice
            className="full-width"
            type="number"
            value={String(eachCondition.attributeOptions[0])}
            placeholder="Price"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleFloatChange(event, identifier)
            }
          />
        </Styles.Input>
      )}
      {eachCondition.attributeName === 'created_on_timestamp' && (
        <DateInput
          isOpen={isCalendarOpen}
          setIsCalendarOpen={calendarActions}
          handleDayClick={day =>
            handleDateChange(identifier, day.toISOString())
          }
          dateValue={
            eachCondition.attributeOptions[0]
              ? new Date(Number(eachCondition.attributeOptions[0]))
              : ''
          }
          disabledAfter={new Date()}
        />
      )}
    </Styles.AttributeContainer>
  );
};
export default AttributeOption;
