import FileUpload from '../FileUpload';
import Dropdown from '../Dropdown';
import { useImageLink } from './helperHook';
import { useTypedSelector } from '../../utils/hooks';
import * as Styles from './styles';

const SupplierFilter = () => {
  const {
    previouslyUploadedImages,
    handleChange,
    uploadedImages,
    failedImages,
    supplierFilter,
  } = useImageLink();

  const allSuppliers = useTypedSelector(
    store => store.catalog.filters.supplier
  );
  const isSupplier = useTypedSelector(store => store.user.is_supplier);

  return (
    <Styles.FilterWrapper>
      <FileUpload
        onChange={handleChange}
        multiple
        className="add-image-button"
        disabled={
          supplierFilter.selectedSupplier === '' ||
          (previouslyUploadedImages.length === 0 &&
            uploadedImages.length === 0 &&
            failedImages.length === 0)
        }
      >
        Add Image
      </FileUpload>
      {!isSupplier && (
        <Dropdown
          options={allSuppliers}
          onChange={supplierFilter.handleSupplierChange}
          value={supplierFilter.selectedSupplier}
          dataname={'type'}
          datalabel="Select Supplier"
          dropdownClass="dropdown-class"
          dropdownBoxClass="dropdownBox-class"
          dropdownItemClass="dropdown-item-class"
          openClass="openClass"
          itemClass="itemClass "
          isSearchBox
          hideAllOption
        />
      )}
    </Styles.FilterWrapper>
  );
};

export default SupplierFilter;
