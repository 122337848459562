import {
  CATALOG_MANAGEMENT_SERVER_URL,
  DEVICE_ID,
  SERVER_URL,
} from "../../config";
import { getAuthToken } from "../../helpers/auth";
import { IObjectType } from "../types";

export enum methodOptions {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

async function _callAPI(
  method: methodOptions,
  url: string | number,
  data: IObjectType = {},
  serverUrl: string | undefined = ""
) {
  const token = getAuthToken();
  let headers: HeadersInit = {
    "Content-Type": "application/json",
    device: DEVICE_ID,
    "abs-referer": window.location.href,
    "page-referer": document.referrer,
  };
  if (token) {
    headers = {
      ...headers,
      Authorization: `Token ${token}`,
    };
  }
  const options: RequestInit = {
    method,
    headers,
  };
  if (method !== methodOptions.GET) {
    options.body = JSON.stringify(data);
  }
  return fetch(serverUrl + url, options).then((res) => res.json());
}

export const callAPIRoot = (
  method: methodOptions,
  url: string | number,
  data: IObjectType = {}
) => {
  return _callAPI(method, url, data, SERVER_URL);
};

export const callAPI = (
  method: methodOptions,
  url: string | number,
  data: IObjectType = {}
) => {
  return _callAPI(method, url, data, CATALOG_MANAGEMENT_SERVER_URL);
};
