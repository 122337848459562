import styled from 'styled-components';

export const InputLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--rhino);
  margin-bottom: 4px;
`;

export const Input = styled.textarea<{
  inputHeight: number;
  isDisabled: boolean;
}>`
  height: ${props => `${props.inputHeight}px`};
  padding: 12px 16px;
  overflow-wrap: break-word;
  resize: none;
  border: 1px solid var(--athens-gray);
  outline: none;
  border-radius: 4px;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  color: var(--rhino);
`;

export const CharacterCount = styled.div`
  padding-top: 4px;
  p {
    line-height: 16px;
    font-size: 12px;
    color: var(--rhino);
  }
`;
