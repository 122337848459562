import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from 'library/checkbox/checkbox';
import { useTypedSelector } from 'utils/hooks';
import { insertOrRemove } from 'utils/utility/utils';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { FilterSlugType } from 'components/AddCatalogList/types';
import * as Styles from './style';

interface IGenderFilterProps {
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const GenderFilter = ({ onFilterChange }: IGenderFilterProps) => {
  const genders =
    useTypedSelector(state => state.catalog.primaryFilters.gender) ?? [];
  const { search } = useLocation();
  const currentQueryParams = getQueryParams(search);
  const [selectedGenders, setSelectedGenders] = useState<Array<string>>(
    currentQueryParams?.gender ?? []
  );

  function handleGenderCheck(gender: string) {
    const newSelectedGenders = insertOrRemove(selectedGenders, gender);
    onFilterChange({ gender: newSelectedGenders });
  }

  function handleGenderClear() {
    onFilterChange({ gender: [] });
  }

  return (
    <Styles.GenderFilterContainer className="fit-height">
      <Styles.GenderFilterHeader className="spread">
        <Styles.GenderFilterHeaderText>Gender</Styles.GenderFilterHeaderText>
        <Styles.GenderFilterHeaderClearAll onClick={handleGenderClear}>
          Clear
        </Styles.GenderFilterHeaderClearAll>
      </Styles.GenderFilterHeader>
      <Styles.GenderfilterResults>
        {genders?.map((eachGender: any) => (
          <Styles.FilterOption key={eachGender.value}>
            <Checkbox
              item={{
                value: eachGender.value,
              }}
              label={eachGender.value}
              onChange={() => handleGenderCheck(eachGender.value)}
              checked={selectedGenders.includes(eachGender.value)}
            />
          </Styles.FilterOption>
        ))}
      </Styles.GenderfilterResults>
    </Styles.GenderFilterContainer>
  );
};

export default GenderFilter;
