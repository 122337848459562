import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBoolean } from 'utils/hooks/useBoolean';
import { useTypedSelector } from 'utils/hooks';
import {
  getAllShareableCatalogs,
  getAllShareableCatalogsThunk,
} from 'actions/catalog';
import { DEFAULT_PRODUCT_IMAGE, DEFAULT_VALUE } from 'utils/defaults';
import ModifyModal from './ModifyModal';
import Table from './Table';
import { getFields } from './Fields';
import { ModifyEnum, OperatorEnum, STARTING_PAGE_NUM } from './constants';
import * as Styled from './styles';

const ShareCatalogList = () => {
  const shareableCatalogs = useTypedSelector(
    state => state.catalog.shareableCatalogs
  );
  const shareableCatalogsCount = useTypedSelector(
    state => state.catalog.shareableCatalogsCount
  );
  const brands = useTypedSelector(state => state.catalog.brands);
  const dispatch = useDispatch();
  const [isUpdateModalVisible, updateModalAction] = useBoolean();
  const [modalData, updateModalData] = useState({
    title: '',
    category: '',
    addedBy: '',
    linkIdentifier: '',
    description: '',
    imageUrl: '',
    operator: ModifyEnum.Add,
  });

  useEffect(() => {
    dispatch(getAllShareableCatalogsThunk(STARTING_PAGE_NUM));
    return () => {
      dispatch(
        getAllShareableCatalogs([], {
          operator: OperatorEnum.Modify,
        })
      );
    };
  }, []);

  function fetchNextSetOfCatalogs() {
    if (shareableCatalogs.length >= shareableCatalogsCount) return;
    dispatch(getAllShareableCatalogsThunk(1));
  }

  const columns = useMemo(() => getFields(), []);

  const sharedData = useMemo(() => {
    return shareableCatalogs.map(row => {
      function addProducts() {
        updateModalData({ ...modifiedData, operator: ModifyEnum.Add });
        updateModalAction.toggle();
      }

      function removeProducts() {
        updateModalData({ ...modifiedData, operator: ModifyEnum.Remove });
        updateModalAction.toggle();
      }

      const dropdownOptions = [
        { value: 'Add Styles', handler: addProducts },
        { value: 'Delete Styles', handler: removeProducts },
      ];

      const modifiedData = {
        title: row.title || DEFAULT_VALUE,
        imageUrl: row.image_url || DEFAULT_PRODUCT_IMAGE,
        linkIdentifier: row.link_identifier || DEFAULT_VALUE,
        description: row.description || DEFAULT_VALUE,
        category: (row.category || DEFAULT_VALUE) as string,
        addedBy: (row.added_by || DEFAULT_VALUE) as string,
        createdOn: row.created_on || DEFAULT_VALUE,
        url: row.url || DEFAULT_VALUE,
        dots: dropdownOptions,
      };

      return modifiedData;
    });
  }, [shareableCatalogs]);

  return (
    <Styled.Wrapper>
      {isUpdateModalVisible && (
        <ModifyModal
          onClose={() => updateModalAction.toggle()}
          data={modalData}
        />
      )}
      <Table
        columns={columns}
        maxWidth="100vw"
        data={sharedData}
        paginationHandler={fetchNextSetOfCatalogs}
      />
    </Styled.Wrapper>
  );
};

export default ShareCatalogList;
