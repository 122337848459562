const nsp = 'trends/';

const TRENDS_TYPES = {
  SET_LOADER_STATUS: `${nsp}SET_LOADER_STATUS`,
  SET_TAGS_DATA: `${nsp}SET_TAGS_DATA`,
  SET_RETAILER_DATA: `${nsp}SET_RETAILER_DATA`,
  SET_CHART_TAGS_DATA: `${nsp}SET_CHART_TAGS_DATA`,
  SET_SELECTED_FILTERS: `${nsp}SET_SELECTED_FILTERS`,
  SET_CHARTS_DATA: `${nsp}SET_CHARTS_DATA`,
  SET_TRENDS_DATES: `${nsp}SET_TRENDS_DATES`,
};

export default TRENDS_TYPES;
