import { combineReducers } from 'redux';

import catalogReducer from '../reducers/catalogReducer';
import userReducer from '../reducers/userReducer';
import awsCredentialReducer from '../reducers/credentialReducer';
import imageLinkReducer from '../reducers/imageLinkReducer';
import ruleEngineReducer from 'reducers/ruleEngineReducer';
import designLibraryReducer from 'reducers/designLibraryReducer';
import emailerReducer from 'reducers/emailerReducer';
import trendsReducer from 'reducers/trendsReducer';

export const rootReducer = combineReducers({
  user: userReducer,
  catalog: catalogReducer,
  awsCredentials: awsCredentialReducer,
  imageLinks: imageLinkReducer,
  ruleEngine: ruleEngineReducer,
  designLibrary: designLibraryReducer,
  emailer: emailerReducer,
  trends: trendsReducer,
});
