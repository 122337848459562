import { clearStorages } from './localStorage';

export const moveToParentApp = () => {
  if (window.top) window.top.postMessage('moveToParentApp', '*');
};

export const moveToCatalogAddRoute = () => {
  if (window.top) window.top.postMessage('moveToCatalogAddRoute', '*');
};

export const moveToCatalogListRoute = () => {
  if (window.top) window.top.postMessage('moveToCatalogListRoute', '*');
};

export const moveToCatalogUploadsRoute = () => {
  if (window.top) window.top.postMessage('moveToCatalogUploadsRoute', '*');
};

export const moveToCatalogCategoryRoute = () => {
  if (window.top) window.top.postMessage('moveToCatalogCategoryRoute', '*');
};

export const routeUrlsChanged = tabType => {
  window.top?.postMessage(`routeUrlsChanged/${tabType}`, '*');
};

window.onmessage = function (event) {
  if (event.data === 'userLogout') {
    clearStorages();
  }
};
